define("ntfrontend/components/users/select-user/component", ["exports", "ntfrontend/mixins/components/power-select/input"], function (_exports, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_input.default, {
    store: Ember.inject.service(),
    allowClear: false,
    includeCollaborators: true,
    includeOrigin: true,
    actions: {
      search: function search(q) {
        return this.processSearch.perform(q);
      },
      onChange: function onChange(user) {
        this.onSelectionChanged(user);
      },
      onClose: function onClose() {
        this.escapeSelect();
      }
    }
  });

  _exports.default = _default;
});