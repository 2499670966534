define("ntfrontend/components/shared/activity-log/changes/description/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MO6nEBU2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h5\"],[9],[1,[29,\"t-scope\",[[25,[\"changeName\"]],\"activityLog.fields\"],null],false],[0,\":\"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"changes\",\"old\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"text-muted\"],[9],[1,[29,\"t\",[\"activityLog.timePeriods.from\"],null],false],[0,\":\"],[10],[0,\"\\n    \"],[7,\"b\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"comment-change blockquote\"],[9],[0,\"\\n        \"],[1,[29,\"html-safe\",[[25,[\"changes\",\"old\"]]],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"text-muted\"],[9],[1,[29,\"t\",[\"activityLog.timePeriods.to\"],null],false],[0,\":\"],[10],[0,\"\\n  \"],[7,\"b\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"comment-change blockquote\"],[9],[0,\"\\n      \"],[1,[29,\"html-safe\",[[25,[\"changes\",\"new\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/activity-log/changes/description/template.hbs"
    }
  });

  _exports.default = _default;
});