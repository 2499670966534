define("ntfrontend/components/shared/confirm-remove-document/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    store: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    tagName: '',
    documentsLength: Ember.computed.alias('transportDocument.transport.transportDocuments.length'),
    isLastDocument: Ember.computed('documentsLength', function () {
      return this.documentsLength === 1;
    }),
    successCallback: function successCallback() {
      this.notify.success('notify.transport.updated');
    },
    actions: {
      openModal: function openModal() {
        this.set('showModal', true);
      },
      closeModal: function closeModal() {
        this.set('showModal', false);
      },
      removeTransportDocument: function removeTransportDocument(transportDocument) {
        var _this = this;

        return transportDocument.destroyRecord().then(function () {
          _this.notify.success('notify.transport.updated');
        });
      }
    }
  });

  _exports.default = _default;
});