define("ntfrontend/models/ability", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    actions: attr(),
    resource: belongsTo('resource', {
      polymorpic: true,
      inverse: null
    })
  });

  _exports.default = _default;
});