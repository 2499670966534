define("ntfrontend/components/create-page/tabs-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fyd8JNCV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[23,\"iconClassName\"]],[9],[0,\"\\n  \"],[7,\"svg\"],[11,\"viewBox\",\"0 0 50 50\"],[11,\"class\",\"create__item-puzzle\"],[9],[0,\"\\n    \"],[7,\"path\"],[11,\"d\",\"M6.254 32.624c4.075 4.078-1.142 3.753-4.17 6.468l-.046.041a6.25 6.25 0 1 0 8.828 8.828c.014-.016.029-.03.041-.045 2.717-3.03 2.39-8.249 6.472-4.169l5.289 5.289a3.306 3.306 0 0 0 4.66 0l5.288-5.287c4.085-4.085 3.757.469 6.475 3.498.014.016.029.03.041.046a6.25 6.25 0 1 0 8.828-8.828l-.045-.04c-3.03-2.718-8.25-1.725-4.167-5.808l5.289-5.289a3.306 3.306 0 0 0 0-4.66l-5.288-5.288c-4.083-4.083 1.138-3.754 4.167-6.473.016-.014.03-.028.046-.04a6.25 6.25 0 1 0-8.828-8.828c-.014.015-.029.029-.041.045-2.717 3.03-2.39 8.248-6.472 4.168l-5.29-5.29a3.306 3.306 0 0 0-4.661 0l-6.144 6.143c-2.449 3.147 2.189 3.072 4.976 5.57l.046.041a6.25 6.25 0 1 1-8.828 8.828c-.015-.015-.029-.03-.041-.045-2.5-2.786-2.424-7.423-5.57-4.977L.965 22.666a3.306 3.306 0 0 0 0 4.66l5.289 5.298z\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"create__item-text\"],[9],[0,\"\\n  \"],[7,\"h2\"],[11,\"class\",\"create__item-name\"],[9],[1,[29,\"t\",[[25,[\"resourceName\"]]],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"create__item-description\"],[9],[1,[29,\"t\",[[25,[\"resourceDescription\"]]],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/create-page/tabs-item/template.hbs"
    }
  });

  _exports.default = _default;
});