define("ntfrontend/components/custom-fields/custom-values/components/richtext/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8dAcFhsK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"col-xs-4 label--top\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"text-muted\"],[9],[1,[25,[\"customValue\",\"name\"]],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"col-xs-8\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"enablePreview\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"wysiwyg-preview\"],[9],[0,\"\\n      \"],[5,\"shared/inplace-editor\",[],[[\"@value\",\"@afterChange\",\"@shortenValue\",\"@editable\",\"@placeholderKey\"],[[25,[\"customValue\",\"value\"]],[29,\"action\",[[24,0,[]],\"saveCustomValue\"],null],[25,[\"customValue\",\"preview\"]],[23,\"editable\"],\"organization.customField.placeholders.setValue\"]]],[0,\"\\n    \"],[10],[0,\"\\n    \"],[5,\"custom-fields/custom-values/components/richtext/modal\",[],[[\"@model\",\"@afterChange\",\"@allowEditing\"],[[23,\"customValue\"],[29,\"action\",[[24,0,[]],\"saveCustomValue\"],null],[23,\"editable\"]]],{\"statements\":[[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"show-more\"],[11,\"type\",\"button\"],[9],[1,[29,\"t\",[\"shared.labels.showMore\"],null],false],[10],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"shared/inplace-editor\",[],[[\"@value\",\"@afterChange\",\"@shortenValue\",\"@editable\",\"@placeholderKey\"],[[25,[\"customValue\",\"value\"]],[29,\"action\",[[24,0,[]],\"saveCustomValue\"],null],[25,[\"customValue\",\"preview\"]],[23,\"editable\"],\"organization.customField.placeholders.setValue\"]]],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/custom-fields/custom-values/components/richtext/template.hbs"
    }
  });

  _exports.default = _default;
});