define("ntfrontend/components/projects/dashboard/state-status/modal/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.reopen({
        description: Ember.computed.alias("model.".concat(this.property, "Description"))
      });
    },
    actions: {
      onClose: function onClose() {
        this.model.rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});