define("ntfrontend/components/work-logs/kanban-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    todayDate: new Date(),
    oldStartDate: '',
    oldEndDate: '',
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.setKanbanViewport();
    },
    setKanbanViewport: Ember.observer('document.readyState', function () {
      if (this._dateWasChanged()) {
        var kanban = document.querySelector('.kanban-grid__wrapper');
        var todayColumn = document.querySelector('.today-column');

        if (kanban && todayColumn) {
          return kanban.scrollLeft = todayColumn.offsetLeft - kanban.offsetWidth / 2 + 100;
        }

        if (kanban && !todayColumn) {
          return kanban.scrollLeft = kanban.scrollWidth / 2 - kanban.offsetWidth / 2;
        }
      }
    }),
    boards: Ember.computed('model.length', 'datesArray', function () {
      var datesArray = this.datesArray,
          model = this.model,
          _isTheSameDay = this._isTheSameDay;
      return datesArray.map(function (date) {
        return {
          date: date,
          records: model.filter(function (workLog) {
            return _isTheSameDay(workLog.workDate, date);
          })
        };
      });
    }),
    datesArray: Ember.computed('startDate', 'endDate', 'model.length', function () {
      var startDate = this.startDate,
          endDate = this.endDate,
          model = this.model;

      if (!startDate && !endDate) {
        var allDates = model.map(function (workLog) {
          return workLog.workDate;
        });
        startDate = new Date(Math.min.apply(null, allDates)).setHours(0, 0, 0, 0);
        endDate = new Date(Math.max.apply(null, allDates)).setHours(23, 59, 59, 99);
      }

      var diff = moment(new Date(startDate)).diff(new Date(endDate), 'days');
      var daysDifference = Math.abs(diff) + 1;
      var daysArray = Array.from({
        length: daysDifference
      });
      return daysArray.map(function (_, idx) {
        return moment(new Date(startDate)).add(idx, 'days');
      });
    }),
    actions: {
      updateWorkDate: function updateWorkDate(board, workLog) {
        var _this = this;

        if (!this._isTheSameDay(workLog.workDate, board.date)) {
          workLog.set('workDate', board.date);
          workLog.save().then(function () {
            _this.notifyPropertyChange('boards');

            _this.notify.success('notify.default.saved');
          });
        }
      }
    },
    _dateWasChanged: function _dateWasChanged() {
      if (this.startDate != this.oldStartDate || this.endDate != this.oldEndDate) {
        this.set('oldStartDate', this.startDate);
        this.set('oldEndDate', this.endDate);
        return true;
      }
    },
    _isTheSameDay: function _isTheSameDay(firstDate, secondDate) {
      return moment(firstDate).isSame(secondDate, 'day');
    }
  });

  _exports.default = _default;
});