define("ntfrontend/components/tasks/task-details/component", ["exports", "ntfrontend/mixins/components/model-saving", "ntfrontend/mixins/components/label-editing"], function (_exports, _modelSaving, _labelEditing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, _labelEditing.default, {
    router: Ember.inject.service(),
    notify: Ember.inject.service('notify-alert'),
    taskDistributionNotifier: Ember.inject.service(),
    fileUpload: Ember.inject.service(),
    classNames: 'page-content--task',
    successCallback: function successCallback() {
      this.taskDistributionNotifier.notify(this.get('model.members'));
    },
    actions: {
      refreshMembers: function refreshMembers() {
        this.taskDistributionNotifier.notifyWithReloadMembers(this.get('model.members'));
      },
      afterRemove: function afterRemove() {
        this.notify.success('notify.task.removed');
        var urlToRedirect = this.router.urlFor(this.documentRoute, this.get('model.document.slug'));
        this.router.transitionTo(urlToRedirect);
      },
      addAttachments: function addAttachments(files) {
        var _this = this;

        this.fileUpload.uploadAttachmentsFor(files, this.model).then(function () {
          _this.successCallback();
        });
      }
    }
  });

  _exports.default = _default;
});