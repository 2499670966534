define("ntfrontend/mixins/components/reference-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    relationshipsObserver: Ember.observer('model.tasks.length', function () {
      var tasksCount = this.get('model.tasks.length');

      if (this.get('model.tasks.content.canonicalState') !== tasksCount) {
        this._refreshTasksRelationship();
      }

      this._refreshAbility(tasksCount);
    }),
    _refreshTasksRelationship: function _refreshTasksRelationship() {
      var _this = this;

      Ember.run.once(this, function () {
        _this.model.hasMany('tasks').reload();
      });
    },
    _refreshAbility: function _refreshAbility(tasksCount) {
      if (tasksCount === 0) {
        this.model.belongsTo('ability').reload();
      }
    }
  });

  _exports.default = _default;
});