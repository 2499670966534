define("ntfrontend/components/documents/meta-data/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FxUu1XGH",
    "block": "{\"symbols\":[\"body\"],\"statements\":[[5,\"shared/labeled-row\",[],[[\"@label\",\"@labelClass\"],[\"shared.labels.creator\",[23,\"labelClass\"]]],{\"statements\":[[0,\"\\n  \"],[1,[25,[\"model\",\"creator\",\"displayName\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"shared/labeled-row\",[],[[\"@label\",\"@labelClass\"],[\"shared.labels.createdAt\",[23,\"labelClass\"]]],{\"statements\":[[0,\"\\n  \"],[1,[29,\"format-date\",[[25,[\"model\",\"createdAt\"]]],[[\"format\"],[\"long\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"shared/labeled-row\",[],[[\"@label\",\"@labelClass\"],[\"shared.labels.documentId\",[23,\"labelClass\"]]],{\"statements\":[[0,\"\\n  \"],[5,\"shared/copy-to-clipboard\",[],[[\"@property\"],[[24,0,[\"linkUrl\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"document-id-wrapper\"],[9],[0,\"\\n    \"],[1,[29,\"document-id\",[[25,[\"model\"]]],[[\"short\",\"allowSlug\"],[true,true]]],false],[0,\"\\n    \"],[6,[24,1,[\"toCopy\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"icon-copy icon-hover-animation\"],[9],[10],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[10],[0,\"\\n  \"],[6,[24,1,[\"copied\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[29,\"t\",[\"shared.copy\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/documents/meta-data/template.hbs"
    }
  });

  _exports.default = _default;
});