define("ntfrontend/components/documents/documents-list/inplace-start-at/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    can: Ember.inject.service(),
    classNames: ['picker--small', 'table-input'],
    init: function init() {
      this._super.apply(this, arguments);

      var startAt = this.model.startAt || new Date();
      this.set('initialValue', startAt);
    },
    actions: {
      afterChange: function afterChange() {
        var initialValue = this.initialValue,
            saveModelTask = this.saveModelTask,
            startAt = this.model.startAt;
        var dateDidChange = initialValue.getTime() !== startAt.getTime();

        if (dateDidChange) {
          this.set('initialValue', startAt);
          saveModelTask.perform();
        }
      }
    }
  });

  _exports.default = _default;
});