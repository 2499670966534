define("ntfrontend/routes/account/users/new", ["exports", "ntfrontend/routes/org-auth", "ntfrontend/mixins/routes/ember-can", "ntfrontend/helpers/model-type-name"], function (_exports, _orgAuth, _emberCan, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend(_emberCan.default, {
    notify: Ember.inject.service('notify-alert'),
    requireAbilities: Ember.computed('abilities', function () {
      this.set('abilities', {
        'create members in account': this.get('currentSession.account')
      });
    }),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      this.set('resource', this.get('currentSession.account'));
    },
    model: function model() {
      return Ember.RSVP.hash({
        roles: this.roles,
        resource: this.resource
      });
    },
    roles: Ember.computed(function () {
      var data = {
        filter: {
          resource: (0, _modelTypeName.modelTypeName)([this.resource])
        }
      };
      return this.store.query('role', data);
    })
  });

  _exports.default = _default;
});