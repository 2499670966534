define("ntfrontend/mixins/controllers/pagination-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      changePage: function changePage(pageObject) {
        this.setProperties({
          page: pageObject.number,
          perPage: pageObject.size
        });
      }
    },
    setFirstPage: function setFirstPage(_ref) {
      var changed = _ref.changed;

      if (this._queryHasChanged(changed)) {
        this.set('page', 1);
      }

      this.set('previousQuery', changed);
    },
    _queryHasChanged: function _queryHasChanged(changed) {
      var _this = this;

      return Object.keys(changed).length === 0 || Object.keys(changed).filter(function (change) {
        return change !== 'page' && change !== _this.previousQuery;
      }).length > 0;
    }
  });

  _exports.default = _default;
});