define("ntfrontend/mixins/components/inplace/editable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    classNameBindings: ['editable:inplace-editing'],
    placeholderKey: '',
    editable: true,
    showField: Ember.computed.or('value', 'editable'),
    actions: {
      toggleEditing: function toggleEditing() {
        if (this.editable) {
          this.toggleProperty('isEditing');
        }
      },
      doneEditing: function doneEditing() {
        var afterChange = this.afterChange;
        this.set('isEditing', false);

        if (afterChange) {
          afterChange();
        }
      }
    }
  });

  _exports.default = _default;
});