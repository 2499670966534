define("ntfrontend/components/shared/search-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mFAl4ASK",
    "block": "{\"symbols\":[\"items\"],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"dropdown-toggle \",[29,\"if\",[[25,[\"disabled\"]],\"disabled\"],null]]]],[11,\"data-toggle\",\"dropdown\"],[11,\"aria-haspopup\",\"true\"],[11,\"aria-expanded\",\"true\"],[11,\"type\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"value\"]]],null,{\"statements\":[[0,\"    \"],[1,[23,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"placeholder\"],[9],[1,[29,\"t\",[[25,[\"placeholderKey\"]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"shownDropdown\"]]],null,{\"statements\":[[0,\"  \"],[5,\"search-box/dropdown-menu\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"li\"],[11,\"class\",\"search-wrapper\"],[9],[0,\"\\n      \"],[5,\"search-box/input\",[],[[\"@class\",\"@value\",\"@placeholder\",\"@input\"],[\"search-input\",[23,\"searchQuery\"],[29,\"t\",[\"shared.placeholders.search\"],null],[29,\"action\",[[24,0,[]],\"search\"],null]]]],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[6,[24,1,[\"results\"]],[],[[\"@loaded\",\"@searchResults\",\"@onChoose\"],[[25,[\"findItems\",\"isIdle\"]],[23,\"results\"],[29,\"action\",[[24,0,[]],\"chooseItem\"],null]]]],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/search-select/template.hbs"
    }
  });

  _exports.default = _default;
});