define("ntfrontend/mixins/components/inplace/placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    placeholderKey: '',
    placeholder: Ember.computed('placeholderKey', function () {
      if (this.placeholderKey) {
        return this.intl.t(this.placeholderKey);
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});