define("ntfrontend/components/navbar-menu/navbar-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SnJDdF/0",
    "block": "{\"symbols\":[\"component\",\"route\"],\"statements\":[[4,\"if\",[[25,[\"currentSession\",\"loggedIn\"]]],null,{\"statements\":[[4,\"if\",[[25,[\"breadCrumbReady\"]]],null,{\"statements\":[[0,\"    \"],[5,\"bread-crumbs\",[],[[\"@tagName\",\"@class\",\"@linkable\"],[\"div\",\"breadcrumb\",true]],{\"statements\":[[0,\"\\n      \"],[5,\"bread-crumb\",[],[[\"@route\",\"@breadCrumbs\",\"@tagName\",\"@classNames\"],[[24,2,[]],[24,1,[]],\"span\",\"navbar__link navbar__arrow\"]]],[0,\"\\n    \"]],\"parameters\":[1,2]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"img\"],[11,\"src\",\"/assets/images/9teams-logo-white.png\"],[12,\"alt\",[29,\"t\",[\"shared.labels.9teamsLogo\"],null]],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/navbar-menu/navbar-header/template.hbs"
    }
  });

  _exports.default = _default;
});