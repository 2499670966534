define("ntfrontend/components/documents/details/project-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t1LAe2sC",
    "block": "{\"symbols\":[\"escapeSelect\"],\"statements\":[[5,\"shared/labeled-row\",[],[[\"@label\"],[\"shared.labels.project\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,[\"editable\"]]],null,{\"statements\":[[0,\"    \"],[5,\"shared/inplace-select\",[],[[\"@value\",\"@type\",\"@placeholder\"],[[25,[\"model\",\"project\",\"id\"]],\"project\",\"shared.labels.setProject\"]],{\"statements\":[[0,\"\\n      \"],[5,\"shared/select-resource\",[],[[\"@allowClear\",\"@scope\",\"@type\",\"@value\",\"@onSelectionChanged\",\"@escapeSelect\"],[\"false\",[25,[\"model\",\"project\",\"account\"]],\"project\",[25,[\"model\",\"project\"]],[29,\"action\",[[24,0,[]],\"changeProject\"],null],[24,1,[]]]]],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,[\"model\",\"project\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/documents/details/project-select/template.hbs"
    }
  });

  _exports.default = _default;
});