define("ntfrontend/routes/projects/project/scenarios/new", ["exports", "ntfrontend/routes/org-auth", "ntfrontend/mixins/routes/ember-can"], function (_exports, _orgAuth, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend(_emberCan.default, {
    requireAbilities: Ember.computed('abilities', function () {
      this.set('abilities', {
        'create scenarios in project': this.modelFor('projects.project')
      });
    })
  });

  _exports.default = _default;
});