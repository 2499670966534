define("ntfrontend/components/navbar-menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentSession: Ember.inject.service(),
    tagName: 'nav',
    classNames: ['navbar'],
    classNameBindings: ['environment'],
    usingIE10:
    /*@cc_on!@*/
    false && document.documentMode <= 10,
    // eslint-disable-line
    account: Ember.computed.alias('currentSession.account'),
    user: Ember.computed.alias('currentSession.user'),
    environment: Ember.computed.readOnly('urlChecker.environment'),
    renderReminder: Ember.computed('currentSession.loggedIn', 'account', 'user', function () {
      return this.get('account.isLoaded') && this.get('user.isLoaded') && this.get('currentSession.loggedIn');
    })
  });

  _exports.default = _default;
});