define("ntfrontend/components/pagination-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'nav',
    totalRecords: Ember.computed.alias('model.meta.total'),
    perPage: 25,
    totalPages: Ember.computed('totalRecords', function () {
      return Math.ceil(this.totalRecords / this.perPage);
    }),
    moreThanOnePage: Ember.computed('totalPages', function () {
      return this.totalPages > 1;
    }),
    pages: Ember.computed('totalPages', function () {
      return this._createRange(1, this.totalPages).map(function (value) {
        return value;
      });
    }),
    pagesButtons: Ember.computed('pages', 'currentPage', function () {
      var index = this.currentPage - 4;

      var sliceIndex = this._sliceIndex(index);

      return this.pages.slice(sliceIndex, sliceIndex + 7);
    }),
    _sliceIndex: function _sliceIndex(index) {
      if (index < 0) {
        return 0;
      } else if (index > this.totalPages - 7) {
        return this.totalPages - 7;
      } else {
        return index;
      }
    },
    _createRange: function _createRange(start, end) {
      var list = [];

      for (var i = start; i <= end; i++) {
        list.push(i);
      }

      return list;
    },
    actions: {
      changePageNumber: function changePageNumber(number) {
        var page = {
          number: number,
          size: this.perPage
        };
        this.changePage(page);
      }
    }
  });

  _exports.default = _default;
});