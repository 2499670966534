define("ntfrontend/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "activityLog": {
      "actions": {
        "addComment": "Added comment",
        "create": "Created",
        "update": "Updated"
      },
      "fields": {
        "completed": "Status",
        "deadlineAt": "Due date",
        "description": "Description",
        "name": "Title",
        "ownerId": "Owner"
      },
      "labels": {
        "activityLog": "Activity log",
        "noActivity": "No activity logs"
      },
      "status": {
        "f": "Todo",
        "t": "Done"
      },
      "timePeriods": {
        "from": "From",
        "to": "To"
      }
    },
    "auth": {
      "agreeTo9teams": "I agree to 9teams",
      "currentPassword": {
        "placeholder": "Current Password"
      },
      "email": {
        "placeholder": "Enter email",
        "text": "Email"
      },
      "forgottenPasswordPrompt": "Forgot your password?",
      "generalTerms": "General Terms and Conditions",
      "identity": {
        "confirm": "I am {name}",
        "question": "Please confirm you are {name}.",
        "reject": "I am not {name}",
        "title": "Confirm your identity"
      },
      "logout": "Logout",
      "magicLink": {
        "or": "or",
        "send": "Send magic link",
        "tooltip": "Send an email to your inbox with a valid login link."
      },
      "name": {
        "placeholder": "Enter full name",
        "text": "Name"
      },
      "organization": {
        "placeholder": "Your organization"
      },
      "password": {
        "mismatchPassword": "Passwords don't match",
        "new": {
          "placeholder": "Enter new password"
        },
        "placeholder": "Enter password",
        "setYourPassword": "Set your password",
        "showPassword": "Show password",
        "text": "Password",
        "wrongCredentials": "Wrong credentials"
      },
      "passwordRequired": "This action requires your current password",
      "passwordReset": "Reset my password",
      "phoneNumber": {
        "placeholder": "Enter phone number",
        "text": "Phone number"
      },
      "rePassword": {
        "new": {
          "placeholder": "Confirm new password"
        },
        "placeholder": "Confirm password",
        "text": "Password confirmation"
      },
      "rememberMe": "Remember me",
      "resendActiation": "Resend activation mail",
      "resendInstructionsPrompt": "Didn't receive confirmation instructions?",
      "setNewPasswords": "Set your new password",
      "signIn": "Sign In",
      "signUp": "Sign up",
      "welcome": {
        "instruction": "Please check your mailbox to get started.",
        "message": "Thank you for registering with 9teams. Your account has been created successfully!",
        "title": "Hi {name}"
      }
    },
    "comment": {
      "actions": {
        "create": "Add",
        "update": "Update comment"
      },
      "labels": {
        "comments": "Comments",
        "createdAt": "Created",
        "updatedAt": "Updated"
      },
      "placeholders": {
        "addComment": "Add new comment",
        "editComment": "Edit Comment"
      }
    },
    "createPage": {
      "actions": {
        "addWorkLog": "Add your work log",
        "chooseTemplate": "Choose template",
        "selectProject": "Select project",
        "selectProjectTemplate": "Select project template"
      },
      "descriptions": {
        "issue": "Streamline the process of identifying and resolving issues – otherwise known as problems, gaps, inconsistencies or conflicts",
        "memo": "Document and share meeting minutes or action plans",
        "project": "Keep everything together in one workspace",
        "projectExtended": "Keep everything together in one workspace. By creating projects you can easily group your documents and stay organized.",
        "request": "Ensure efficient, transparent and prompt handling of all changes that are being requested",
        "scenario": "Guarantee full transparency on test scenarios, migration plans and repetitive procedures",
        "user": "Create collaborators, managers and admins",
        "work": "Add your log to remain on track with all of your projects."
      },
      "labels": {
        "status": "Status"
      },
      "templates": {
        "empty": "Empty",
        "noTemplateDescriptionDocument": "I don’t want to use any template for this document.",
        "noTemplateDescriptionProject": "I don’t want to use any template for this project.",
        "noTemplateName": "No template"
      }
    },
    "dashboard": {
      "actions": {
        "edit": "Edit",
        "updateStatus": "Update status"
      },
      "labels": {
        "overallStatus": "Overall status"
      },
      "milestone": {
        "actions": {
          "create": "Add milestone"
        },
        "labels": {
          "createMilestone": "Create milestone",
          "editMilestone": "Edit milestone"
        },
        "placeholders": {
          "deadlineAt": "Specify deadline date for milestone",
          "description": "Add description",
          "name": "Add name",
          "startAt": "Specify start date for milestone"
        }
      },
      "placeholders": {
        "description": "Specify status comment"
      },
      "workPackage": {
        "actions": {
          "create": "Add work package"
        },
        "labels": {
          "createWorkPackage": "Create work package",
          "editWorkPackage": "Edit work package",
          "unassigned": "Unassigned"
        },
        "placeholders": {
          "description": "Add description",
          "name": "Add name"
        }
      }
    },
    "document": {
      "actions": {
        "archive": "Archive",
        "createFollowup": "Create followup",
        "duplicate": "Duplicate",
        "importTemplate": "Import template",
        "share": "Share",
        "unarchive": "Unarchive"
      },
      "alts": {
        "noDocumentIcon": "No document icon",
        "noStepsIcon": "No document icon",
        "noTasksIcon": "No tasks icon",
        "noTopicsIcon": "No topics icon"
      },
      "labels": {
        "addFollowers": "Add followers",
        "archiveParentMemo": "Archive parent meeting",
        "archived": "Archived",
        "changeOwner": "Click to change owner",
        "changeReporter": "Click to change reporter",
        "changeRequester": "Click to change requester",
        "cloneTasks": "Duplicate tasks",
        "createdAt": "Created at",
        "description": "Memo Description",
        "details": "Details",
        "dueDate": "Due date",
        "followersList": "Followers",
        "importTemplate": "Import template",
        "membersList": "Members List",
        "owner": "Owner",
        "startAt": "Start at",
        "statusDescription": "Status Details",
        "steps": "Steps",
        "topics": "Topics"
      },
      "placeholders": {
        "chooseDocument": "Choose document",
        "description": "Add description",
        "location": "Add location",
        "noDocumentText": "Use the create button in the sidebar. Or select all {modelName} to see archived documents too.",
        "noDocumentsHeader": "No active {modelName} for this project.",
        "noFollowers": "No followers yet",
        "noStepsContent": "Use the form at the bottom to add some",
        "noStepsHeader": "No step has been added yet",
        "noStepsText": "You have to add a step before you can add tasks",
        "noTabsContent": "Use the <span class='icon-plus-circle icon-color-success'></span> button on the left to add tasks and files. Drop files to attach!",
        "noTabsHeader": "It's empty in here",
        "noTasksContent": "Update your task selection in the filter bar here above or add tasks in requests, issues, scenarios and memos to structure your work",
        "noTasksHeader": "No tasks in the current selection",
        "noTemplate": "Choose template",
        "noTemplatesDefined": "No templates defined yet",
        "noTemplatesDefinedFor": "No templates defined yet for {documentType}",
        "noTopicsContent": "Use the form at the bottom to add some",
        "noTopicsHeader": "No topic has been added yet",
        "noTopicsText": "You have to add a topic before you can add tasks",
        "shortLink": "Short link to document: {url}",
        "statusDescription": "Add request status details"
      },
      "share": {
        "optionalMessagePlaceholder": "Add your message"
      },
      "statuses": {
        "active": "Active",
        "blocked": "Blocked",
        "closed": "Closed",
        "draft": "Draft",
        "escalated": "Escalated",
        "forApproval": "For approval",
        "implementing": "Implementing",
        "investigating": "Investigating",
        "onHold": "On hold",
        "open": "Open",
        "resolved": "Resolved",
        "testing": "Testing"
      }
    },
    "errors": {
      "100": {
        "message": "Form you submitted is incorrect.",
        "title": "Validation error!"
      },
      "1001": {
        "message": "Incorrect login credentials.",
        "title": "Login error!"
      },
      "1002": {
        "message": "This user has been not activated yet.",
        "title": "Inactive user!"
      },
      "1003": {
        "message": "Login token is invalid.",
        "title": "Invalid login token!"
      },
      "1004": {
        "message": "Email is incorrect or is in use by other user.",
        "title": "Incorrect email!"
      },
      "1005": {
        "message": "Your user account requires password change.",
        "title": "Password change required!"
      },
      "1006": {
        "message": "You will be redirected to default domain in 2 seconds",
        "title": "Organization was not found"
      },
      "1007": {
        "message": "Form you submitted is incorrect.",
        "title": "Invalid parameters!"
      },
      "1008": {
        "message": "Please, try sign in using login form.",
        "title": "User is already confirmed!"
      },
      "1009": {
        "message": "User doesn't exist.",
        "title": "Missing user!"
      },
      "1010": {
        "message": "Your account doesn't exist or has already been confirmed.",
        "title": "Confirmation error!"
      },
      "1011": {
        "message": "Please, confirm your account before login.",
        "title": "User not confirmed!"
      },
      "1012": {
        "message": "Your can only upload images.",
        "title": "Invalid file type!"
      },
      "1013": {
        "message": "You are trying to reach the resource which does not exist.",
        "title": "Resource not found!"
      },
      "1014": {
        "message": "You do not have access to the following resource.",
        "title": "Resource not authorized!"
      },
      "1015": {
        "message": "You need to login using your password.",
        "title": "Login token has expired!"
      },
      "1501": {
        "message": "It looks like you don't have enough permissions to do that action.",
        "title": "Permission Error!"
      },
      "1502": {
        "message": "It looks like you don't have enough permissions to do that action.",
        "title": "Action error!"
      },
      "1503": {
        "message": "This resource can not be archived yet.",
        "title": "Resource not archivable!"
      },
      "1504": {
        "message": "This member has already been added.",
        "title": "Membership existed!"
      },
      "1505": {
        "message": "You are not authorized to do that action.",
        "title": "You are not authorized!"
      },
      "1506": {
        "message": "Something went wrong during sending the invitation.",
        "title": "Invitation error!"
      },
      "1507": {
        "message": "Please contact our support to solve this problem.",
        "title": "Policy validation error!"
      },
      "1508": {
        "message": "To add new managers, you need to upgrade your plan.",
        "title": "You've reached your manager limit."
      },
      "500": {
        "message": "Please contact the support.",
        "title": "Unexpected error!"
      },
      "fieldRequired": "{details}"
    },
    "intro": {
      "dashboard": {
        "modalTitle": "Welcome to 9TEAMS",
        "step1": "We have prepared a demo project for you. Have a look around and play with it. You can remove or archive whenever you want.",
        "step2": "With the CREATE button, you can create stuff like projects, requests, issues, scenarios, memos, and users",
        "step3": "Click the support button to get instant support or leave us a message.",
        "welcomeUser": "Hi {userName}, this is your organization dashboard. To get up and running, there are 3 simple things you need to know."
      },
      "done": "Got it",
      "issue": {
        "step1": "Specify the overall issue resolution date.",
        "step2": "Add topics to structure your issue. Use templates to speed up the work.",
        "step3": "Update the issue status.",
        "step4": "Update the owner of the issue, if required.",
        "step5": "Invite members to your issue. By this you'll grant them access to the issue and all linked tasks. If the user is not known yet, you can specify the e-mail address; read-only access will be granted for this issue only.",
        "step6": "Managing distribution list is important to make everyone stay up to every change in your document. Click <a href='https://9teams.zendesk.com/hc/en-us/articles/213152885' target='_blank'>here</a> if you need more information about distributing document.",
        "step7": "When your issue is ready, share to all members. They will receive the issue details in their mailbox."
      },
      "memo": {
        "step1": "Add topics to structure your memo.",
        "step2": "Invite members to your memo. By this you'll grant them access to the memo and all linked tasks. If the user is not known yet, you can specify the e-mail address. Read-only access will be granted for this memo only.",
        "step3": "Managing distribution list is important to make everyone stay up to every change in your document. Click <a href='https://9teams.zendesk.com/hc/en-us/articles/213152885' target='_blank'>here</a> if you need more information about distributing document.",
        "step4": "When your memo is ready, share to all members. They will receive it in their mailbox."
      },
      "request": {
        "step1": "Specify the overall request implementation date.",
        "step2": "Add topics to structure your request. Use templates to speed up the work.",
        "step3": "Update the request status.",
        "step4": "Update the owner of the request, if required.",
        "step5": "Invite members to your request. By this you'll grant them access to the request and all linked tasks. If the user is not known yet, you can specify the e-mail address; read-only access will be granted for this issue only.",
        "step6": "Managing distribution list is important to make everyone stay up to every change in your document. Click <a href='https://9teams.zendesk.com/hc/en-us/articles/213152885' target='_blank'>here</a> if you need more information about distributing document.",
        "step7": "When your request is ready, share to all members. They will receive the issue details in their mailbox."
      },
      "scenario": {
        "step1": "Add steps to structure your scenario.",
        "step2": "Invite members to your scenario. By this you'll grant them access to the scenario and all linked tasks. If the user is not known yet, you can specify the e-mail address. Read-only access will be granted for this scenario only.",
        "step3": "Managing distribution list is important to make everyone stay up to every change in your document. Click <a href='https://9teams.zendesk.com/hc/en-us/articles/213152885' target='_blank'>here</a> if you need more information about distributing document.",
        "step4": "When your scenario is ready, share to all members. They will receive it in their mailbox."
      },
      "skip": "Got it",
      "task": {
        "step1": "Hey, looks like you are new to tasks in 9teams! We'll quickly take you through the things you need to know.",
        "step2": "This is the task description with a brief explanation about what is being expected.",
        "step3": "You can follow this link if you want to know more about the origin and the context of the task.",
        "step4": "The 'task owner' is the user from whom the next action is being expected. Please update if required. Tasks owner, requester and watchers will be notified automatically.",
        "step5": "Use the comments bar to communicate on this task. You can also add screenshots by 'copy-paste' or 'drag-and-drop'. Tasks owner, requester and watchers will be notified automatically. It's easier and quicker than e-mail!",
        "step6": "If required, you can upload files to this task. Tasks owner, requester and watchers will be notified automatically.",
        "step7": "Add people to task to keep them up to date."
      }
    },
    "issue": {
      "actions": {
        "sendIssue": "Send issue"
      },
      "add": "Add Issue",
      "labels": {
        "datepicker": "Due Date"
      },
      "new": "New Issue",
      "openIssues": "Open issues",
      "overdueIssues": "Overdue issues",
      "share": {
        "shareHeader": "Share Issue",
        "shareMessage": "You are going to share issue.",
        "shareMessageSingle": "You are going to share issue to {userName}"
      },
      "statuses": {
        "escalated": "Escalated",
        "implementing": "Implementing",
        "investigating": "Investigating",
        "open": "Open",
        "resolved": "Resolved"
      }
    },
    "kanban": {
      "labels": {
        "help": "You can assign it in document page",
        "placeholder": "No type was assigned to your documents",
        "property-switch": "Group by",
        "status": "status"
      },
      "switcher": {
        "kanban": "Kanban",
        "list": "List"
      },
      "tooltip": {
        "false": "Switch to kanban board view",
        "true": "Switch to list view"
      }
    },
    "member": {
      "accesses": {
        "readOnly": "Read-only access"
      },
      "add": "Add Manager",
      "deliveries": {
        "delivered": "Delivered",
        "draft": "Sending",
        "failed": "Failed",
        "notSent": "Not sent",
        "outdated": "Outdated",
        "sent": "Sent"
      },
      "edit": "Edit Manager",
      "email": {
        "placeholder": "Enter email"
      },
      "invitationEmail": {
        "collaboratorInfo": "Email for the collaborator will not be send",
        "header": "Invitation email preview",
        "message": "You have been invited by {name} to activate your user account and set your personal password.",
        "namePlaceholder": "New user name",
        "title": "Dear {name},",
        "welcome": "Welcome to 9teams! You’re on your way to super-productivity and beyond! 9teams is an extremely intuitive team collaboration platform that helps you and your team to save time and get things done."
      },
      "labels": {
        "accessInfo": "Access information",
        "email": "Email",
        "name": "Name",
        "roles": "Roles"
      },
      "message": {
        "placeholder": "Enter custom message"
      },
      "name": {
        "placeholder": "Enter name"
      },
      "new": "New Manager",
      "placeholders": {
        "powerSelectInvite": "user@email.com User Name"
      },
      "roles": {
        "account_member": "Manager",
        "admin": "Admin",
        "admins": "Admins",
        "collaborator": "Collaborator",
        "collaborators": "Collaborators",
        "description": {
          "account_member": "Has full access to the projects and documents where user has been assigned to.",
          "admin": "Has full access to the organization, including the ability to maintain users and administer the organization",
          "collaborator": "Has read-only access to the documents where user has been assigned to. has full access to the tasks where user is the task owner. Collaborators cannot be assigned as project member. Collaborators can never create, they can only collaborate. Collaborators don’t consume any subscriptions.",
          "owner": "Has full access to the organization, including the ability to maintain users and administer the organization"
        },
        "manager": "Manager",
        "managers": "Managers",
        "owner": "Owner",
        "removed": "Removed",
        "super_admin": "9Teams Admin",
        "support": "9Teams Support"
      },
      "update": {
        "success": "Manager has been successfuly updated!"
      }
    },
    "memo": {
      "actions": {
        "sendMemo": "Send memo"
      },
      "add": "Add Memo",
      "allMemos": "All memos",
      "new": "New Memo",
      "overdueMemos": "Overdue memos",
      "share": {
        "shareHeader": "Share Memo",
        "shareMessage": "You are going to share memo.",
        "shareMessageSingle": "You are going to share memo to {userName}"
      }
    },
    "milestone": {
      "labels": {
        "statuses": {
          "behindTrack": "Behind track",
          "completed": "Completed",
          "future": "Future",
          "onTrack": "On track",
          "slightlyBehindTrack": "Slightly behind track"
        }
      },
      "placeholders": {
        "addDescription": "Add milestone description"
      }
    },
    "notify": {
      "attachment": {
        "added": "Attachment has been added",
        "removed": "Attachment has been removed"
      },
      "auth": {
        "login": {
          "magicLink": "Magic link has been sent to your mailbox.",
          "successful": "You are successfully logged in."
        },
        "noAccess": {
          "message": "You have no access."
        },
        "password": {
          "reset": "Your new password has been set.",
          "send": "Your new password was sent to the given email."
        },
        "user": {
          "confirmed": "Your account has been confirmed.",
          "register": "Your account has been registered and waiting for your confirmation.",
          "resend": "We sent confirmation mail to your email."
        }
      },
      "changesNotification": {
        "scheduled": "This change will be shared automatically in 5 minutes"
      },
      "comment": {
        "created": "Comment has been created",
        "removed": "Comment has been removed"
      },
      "customField": {
        "removed": "Custom field has been removed"
      },
      "default": {
        "confirm": {
          "message": "Click OK to proceed",
          "title": "Are you sure?"
        },
        "error": {
          "message": "Something went wrong!",
          "title": "Error!"
        },
        "info": {
          "message": "Action has been successfully completed.",
          "title": "Information"
        },
        "notFound": {
          "message": "Object has not been found."
        },
        "prompt": {
          "message": "You has been prompt to fill in this field.",
          "title": "Fill in this field"
        },
        "saved": "The changes have been saved successfully.",
        "success": {
          "message": "Action has been successfully completed.",
          "title": "Success!"
        },
        "upload": {
          "progress": "Files are uploading..."
        },
        "warning": {
          "message": "Action may cause damage!",
          "title": "Warning!"
        }
      },
      "documentTemplate": {
        "removed": "Document template has been removed"
      },
      "issue": {
        "created": "New issue has been created",
        "distributed": "Issue has been shared to:",
        "removed": "Issue has been removed"
      },
      "member": {
        "removed": "Member has been removed"
      },
      "memo": {
        "created": "New memo has been created",
        "distributed": "Memo has been shared to:",
        "followed": "Follow-up has been created",
        "removed": "Memo has been removed"
      },
      "milestone": {
        "created": "New milestone has been created",
        "removed": "Milestone has been removed"
      },
      "organization": {
        "created": "The organization has been created.",
        "noAccess": "Sorry, you do not have an access to this organization",
        "notExist": "Sorry, this organization does not exist"
      },
      "project": {
        "created": "New project has been created",
        "removed": "Project has been removed"
      },
      "projectTemplate": {
        "changing": "Changing this field will cause a reset of templates in documents belonging to the project",
        "removed": "Project template has been removed",
        "removing": "Removing this template set will cause a reset of templates in related documents and projects"
      },
      "request": {
        "created": "New request has been created",
        "distributed": "Request has been shared to:",
        "removed": "Request has been removed"
      },
      "scenario": {
        "created": "New scenario has been created",
        "distributed": "Scenario has been shared to:",
        "removed": "Scenario has been removed"
      },
      "shared": {
        "requiredField": "This field can not be blank"
      },
      "step": {
        "created": "Step has been created",
        "removed": "Step has been removed",
        "reordered": "Steps have been reordered"
      },
      "subscriptions": {
        "cantOrderLessMembers": "To order lower number of seats, you need to remove unused members first from your organization."
      },
      "task": {
        "created": "Task has been created",
        "distributed": "Task will be shared in 5 minutes to:",
        "muted": "As long as document has not been shared, the following users will <b>not be notified</b> of the update.",
        "removed": "Task has been removed",
        "updated": "Task has been updated"
      },
      "template": {
        "cloned": "Template has been cloned",
        "importResources": "Importing of template's sub-resources is in progress - we will inform you when sub-resources are ready to use",
        "imported": "Template has been imported successfully",
        "importedTemplates": "Templates has been imported successfully",
        "published": "Template has been published",
        "removed": "Template has been removed",
        "updated": "Template has been updated"
      },
      "thumbnail": {
        "updated": "Thumbnail has been updated"
      },
      "topic": {
        "created": "New topic has been created",
        "removed": "Topic has been removed",
        "reordered": "Topics have been reordered"
      },
      "transport": {
        "assigned": "Transport has been assigned",
        "created": "Transport has been created",
        "removed": "Transport has been removed",
        "updated": "Transport has been updated"
      },
      "user": {
        "accountInvite": "New user has been invited.",
        "currentPasswordInvalid": "Invalid current password!",
        "invited": "Member with full document access has been added to the distribution list.",
        "invitedNew": "Collaborator with read-only access has been added to the distribution list.",
        "passwordPrompt": {
          "message": "To confirm this action, please type your current password.",
          "title": "Type your password"
        },
        "projectInvite": "Member with full project access has been added."
      },
      "watcher": {
        "added": "New watcher with read-only access has been added to the distribution list.",
        "removed": "Watcher has been removed. E-mail notifications will no longer be sent out."
      },
      "workItem": {
        "created": "New work item has been created",
        "removed": "Work item has been removed",
        "reordered": "Work item has been reordered",
        "updated": "Work item has been updated"
      },
      "workLog": {
        "completed": "Work item has been completed",
        "created": "New work log has been created",
        "removed": "Work log has been removed",
        "updated": "Work log has been updated",
        "updatedPermission": "Permission to manage work has been updated"
      },
      "workPackage": {
        "created": "New work package has been created",
        "removed": "Work package has been removed",
        "reordered": "Work item has been reordered"
      }
    },
    "organization": {
      "create": "Create organization",
      "customField": {
        "actions": {
          "assign": "Assign custom field",
          "create": "Add Custom Field"
        },
        "labels": {
          "assignCustomField": "Assign custom field",
          "createCustomField": "Create Custom Field",
          "customFieldType": "Custom field type",
          "disabledEditWarning": "You cannot edit custom field type after creation",
          "document": "Document",
          "documentType": "Document type",
          "editCustomField": "Update Custom Field",
          "fieldType": "Field type",
          "listOptions": "List options",
          "multipleValues": "Multiple",
          "name": "Name",
          "projectType": "Document type set",
          "selectOption": "Select an option",
          "singleValue": "Single",
          "visibleOnList": "Visible on list"
        },
        "placeholders": {
          "chooseContact": "Choose contact",
          "empty": "Empty",
          "input": "Input",
          "list": "List",
          "number": "Numbers",
          "richtext": "Memo",
          "selectUser": "Select member",
          "setValue": "Set value",
          "user": "Contact"
        }
      },
      "details": "Details",
      "documentTemplate": {
        "actions": {
          "create": "Add Template",
          "import": "Import Template"
        },
        "labels": {
          "cloneDocumentTemplate": "Clone Template",
          "createDocumentTemplate": "Create Template",
          "editDocumentTemplate": "Edit Template",
          "importDocumentTemplate": "Import Template"
        },
        "placeholders": {
          "choose": "Choose document templates",
          "description": "Add template description",
          "empty": "There is no available templates",
          "name": "Add name"
        }
      },
      "domain": {
        "9TeamsPart": ".9teams.com",
        "httpPart": "https://",
        "placeholder": "Name without special characters"
      },
      "edit": "Edit Organization",
      "labels": {
        "callToAction": {
          "createOrganization": "Create your own organization",
          "list": {
            "collaborate": "collaborate on memos, issues, requests and scenarios",
            "manageProjects": "manage projects",
            "organizeTasks": "organize tasks"
          }
        },
        "details": "Organization details",
        "documentTemplates": "Templates",
        "domain": "Domain",
        "name": "Name",
        "settings": "Organization settings",
        "subscription": "Subscription",
        "templatesSets": "Project Templates",
        "transportSystems": "Transport systems",
        "url": "Url"
      },
      "list": "Organization list",
      "manage": "Manage Organization",
      "members": "Users",
      "placeholders": {
        "autoLogin": "The next time you log in, you will be automatically taken to the last used organization.",
        "choose": "Choose organization",
        "noAccounts": "You have not been assigned to any organization yet"
      },
      "projectTemplate": {
        "actions": {
          "create": "Add Project Template"
        },
        "labels": {
          "createProjectTemplate": "Create Project Template",
          "editProjectTemplate": "Edit Project Template"
        },
        "placeholders": {
          "chooseTemplateSet": "Choose Template Set",
          "name": "Add name"
        }
      },
      "switch": "Switch organization",
      "update": {
        "success": "Organization has been successfuly updated!"
      }
    },
    "project": {
      "add": "Add Project",
      "code": {
        "placeholder": "Add project code"
      },
      "dashboard": {
        "labels": {
          "budget": "Budget",
          "scope": "Scope",
          "time": "Time"
        },
        "link": "Link",
        "progress": "Progress",
        "progressComment": "Specify progress comment",
        "showOpenTasks": "Show open tasks",
        "states": {
          "attention": {
            "description": "Project management efforts turn out to be higher than expected/budgeted. When local business management is increasing efforts on organizational change management, we still have the possibility to finalize the project 'on budget'.",
            "label": "Attention"
          },
          "critical": {
            "description": "We are highly concerned about the number of change requests we are receiving. In our opinion, the lack of commitment and empowerment is driving the project towards scope creep.",
            "label": "Critical"
          },
          "notRelevant": {
            "description": "",
            "label": "Not Relevant"
          },
          "onTrack": {
            "description": "At the moment we are progressing as expected.",
            "label": "On Track"
          },
          "unknown": {
            "description": "",
            "label": "Unknown"
          }
        },
        "tasksPerOwner": "Tasks per owner"
      },
      "filter": {
        "all": "All",
        "assigned": "Assigned",
        "unassigned": "Unassigned"
      },
      "labels": {
        "addMembers": "Add members",
        "code": "Project code",
        "createdAt": "Created at",
        "description": "Project description",
        "details": "Project details",
        "endAt": "Stop at",
        "impact": "Impact",
        "importInformation": "Preparing your project, configuration and templates. This might take a while.",
        "issuesCount": "{resource, plural, =0 {No active issues} =1 {# active issue} other {# active issues}}",
        "manageWork": "Manage work",
        "memosCount": "{resource, plural, =0 {No active memos} =1 {# active memo} other {# active memos}}",
        "name": "Project name",
        "notificationInformation": "We’ll notify you here when ready.",
        "projectTypeImportInfo": "The project template will be imported to your organization with all connected resources.",
        "requestsCount": "{resource, plural, =0 {No active requests} =1 {# active request} other {# active requests}}",
        "scenariosCount": "{resource, plural, =0 {No active scenarios} =1 {# active scenario} other {# active scenarios}}",
        "startAt": "Start at",
        "status": "Status",
        "statusDescription": "Status Details",
        "tags": "Tags"
      },
      "menu": {
        "dashboard": "Dashboard",
        "details": "Details",
        "work": "Work"
      },
      "name": {
        "placeholder": "Enter Name"
      },
      "new": "New Project",
      "options": {
        "basic": "Create a new project with assigned type"
      },
      "placeholders": {
        "date": "Click to set date"
      },
      "projectDescription": {
        "placeholder": "Add project description"
      },
      "settings": {
        "enabledIssues": "Enabled Issues",
        "enabledMemos": "Enabled Memos",
        "enabledRequests": "Enabled Change Requests",
        "enabledScenarios": "Enabled Scenarios",
        "projectTemplate": "Project Template"
      },
      "statusDescription": {
        "placeholder": "Add project status details"
      },
      "statuses": {
        "approved": "Approved",
        "cancelled": "Cancelled",
        "completed": "Completed",
        "concept": "Concept",
        "inProgress": "In progress",
        "onHold": "On hold",
        "request": "Request"
      }
    },
    "reminder": {
      "noTasksCreated": {
        "document": "No topics were added to your document yet. You have to add topics before you can add tasks.",
        "scenario": "No steps were added to your scenario yet. You have to add steps before you can add tasks."
      }
    },
    "request": {
      "actions": {
        "sendRequest": "Send request"
      },
      "add": "Add Request",
      "labels": {
        "datepicker": "Due Date"
      },
      "new": "New Request",
      "openRequests": "Open requests",
      "overdueRequests": "Overdue requests",
      "share": {
        "shareHeader": "Share Request",
        "shareMessage": "You are going to share request.",
        "shareMessageSingle": "You are going to share request to {userName}"
      },
      "statuses": {
        "closed": "Closed",
        "forApproval": "For approval",
        "implementing": "Implementing",
        "investigating": "Investigating",
        "onHold": "On hold",
        "open": "Open",
        "testing": "Testing"
      }
    },
    "scenario": {
      "actions": {
        "updateStatus": "Update Status"
      },
      "add": "Add Scenario",
      "labels": {
        "datepicker": "Due Date",
        "lastStepStatus": "Last step status",
        "progress": "Progress",
        "status": "Status",
        "stepProgress": {
          "done": "Done",
          "failed": "Failed",
          "open": "Open"
        },
        "updateStatus": "Update Step Status"
      },
      "new": "New Scenario",
      "openScenarios": "Open scenarios",
      "overdueScenarios": "Overdue scenarios",
      "placeholders": {
        "noTabsContent": "Use the <span class='icon-plus-circle icon-color-success'></span> button on the left to add tasks and files. Drop files to attach!",
        "status": "Update Status"
      },
      "share": {
        "shareHeader": "Share Scenario",
        "shareMessage": "You are going to share scenario.",
        "shareMessageSingle": "You are going to share scenario to {userName}"
      },
      "statuses": {
        "active": "Active",
        "blocked": "Blocked",
        "closed": "Closed",
        "draft": "Draft",
        "forApproval": "For approval",
        "onHold": "On hold",
        "open": "Open"
      }
    },
    "search": {
      "types": {
        "issue": "Issues",
        "memo": "Memos",
        "project": "Projects",
        "request": "Requests",
        "scenario": "Scenarios",
        "step": "Steps",
        "task": "Tasks",
        "topic": "Topics"
      }
    },
    "select": {
      "search": {
        "message": {
          "left": {
            "one": "Please enter 1 or more characters",
            "two": "Please enter 2 or more characters"
          }
        }
      }
    },
    "shared": {
      "actions": {
        "add": "Add",
        "addLabel": "Add label",
        "addTransport": "Add transport",
        "addWorkLog": "ADD WORK LOG",
        "back": "Back",
        "cancel": "Cancel",
        "clear": "Clear",
        "clearDate": "Clear date",
        "clone": "Clone",
        "close": "Close",
        "confirm": "Confirm",
        "create": "Create",
        "createExcel": "Download as a Excel",
        "createPdf": "Download as a PDF",
        "download": "Download",
        "downloadPDF": "Download PDF",
        "edit": "Edit",
        "escToCloseModal": "Press ESC to close modal",
        "import": "Import",
        "invite": "Invite",
        "moreActions": "More actions",
        "next": "Next",
        "publish": "Publish",
        "remove": "Remove",
        "save": "Save",
        "see": "See",
        "seeAll": "See all",
        "select": "Select",
        "share": "Share",
        "showIntro": "Show intro",
        "update": "Update"
      },
      "appName": "9Teams",
      "copy": "Link copied to clipboard",
      "dashboard": "Dashboard",
      "filters": {
        "all": "All",
        "due": {
          "inFuture": "In future",
          "nextMonth": "Next month",
          "nextWeek": "Next week",
          "overdue": "Overdue",
          "thisMonth": "This month",
          "thisWeek": "This week",
          "today": "Today"
        }
      },
      "labels": {
        "9teamsLogo": "9teams logo",
        "addAsCollaborator": "Add as collaborator",
        "addFirstMilestone": "Before creating first work item you need to create a milestone",
        "addFirstWorkItem": "Create first work item",
        "addFirstWorkPackage": "Before creating first work item you need to create a work package",
        "all": "All",
        "assign": "Assign",
        "assignTransport": "Assign transport",
        "attachments": "Attachments",
        "autoClone": "Auto clone",
        "changeProject": "Change project",
        "changes": "Changes",
        "choose": "Choose",
        "clickToAdd": "Click to add",
        "comment": "Comment",
        "commentOptional": "Comment (optional)",
        "completeWorkItem": "Complete work item",
        "completeWorkItemShort": "Complete",
        "copied": "Copied",
        "create": "Create",
        "createATransport": "Create a transport",
        "createdAt": "Created at",
        "creator": "Creator",
        "current": "Current",
        "customFields": "Custom fields",
        "dashboard": "Dashboard",
        "date": "Date",
        "deadline": "Deadline",
        "description": "Description",
        "deselectAll": "Deselect All",
        "details": "Details",
        "document": "Document",
        "documentId": "Document ID",
        "documentTemplates": "Document templates",
        "documentType": "Type",
        "documentTypes": "Types",
        "documents": "Documents",
        "dropIeSupport": "We are about dropping IE 10 support at the beginning of the December. Please update your browser",
        "due": "Due",
        "dueDate": "Due Date",
        "edit": "Edit",
        "editMember": "Edit member",
        "editOrganization": "Edit organization",
        "emptyProjectTemplate": "Empty project template",
        "enteredBy": "Entered by",
        "excel": "Excel",
        "fields": "Fields",
        "filter": "Filter",
        "forgotPassword": "Forgot password",
        "hideSettings": "Hide settings",
        "id": "ID",
        "impact": "Impact",
        "info": "Information",
        "issue": "Issue",
        "issues": "Issues",
        "lastStepStatus": "Last step status",
        "link": "Link",
        "linkedDocuments": "Linked Documents",
        "login": "Login",
        "logs": "Logs",
        "manageProjectTemplate": "Manage project templates",
        "members": "Members",
        "memo": "Memo",
        "memos": "Memos",
        "metadata": "Metadata",
        "milestone": "Milestone",
        "milestones": "Milestones",
        "move": "Move",
        "name": "Name",
        "nameAndDescription": "Name and description",
        "new": "New",
        "newMember": "New member",
        "newTask": "New Task",
        "nextStepOwner": "Next step owner",
        "noOverdueTasks": "No overdue tasks",
        "noRecordsFound": "No records found",
        "noTasks": "No tasks",
        "noTasksReportedByMe": "No overdue tasks reported by you",
        "notDefined": "Not defined",
        "notSpecified": "Not specified",
        "optional": "(optional)",
        "organization": "Organization settings",
        "organizationLabel": "Organization",
        "other": "Other",
        "owner": "Owner",
        "period": "period",
        "plan": "Plan",
        "preview": "Preview",
        "profile": "Profile",
        "progress": "Progress",
        "project": "Project",
        "projectPlaceholder": "Select a project",
        "projectTemplateThumbnail": "Project template thumbnail",
        "projectTemplates": "Project templates",
        "projectTemplatesPlaceholder": "Select some project templates...",
        "projectType": "Projec type",
        "projectTypePlaceholder": "Select a project template...",
        "projects": "Projects",
        "reloadButton": "Reload",
        "remaining": "Remaining",
        "reportedBy": "Reported by",
        "reporter": "Reporter",
        "request": "Request",
        "requester": "Requester",
        "requests": "Requests",
        "scenario": "Scenario",
        "scenarios": "Scenarios",
        "searchMatchCount": "Best matching {count} results",
        "searchPlaceholder": "Use this tool to search through:",
        "searchProTip": "PRO TIP: You can open search box using the <kbd>CTRL</kbd> + <kbd>SPACE</kbd> shortcut",
        "select": "Select",
        "selectAll": "Select All",
        "selectTransport": "Select transport",
        "setDocumentType": "Set Type",
        "setMilestone": "Set milestone",
        "setProject": "Set project",
        "setWorkPackage": "Set work package",
        "showMore": "Show more",
        "showSettings": "Show settings",
        "start": "Start",
        "startDate": "Start date",
        "status": "Status",
        "step": "Step",
        "subscription": "Subscription",
        "subscriptions": "Subscriptions",
        "support": "Support",
        "task": "Task",
        "tasks": "Tasks",
        "templates": "Templates",
        "thumbnail": "Thumbnail",
        "time": "Time",
        "topic": "Topic",
        "topics": "Topics",
        "transport": "Transport",
        "transports": "Transports",
        "typeName": "Type",
        "updatedAppVersion": "9Teams has been updated. Please save any unsaved work and refresh the browser",
        "updatedAt": "Updated at",
        "user": "User",
        "users": "Users",
        "view": "View",
        "viewProject": "View Project",
        "warning": "Issues",
        "welcome": "Welcome",
        "work": "Work",
        "workDate": "Work date",
        "workItem": "Work item",
        "workLog": "Work log",
        "workLogDescription": "Work log description",
        "workPackage": "Work Package",
        "workPackages": "Work packages",
        "worked": "Worked",
        "zeroProjects": "0"
      },
      "percentValue": "{value}%",
      "placeholders": {
        "addFiles": "Add files",
        "addThumbnail": "Add thumbnail",
        "clickToUploadFiles": "Drop files or click to select",
        "dropFilesHere": "Drop files here",
        "name": "Enter name",
        "noDate": "No date",
        "search": "search...",
        "setDate": "Set date"
      },
      "switcher": {
        "active": "Active",
        "all": "All",
        "done": "Done",
        "draft": "Draft",
        "markAsDone": "Mark as done",
        "markAsTodo": "Mark as todo",
        "off": "Off",
        "on": "On",
        "todo": "Todo"
      }
    },
    "step": {
      "labels": {
        "agendaMode": "Collapse steps",
        "description": "Description",
        "expandStep": "Expand step",
        "fullMode": "Extand steps",
        "outcome": "Outcome",
        "owner": "Owner"
      },
      "placeholders": {
        "description": "Add step description",
        "name": "Add new step",
        "outcome": "Add step outcome",
        "status": {
          "outcome": "Specify outcome to update step status"
        }
      },
      "tooltips": {
        "status": "Update Step Status"
      }
    },
    "subscription": {
      "changeButton": "Change",
      "changeInProgress": "Your plan will be changed immediately after we get the confirmation from the provider. It can take some time. ",
      "chooseSubscription": "Choose the right subscription for you",
      "currentSubscriptionStatus": "Current subscription",
      "dateWithDaysLeft": "{date} - {daysLeft} days left",
      "expireTime": "Subscription will expire in {daysLeft} days",
      "expiredAt": "{daysLeft, plural, =0 {Expired today} other {Expired {daysLeft} days ago}}",
      "freeAccount": "Free account",
      "manage": "Manage subscriptions",
      "managersSlotsLeft": "{currentManagersCount} of {maxManagerLimitForPlan}",
      "membersSlotsLeft": "You can add {membersLeft} members left",
      "moreInfo": "More info",
      "orderButton": "Order",
      "perMonth": "Per month",
      "plans": {
        "enterprise": {
          "collaborators": "Unlimited collaborators",
          "header": "Enterprise",
          "managers": "100 managers",
          "price": "2.699 €"
        },
        "free": {
          "collaborators": "Unlimited collaborators",
          "header": "Free",
          "managers": "1 manager",
          "price": "free"
        },
        "plus": {
          "collaborators": "Unlimited collaborators",
          "header": "Plus",
          "managers": "50 managers",
          "price": "1.699 €"
        },
        "regular": {
          "collaborators": "Unlimited collaborators",
          "header": "Regular",
          "managers": "20 managers",
          "price": "699 €"
        },
        "starter": {
          "collaborators": "Unlimited collaborators",
          "header": "Starter",
          "managers": "5 managers",
          "price": "199 €"
        }
      },
      "readOnly": "Read Only",
      "trial": "Trial",
      "upgradeSubscription": "Upgrade subscription"
    },
    "task": {
      "actions": {
        "create": "Create task",
        "destroy": "Delete this task",
        "move": "Move task",
        "new": "Add new task",
        "newForStep": "Add task for step"
      },
      "labels": {
        "addWatchers": "Add Watchers",
        "allTasks": "All tasks",
        "completed": {
          "all": "All",
          "false": "Todo",
          "true": "Done"
        },
        "copy": "Copy",
        "createdAt": "Created at",
        "creator": "Creator",
        "deadlineAt": "Deadline at",
        "details": "Task description",
        "dueDate": "Task due",
        "linkToFullTask": "Go to full task",
        "myTasks": "My tasks",
        "openTasks": "All open tasks",
        "overdueTasks": "Overdue tasks",
        "ownedByMe": "Owned by me",
        "owner": "Task Owner",
        "reportedByMe": "Reported by me",
        "requester": "Task Requester",
        "status": "Status",
        "task": "Task",
        "taskId": "Task ID",
        "taskSource": "Task source",
        "watchers": "Watchers"
      },
      "open": {
        "myOpen": "My open tasks"
      },
      "overdue": {
        "allOverdue": "All overdue tasks",
        "myOverdue": "My overdue tasks",
        "overdue": "Overdue tasks",
        "reportedByMe": "Overdue tasks reported by me"
      },
      "placeholders": {
        "byCreator": "By {name}",
        "description": "Add task description",
        "name": "Type task name",
        "owner": "Choose task owner",
        "requester": "Choose task requester"
      },
      "taskIdInfo": "Short link to task: {shortLinkUrl}"
    },
    "topic": {
      "labels": {
        "agendaMode": "Collapse topics",
        "expandTopic": "Expand topic",
        "fullMode": "Expand topics"
      },
      "placeholders": {
        "chooseTopic": "Choose topic",
        "description": "Add topic description",
        "name": "Add new topic"
      }
    },
    "transport": {
      "alts": {
        "noTransportsIcon": "No transports icon"
      },
      "labels": {
        "createNewTransport": "Create a new transport",
        "or": "or"
      },
      "placeholders": {
        "addSystem": "Add system",
        "noTransportsContent": "Update your transport selection in the filter bar here above or add a new transport",
        "noTransportsHeader": "No transport in the current selection",
        "system": "System"
      },
      "removeModal": {
        "cancel": "CANCEL",
        "remove": "REMOVE",
        "removeDocument": "Are you sure you want to remove {documentType} <b>{documentName}</b> from the transport <b>{transportDescription}</b>?",
        "removeLastDocument": "This document is the only one linked to this transport. If you remove it, transport <b>{transportDescription}</b> will be empty.",
        "title": "Remove the document from the transport"
      },
      "tooltips": {
        "itemsAssigned": "{itemsLength, plural, =0 {No items} =1 {One item} other {# items}} assigned",
        "moreDocuments": "more documents",
        "showListDocuments": "See the documents list"
      }
    },
    "user": {
      "actions": {
        "select": "Select user"
      },
      "add": "Add User",
      "edit": "Edit User",
      "email": {
        "changeInfo": "After changing the email, you will have to confirm it.",
        "placeholder": "Enter email"
      },
      "labels": {
        "creator": "Creator",
        "email": "Email",
        "me": "Me",
        "name": "Name",
        "owner": "Owner",
        "reporter": "Reporter",
        "requester": "Requester",
        "role": "Role",
        "withAccessToDocument": "Users with access to document",
        "withNoNotifications": "These users will not recieve updates notifications:",
        "withNotifications": "These users will recieve notifications for updates:"
      },
      "name": {
        "placeholder": "Enter name"
      },
      "new": "New User",
      "phoneNumber": {
        "placeholder": "Enter phone number"
      },
      "settings": "User settings",
      "update": {
        "currentEmail": "Confirmation email was sent to your current email address",
        "newEmail": "Confirmation email was sent to your new email adress, you will be logged out in 5 seconds",
        "success": "User has been successfuly updated!"
      }
    },
    "validation": {
      "errors": {
        "email": "Address email is incorect."
      }
    },
    "work": {
      "add": "Log Work",
      "alts": {
        "noMilestonesIcon": "No milstones icon",
        "noWorkItemsIcon": "No work items icon",
        "noWorkLogsIcon": "No work logs icon",
        "noWorkPackagesIcon": "No work packages icon"
      },
      "createAnotherWorkItem": "Create another work item",
      "filters": {
        "days": "Days",
        "hours": "Hours"
      },
      "kanban": {
        "total": "Total:"
      },
      "keepDataDescription": "Keep the data after adding the log",
      "labels": {
        "createWorkItem": "Create work item",
        "days": "days",
        "details": "Details",
        "editWorkItem": "Edit work item",
        "editWorkLog": "Edit work log",
        "estimated": "ESTIMATED",
        "less": "less",
        "logAnother": "LOG ANOTHER",
        "logWork": "Log work",
        "manageWork": "{canManageWork, select, false {Click to grant permission to manage and log work.} true {Click to revoke permission to manage and log work.} other {Click to grant permission to manage and log work.}}",
        "more": "more",
        "newWorkLog": "New work log",
        "planWork": "Plan work",
        "planned": "PLANNED",
        "remaining": "REMAINING",
        "report": "Report",
        "thanPlanned": "than planned",
        "timeScope": "{scope, select,\ndays { days }\nhours { hours }\nother { hours }\n}",
        "unitHours": "h",
        "usersWorking": "USERS WORKING",
        "variance": "VARIANCE",
        "workItem": "Work item",
        "workLogs": "Work logs",
        "worked": "Worked",
        "workedTime": "WORKED TIME"
      },
      "placeholders": {
        "date": "Date",
        "document": "Document (optional)",
        "estimated": "Estimated",
        "hours": "hours",
        "hoursEstimated": "{scope, select,\ndays { Estimated time (mandays) }\nother { Estimated time (hours) }\n}",
        "hoursPlanned": "{scope, select,\ndays { Planned time (mandays) }\nother { Planned time (hours) }\n}",
        "hoursRemaining": "Hours remaining",
        "hoursWorked": "Hours worked",
        "less": "less",
        "logWork": "Log work",
        "milestone": "Milestone",
        "noMilestonesContent": "Click the button to create your first milestone:",
        "noMilestonesHeader": "Hi, looks like you have not created any milestones yet.",
        "noWorkItemsContent": "Click the button to create your first work item:",
        "noWorkItemsHeader": "Hi, looks like you have not created any work items yet.",
        "noWorkLogsContent": "You can add a work log from the button in the right corner of this view.",
        "noWorkLogsHeader": "No work logs for this period.",
        "noWorkPackagesContent": "Click the button to create your first work package:",
        "noWorkPackagesHeader": "Hi, looks like you have not created any work packages yet.",
        "owner": "Owner",
        "planned": "Planned",
        "remaining": "Remaining",
        "user": "User",
        "variance": "Variance",
        "workDescription": "Work description",
        "workItem": "Work item",
        "workLogDescription": "Work log description",
        "workPackage": "Work package"
      },
      "remainingTooltip": "This field shows time remaining for selected work item. If you change the value, it will affect this work item.",
      "tooltips": {
        "addWorkLog": "Add work log"
      }
    }
  };
  _exports.default = _default;
});