define("ntfrontend/components/accounts/account-details/info/transport/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    tagName: '',
    systems: Ember.computed.alias('transportSetting.systems'),
    disabledButton: Ember.computed('newSystemName', 'systems', function () {
      if (this.systems) {
        return Boolean(this.systems.includes(this.newSystemName) || !this.newSystemName);
      }
    }),
    successCallback: function successCallback() {
      this.notify.success('notify.default.saved');
    },
    actions: {
      submitEditing: function submitEditing(system) {
        this.systems.pushObject(system);
        this.set('newSystemName', '');

        this._updateSystems();
      },
      reorderItems: function reorderItems(itemModels, draggedModel) {
        this._reorderSystems(itemModels, draggedModel);
      },
      removeSystem: function removeSystem(system) {
        var index = this.systems.indexOf(system);
        this.systems.splice(index, 1);

        this._updateSystems();
      }
    },
    _reorderSystems: function _reorderSystems(systems) {
      if (this.systems.join() !== systems.join()) {
        this.set('systems', systems);

        this._updateSystems();
      }
    },
    _updateSystems: function _updateSystems() {
      this.set('model', this.transportSetting.content);
      this.saveModelTask.perform();
    }
  });

  _exports.default = _default;
});