define("ntfrontend/components/tasks/watchers-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filteredMembers: Ember.computed('model.{members.length,owner.id,requester.id}', function () {
      var ignoredIds = [this.get('model.owner.id'), this.get('model.requester.id')];
      return this.get('model.members').filter(function (member) {
        return !ignoredIds.includes(member.get('user.id'));
      });
    }),
    actions: {
      afterRemove: function afterRemove() {
        this.notify.success('notify.watcher.removed');
      },
      afterInvite: function afterInvite() {
        this.notify.success('notify.watcher.added');
      }
    }
  });

  _exports.default = _default;
});