define("ntfrontend/components/memos/followup-memo/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('tempObject', Ember.Object.create({
        name: this.get('followingMemo.name')
      }));
    },
    afterCreate: function afterCreate(model) {
      var urlToRedirect = this.router.urlFor('memos.memo', model.get('slug'));
      this.router.transitionTo(urlToRedirect);
    },
    actions: {
      create: function create() {
        var _this = this;

        var newObject = this.store.createRecord('memo', {
          followingMemo: this.followingMemo,
          name: this.get('tempObject.name')
        });
        this.set('disabled', true);
        newObject.save().then(function () {
          _this.notify.success('notify.memo.followed');

          _this.afterCreate(newObject);
        }, function () {
          _this.set('newObject', newObject);

          newObject.rollbackAttributes();
        }).finally(function () {
          _this.set('disabled', false);
        });
      }
    }
  });

  _exports.default = _default;
});