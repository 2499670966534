define("ntfrontend/components/documents/participation-members/user-access/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: 'a',
    click: function click() {
      this.set('showModal', true);
      this.notifyPropertyChange('userAccesses');
    },
    userAccesses: Ember.computed('model.userAccesses', function () {
      return this.get('model.userAccesses');
    }),
    filteredUsers: Ember.computed.filter('userAccesses', function (userAccess) {
      return userAccess.get('user.id') != this.get('model.owner.id');
    }),
    documentUsers: Ember.computed('filteredUsers', function () {
      return this.filteredUsers.filterBy('notifiable').sortBy('user.name');
    }),
    othersUsers: Ember.computed('filteredUsers', function () {
      return this.filteredUsers.filterBy('notifiable', false).sortBy('user.name');
    })
  });

  _exports.default = _default;
});