define("ntfrontend/abilities/step", ["exports", "ntfrontend/abilities/topic"], function (_exports, _topic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _topic.default.extend();

  _exports.default = _default;
});