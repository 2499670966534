define("ntfrontend/components/sidebar-menu/create-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wai7dtMW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"params\",\"redirectByRouteParams\"],[[25,[\"params\"]],[29,\"action\",[[24,0,[]],\"redirectByRouteParams\"],null]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/sidebar-menu/create-button/template.hbs"
    }
  });

  _exports.default = _default;
});