define("ntfrontend/components/dashboard/members-count/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dashboard__section', 'dashboard__section--users']
  });

  _exports.default = _default;
});