define("ntfrontend/components/shared/inplace-textarea/component", ["exports", "ntfrontend/mixins/components/inplace/editable", "ntfrontend/mixins/components/inplace/focusable", "ntfrontend/mixins/components/inplace/placeholder"], function (_exports, _editable, _focusable, _placeholder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_editable.default, _focusable.default, _placeholder.default, {
    focusField: function focusField() {
      this._super.apply(this, arguments);

      autosize(this.element.querySelector('.form-control'));
    },
    willDestroyElement: function willDestroyElement() {
      autosize.destroy(this.element.querySelector('.form-control'));

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});