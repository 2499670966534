define("ntfrontend/templates/memos/memo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G0VYQgJm",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"page-title\",[[25,[\"model\",\"name\"]]],null],false],[0,\"\\n\\n\"],[5,\"projects/sub-menu\",[],[[\"@project\"],[[25,[\"model\",\"project\"]]]]],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"page-content__inner\"],[9],[0,\"\\n  \"],[1,[23,\"outlet\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/memos/memo.hbs"
    }
  });

  _exports.default = _default;
});