define("ntfrontend/components/labels/labels-list/component", ["exports", "ntfrontend/mixins/components/inplace/focusable"], function (_exports, _focusable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_focusable.default, {
    store: Ember.inject.service(),
    notify: Ember.inject.service('notify-alert'),
    labelsOptions: Ember.computed('', function () {
      return this.store.query('label', {
        filter: {
          projectId: this.get('project.id')
        }
      });
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!this.selectedLabels) {
        this.set('selectedLabels', this.labels);
      }
    },
    focusField: function focusField() {
      this.element.querySelector('input').focus();
    },
    focusOut: function focusOut() {},
    onChange: function onChange() {},
    actions: {
      createNewLabel: function createNewLabel(name) {
        var project = this.project,
            store = this.store,
            selectedLabels = this.selectedLabels;
        store.createRecord('label', {
          name: name,
          project: project
        }).save().then(function (label) {
          selectedLabels.pushObject(label);
        });
      },
      toggleEditing: function toggleEditing() {
        this.toggleProperty('isEditing');
      },
      doneEditing: function doneEditing() {
        var _this = this;

        var matchArrays = this.selectedLabels.every(function (l) {
          return _this.labels.includes(l);
        });
        var matchLength = this.get('selectedLabels.length') === this.get('labels.content.canonicalState.length');

        if (!matchLength || !matchArrays) {
          this.changeLabels(this.selectedLabels);
          this.onChange();
        }

        this.toggleProperty('isEditing');
      },
      cancelEditing: function cancelEditing() {
        this.set('selectedLabels', this.labels);
        this.toggleProperty('isEditing');
      }
    }
  });

  _exports.default = _default;
});