define("ntfrontend/components/auth/auth-links/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zLyau6oq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"hr\"],[9],[10],[0,\"\\n\"],[7,\"ul\"],[11,\"class\",\"list-inline text-muted text-right\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"app-version\"],[9],[0,\"\\n    \"],[1,[23,\"app-version\"],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[29,\"not-eq\",[[25,[\"route\"]],\"auth.login\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[5,\"auth/auth-links/auth-to\",[],[[\"@linkName\"],[\"login\"]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[29,\"not-eq\",[[25,[\"route\"]],\"auth.register\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[5,\"auth/auth-links/auth-to\",[],[[\"@linkName\"],[\"register\"]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[29,\"not-eq\",[[25,[\"route\"]],\"auth.forgot\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[5,\"auth/auth-links/auth-to\",[],[[\"@linkName\"],[\"forgot\"]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/auth/auth-links/template.hbs"
    }
  });

  _exports.default = _default;
});