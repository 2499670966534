define("ntfrontend/utils/group-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = groupBy;
  _exports.groupAsObject = groupAsObject;

  function groupBy(collection, property) {
    var dependentKey = "".concat(collection, ".@each.").concat(property);
    return Ember.computed(dependentKey, function () {
      var groups = Ember.A();
      var items = Ember.get(this, collection);

      if (items) {
        items.forEach(function (item) {
          var value = Ember.get(item, property);
          var group = groups.findBy('value', value);

          if (Ember.isPresent(group)) {
            Ember.get(group, 'items').push(item);
          } else {
            group = {
              property: property,
              value: value,
              items: [item]
            };
            groups.push(group);
          }
        });
      }

      return groups;
    }).readOnly();
  }

  function groupAsObject(collection, property) {
    var groups = {};

    if (collection) {
      collection.forEach(function (item) {
        var value = Ember.get(item, property);

        if (groups[value]) {
          groups[value].push(item);
        } else {
          groups[value] = [item];
        }
      });
    }

    return groups;
  }
});