define("ntfrontend/components/custom-fields/document-list/item-row/item/component", ["exports", "ntfrontend/mixins/components/custom-fields-list", "ntfrontend/components/custom-fields/custom-values/component"], function (_exports, _customFieldsList, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_customFieldsList.default, {
    tagName: '',
    customFieldComponent: Ember.computed('customValue.fieldType', function () {
      var availableTypes = this.availableTypes();
      var basePath = 'custom-fields/document-list/item-row/item';
      var componentName = availableTypes[this.get('customValue.field_type').camelize()];
      return "".concat(basePath, "/").concat(componentName);
    })
  });

  _exports.default = _default;
});