define("ntfrontend/components/transports/transport-list/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    paneManager: Ember.inject.service(),
    tagName: '',
    transportSelected: Ember.computed('paneManager.sneakPeekTransport', function () {
      var transportId = this.get('paneManager.sneakPeekTransport.id');
      return transportId === this.model.id;
    })
  });

  _exports.default = _default;
});