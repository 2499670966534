define("ntfrontend/components/projects/sub-menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentSession: Ember.inject.service(),
    router: Ember.inject.service(),
    features: Ember.inject.service(),
    classNames: 'sub-nav',
    isWorkRoute: Ember.computed('router.currentURL', 'project.slug', function () {
      return this.get('router.currentURL').includes("projects/".concat(this.project.get('slug'), "/work"));
    })
  });

  _exports.default = _default;
});