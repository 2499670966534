define("ntfrontend/components/transports/transport-list/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "znzvFJ8p",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"tr\"],[12,\"class\",[29,\"if\",[[25,[\"transportSelected\"]],\"-highlighted\"],null]],[9],[0,\"\\n  \"],[7,\"td\"],[11,\"class\",\"padding-0\"],[9],[0,\"\\n    \"],[5,\"transports/transport-system\",[],[[\"@model\"],[[24,1,[]]]]],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"td\"],[9],[0,\"\\n    \"],[5,\"transports/sneak-peek-button\",[],[[\"@model\"],[[24,1,[]]]],{\"statements\":[[1,[24,1,[\"description\"]],false]],\"parameters\":[]}],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"td\"],[9],[1,[24,1,[\"comment\"]],false],[10],[0,\"\\n  \"],[5,\"transports/transport-list/documents-wrapper\",[],[[\"@tagName\",\"@model\"],[\"td\",[24,1,[]]]]],[0,\"\\n  \"],[7,\"td\"],[9],[5,\"users/profile-box/picture\",[],[[\"@user\",\"@width\",\"@height\"],[[24,1,[\"creator\"]],\"30\",\"30\"]]],[10],[0,\"\\n  \"],[7,\"td\"],[9],[1,[29,\"format-date\",[[24,1,[\"createdAt\"]]],[[\"format\"],[\"short\"]]],false],[10],[0,\"\\n  \"],[7,\"td\"],[9],[5,\"transports/dropdown\",[],[[\"@model\"],[[24,1,[]]]]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/transports/transport-list/item/template.hbs"
    }
  });

  _exports.default = _default;
});