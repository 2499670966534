define("ntfrontend/mixins/models/stepable", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var hasMany = _emberData.default.hasMany;

  var _default = Ember.Mixin.create({
    steps: hasMany()
  });

  _exports.default = _default;
});