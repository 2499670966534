define("ntfrontend/components/shared/wysiwyg-editor/component", ["exports", "jquery", "lodash/forEach"], function (_exports, _jquery, _forEach) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    s3Uploader: Ember.inject.service(),
    classNames: 'summernote',
    toolbar: true,
    disabled: false,
    focus: true,
    disabledChanged: Ember.observer('disabled', function () {
      this.disabled ? this.summernote.summernote('disable') : this.summernote.summernote('enable');
    }),
    summernote: Ember.computed(function () {
      return (0, _jquery.default)(this.element);
    }),
    placeholder: Ember.computed('placeholderKey', function () {
      return this.placeholderKey ? this.intl.t(this.placeholderKey) : '';
    }),
    valueChanged: Ember.observer('value', function () {
      if (this.value != this.summernote.summernote('code')) {
        this.summernote.summernote('code', this.value);
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._setupSummerNote();

      this.valueChanged();
      this.disabledChanged();
    },
    willDestroyElement: function willDestroyElement() {
      this.summernote.summernote('destroy');

      this._super.apply(this, arguments);
    },
    _setupSummerNote: function _setupSummerNote() {
      this.summernote.summernote({
        focus: this.focus,
        toolbar: this._toolbar(),
        airMode: this.airMode,
        dialogsInBody: true,
        placeholder: this.placeholder,
        styleTags: ['p', 'h3', 'h4', 'h5', 'blockquote'],
        callbacks: {
          onChange: this._onChange.bind(this),
          onImageUpload: this._onImageUpload.bind(this),
          onBlur: this._onBlur.bind(this)
        },
        buttons: {
          save: this._saveButton.bind(this),
          cancel: this._cancelButton.bind(this)
        }
      });
      this.summernote.summernote('removeModule', 'autoLink');
    },
    _toolbar: function _toolbar() {
      return this.toolbar ? this._toolbarConfig() : false;
    },
    _toolbarConfig: function _toolbarConfig() {
      return [['para', ['style']], ['style', ['bold', 'italic', 'underline', 'clear']], ['color', ['color']], ['para', ['ul', 'ol', 'paragraph']], ['insert', ['picture', 'link', 'hr']], ['actions', ['save', 'cancel']]];
    },
    _onImageUpload: function _onImageUpload(files) {
      var _this = this;

      (0, _forEach.default)(files, function (blob) {
        _this.s3Uploader.uploadBlob(blob).then(function (url) {
          _this.summernote.summernote('insertImage', url);
        });
      });
    },
    _onChange: function _onChange(value) {
      if (this.summernote.summernote('isEmpty')) {
        this.set('value', '');
      } else {
        this.set('value', value);
      }
    },
    _onBlur: function _onBlur(e) {
      var target = (0, _jquery.default)(e.relatedTarget);

      if (e.relatedTarget && !target.is('button') && !target.is('.modal') && document.hasFocus() && this.onBlur) {
        this.onBlur();
      }
    },
    _saveButton: function _saveButton() {
      if (this.saveButton) {
        return _jquery.default.summernote.ui.button({
          className: 'save-button btn-success--solid',
          contents: this.intl.t('shared.actions.save'),
          click: this.saveButton
        }).render();
      }
    },
    _cancelButton: function _cancelButton() {
      if (this.cancelButton) {
        return _jquery.default.summernote.ui.button({
          className: 'cancel-button btn-danger--solid',
          contents: this.intl.t('shared.actions.cancel'),
          click: this.cancelButton
        }).render();
      }
    }
  });

  _exports.default = _default;
});