define("ntfrontend/components/auth/auth-links/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'auth-links',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('route', Ember.getOwner(this).lookup('controller:application').currentPath);
    }
  });

  _exports.default = _default;
});