define("ntfrontend/components/shared/transports-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3iyImL/0",
    "block": "{\"symbols\":[\"item\",\"@addTransport\"],\"statements\":[[5,\"power-select\",[],[[\"@options\",\"@searchField\",\"@placeholder\",\"@onchange\"],[[24,0,[\"transportsList\"]],\"description\",[29,\"t\",[\"shared.labels.selectTransport\"],null],[24,2,[]]]],{\"statements\":[[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"icon-transport\"],[9],[10],[0,\" \"],[1,[24,1,[\"description\"]],false],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/transports-select/template.hbs"
    }
  });

  _exports.default = _default;
});