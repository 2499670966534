define("ntfrontend/components/custom-fields/document-list/item-row/item/user-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    user: Ember.computed(function () {
      return this.store.findRecord('user', this.get('customValue.value'));
    }),
    actions: {
      updateUserModel: function updateUserModel(value) {
        this.set('customValue.value', value);
        this.afterChange();
      }
    }
  });

  _exports.default = _default;
});