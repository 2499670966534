define("ntfrontend/routes/projects/project/memos/index", ["exports", "ntfrontend/utils/ajaxer", "ntfrontend/routes/org-auth"], function (_exports, _ajaxer, _orgAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend({
    model: function model(params) {
      var data = {
        include: 'owner,labels',
        filter: this._filters(params),
        page: {
          size: params.perPage,
          number: params.page
        },
        sort: params.sort
      };
      return Ember.RSVP.hash({
        memos: this.store.query('memo', data),
        project: this.modelFor('projects.project')
      });
    },
    _filters: function _filters(params) {
      var filters = {
        projectId: this.modelFor('projects.project').id,
        archived: params.archived,
        q: params.q,
        customFields: params.customFields,
        workPackageId: params.workPackageId,
        milestoneId: params.milestoneId,
        ownerId: params.ownerId
      };
      return _ajaxer.default.reduceObject(filters);
    }
  });

  _exports.default = _default;
});