define("ntfrontend/components/shared/document-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ky9oON6M",
    "block": "{\"symbols\":[\"item\",\"@customClass\"],\"statements\":[[7,\"a\"],[12,\"class\",[30,[[29,\"search-group-icon\",[[24,0,[\"documentType\"]]],null],\" \",[24,2,[]]]]],[9],[0,\"\\n  \"],[1,[25,[\"document\",\"name\"]],false],[0,\"\\n  \"],[5,\"bs-tooltip\",[[13,\"class\",\"\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"document-link-tooltip\"],[9],[0,\"\\n      \"],[7,\"div\"],[9],[1,[29,\"t\",[\"transport.tooltips.itemsAssigned\"],[[\"itemsLength\"],[[24,0,[\"sortedAssignedItems\",\"length\"]]]]],false],[10],[0,\"\\n      \"],[7,\"ul\"],[11,\"class\",\"document-link-tooltip__list-item\"],[9],[0,\"\\n\"],[4,\"each\",[[24,0,[\"sortedAssignedItems\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\"],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"redirect\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/document-link/template.hbs"
    }
  });

  _exports.default = _default;
});