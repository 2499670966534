define("ntfrontend/components/documents/participation-members/membership/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "01w3Tva0",
    "block": "{\"symbols\":[],\"statements\":[[5,\"users/profile-box\",[],[[\"@user\",\"@onUserChange\",\"@editable\",\"@class\",\"@tooltipTitleKey\",\"@includeCollaborators\",\"@scope\",\"@role\"],[[23,\"user\"],[29,\"action\",[[24,0,[]],\"saveMembership\"],null],[23,\"editable\"],\"profile-box--owner\",[23,\"tooltipTitleKey\"],[23,\"includeCollaborators\"],[23,\"model\"],[25,[\"member\",\"role\"]]]],{\"statements\":[[0,\"\\n  \"],[5,\"documents/participation-members/dropdown\",[],[[\"@model\",\"@member\"],[[23,\"model\"],[23,\"member\"]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/documents/participation-members/membership/template.hbs"
    }
  });

  _exports.default = _default;
});