define("ntfrontend/components/works/plan-tab/milestone-list/header/date-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MCsCjCsh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"milestone__date\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"milestone__date-label text-muted\"],[9],[1,[29,\"t\",[[25,[\"placeholder\"]]],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"milestone__date-edit picker--small\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"deadline\"]]],null,{\"statements\":[[0,\"      \"],[5,\"shared/inplace-datepicker\",[],[[\"@afterChange\",\"@editable\",\"@value\",\"@format\"],[[29,\"action\",[[24,0,[]],\"afterChange\"],null],true,[25,[\"model\",\"deadlineAt\"]],[23,\"format\"]]],{\"statements\":[[0,\"\\n        \"],[1,[29,\"format-date\",[[25,[\"model\",\"deadlineAt\"]]],[[\"format\"],[\"short\"]]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"shared/inplace-datepicker\",[],[[\"@afterChange\",\"@editable\",\"@value\",\"@format\"],[[29,\"action\",[[24,0,[]],\"afterChange\"],null],true,[25,[\"model\",\"startAt\"]],[23,\"format\"]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,[\"model\",\"startAt\"]]],null,{\"statements\":[[0,\"          \"],[1,[29,\"format-date\",[[25,[\"model\",\"startAt\"]]],[[\"format\"],[\"short\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"span\"],[11,\"class\",\"placeholder\"],[9],[1,[29,\"t\",[\"shared.labels.notSpecified\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/works/plan-tab/milestone-list/header/date-picker/template.hbs"
    }
  });

  _exports.default = _default;
});