define("ntfrontend/components/shared/header-sort/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UWMs3O9F",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"t\",[[25,[\"label\"]]],null],false],[7,\"i\"],[12,\"class\",[23,\"iconClass\"]],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/header-sort/template.hbs"
    }
  });

  _exports.default = _default;
});