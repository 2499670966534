define("ntfrontend/services/jwt", ["exports", "ember-jwt-decode"], function (_exports, _emberJwtDecode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    verify: function verify(token) {
      try {
        return (0, _emberJwtDecode.default)(token);
      } catch (e) {
        return null;
      }
    }
  });

  _exports.default = _default;
});