define("ntfrontend/components/documents/new-document/select-project/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VnohPc2g",
    "block": "{\"symbols\":[\"project\"],\"statements\":[[7,\"div\"],[12,\"data-select-placeholder\",[29,\"t\",[\"shared.labels.projectPlaceholder\"],null]],[12,\"class\",[23,\"classes\"]],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"options\",\"searchField\",\"selected\",\"tagName\",\"loadingMessage\",\"onchange\",\"click\",\"onclose\"],[[25,[\"projects\"]],\"name\",[25,[\"project\"]],\"div\",\"Fetching available projects\",[29,\"action\",[[24,0,[]],\"selectProject\"],null],[29,\"action\",[[24,0,[]],\"change\",true],null],[29,\"action\",[[24,0,[]],\"change\",false],null]]],{\"statements\":[[0,\"    \"],[1,[24,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[5,\"shared/loading-state/block\",[],[[\"@model\",\"@tagName\"],[[23,\"projects\"],\"\"]]],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/documents/new-document/select-project/template.hbs"
    }
  });

  _exports.default = _default;
});