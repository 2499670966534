define("ntfrontend/components/works/plan-tab/indicator-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J9IRQH+c",
    "block": "{\"symbols\":[\"@variance\"],\"statements\":[[4,\"if\",[[25,[\"isMilestoneHeader\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[\"milestone__plan \",[24,0,[\"arrowColor\"]]]]],[9],[0,\"\\n    \"],[7,\"span\"],[12,\"class\",[30,[\"milestone__plan-icon icon-arrow-\",[24,0,[\"arrowDirection\"]],\"-sm\"]]],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"milestone__plan-text\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"milestone__plan-color\"],[9],[1,[29,\"t\",[\"shared.percentValue\"],[[\"value\"],[[29,\"abs\",[[24,1,[]]],null]]]],false],[0,\" \"],[1,[29,\"t\",[[24,0,[\"arrowLabel\"]]],null],false],[10],[0,\"\\n      \"],[1,[29,\"t\",[\"work.labels.thanPlanned\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\"],[12,\"class\",[30,[\"plan-work__variance-icon icon-arrow-\",[24,0,[\"arrowDirection\"]],\"-sm \",[24,0,[\"arrowColor\"]]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/works/plan-tab/indicator-status/template.hbs"
    }
  });

  _exports.default = _default;
});