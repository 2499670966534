define("ntfrontend/components/shared/n-query/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    bullet: Ember.inject.service(),
    details: Ember.computed('bullet.resultnQueries', function () {
      var sum = this.bullet.resultSumOfQueries;
      var dup = this.bullet.resultSumOfDuplicates;
      var meta = "Queries: ".concat(sum, " | Duplicates: ").concat(dup);
      var nqueries = this.get('bullet.resultnQueries').join('<hr>');
      return Ember.String.htmlSafe("".concat(meta, "<hr>").concat(nqueries));
    })
  });

  _exports.default = _default;
});