define("ntfrontend/components/shared/kanban-properties-switch/component", ["exports", "ntfrontend/mixins/components/open-dropdown"], function (_exports, _openDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_openDropdown.default, {
    classNames: ['dropdown'],
    allColumns: [{
      name: 'status',
      label: 'kanban.labels.status'
    }, {
      name: 'documentType',
      label: 'kanban.labels.documentType'
    }],
    modelHasTypes: Ember.computed('model.@each.documentTypes', function () {
      return this.model.any(function (item) {
        return item.get('documentType.name') !== undefined;
      });
    }),
    didRender: function didRender() {
      this._super.apply(this, arguments);

      if (this.column === undefined) {
        this.set('column', {
          name: 'status',
          label: 'kanban.labels.status'
        });
      }
    },
    actions: {
      changeProperty: function changeProperty(column) {
        this.set('column', column);
      }
    }
  });

  _exports.default = _default;
});