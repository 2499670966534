define("ntfrontend/mixins/components/document-show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentSession: Ember.inject.service(),
    filterUsers: [],
    disabled: Ember.computed('model.availableMembers.length', function () {
      var _this = this;

      return this.get('model.availableMembers').filter(function (member) {
        return member.get('user.id') !== _this.get('currentSession.user.id');
      }).get('length') < 1;
    }),
    filteredMembers: Ember.computed('model.availableMembers', 'filterUsers', function () {
      var _this2 = this;

      return this.get('model.availableMembers').filter(function (member) {
        return !_this2.filterUsers.mapBy('id').includes(member.get('user.id'));
      });
    })
  });

  _exports.default = _default;
});