define("ntfrontend/components/dashboard/resource-count/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+oIuFUZZ",
    "block": "{\"symbols\":[],\"statements\":[[5,\"shared/loading-state/block\",[],[[\"@model\",\"@tagName\"],[[23,\"resource\"],\"\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"number__number\"],[9],[0,\"\\n    \"],[1,[23,\"resourceValue\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/dashboard/resource-count/template.hbs"
    }
  });

  _exports.default = _default;
});