define("ntfrontend/components/shared/inplace-datepicker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z4kInql7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"isEditing\"]]],null,{\"statements\":[[0,\"  \"],[5,\"shared/input-datefield\",[],[[\"@changeDate\",\"@value\",\"@format\"],[[29,\"action\",[[24,0,[]],\"doneEditing\"],null],[23,\"value\"],[23,\"format\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[24,0,[\"datePickerClass\"]]],[9],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n\"],[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"        \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"label\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"class\",\"text-muted\"],[9],[1,[23,\"label\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n        \"],[5,\"shared/calendar-date\",[],[[\"@date\",\"@editable\"],[[23,\"value\"],[23,\"editable\"]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[3,\"action\",[[24,0,[]],\"toggleEditing\"]],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"clearable\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"class\",\"icon-clear text-danger text-muted\"],[9],[1,[29,\"t\",[\"shared.actions.clearDate\"],null],false],[3,\"action\",[[24,0,[]],\"clearDate\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/inplace-datepicker/template.hbs"
    }
  });

  _exports.default = _default;
});