define("ntfrontend/components/shared/drop-zone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nKxQH10O",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"isClickable\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[12,\"class\",[30,[\"drop-zone__label \",[29,\"if\",[[25,[\"isContentHidden\"]],\"-hidden\"],null]]]],[11,\"for\",\"drop-zone-file-input\"],[9],[0,\"\\n    \"],[15,1,[[29,\"hash\",null,[[\"content\"],[[29,\"component\",[[25,[\"contentComponent\"]]],null]]]]]],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"input\"],[11,\"class\",\"drop-zone__input\"],[11,\"id\",\"drop-zone-file-input\"],[12,\"onchange\",[29,\"action\",[[24,0,[]],\"onInputChange\"],null]],[11,\"type\",\"file\"],[9],[10],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[15,1,[[29,\"hash\",null,[[\"content\"],[[29,\"component\",[[25,[\"contentComponent\"]]],null]]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[25,[\"isDraggable\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[\"drop-zone-overlay \",[29,\"unless\",[[25,[\"isContentHidden\"]],\"-absolute\"],null]]]],[9],[0,\"\\n    \"],[15,1,[[29,\"hash\",null,[[\"overlay\"],[[29,\"component\",[[25,[\"overlayComponent\"]]],null]]]]]],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"drop-zone-overlay -absolute\"],[12,\"ondragenter\",[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"isDraggedOnOverlay\"]]],null],true],null]],[12,\"ondragleave\",[29,\"action\",[[24,0,[]],\"onDragLeave\"],null]],[12,\"ondrop\",[29,\"action\",[[24,0,[]],\"onFileDrop\"],null]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/drop-zone/template.hbs"
    }
  });

  _exports.default = _default;
});