define("ntfrontend/components/pagination-list/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['isActive:active'],
    isActive: Ember.computed('page', 'currentPage', function () {
      return this.page === this.currentPage;
    }),
    click: function click() {
      this.changePageNumber(this.page);
    }
  });

  _exports.default = _default;
});