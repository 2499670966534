define("ntfrontend/components/tasks/watcher-item/dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xOFfsFv0",
    "block": "{\"symbols\":[],\"statements\":[[5,\"shared/actions/more-actions-button\",[],[[],[]]],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"openDropdown\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\"],[11,\"class\",\"dropdown-menu dropdown-menu-right\"],[9],[0,\"\\n    \"],[5,\"shared/loading-state/block\",[],[[\"@model\",\"@tagName\"],[[25,[\"model\",\"ability\"]],\"\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[29,\"can\",[\"create members in task\",[25,[\"task\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[0,\"\\n          \"],[5,\"shared/actions/remove-button\",[],[[\"@model\",\"@afterRemove\",\"@tagName\"],[[23,\"model\"],[29,\"action\",[[24,0,[]],\"afterRemove\"],null],\"a\"]],{\"statements\":[[0,\"\\n            \"],[1,[29,\"t\",[\"shared.actions.remove\"],null],false],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/tasks/watcher-item/dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});