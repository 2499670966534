define("ntfrontend/components/projects/add-new-project/form/import-project-type/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    addClass: null,
    attributeBindings: ['dataPlaceholder:data-select-placeholder'],
    classes: Ember.computed('addClass', function () {
      return "form-wrapper ".concat(this.addClass);
    }),
    projectTypesAccountName: Ember.computed.mapBy('projectTypes', 'accountName'),
    currentAccount: Ember.computed.alias('currentSession.account'),
    projectTypeRequiresImport: Ember.computed('project.projectType', function () {
      var projectTypesIds = this._projectTypesById();

      var projectTypeId = this.get('project.projectType.id');
      return projectTypeId && !projectTypesIds.includes(projectTypeId);
    }),
    accountsNames: Ember.computed('projectTypesAccountName.length', 'currentAccount.name', function () {
      var accountsNames = this.projectTypesAccountName;
      accountsNames.unshift(this.get('currentAccount.name'));
      return accountsNames.uniq();
    }),
    groupedProjectTypes: Ember.computed('accountsNames.[]', 'projectTypes.[]', function () {
      var _this = this;

      return this.accountsNames.map(function (accountName) {
        var types = _this._filterProjectTypesByAccount(accountName);

        return types.length > 0 ? {
          groupName: accountName,
          options: types
        } : {};
      });
    }),
    actions: {
      change: function change(value) {
        return value ? this.set('addClass', 'project__selected') : this._setClassesByProject();
      }
    },
    _filterProjectTypesByAccount: function _filterProjectTypesByAccount(accountName) {
      return this.projectTypes.filterBy('accountName', accountName).sortBy('createdAt');
    },
    _projectTypesById: function _projectTypesById() {
      return this.projectTypes.mapBy('id');
    },
    _setClassesByProject: function _setClassesByProject() {
      return this.set('addClass', this.project.projectType ? 'project__selected project__closed' : 'notSelected');
    }
  });

  _exports.default = _default;
});