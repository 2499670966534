define("ntfrontend/components/shared/activity-log/component", ["exports", "ntfrontend/helpers/model-type-name"], function (_exports, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    modelName: Ember.computed('resource', function () {
      return (0, _modelTypeName.modelTypeName)([this.resource]);
    }),
    activityLogs: Ember.computed('resource.id', 'modelName', function () {
      var resourceId = this.get('resource.id');
      var resourceType = this.modelName;
      return this.store.query('activityLog', {
        filter: {
          resourceId: resourceId,
          resourceType: resourceType
        },
        include: 'creator'
      });
    })
  });

  _exports.default = _default;
});