define("ntfrontend/services/errors-notifier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    notify: Ember.inject.service('notify-alert'),
    processErrors: function processErrors(errors) {
      if (Ember.isPresent(errors)) {
        this._displayErrorMessage(errors);
      }
    },
    _displayErrorMessage: function _displayErrorMessage(errors) {
      var _this = this;

      if (errors && errors.length) {
        errors.forEach(function (error) {
          var translate = _this.intl.errorKeys(error.code);

          _this.notify.error(translate.message, translate.title);
        });
      } else {
        this.notify.error();
      }
    }
  });

  _exports.default = _default;
});