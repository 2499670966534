define("ntfrontend/components/documents/details/work-package/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bebeYlva",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[29,\"can\",[\"show detail of document\",[25,[\"model\"]]],[[\"detail\"],[[25,[\"model\",\"workPackage\",\"name\"]]]]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"can\",[\"edit document\",[25,[\"model\"]]],null]],null,{\"statements\":[[0,\"      \"],[5,\"documents/details/work-package/dropdown\",[],[[\"@model\"],[[23,\"model\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"shared/labeled-row\",[],[[\"@label\"],[\"shared.labels.workPackage\"]],{\"statements\":[[0,\"\\n        \"],[1,[25,[\"model\",\"workPackage\",\"name\"]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/documents/details/work-package/template.hbs"
    }
  });

  _exports.default = _default;
});