define("ntfrontend/components/documents/details/work-package/dropdown/menu/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _jquery.default)('.dropdown', this.element).on('show.bs.dropdown', function () {
        if (!_this.shownDropdown) {
          _this.set('shownDropdown', true);
        }
      });
    }
  });

  _exports.default = _default;
});