define("ntfrontend/components/steps/step-item/component", ["exports", "ntfrontend/mixins/components/model-saving", "ntfrontend/mixins/components/reference-refresh", "ntfrontend/helpers/type-anchor"], function (_exports, _modelSaving, _referenceRefresh, _typeAnchor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, _referenceRefresh.default, {
    classNames: 'step__item',
    can: Ember.inject.service(),
    fileUpload: Ember.inject.service(),
    taskDistributionNotifier: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('elementId', (0, _typeAnchor.typeAnchor)([this.model]));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!this.get('model.hasTasks')) {
        this.set('agendaMode', false);
      }
    },
    isOpen: Ember.computed.equal('model.status', 0),
    isEmptyOutcome: Ember.computed.empty('model.outcome'),
    editable: Ember.computed('model', function () {
      return this.can.can('edit step', this.model);
    }),
    displayEmptyState: Ember.computed('model.{hasTasks,attachments,transportDocuments}', 'editable', 'attachmentsLoaded', function () {
      var editable = this.editable,
          _this$model = this.model,
          attachments = _this$model.attachments,
          transportDocuments = _this$model.transportDocuments;
      return !(this.get('model.hasTasks') || Ember.isPresent(attachments) || Ember.isPresent(transportDocuments)) && editable;
    }),
    isOutcomeEditable: Ember.computed('isEmptyOutcome', 'isOpen', function () {
      return !(this.isOpen && this.isEmptyOutcome);
    }),
    actions: {
      afterRemove: function afterRemove() {
        this.notify.success('notify.step.removed');
      },
      attachmentsFulfilled: function attachmentsFulfilled() {
        this.set('attachmentsLoaded', true);
      },
      addAttachments: function addAttachments(files) {
        var _this = this;

        this.fileUpload.uploadAttachmentsFor(files, this.model).then(function () {
          _this.taskDistributionNotifier.notifyWithReloadMembers(_this.get('model.members'));
        });
      }
    }
  });

  _exports.default = _default;
});