define("ntfrontend/components/documents/details/status-select/modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ul3eonCm",
    "block": "{\"symbols\":[\"mat-label\"],\"statements\":[[5,\"shared/modal-dialog\",[],[[\"@showModal\",\"@onClose\",\"@onSuccess\",\"@title\"],[[23,\"showModal\"],[29,\"action\",[[24,0,[]],\"onClose\"],null],[29,\"action\",[[24,0,[]],\"saveModel\"],null],[29,\"t\",[\"dashboard.actions.updateStatus\"],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-body\"],[9],[0,\"\\n    \"],[5,\"shared/form-input\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"shared/material-labels\",[],[[\"@key\",\"@labelFor\",\"@placeholder\"],[\"hasDescription\",\"inputDescription\",[29,\"t\",[\"dashboard.placeholders.description\"],null]]],{\"statements\":[[0,\"\\n        \"],[5,\"input\",[],[[\"@autofocus\",\"@class\",\"@id\",\"@type\",\"@value\",\"@key-up\"],[\"true\",\"form-control input-label\",\"inputDescription\",\"text\",[23,\"description\"],[24,1,[\"changeValue\"]]]]],[0,\"\\n      \"]],\"parameters\":[1]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[5,\"shared/form-confirm-button\",[],[[\"@disabled\",\"@label\"],[[23,\"disabled\"],[29,\"t\",[\"shared.actions.update\"],null]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/documents/details/status-select/modal/template.hbs"
    }
  });

  _exports.default = _default;
});