define("ntfrontend/components/memos/list-item/component", ["exports", "ntfrontend/mixins/components/lists/hidden-custom-values-filter", "ntfrontend/mixins/components/lists/mouse-over-async-abilities"], function (_exports, _hiddenCustomValuesFilter, _mouseOverAsyncAbilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_hiddenCustomValuesFilter.default, _mouseOverAsyncAbilities.default, {
    tagName: 'tbody',
    classNameBindings: ['model.archived:row-muted'],
    customValuesCollectionType: 'memos'
  });

  _exports.default = _default;
});