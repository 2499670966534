define("ntfrontend/components/shared/property-label/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['colorMapValue'],
    propertyValue: Ember.computed('property', 'list', function () {
      return this.list[this.property];
    }),
    propertyLabel: Ember.computed('propertyValue', function () {
      if (this.translationKey) {
        return this.translationKey;
      } else {
        return "".concat(this.translationScope, ".").concat(this.propertyValue);
      }
    }),
    didRender: function didRender() {
      this._super.apply(this, arguments);

      var colorMap = this.colorMap,
          coloredStatus = this.coloredStatus,
          property = this.property;

      if (coloredStatus) {
        this.set('colorMapValue', colorMap[property]);
      }
    },
    colorMap: {
      1: 'colored-status -green',
      2: 'colored-status -grey',
      3: 'colored-status -orange',
      4: 'colored-status -blue',
      5: 'colored-status -yellow',
      6: 'colored-status -red',
      7: 'colored-status -black'
    }
  });

  _exports.default = _default;
});