define("ntfrontend/components/projects/project-filters/status/component", ["exports", "ntfrontend/models/project"], function (_exports, _project) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['filter'],
    statuses: Ember.computed(function () {
      return _project.statuses;
    }),
    actions: {
      changeStatus: function changeStatus(status) {
        this.set('value', status);
      },
      resetFilter: function resetFilter() {
        this.set('value', null);
      }
    }
  });

  _exports.default = _default;
});