define("ntfrontend/components/topics/add-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dropdown'],
    currentSession: Ember.inject.service(),
    account: Ember.computed.alias('currentSession.account'),
    topicIsNotEmpty: Ember.computed.or('topic.hasTasks', 'topic.attachments.length', 'topic.transportDocuments.length'),
    topicIsEmpty: Ember.computed.not('topicIsNotEmpty')
  });

  _exports.default = _default;
});