define("ntfrontend/components/shared/attachments/file-uploader/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    fileUpload: Ember.inject.service(),
    onSuccess: function onSuccess() {},
    attachments: Ember.computed('model.attachments', function () {
      return this.get('model.attachments') || [];
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.element.querySelector("input[type='file']").onclick = function (event) {
        event.stopPropagation();
      };
    },
    click: function click() {
      this.element.querySelector("input[type='file']").click();
    },
    actions: {
      uploadAttachments: function uploadAttachments(files) {
        if (!this.get('model.id')) {
          this._createAttachments(files);
        } else if (!Ember.isEmpty(files)) {
          this._uploadFiles(files);
        }
      }
    },
    _concatAttachments: function _concatAttachments(attachments) {
      this.set('model.attachments', this.attachments.concat(attachments));
    },
    _uploadFiles: function _uploadFiles(files) {
      var _this = this;

      this.fileUpload.uploadAttachmentsFor(files, this.model).then(function () {
        _this._clearInput();

        _this.onSuccess();
      });
    },
    _clearInput: function _clearInput() {
      this.element.querySelector('input').value = '';
    },
    _createAttachments: function _createAttachments(files) {
      var _this2 = this;

      this.fileUpload.createAttachments(files, this.model).then(function (attachments) {
        _this2._concatAttachments(attachments);

        _this2._clearInput();
      });
    }
  });

  _exports.default = _default;
});