define("ntfrontend/components/shared/attachments/file-loader/component", ["exports", "ember-uploader/components/file-field"], function (_exports, _fileField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fileField.default.extend({
    multiple: false,
    classNames: ['hide'],
    filesDidChange: function filesDidChange(files) {
      this.afterChange(files);
    }
  });

  _exports.default = _default;
});