define("ntfrontend/components/templates/document-templates/edit/topics/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kZY1Aw7w",
    "block": "{\"symbols\":[\"topic\",\"editable\",\"agendaMode\",\"expandTopic\"],\"statements\":[[5,\"topics/topic-list\",[],[[\"@model\",\"@editable\"],[[23,\"model\"],[23,\"editable\"]]],{\"statements\":[[0,\"\\n  \"],[5,\"topics/template-topic-item\",[],[[\"@model\",\"@editable\",\"@agendaMode\",\"@expandTopic\"],[[24,1,[]],[24,2,[]],[24,3,[]],[24,4,[]]]]],[0,\"\\n\"]],\"parameters\":[1,2,3,4]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/templates/document-templates/edit/topics/template.hbs"
    }
  });

  _exports.default = _default;
});