define("ntfrontend/services/current-session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    can: Ember.inject.service(),
    fetcher: Ember.inject.service(),
    store: Ember.inject.service(),
    rollbar: Ember.inject.service(),
    zendesk: Ember.inject.service(),
    session: Ember.inject.service(),
    endpoint: Ember.inject.service(),
    errorsNotifier: Ember.inject.service(),
    phoenixChannel: Ember.inject.service(),
    notify: Ember.inject.service('notify-alert'),
    serverRegisterEndpoint: Ember.computed.readOnly('endpoint.register'),
    resetPasswordEndpoint: Ember.computed.readOnly('endpoint.resetPassword'),
    confirmationEndpoint: Ember.computed.readOnly('endpoint.confirmation'),
    magicLinkEndpoint: Ember.computed.readOnly('endpoint.magicLink'),
    loggedIn: Ember.computed.bool('session.isAuthenticated'),
    subdomain: Ember.computed.reads('urlChecker.subdomain'),
    isApp: Ember.computed.reads('urlChecker.isApp'),
    authenticatedId: Ember.computed.reads('session.data.authenticated.user.id'),
    authToken: Ember.computed.readOnly('session.data.authenticated.token'),
    user: null,
    account: null,
    ensureIdentity: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.userObserver();
      this.connectionObserver();
    },
    userObserver: Ember.observer('loggedIn', 'authenticatedId', function () {
      if (this.loggedIn) {
        this._setLoggerSession();

        return this.fetchUser();
      }
    }),
    connectionObserver: Ember.observer('user.email', function () {
      if (!this.get('phoenixChannel.connection.endpoint') && this.get('user.email')) {
        var token = this.authToken;
        var userEmail = this.get('user.email');
        var socket = this.phoenixChannel;
        socket.connect(userEmail, token);
        socket.joinNotificationsChannel(this.get('user.id'));
      }
    }),
    fetchUser: function fetchUser() {
      var _this = this;

      var id = this.authenticatedId;
      var userPromise = id ? this.store.findRecord('user', id, {
        include: 'ability'
      }) : Ember.RSVP.resolve(null);
      return userPromise.then(function (user) {
        return _this.set('user', user) && user;
      });
    },
    fetchAccount: function fetchAccount() {
      if (this.get('urlChecker.isApp')) {
        return Ember.RSVP.resolve();
      }

      var tmpAccount = this.store.createRecord('account');
      return tmpAccount.current({
        include: 'ability'
      }).then(function (current) {
        return current;
      }).finally(function () {
        return tmpAccount.deleteRecord();
      });
    },
    logout: function logout() {
      return this.session.invalidate(this.get('session.data.authenticated.token'));
    },
    login: function login(email, password) {
      var _this2 = this;

      return this.session.authenticate('authenticator:devise', email, password).then(function () {
        _this2.notify.success('notify.auth.login.successful');

        _this2.checkAccount();
      }).catch(function (errors) {
        if (!!errors && errors[0].code === 1005) {
          _this2.resetPassword(email);
        } else {
          _this2.errorsNotifier.processErrors(errors);

          throw new Error();
        }
      });
    },
    loginWithToken: function loginWithToken(token) {
      var _this3 = this;

      if (this.loggedIn) {
        return Ember.RSVP.resolve();
      } else {
        return this.session.authenticate('authenticator:token', token).then(function () {
          _this3.checkAccount().then(function () {
            _this3.notify.success('notify.auth.login.successful');
          });
        });
      }
    },
    sendMagicLink: function sendMagicLink(data) {
      var _this4 = this;

      var options = {
        data: {
          data: data
        }
      };
      return this.fetcher.post(this.magicLinkEndpoint, options).then(function () {
        _this4.notify.success('notify.auth.login.magicLink');
      }).catch(function (errors) {
        _this4.errorsNotifier.processErrors(errors);
      });
    },
    resetPassword: function resetPassword(email) {
      var options = {
        data: {
          email: email
        }
      };
      return this.fetcher.post(this.resetPasswordEndpoint, options);
    },
    newPassword: function newPassword(user) {
      var _this5 = this;

      var options = {
        data: {
          user: user
        }
      };
      return this.fetcher.put(this.resetPasswordEndpoint, options).then(function () {
        _this5.notify.success('notify.auth.password.reset');
      }).catch(function (errors) {
        _this5.errorsNotifier.processErrors(errors);
      });
    },
    resendActivationMail: function resendActivationMail(email) {
      var _this6 = this;

      var options = {
        data: {
          user: {
            email: email
          }
        }
      };
      return this.fetcher.post(this.confirmationEndpoint, options).then(function () {
        _this6.notify.success('notify.auth.user.resend');
      }).catch(function (errors) {
        _this6.errorsNotifier.processErrors(errors);
      });
    },
    register: function register(user) {
      var _this7 = this;

      var options = {
        data: {
          user: user
        }
      };
      return this.fetcher.post(this.serverRegisterEndpoint, options).then(function () {
        _this7.notify.success('notify.auth.user.register');
      }).catch(function (errors) {
        _this7.errorsNotifier.processErrors(errors);
      });
    },
    confirm: function confirm(token) {
      var _this8 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var options = {
          data: {
            token: token
          }
        };
        return _this8.fetcher.get(_this8.confirmationEndpoint, options).then(function (data) {
          _this8.notify.success('notify.auth.user.confirmed');

          resolve(data.authToken);
        }).catch(function () {
          return reject();
        });
      });
    },
    checkAccount: function checkAccount() {
      var _this9 = this;

      return this.fetchAccount().then(function (account) {
        if (_this9._accountExist(account) && _this9._isAuthorized(account)) {
          _this9.set('account', account);

          return Ember.RSVP.resolve(account);
        } else {
          return Ember.RSVP.reject(account);
        }
      }).catch(function (errors) {
        _this9.errorsNotifier.processErrors(errors);
      });
    },
    _isAuthorized: function _isAuthorized(account) {
      if (this.can.can('visit account', account) || !this.loggedIn) {
        return true;
      } else {
        this.notify.error('notify.organization.noAccess');
        return false;
      }
    },
    _accountExist: function _accountExist(account) {
      if (account) {
        return true;
      } else {
        if (!this.isApp) {
          this.notify.error('notify.organization.notExist');
        }

        return false;
      }
    },
    _setLoggerSession: function _setLoggerSession() {
      var session = {
        id: this.authenticatedId,
        email: this.get('session.data.authenticated.email')
      };
      this.set('rollbar.currentUser', session);
    },
    zendeskSession: Ember.observer('loggedIn', 'user.{name,email}', function () {
      if (this.get('user.isLoaded')) {
        this.zendesk.identify(this.get('user.name'), this.get('user.email'));
      }
    })
  });

  _exports.default = _default;
});