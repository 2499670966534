define("ntfrontend/components/shared/loading-state/block/placeholder/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WAwbK+yz",
    "block": "{\"symbols\":[\"placeholder\",\"&default\"],\"statements\":[[4,\"if\",[[25,[\"loaded\"]]],null,{\"statements\":[[0,\"  \"],[15,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"content-placeholders\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[24,1,[\"heading\"]],[],[[\"@img\"],[[23,\"useImage\"]]]],[0,\"\\n    \"],[6,[24,1,[\"text\"]],[],[[\"@lines\"],[[23,\"numberOfLines\"]]]],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/loading-state/block/placeholder/template.hbs"
    }
  });

  _exports.default = _default;
});