define("ntfrontend/components/templates/document-templates/document-sneak-peek/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    paneManager: Ember.inject.service(),
    model: Ember.computed.alias('paneManager.sneakPeekDocumentTemplate'),
    actions: {
      hidePane: function hidePane() {
        this.paneManager.closePane('documentTransport');
      },
      attachmentsFulfilled: function attachmentsFulfilled() {
        this.set('attachmentsLoaded', true);
      }
    }
  });

  _exports.default = _default;
});