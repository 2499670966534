define("ntfrontend/models/member", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    deliveryStatus: attr(),
    role: attr(),
    user: belongsTo(),
    projectMember: belongsTo(),
    resource: belongsTo('resource', {
      polymorphic: true,
      inverse: 'members'
    }),
    roles: hasMany(),
    documentTemplate: hasMany()
  });

  _exports.default = _default;
});