define("ntfrontend/components/accounts/document-types/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentSession: Ember.inject.service(),
    store: Ember.inject.service(),
    account: Ember.computed.alias('currentSession.account'),
    templates: Ember.computed('account.id', function () {
      return this.store.query('project-type', {
        filter: {
          accountId: this.get('account.id')
        }
      });
    })
  });

  _exports.default = _default;
});