define("ntfrontend/initializers/url-checker", ["exports", "ntfrontend/config/environment", "ntfrontend/utils/url-maker"], function (_exports, _environment, _urlMaker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var urlChecker = Ember.Object.extend({
    isApp: Ember.computed('subdomain', function () {
      return this.subdomain === 'app';
    }),
    subdomain: Ember.computed('hostname', function () {
      return _urlMaker.default.subdomain();
    }),
    appUrl: Ember.computed(function () {
      return _urlMaker.default.subdomainUrl('app');
    }),
    hostname: Ember.computed('customURI', function () {
      return _urlMaker.default.hostname();
    }),
    isStaging: Ember.computed(function () {
      return this.environment === 'staging';
    }),
    environment: Ember.computed(function () {
      switch (_urlMaker.default.hostNameOnly()) {
        case '9teams.com':
          return 'production';

        case 'betateams.com':
          return 'staging';

        default:
          return 'local';
      }
    }),
    normalize: function normalize(subdomain) {
      return _environment.default.subdomainMapping[subdomain] || subdomain;
    },
    subdomainUrl: function subdomainUrl(subdomain) {
      var secure = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      return _urlMaker.default.subdomainUrl(subdomain, secure);
    },
    customURI: ''
  });
  var _default = {
    name: 'subdomain',
    initialize: function initialize(App) {
      App.register('url-checker:main', urlChecker);
      App.inject('route', 'urlChecker', 'url-checker:main');
      App.inject('controller', 'urlChecker', 'url-checker:main');
      App.inject('component', 'urlChecker', 'url-checker:main');
      App.inject('service', 'urlChecker', 'url-checker:main');
    },
    urlChecker: urlChecker
  };
  _exports.default = _default;
});