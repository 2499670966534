define("ntfrontend/components/auth/register-form/country-select/selected/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['selected-country'],
    countryFlag: Ember.computed('option', function () {
      return "https://lipis.github.io/flag-icon-css/flags/4x3/".concat(this.get('option.code').toLowerCase(), ".svg");
    })
  });

  _exports.default = _default;
});