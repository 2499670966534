define("ntfrontend/components/tasks/task-details/dropdown/component", ["exports", "ember-inflector", "ntfrontend/helpers/model-type-name", "ntfrontend/mixins/components/model-saving", "ntfrontend/mixins/components/open-dropdown"], function (_exports, _emberInflector, _modelTypeName, _modelSaving, _openDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, _openDropdown.default, {
    sweetAlert: Ember.inject.service('sweet-alert'),
    router: Ember.inject.service(),
    classNames: ['dropdown'],
    dropdownModel: Ember.computed.alias('model.ability'),
    documentRoute: Ember.computed('model.document', function () {
      var documentName = (0, _modelTypeName.modelTypeName)([this.get('model.document')]).camelize();
      return "".concat((0, _emberInflector.pluralize)(documentName), ".").concat(documentName);
    }),
    onRemove: function onRemove() {},
    actions: {
      remove: function remove() {
        var _this = this;

        var documentRoute = this.documentRoute;
        var documentSlug = this.get('model.document.slug');
        this.model.destroyRecord().then(function () {
          var urlToRedirect = _this.router.urlFor(documentRoute, documentSlug);

          _this.router.transitionTo(urlToRedirect);

          _this.notify.success('notify.task.removed');

          _this.onRemove();
        });
      },
      cloneDataTask: function cloneDataTask(task) {
        var completed = task.completed,
            creator = task.creator,
            deadlineAt = task.deadlineAt,
            description = task.description,
            name = task.name,
            owner = task.owner,
            requester = task.requester;
        var tempTask = Ember.Object.create({
          completed: completed,
          creator: creator,
          deadlineAt: deadlineAt,
          description: description,
          name: name,
          owner: owner,
          requester: requester
        });
        this.set('modalTask', tempTask);
      }
    }
  });

  _exports.default = _default;
});