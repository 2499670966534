define("ntfrontend/models/request", ["exports", "ember-data", "ntfrontend/models/document", "ntfrontend/mixins/models/tagable", "ntfrontend/mixins/models/topicable"], function (_exports, _emberData, _document, _tagable, _topicable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.statuses = void 0;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var statuses = {
    1: 'open',
    2: 'investigating',
    3: 'forApproval',
    4: 'implementing',
    5: 'testing',
    6: 'onHold',
    7: 'closed'
  };
  _exports.statuses = statuses;

  var _default = _document.default.extend(_topicable.default, _tagable.default, {
    dueDate: attr('date'),
    statusDescription: attr(),
    status: attr('number', {
      defaultValue: 1
    }),
    requester: belongsTo('user'),
    statuses: Ember.computed(function () {
      return statuses;
    })
  });

  _exports.default = _default;
});