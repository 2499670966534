define("ntfrontend/components/shared/activity-log/changes/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    availableTypes: Ember.computed(function () {
      return {
        completed: 'status',
        comment: 'comment',
        description: 'description',
        deadlineAt: 'due-date'
      };
    }).readOnly(),
    changesComponentPath: Ember.computed('changeName', function () {
      var basePath = 'shared/activity-log/changes';
      var componentName = this.availableTypes[this.changeName.camelize()] || 'default';
      return "".concat(basePath, "/").concat(componentName);
    })
  });

  _exports.default = _default;
});