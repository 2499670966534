define("ntfrontend/components/projects/project-card/-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JDUauWth",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"tile__overview-status\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"statusDescription\"]]],null,{\"statements\":[[0,\"    \"],[5,\"bs-tooltip\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[23,\"statusDescription\"],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[5,\"projects/project-card/-header/-status\",[],[[\"@model\",\"@editable\",\"@resourceType\"],[[23,\"project\"],[23,\"editable\"],\"project\"]]],[0,\"\\n\"],[10],[0,\"\\n\"],[5,\"projects/project-card/-header/-progress-bar\",[],[[\"@model\",\"@editable\"],[[23,\"project\"],[23,\"editable\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/project-card/-header/template.hbs"
    }
  });

  _exports.default = _default;
});