define("ntfrontend/components/shared/copy-to-clipboard/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      copyProperty: function copyProperty() {
        var _this = this;

        this.set('propertyCopied', true);

        this._copyStringToClipboard(this.property);

        Ember.run.later(function () {
          return _this.set('propertyCopied', false);
        }, 1000);
      }
    },
    _copyStringToClipboard: function _copyStringToClipboard(str) {
      var el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }
  });

  _exports.default = _default;
});