define("ntfrontend/components/shared/power-select-with-placeholder/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var searchField = this.objectType == 'member' ? 'user.name' : 'name';
      this.set('searchField', searchField);
    },
    isActive: false,
    modifier: Ember.computed('isActive', function () {
      return this.isActive ? '--is-selected' : '--dashed';
    }),
    triggerClass: Ember.computed('isActive', function () {
      return "ember-power-select-trigger".concat(this.modifier);
    }),
    item: Ember.computed('value', function () {
      if (this.value) {
        return this.value;
      }
    }),
    isNotEmpty: Ember.computed('item', function () {
      return this.item ? true : false;
    }),
    actions: {
      onchange: function onchange(item) {
        this.onChange(item);
        this.set('item', item);

        this._setSelected();
      },
      onfocus: function onfocus(options) {
        var selected = options.selected,
            highlighted = options.highlighted,
            isOpen = options.isOpen;

        if (selected || highlighted) {
          this._setSelected();
        } else {
          this._setDashed();
        }

        isOpen ? this._setSelected() : this._setDashed();
      },
      onclose: function onclose() {
        this._setDashed();
      }
    },
    _setSelected: function _setSelected() {
      this.set('isActive', true);
    },
    _setDashed: function _setDashed() {
      this.set('isActive', false);
    }
  });

  _exports.default = _default;
});