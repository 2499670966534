define("ntfrontend/components/projects/project-card/-documents/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    classNames: 'tile__overview-documents',
    projectSlug: Ember.computed.alias('project.slug'),
    hasDocuments: Ember.computed('project', function () {
      var resources = this.project.getProperties('memosCount', 'issuesCount', 'requestsCount', 'scenariosCount');
      var resourcesCount = Object.values(resources).reduce(function (acc, resource) {
        return acc + resource;
      });
      return Boolean(resourcesCount);
    }),
    actions: {
      redirectToDocument: function redirectToDocument(documentName) {
        var urlToRedirect = this.router.urlFor("projects.project.".concat(documentName), this.projectSlug);
        this.router.transitionTo(urlToRedirect);
      },
      redirectToCreateMemoPage: function redirectToCreateMemoPage() {
        this.router.transitionTo('create', {
          queryParams: {
            projectSlug: this.projectSlug,
            tab: 'memo'
          }
        });
      }
    }
  });

  _exports.default = _default;
});