define("ntfrontend/components/members/select-role/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    account: Ember.computed.alias('currentSession.account'),
    currentRoleName: Ember.computed('currentRole', function () {
      if (this.currentRole) {
        return this.intl.t("member.roles.".concat(this.get('currentRole.name')));
      }
    }),
    filteredRoles: Ember.computed.filter('roles', function (role) {
      return role.get('name') !== 'owner';
    })
  });

  _exports.default = _default;
});