define("ntfrontend/components/projects/project-details/members/list/component", ["exports", "ntfrontend/components/members/member-list/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    actions: {
      updateWorkManageAbility: function updateWorkManageAbility(member) {
        var _this = this;

        var projectMember = this.store.peekRecord('project-member', member.get('projectMember.id'));
        projectMember.set('canWorkLog', !projectMember.canWorkLog);
        projectMember.save().then(function () {
          _this.notify.success('notify.workLog.updatedPermission');
        });
      }
    }
  });

  _exports.default = _default;
});