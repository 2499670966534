define("ntfrontend/components/shared/loading-state/table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cqGv9llr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"loaded\"]]],null,{\"statements\":[[0,\"  \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"tr\"],[9],[0,\"\\n    \"],[7,\"td\"],[11,\"class\",\"table__loader\"],[12,\"colspan\",[23,\"colspan\"]],[9],[5,\"shared/loading-indicator\",[],[[],[]]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/loading-state/table/template.hbs"
    }
  });

  _exports.default = _default;
});