define("ntfrontend/components/auth/welcome-page/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    classNames: ['col-sm-6', 'auth-page', '-spacing'],
    name: Ember.computed.readOnly('router._router.currentState.routerJsState.fullQueryParams.name')
  });

  _exports.default = _default;
});