define("ntfrontend/components/custom-fields/document-list/item-row/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    can: Ember.inject.service(),
    tagName: '',
    sorting: ['name'],
    sortedValues: Ember.computed.sort('customValues', 'sorting')
  });

  _exports.default = _default;
});