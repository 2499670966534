define("ntfrontend/components/templates/project-templates/edit/document-templates/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BRCJ7gJh",
    "block": "{\"symbols\":[],\"statements\":[[5,\"templates/project-templates/edit/document-templates/document-names\",[],[[\"@documentTemplates\",\"@item\",\"@selectedProjectType\"],[[25,[\"model\",\"documentTemplates\"]],[23,\"projectType\"],[23,\"selectedProjectType\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/templates/project-templates/edit/document-templates/template.hbs"
    }
  });

  _exports.default = _default;
});