define("ntfrontend/components/projects/dashboard/overall-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O+JmYpCx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-header\"],[9],[0,\"\\n    \"],[7,\"h2\"],[11,\"class\",\"h2\"],[9],[0,\"\\n      \"],[1,[29,\"t\",[\"dashboard.labels.overallStatus\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[29,\"can\",[\"createPdf for project\",[25,[\"model\",\"project\"]]],null]],null,{\"statements\":[[0,\"      \"],[5,\"shared/pdf-button\",[],[[\"@model\",\"@button\"],[[25,[\"model\",\"project\"]],true]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"project__status\"],[9],[0,\"\\n    \"],[5,\"projects/dashboard/progress-bar\",[],[[\"@model\",\"@editable\"],[[23,\"model\"],[29,\"can\",[\"manage work in project\",[25,[\"model\",\"project\"]]],null]]]],[0,\"\\n    \"],[5,\"projects/dashboard/state-status\",[],[[\"@model\",\"@property\",\"@editable\"],[[23,\"model\"],\"time\",[29,\"can\",[\"edit project\",[25,[\"model\",\"project\"]]],null]]]],[0,\"\\n    \"],[5,\"projects/dashboard/state-status\",[],[[\"@model\",\"@property\",\"@editable\"],[[23,\"model\"],\"budget\",[29,\"can\",[\"edit project\",[25,[\"model\",\"project\"]]],null]]]],[0,\"\\n    \"],[5,\"projects/dashboard/state-status\",[],[[\"@model\",\"@property\",\"@editable\"],[[23,\"model\"],\"scope\",[29,\"can\",[\"edit project\",[25,[\"model\",\"project\"]]],null]]]],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/dashboard/overall-status/template.hbs"
    }
  });

  _exports.default = _default;
});