define("ntfrontend/controllers/account/templates/projects/index", ["exports", "ember-parachute", "ntfrontend/mixins/controllers/refresh-model", "ntfrontend/mixins/controllers/pagination-utils", "ntfrontend/query-params/shared/pagination"], function (_exports, _emberParachute, _refreshModel, _paginationUtils, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.queryParams = void 0;
  var localQueryParams = {
    sort: {
      defaultValue: null,
      refresh: true
    },
    projectTypeId: {
      defaultValue: null,
      refresh: true
    }
  };
  var queryParams = new _emberParachute.default(localQueryParams, _pagination.default);
  _exports.queryParams = queryParams;

  var _default = Ember.Controller.extend(_paginationUtils.default, queryParams.Mixin, _refreshModel.default, {
    queryParamsDidChange: function queryParamsDidChange(parachuteEvent) {
      this.setFirstPage(parachuteEvent);

      this._refreshModel();
    }
  });

  _exports.default = _default;
});