define("ntfrontend/components/tasks/watchers-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+8H6RZk2",
    "block": "{\"symbols\":[\"member\"],\"statements\":[[5,\"shared/loading-state/block\",[],[[\"@model\"],[[25,[\"model\",\"members\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[29,\"can\",[\"create members in task\",[25,[\"model\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"h3\"],[9],[1,[29,\"t\",[\"task.labels.addWatchers\"],null],false],[10],[0,\"\\n    \"],[5,\"members/add-member\",[],[[\"@model\",\"@afterInvite\",\"@includeOrigin\"],[[23,\"model\"],[29,\"action\",[[24,0,[]],\"afterInvite\"],null],false]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,[\"filteredMembers\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"h3\"],[9],[1,[29,\"t\",[\"task.labels.watchers\"],null],false],[10],[0,\"\\n\"],[4,\"each\",[[25,[\"filteredMembers\"]]],null,{\"statements\":[[0,\"      \"],[5,\"users/profile-box\",[],[[\"@user\",\"@editable\",\"@rightSidePopover\"],[[24,1,[\"user\"]],false,true]],{\"statements\":[[0,\"\\n        \"],[5,\"tasks/watcher-item/dropdown\",[],[[\"@model\",\"@task\",\"@afterRemove\"],[[24,1,[]],[23,\"model\"],[29,\"action\",[[24,0,[]],\"afterRemove\"],null]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/tasks/watchers-list/template.hbs"
    }
  });

  _exports.default = _default;
});