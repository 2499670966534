define("ntfrontend/templates/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DWm5eFXc",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"page-title\",[[29,\"t\",[\"shared.labels.create\"],null]],null],false],[0,\"\\n\"],[5,\"create-page\",[],[[\"@activeTab\",\"@projectSlug\",\"@transitionPath\"],[[24,0,[\"tab\"]],[24,0,[\"projectSlug\"]],[24,0,[\"transitionPath\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/create.hbs"
    }
  });

  _exports.default = _default;
});