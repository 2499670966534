define("ntfrontend/components/shared/material-labels/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['inputError:material-label__error:material-label', 'hasValue:is-show'],
    inputError: null,
    didRender: function didRender() {
      this._super.apply(this, arguments);

      return this._checkAutocompletedValue();
    },
    actions: {
      changeValue: function changeValue(value) {
        this.set('hasValue', !!value);
      }
    },
    _checkAutocompletedValue: function _checkAutocompletedValue() {
      var input = this.element.querySelector("#".concat(this.labelFor));

      if (input && input.value) {
        this.set('hasValue', true);
      }
    }
  });

  _exports.default = _default;
});