define("ntfrontend/components/search-box/input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    autofocus: 'autofocus',
    delay: null,
    input: function input() {},
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.delay) {
        Ember.run.later(function () {
          var searchInput = _this.element;

          if (searchInput) {
            searchInput.focus();
            searchInput.select();
          }
        }, this.delay);
      }

      Ember.run.scheduleOnce('afterRender', function () {
        document.querySelector('input').focus();
      });
      this.input();
    },
    makeFirstResponderOnFocusIn: Ember.on('click', 'focusIn', function () {
      Ember.setProperties(this, {
        keyboardActivated: true,
        keyboardFirstResponder: false
      });
    })
  });

  _exports.default = _default;
});