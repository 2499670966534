define("ntfrontend/components/content-placeholders-nav", ["exports", "ember-content-placeholders/components/content-placeholders-nav"], function (_exports, _contentPlaceholdersNav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _contentPlaceholdersNav.default;
    }
  });
});