define("ntfrontend/components/projects/project-card/project-sneak-peek/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    paneManager: Ember.inject.service(),
    selectedProjectType: Ember.computed.alias('paneManager.sneakPeekProject'),
    actions: {
      hidePane: function hidePane() {
        this.paneManager.closePane('project');
      }
    }
  });

  _exports.default = _default;
});