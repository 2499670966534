define("ntfrontend/components/tasks/inline-deadline-date/component", ["exports", "ntfrontend/mixins/components/tasks/task-deadline-class", "ntfrontend/utils/date-utils"], function (_exports, _taskDeadlineClass, _dateUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_taskDeadlineClass.default, {
    classNames: ['inline-deadline-date'],
    past: Ember.computed('model.deadlineAt', function () {
      return (0, _dateUtils.pastDate)(this.get('model.deadlineAt'));
    })
  });

  _exports.default = _default;
});