define("ntfrontend/components/accounts/subscriptions/plan-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SJZv/HC2",
    "block": "{\"symbols\":[\"plan\"],\"statements\":[[7,\"div\"],[11,\"class\",\"pricing-plans\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"plans\"]]],null,{\"statements\":[[0,\"    \"],[5,\"accounts/subscriptions/plan-picker\",[],[[\"@user\",\"@planName\",\"@activeSubscription\",\"@currentPlan\",\"@managersCount\",\"@updateSubscription\"],[[23,\"user\"],[24,1,[]],[23,\"activeSubscription\"],[23,\"currentPlan\"],[23,\"managersCount\"],[23,\"updateSubscription\"]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/accounts/subscriptions/plan-table/template.hbs"
    }
  });

  _exports.default = _default;
});