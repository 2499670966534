define("ntfrontend/components/tasks/new-task/attachments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zlsqc/4g",
    "block": "{\"symbols\":[\"attachment\"],\"statements\":[[4,\"if\",[[25,[\"attachments\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"uploader-files__labels\"],[9],[0,\"\\n    \"],[7,\"th\"],[11,\"class\",\"uploader-files__label\"],[9],[1,[29,\"t\",[\"shared.labels.creator\"],null],false],[10],[0,\"\\n    \"],[7,\"th\"],[11,\"class\",\"uploader-files__label\"],[9],[1,[29,\"t\",[\"shared.labels.name\"],null],false],[10],[0,\"\\n    \"],[7,\"th\"],[11,\"class\",\"uploader-files__label\"],[9],[1,[29,\"t\",[\"shared.labels.createdAt\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[25,[\"attachments\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"file\"],[9],[0,\"\\n    \"],[5,\"users/profile-box/picture\",[],[[\"@user\",\"@width\",\"@height\",\"@class\"],[[25,[\"currentSession\",\"user\"]],\"30\",\"30\",\"file__avatar\"]]],[0,\"\\n    \"],[7,\"a\"],[12,\"class\",[30,[\"file__link \",[29,\"file-icon\",[[24,1,[\"format\"]]],null]]]],[12,\"href\",[24,1,[\"url\"]]],[11,\"download\",\"\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"file__name\"],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"file__date\"],[9],[1,[29,\"format-date\",[[24,1,[\"createdAt\"]]],[[\"format\"],[\"long\"]]],false],[10],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"icon-remove\"],[9],[3,\"action\",[[24,0,[]],\"remove\",[24,1,[]]]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/tasks/new-task/attachments/template.hbs"
    }
  });

  _exports.default = _default;
});