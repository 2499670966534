define("ntfrontend/mixins/controllers/paginated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: ['page', 'perPage'],
    page: 1,
    perPage: 25,
    actions: {
      changePage: function changePage(pageObject) {
        this.setProperties({
          page: pageObject.number,
          perPage: pageObject.size
        });
      }
    },
    setFirstPage: function setFirstPage() {
      this.set('page', 1);
    }
  });

  _exports.default = _default;
});