define("ntfrontend/components/shared/loading-state/block/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onlyFirstFetch: true,
    loaded: Ember.computed('model.{isLoaded,isFulfilled,isReloading}', function () {
      return (this.get('model.isLoaded') || this.get('model.isFulfilled')) && !this.get('model.isReloading');
    }),
    taskIsLoading: Ember.computed('task.performCount', 'task.isRunning', 'onlyFirstFetch', function () {
      var onlyFirstFetch = this.onlyFirstFetch,
          _this$task = this.task,
          performCount = _this$task.performCount,
          isRunning = _this$task.isRunning;
      var isFirstTaskRunning = performCount === 1 && isRunning;
      return onlyFirstFetch ? isFirstTaskRunning : isRunning;
    })
  });

  _exports.default = _default;
});