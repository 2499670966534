define("ntfrontend/components/works/create-tab/details/planned/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KNhjny4I",
    "block": "{\"symbols\":[\"@hoursPlanned\"],\"statements\":[[7,\"div\"],[11,\"class\",\"create-item__overview-data\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"create-item__overview-label text-muted\"],[9],[1,[29,\"t\",[\"work.labels.planned\"],null],false],[10],[0,\"\\n  \"],[1,[29,\"hours-to-mandays\",[[24,1,[]]],null],false],[0,\" \"],[1,[29,\"t\",[\"work.labels.timeScope\"],[[\"scope\"],[\"days\"]]],false],[0,\" / \"],[1,[24,1,[]],false],[0,\" \"],[1,[29,\"t\",[\"work.labels.unitHours\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/works/create-tab/details/planned/template.hbs"
    }
  });

  _exports.default = _default;
});