define("ntfrontend/components/custom-fields/document-list/headers/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    columns: Ember.computed('projectSetting', 'collectionType', function () {
      return this.get("projectSetting.customFieldColumns.".concat(this.collectionType));
    }),
    sortedColumns: Ember.computed('columns', function () {
      return this.columns.map(function (column) {
        return column.name;
      }).sort(this._columnsSort);
    }),
    _columnsSort: function _columnsSort(a, b) {
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }

      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      }

      return 0;
    }
  });

  _exports.default = _default;
});