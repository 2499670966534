define("ntfrontend/components/projects/dashboard/work-packages/add/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    store: Ember.inject.service(),
    disabled: false,
    successCallback: function successCallback() {
      var refreshModel = this.refreshModel,
          onCreate = this.onCreate;
      this.notify.success('notify.workPackage.created');

      if (refreshModel) {
        refreshModel();
      }

      if (onCreate) {
        onCreate();
      }
    },
    errorCallback: function errorCallback() {
      this.set('workPackage.errors', this.get('model.errors'));

      this._super.apply(this, arguments);
    },
    finallyCallback: function finallyCallback() {
      this.set('disabled', false);
    },
    actions: {
      openModal: function openModal() {
        this._createWorkPackageObject();

        this.set('showModal', true);
      },
      createWorkPackage: function createWorkPackage() {
        this.set('disabled', true);
        var attributes = ['name', 'description', 'owner'];
        var formProps = this.workPackage.getProperties(attributes);
        var data = Ember.assign({}, formProps, {
          project: this.project
        });
        this.set('model', this.store.createRecord('workPackage', data));
        return this.saveModelTask.perform();
      }
    },
    _createWorkPackageObject: function _createWorkPackageObject() {
      this.set('workPackage', Ember.Object.create({
        owner: this.get('currentSession.user')
      }));
    }
  });

  _exports.default = _default;
});