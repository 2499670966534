define("ntfrontend/components/subscription-reminder/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['expired:subscription-reminder--expired', 'statusComponent:subscription-reminder'],
    basePath: 'subscription-reminder',
    reminderTime: 15,
    userIsOwner: Ember.computed.equal('user.roleInOrganization', 'owner'),
    expired: Ember.computed.lte('account.daysLeft', 0),
    showReminder: Ember.computed('account.daysLeft', function () {
      return this.get('account.daysLeft') < this.reminderTime;
    }),
    statusComponent: Ember.computed('showReminder', 'expired', 'userIsOwner', function () {
      var path = null;

      if (this.showReminder && this.userIsOwner) {
        path = this._reminderComponentPath();
      } else if (this.expired) {
        path = this._expiredComponentPath();
      }

      return path;
    }),
    _reminderComponentPath: function _reminderComponentPath() {
      return "".concat(this.basePath, "/reminder-subscription");
    },
    _expiredComponentPath: function _expiredComponentPath() {
      return "".concat(this.basePath, "/expired-subscription");
    }
  });

  _exports.default = _default;
});