define("ntfrontend/components/shared/activity-log/log/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7yX06TN/",
    "block": "{\"symbols\":[\"value\",\"key\"],\"statements\":[[7,\"div\"],[11,\"class\",\"creator\"],[9],[0,\"\\n  \"],[5,\"users/profile-box/picture\",[],[[\"@user\",\"@renderInPlace\"],[[25,[\"log\",\"creator\"]],false]]],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"text-muted short-column\"],[9],[0,\"\\n  \"],[1,[29,\"format-relative\",[[25,[\"log\",\"updatedAt\"]]],[[\"style\",\"interval\"],[\"best fit\",60000]]],false],[0,\"\\n  \"],[5,\"bs-tooltip\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[29,\"format-time\",[[25,[\"log\",\"updatedAt\"]]],[[\"day\",\"month\",\"year\",\"hour\",\"minute\",\"weekday\"],[\"numeric\",\"short\",\"numeric\",\"2-digit\",\"2-digit\",\"long\"]]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"h4 text\"],[9],[1,[29,\"t-scope\",[[25,[\"log\",\"action\"]],\"activityLog.actions\"],null],false],[10],[0,\"\\n\"],[4,\"each\",[[29,\"-each-in\",[[25,[\"changes\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"shared/activity-log/changes\",[],[[\"@changeName\",\"@changes\"],[[24,2,[]],[24,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/activity-log/log/template.hbs"
    }
  });

  _exports.default = _default;
});