define("ntfrontend/components/users/profile-box/icon/component", ["exports", "ntfrontend/mixins/components/tooltip"], function (_exports, _tooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tooltip.default, {
    tagName: 'a',
    classNameBindings: ['icon'],
    tooltipRefresh: false,
    tooltipTitle: Ember.computed('userScoped', function () {
      return this.value;
    })
  });

  _exports.default = _default;
});