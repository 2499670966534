define("ntfrontend/components/shared/filters/switcher-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+Vuo7VYj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\"],[11,\"class\",\"text-muted filter-name\"],[9],[0,\"\\n  \"],[1,[23,\"translationText\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[5,\"input\",[],[[\"@type\",\"@checked\"],[\"checkbox\",[23,\"value\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/filters/switcher-filter/template.hbs"
    }
  });

  _exports.default = _default;
});