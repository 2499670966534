define("ntfrontend/components/tasks/new-task/attachments/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      remove: function remove(attachment) {
        var _this = this;

        attachment.destroyRecord().then(function (attachment) {
          _this.attachments.removeObject(attachment);
        });
      }
    }
  });

  _exports.default = _default;
});