define("ntfrontend/components/documents/participation-members/dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ECmsh8MH",
    "block": "{\"symbols\":[],\"statements\":[[5,\"shared/actions/more-actions-button\",[],[[],[]]],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"openDropdown\"]]],null,{\"statements\":[[0,\"  \"],[5,\"shared/loading-state/block\",[],[[\"@model\",\"@tagName\",\"@class\"],[[25,[\"member\",\"ability\"]],\"ul\",\"dropdown-menu dropdown-menu-right\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[29,\"can\",[\"distribute document\",[25,[\"model\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[0,\"\\n        \"],[7,\"a\"],[9],[1,[29,\"t\",[\"document.actions.share\"],null],false],[3,\"action\",[[24,0,[]],\"openModal\"]],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[29,\"can\",[\"remove member\",[25,[\"member\"]]],null]],null,{\"statements\":[[0,\"      \"],[5,\"shared/actions/remove-button\",[],[[\"@model\",\"@tagName\",\"@afterRemove\"],[[23,\"member\"],\"li\",[29,\"action\",[[24,0,[]],\"afterRemove\"],null]]],{\"statements\":[[0,\"\\n        \"],[7,\"a\"],[9],[1,[29,\"t\",[\"shared.actions.remove\"],null],false],[10],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[5,\"documents/distribute-document\",[],[[\"@document\",\"@memberId\",\"@showModal\"],[[25,[\"member\",\"resource\",\"content\"]],[25,[\"member\",\"id\"]],[23,\"showModal\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/documents/participation-members/dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});