define("ntfrontend/components/shared/modal-dialog/wrapper/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    elementId: 'modal-dialog-wrapper',
    classNames: 'modal-dialog-wrapper'
  });

  _exports.default = _default;
});