define("ntfrontend/components/memos/memo-details/detail-datepicker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6wqBVak9",
    "block": "{\"symbols\":[],\"statements\":[[5,\"shared/inplace-datepicker\",[],[[\"@afterChange\",\"@editable\",\"@model\",\"@value\"],[[29,\"action\",[[24,0,[]],\"saveModel\"],null],[29,\"can\",[\"edit memo\",[25,[\"model\"]]],null],[23,\"model\"],[23,\"value\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/memos/memo-details/detail-datepicker/template.hbs"
    }
  });

  _exports.default = _default;
});