define("ntfrontend/router", ["exports", "ntfrontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    paneManager: Ember.inject.service(),
    metrics: Ember.inject.service(),
    bullet: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.on('routerWillChange', function () {
        _this.set('paneManager.showPane', false);

        _this.set('paneManager.selectedSneak', null);

        _this._trackPage();

        _this.bullet.willTransition();
      });
    },
    _trackPage: function _trackPage() {
      var _this2 = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var page = _this2.url;

        var name = _this2._activateAdapterName();

        _this2.metrics.trackPage(name, {
          page: page
        });
      });
    },
    _activateAdapterName: function _activateAdapterName() {
      var page = this.url;

      if (page === '/auth/welcome' || page === '/auth/register') {
        return 'GoogleAnalytics';
      }

      return 'GoogleAnalyticsApplication';
    }
  });
  Router.map(function () {
    this.route('resource', {
      path: '/:code'
    });
    this.route('missing', {
      path: '/*path'
    });
    this.route('terms', {});
    this.route('auth', {}, function () {
      this.route('login', {});
      this.route('logout', {});
      this.route('forgot', {});
      this.route('welcome', {});
      this.route('password', {});
      this.route('register', {});
      this.route('confirmation', {});
    });
    this.route('dashboard', {});
    this.route('create', {});
    this.route('account', function () {
      this.route('details', {});
      this.route('subscriptions', {});
      this.route('types', {});
      this.route('fields', {});
      this.route('templates', function () {
        this.route('documents', function () {
          this.route('edit', {
            path: 'edit/:template_id'
          });
        });
        this.route('projects', function () {
          this.route('edit', {
            path: 'edit/:template_id'
          });
        });
      });
      this.route('users', {}, function () {
        this.route('new', {});
        this.route('edit', {
          path: 'edit/:user_id'
        });
      });
    });
    this.route('user', {}, function () {
      this.route('edit', {});
    });
    this.route('projects', function () {
      this.route('new', {});
      this.route('project', {
        path: ':project_slug'
      }, function () {
        this.route('dashboard', {});
        this.route('tasks', {});
        this.route('scenarios', {}, function () {
          this.route('new', {});
        });
        this.route('memos', {}, function () {
          this.route('new', {});
        });
        this.route('requests', {}, function () {
          this.route('new', {});
        });
        this.route('issues', {}, function () {
          this.route('new', {});
        });
        this.route('work', function () {
          this.route('new');
          this.route('plan');
          this.route('logs');
        });
        this.route('transport', {});
      });
    });
    this.route('memos', function () {
      this.route('memo', {
        path: ':memo_slug'
      }, function () {
        this.route('followup', {
          path: '/followup'
        });
      });
    });
    this.route('requests', function () {
      this.route('request', {
        path: ':request_slug'
      });
    });
    this.route('issues', function () {
      this.route('issue', {
        path: ':issue_slug'
      });
    });
    this.route('tasks', function () {
      this.route('task', {
        path: ':task_slug'
      });
    });
    this.route('transports');
    this.route('topics', function () {
      this.route('topic', {
        path: ':topic_id'
      });
    });
    this.route('steps', function () {
      this.route('step', {
        path: ':step_id'
      });
    });
    this.route('scenarios', function () {
      this.route('scenario', {
        path: ':scenario_slug'
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});