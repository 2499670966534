define("ntfrontend/routes/projects/project/tasks", ["exports", "ntfrontend/utils/ajaxer", "ntfrontend/routes/org-auth"], function (_exports, _ajaxer, _orgAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend({
    model: function model(params) {
      var q = params.q,
          due = params.due,
          requesterId = params.requesterId,
          workPackageId = params.workPackageId,
          milestoneId = params.milestoneId,
          perPage = params.perPage,
          page = params.page,
          sort = params.sort,
          ownerId = params.ownerId,
          completed = params.completed;
      var projectId = this.modelFor('projects.project').id;
      var userId = this.get('currentSession.user.id');
      var filter = {
        q: q,
        due: due,
        requesterId: requesterId,
        workPackageId: workPackageId,
        milestoneId: milestoneId,
        parentResource: "Project-".concat(projectId),
        ownerId: this.mapParam(ownerId, 'me', userId),
        completed: this.mapParam(completed, 'all', null)
      };
      var data = {
        include: 'owner,requester,resource,ability',
        filter: _ajaxer.default.reduceObject(filter),
        page: {
          size: perPage,
          number: page
        },
        sort: sort
      };
      return Ember.RSVP.hash({
        tasks: this.store.query('task', data),
        project: this.modelFor('projects.project')
      });
    }
  });

  _exports.default = _default;
});