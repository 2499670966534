define("ntfrontend/components/accounts/account-details/info/transport/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "csGqFvT5",
    "block": "{\"symbols\":[\"group\",\"system\",\"item\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-6\"],[9],[0,\"\\n    \"],[5,\"sortable-group\",[],[[\"@tagName\",\"@class\",\"@onChange\"],[\"ol\",\"system__list\",[29,\"action\",[[24,0,[]],\"reorderItems\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,0,[\"systems\"]]],null,{\"statements\":[[0,\"        \"],[6,[24,1,[\"item\"]],[],[[\"@tagName\",\"@model\",\"@group\"],[\"li\",[24,2,[]],[24,1,[]]]],{\"statements\":[[0,\"\\n          \"],[6,[24,3,[\"handle\"]],[],[[\"@class\"],[\"system__counter draggable\"]]],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"system__name\"],[9],[0,\"\\n              \"],[1,[24,2,[]],false],[0,\"\\n            \"],[10],[0,\"\\n            \"],[5,\"shared/confirm-remove-link\",[],[[\"@afterConfirm\"],[[29,\"action\",[[24,0,[]],\"removeSystem\",[24,2,[]]],null]]],{\"statements\":[[0,\"\\n              \"],[7,\"i\"],[11,\"class\",\"icon-remove\"],[9],[10],[0,\"\\n            \"]],\"parameters\":[]}],[0,\"\\n        \"]],\"parameters\":[3]}],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"]],\"parameters\":[1]}],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"flex\"],[9],[0,\"\\n        \"],[5,\"input\",[],[[\"@class\",\"@value\",\"@placeholder\",\"@enter\"],[\"system__name\",[24,0,[\"newSystemName\"]],[29,\"t\",[\"transport.placeholders.addSystem\"],null],\"submitEditing\"]]],[0,\"\\n\\n        \"],[5,\"bs-button\",[],[[\"@type\",\"@class\",\"@onClick\",\"@disabled\"],[\"success\",\"btn-sm\",[29,\"action\",[[24,0,[]],\"submitEditing\",[24,0,[\"newSystemName\"]]],null],[24,0,[\"disabledButton\"]]]],{\"statements\":[[0,\"\\n          \"],[1,[29,\"t\",[\"shared.actions.save\"],null],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/accounts/account-details/info/transport/template.hbs"
    }
  });

  _exports.default = _default;
});