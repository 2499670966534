define("ntfrontend/mixins/routes/members-listing", ["exports", "ntfrontend/mixins/routes/ember-can", "ntfrontend/helpers/model-type-name"], function (_exports, _emberCan, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Logger = Ember.Logger;

  var _default = Ember.Mixin.create(_emberCan.default, {
    resource: Ember.computed(function () {
      Logger.error('Resource object in members listing is not defined!');
    }),
    requireAbilities: Ember.computed('abilities', function () {
      var abilities = {};
      abilities["create members in ".concat((0, _modelTypeName.modelTypeName)([this.resource]))] = this.resource;
      this.set('abilities', abilities);
    }),
    model: function model(params) {
      var data = {
        include: 'user,ability',
        filter: {
          resourceId: this.get('resource.id'),
          resourceType: (0, _modelTypeName.modelTypeName)([this.resource])
        },
        page: {
          size: params.perPage,
          number: params.page
        }
      };
      return Ember.RSVP.hash({
        members: this.store.query('member', data),
        resource: this.resource
      });
    }
  });

  _exports.default = _default;
});