define("ntfrontend/components/work-logs/list-item/dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nls5ghu2",
    "block": "{\"symbols\":[\"@model\",\"@project\"],\"statements\":[[5,\"shared/actions/more-actions-button\",[],[[],[]]],[0,\"\\n\"],[4,\"if\",[[25,[\"openDropdown\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\"],[11,\"class\",\"dropdown-menu dropdown-menu-right\"],[9],[0,\"\\n    \"],[5,\"shared/loading-state/block\",[[13,\"}}\",\"\"]],[[\"@model\",\"@tagName\"],[[24,1,[\"ability\"]],\"\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[29,\"can\",[\"remove work-log\",[24,1,[]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[0,\"\\n          \"],[5,\"works/sneak-peek/sneak-peek-button\",[],[[\"@isUpdated\",\"@model\",\"@project\"],[true,[24,1,[]],[24,2,[]]]],{\"statements\":[[0,\"\\n            \"],[1,[29,\"t\",[\"shared.actions.edit\"],null],false],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[29,\"can\",[\"remove work-log\",[24,1,[]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[5,\"shared/confirm-remove-link\",[],[[\"@afterConfirm\"],[[29,\"action\",[[24,0,[]],\"remove\"],null]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/work-logs/list-item/dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});