define("ntfrontend/templates/account/types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0h+WY/ZR",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"page-title\",[[29,\"t\",[\"shared.labels.editOrganization\"],null]],null],false],[0,\"\\n\\n\"],[5,\"accounts/document-types\",[],[[],[]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/account/types.hbs"
    }
  });

  _exports.default = _default;
});