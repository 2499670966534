define("ntfrontend/routes/tasks/task", ["exports", "ember-inflector", "ntfrontend/routes/org-auth", "ntfrontend/helpers/model-type-name"], function (_exports, _emberInflector, _orgAuth, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend({
    intl: Ember.inject.service(),
    states: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    breadCrumb: Ember.computed(function () {
      var crumb = {
        title: this.get('crumbModels.task.name'),
        injection: [{
          model: this.get('crumbModels.resource'),
          offset: -1,
          linkable: true
        }, {
          model: this.get('crumbModels.document'),
          offset: -2,
          linkable: true
        }, this.documentTypeCrumb, {
          model: this.get('crumbModels.project'),
          offset: -4,
          linkable: true
        }, {
          title: 'Projects',
          path: 'projects',
          offset: -5,
          linkable: true
        }]
      };
      return crumb;
    }),
    documentTypeCrumb: Ember.computed('crumbModels.document', function () {
      var doc = this.get('crumbModels.document');
      var docType = (0, _emberInflector.pluralize)((0, _modelTypeName.modelTypeName)([doc])).toLowerCase();
      var title = this.intl.t("shared.labels.".concat(docType));
      var path = "projects.project.".concat(docType);
      return {
        path: path,
        title: title,
        offset: -3,
        model: this.get('crumbModels.project'),
        linkable: true
      };
    }),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      this.set('states.breadCrumbReady', false);
    },
    model: function model(params) {
      var _this = this;

      var include = ['ability', 'owner', 'creator', 'requester', 'resource.document.project'];
      var filter = {
        slug: "".concat(params.task_slug, ",").concat(this.get('currentSession.account.id'))
      };
      var data = {
        include: include.join(','),
        filter: filter
      };
      return this.store.query('task', data).then(function (tasks) {
        return _this.objectOrRedirect(tasks.get('firstObject'), 'dashboard');
      });
    },
    afterModel: function afterModel(model) {
      var _this2 = this;

      this._super.apply(this, arguments);

      var data = {
        task: model,
        resource: model.get('resource'),
        document: model.get('resource.document'),
        project: model.get('resource.document.project')
      };
      Ember.RSVP.hash(data).then(function (loadedData) {
        _this2.set('crumbModels', loadedData);

        _this2.notifyPropertyChange('breadCrumb');

        _this2.set('states.breadCrumbReady', true);
      });
    }
  });

  _exports.default = _default;
});