define("ntfrontend/components/search-box/previews/document/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: 'document-search-preview',
    typeName: Ember.computed.readOnly('result.resourceType'),
    model: Ember.computed('result.resource.id', 'typeName', function () {
      var id = this.result.belongsTo('resource').id();
      var data = {
        include: 'project,creator'
      };
      return this.store.findRecord(this.typeName, id, data);
    }),
    onClose: function onClose() {},
    actions: {
      redirect: function redirect() {
        this.onClose();
        var urlToRedirect = this.router.urlFor(this.get('result.resourceRoute'), this.get('result.slug'));
        this.router.transitionTo(urlToRedirect);
      },
      redirectTo: function redirectTo(route, id) {
        this.onClose();
        var urlToRedirect = this.router.urlFor(route, id);
        this.router.transitionTo(urlToRedirect);
      }
    }
  });

  _exports.default = _default;
});