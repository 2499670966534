define("ntfrontend/components/shared/kanban-view/switcher/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: 'bootstrap-switch-sm -view',
    didInsertElement: function didInsertElement() {
      this._setupSwitcher();
    },
    _setupSwitcher: function _setupSwitcher() {
      var _this = this;

      (0, _jquery.default)('input', this.element).bootstrapSwitch(this._options());
      (0, _jquery.default)('input', this.element).on('switchChange.bootstrapSwitch', function () {
        _this.toggleProperty('displayKanban');
      });
    },
    _options: function _options() {
      return {
        onColor: 'primary',
        offColor: 'primary',
        onText: ' ',
        offText: ' '
      };
    }
  });

  _exports.default = _default;
});