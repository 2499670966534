define("ntfrontend/components/shared/multi-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    class: 'form-group',
    searchField: 'name',
    triggerClass: 'select form-control'
  });

  _exports.default = _default;
});