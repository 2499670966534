define("ntfrontend/components/templates/templates-list/item/dropdown/component", ["exports", "ntfrontend/mixins/components/model-saving", "ntfrontend/mixins/components/open-dropdown"], function (_exports, _modelSaving, _openDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, _openDropdown.default, {
    sweetAlert: Ember.inject.service('sweet-alert'),
    classNames: ['dropdown'],
    resourceName: 'documents',
    dropdownModel: Ember.computed.alias('model.ability'),
    resourceEditRoute: Ember.computed('resourceName', function () {
      return "account.templates.".concat(this.resourceName, ".edit");
    }),
    actions: {
      afterRemove: function afterRemove() {
        this.notify.success('notify.template.removed');
      }
    }
  });

  _exports.default = _default;
});