define("ntfrontend/components/transports/transport-sneak-peek/component", ["exports", "ntfrontend/mixins/components/label-editing", "ntfrontend/mixins/components/model-saving"], function (_exports, _labelEditing, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, _labelEditing.default, {
    can: Ember.inject.service(),
    notify: Ember.inject.service('notify-alert'),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    paneManager: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    transport: Ember.computed.alias('paneManager.sneakPeekTransport'),
    transportDocuments: Ember.computed.uniqBy('model.transportDocuments', 'document.symbolName'),
    init: function init() {
      this._super.apply(this, arguments);

      this.transportObserver();
    },
    transportObserver: Ember.observer('transport', function () {
      var _this = this;

      var transport = this.transport,
          store = this.store;

      if (transport) {
        this.set('model', null);
        store.query('transport', {
          filter: {
            Id: transport.get('id')
          },
          include: 'creator,account,transport-documents.document,transport-documents.document-item'
        }).then(function (transport) {
          return _this.set('model', transport.get('firstObject'));
        });
      }
    }),
    editable: Ember.computed(function () {
      return this.can.can('create transports in account', this.model.account);
    }),
    actions: {
      afterRemove: function afterRemove() {
        this.notify.success('notify.transport.removed');
        this.paneManager.closePane('transport');
      },
      hidePane: function hidePane() {
        this.paneManager.closePane('transport');
      },
      addDocument: function addDocument(document) {
        var _this2 = this;

        this.store.createRecord('transport-document', {
          transport: this.model,
          document: document
        }).save().then(function () {
          _this2.notify.success('notify.transport.updated');
        });
      },
      redirectTo: function redirectTo(route, id) {
        var router = this.router;
        var urlToRedirect = router.urlFor(route, id);
        router.transitionTo(urlToRedirect);
      },
      setSystem: function setSystem(system) {
        this.model.set('system', system);
        this.saveModelTask.perform();
      }
    }
  });

  _exports.default = _default;
});