define("ntfrontend/mixins/routes/ember-can", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    canService: Ember.inject.service('can'),
    abilities: {},
    can: function can(abilityName, resource, aditionalProperties) {
      return this.canService.can(abilityName, resource, aditionalProperties);
    },
    cannot: function cannot(abilityName, resource, aditionalProperties) {
      return !this.can(abilityName, resource, aditionalProperties);
    },
    redirect: function redirect() {
      this._super.apply(this, arguments);

      if (!this._checkAbilities()) {
        this.transitionTo('index');
      }
    },
    _checkAbilities: function _checkAbilities() {
      this.requireAbilities;
      var abilities = this.abilities;
      var status = true;

      if (abilities) {
        for (var key in abilities) {
          if (this.cannot(key, abilities[key])) {
            status = false;
          }
        }
      }

      return status;
    }
  });

  _exports.default = _default;
});