define("ntfrontend/components/documents/new-document/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "InlnQAgD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[11,\"class\",\"text-center page-header\"],[9],[1,[29,\"t\",[[25,[\"headerTranslation\"]]],null],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4\"],[9],[0,\"\\n  \"],[7,\"form\"],[11,\"class\",\"project-form\"],[9],[0,\"\\n    \"],[5,\"documents/new-document/document-name\",[],[[\"@tempObject\",\"@newObject\"],[[23,\"tempObject\"],[23,\"newObject\"]]]],[0,\"\\n    \"],[5,\"documents/templates/import-button/dropdown\",[],[[\"@modelTypeName\",\"@project\",\"@changeTemplate\"],[[23,\"objectType\"],[23,\"project\"],[29,\"action\",[[24,0,[]],\"changeTemplate\"],null]]]],[0,\"\\n    \"],[5,\"shared/form-confirm-button\",[],[[\"@disabled\",\"@label\"],[[23,\"disabled\"],[29,\"t\",[\"shared.actions.\"],null]]]],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"create\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/documents/new-document/template.hbs"
    }
  });

  _exports.default = _default;
});