define("ntfrontend/components/shared/inplace-user/component", ["exports", "ntfrontend/mixins/components/tooltip", "ntfrontend/mixins/components/inplace/editable", "ntfrontend/mixins/components/inplace/focusable"], function (_exports, _tooltip, _editable, _focusable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_editable.default, _focusable.default, _tooltip.default, {
    focusOut: function focusOut() {},
    tooltipTitle: Ember.computed('tooltipTitleKey', function () {
      var tooltipTitleKey = this.tooltipTitleKey;

      if (tooltipTitleKey) {
        return this.intl.t(tooltipTitleKey);
      }
    }),
    actions: {
      escapeSelect: function escapeSelect() {
        this.set('isEditing', false);
      }
    }
  });

  _exports.default = _default;
});