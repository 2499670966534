define("ntfrontend/components/shared/drop-zone/-default-overlay/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oLThSpT/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"drop-here-block\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[1,[29,\"t\",[\"shared.placeholders.dropFilesHere\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/drop-zone/-default-overlay/template.hbs"
    }
  });

  _exports.default = _default;
});