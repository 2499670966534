define("ntfrontend/components/shared/form-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lLnIuNM0",
    "block": "{\"symbols\":[\"error\",\"message\",\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"form-group \",[29,\"if\",[[29,\"or\",[[25,[\"errors\"]],[25,[\"changeset\"]]],null],\"has-error\"],null]]]],[9],[0,\"\\n  \"],[15,3],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"changeset\"]]],null,{\"statements\":[[4,\"each\",[[25,[\"changeset\",\"validation\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"help-block\"],[9],[1,[24,2,[]],false],[10],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[25,[\"errors\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"help-block\"],[9],[1,[24,1,[\"message\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/form-input/template.hbs"
    }
  });

  _exports.default = _default;
});