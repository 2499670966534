define("ntfrontend/components/shared/header-with-total/members-count/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'h2',
    classNames: 'h2',
    additionalString: '',
    subscriptionMembers: Ember.computed.alias('subscription.membersQuantity'),
    count: Ember.computed('total', function () {
      return (this.total || 0).toString();
    }),
    usersInAccount: Ember.computed('count', 'subscriptionMembers', function () {
      if (this.subscriptionMembers) {
        this.set('additionalString', "/".concat(this.subscriptionMembers));
      }

      return "(".concat(this.count).concat(this.additionalString, ")");
    })
  });

  _exports.default = _default;
});