define("ntfrontend/templates/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "guz2SQ5y",
    "block": "{\"symbols\":[],\"statements\":[[5,\"accounts/sub-menu\",[],[[\"@account\"],[[23,\"model\"]]]],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"page-content__inner\"],[9],[0,\"\\n  \"],[1,[23,\"outlet\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/account.hbs"
    }
  });

  _exports.default = _default;
});