define("ntfrontend/components/templates/templates-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5SmSCDFD",
    "block": "{\"symbols\":[\"template\"],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"page-header\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[1,[29,\"t\",[\"shared.labels.documentTemplates\"],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-header__menu\"],[9],[0,\"\\n    \"],[5,\"templates/new-template\",[],[[\"@task\",\"@refreshProjectTemplates\"],[[23,\"getTemplates\"],[29,\"action\",[[24,0,[]],\"refreshProjectTemplates\"],null]]]],[0,\"\\n    \"],[5,\"accounts/account-details/document-templates/import-template\",[],[[],[]]],[0,\"\\n    \"],[5,\"shared/filters/switcher-filter\",[],[[\"@state\",\"@secondState\",\"@firstState\",\"@documentName\"],[[23,\"published\"],null,true,\"templates\"]]],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"table\"],[11,\"class\",\"table table--document-templates\"],[9],[0,\"\\n  \"],[7,\"thead\"],[9],[0,\"\\n    \"],[7,\"th\"],[9],[1,[29,\"t\",[\"shared.labels.creator\"],null],false],[10],[0,\"\\n    \"],[5,\"shared/header-sort\",[],[[\"@label\",\"@sort\",\"@name\"],[\"shared.labels.name\",[23,\"sort\"],\"name\"]]],[0,\"\\n    \"],[7,\"th\"],[9],[10],[0,\"\\n    \"],[7,\"th\"],[9],[1,[29,\"t\",[\"shared.labels.documentType\"],null],false],[10],[0,\"\\n    \"],[5,\"shared/header-sort\",[],[[\"@label\",\"@sort\",\"@name\"],[\"shared.labels.createdAt\",[23,\"sort\"],\"createdAt\"]]],[0,\"\\n    \"],[7,\"th\"],[9],[1,[29,\"t\",[\"shared.labels.autoClone\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"documentTemplates\"]]],null,{\"statements\":[[0,\"      \"],[5,\"templates/templates-list/item\",[],[[\"@model\",\"@refreshProjectTemplates\"],[[24,1,[]],[29,\"action\",[[24,0,[]],\"refreshProjectTemplates\"],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/templates/templates-list/template.hbs"
    }
  });

  _exports.default = _default;
});