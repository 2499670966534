define("ntfrontend/routes/account/users/edit", ["exports", "ntfrontend/routes/org-auth", "ntfrontend/mixins/routes/ember-can", "ntfrontend/helpers/model-type-name"], function (_exports, _orgAuth, _emberCan, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend(_emberCan.default, {
    currentSession: Ember.inject.service(),
    requireAbilities: Ember.computed('abilities', 'member.resource', 'resourceName', function () {
      var resourceName = (0, _modelTypeName.modelTypeName)([this.get('member.resource')]);

      if (resourceName) {
        var ability = {};
        ability["create members in ".concat(resourceName)] = this.get('member.resource');
        this.set('abilities', ability);
      }
    }),
    model: function model(params) {
      var userId = params.user_id;
      var member = this.store.findRecord('member', userId, {
        include: 'user,resource,roles'
      });
      this.set('member', member);
      return member;
    }
  });

  _exports.default = _default;
});