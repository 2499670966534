define("ntfrontend/components/shared/inplace-editor/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ['inplace-editor'],
    isEditing: false,
    editable: true,
    editorValue: Ember.computed.reads('value'),
    showingValue: Ember.computed.or('shortenValue', 'value'),
    isInplaceEditing: Ember.computed('editable', 'isEditing', function () {
      return this.editable && !this.isEditing;
    }),
    placeholder: Ember.computed('placeholderKey', function () {
      return this.placeholderKey ? this.intl.t(this.placeholderKey) : '';
    }),
    showPlaceholder: Ember.computed('value', 'isEditing', 'placeholderKey', function () {
      return !this.value && !this.isEditing && this.placeholderKey;
    }),
    showField: Ember.computed('value', 'editable', function () {
      return this.value || this.editable;
    }),
    click: function click(event) {
      if (!this.isEditing) {
        this._openOrEdit((0, _jquery.default)(event.target));
      }
    },
    actions: {
      save: function save() {
        this.toggleProperty('isEditing');
        this.set('value', this.editorValue);

        if (this.afterChange) {
          this.afterChange(this.value);
        }
      },
      cancel: function cancel() {
        this.toggleProperty('isEditing');
        this.set('editorValue', this.value);
      },
      focusedIn: function focusedIn() {
        this.set('isEditing', true);
      }
    },
    // private
    _openOrEdit: function _openOrEdit(target) {
      if (target.is('a')) {
        target.attr('target', '_blank');
      } else if (this.editable) {
        this.set('editorValue', this.value);
        this.set('isEditing', true);
      }
    }
  });

  _exports.default = _default;
});