define("ntfrontend/utils/url-maker", ["exports", "ntfrontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var hostNameOnlyRegexp = '[a-z-0-9]{2,63}.[a-z.]{2,5}$';
  var hostNameRegexp = '[a-z-0-9]{2,63}.[a-z.]{2,5}(:[0-9]+){0,1}$';
  var _default = {
    // http://myorg.9teams.local:4200/
    // http://undefined.9teams.local:4200
    subdomainUrl: function subdomainUrl(subdomain) {
      var secure = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var protocol = "".concat(window.location.protocol, "//");

      if (secure) {
        protocol = 'https://';
      }

      return "".concat(protocol + subdomain, ".").concat(this.hostname());
    },
    // myorg
    subdomain: function subdomain() {
      if (this.hostname()) {
        var _this$host$split = this.host().split(".".concat(this.hostname())),
            _this$host$split2 = _slicedToArray(_this$host$split, 1),
            subdomains = _this$host$split2[0];

        var subdomain = subdomains.split('.').pop();
        return this.normalize(subdomain);
      } else {
        return this.normalize('');
      }
    },
    // http://myorg.9teams.local:4200/
    appUrl: function appUrl() {
      return this.subdomainUrl('app');
    },
    // myorg.9teams.local
    hostNameOnly: function hostNameOnly() {
      return window.location.hostname.match(hostNameOnlyRegexp)[0];
    },
    // myorg.9teams.local:4200
    host: function host() {
      return window.location.host;
    },
    // 9teams.local:4200
    hostname: function hostname() {
      return this.host().match(hostNameRegexp)[0];
    },
    normalize: function normalize(subdomain) {
      return _environment.default.subdomainMapping[subdomain] || subdomain;
    },
    customURI: ''
  };
  _exports.default = _default;
});