define("ntfrontend/components/custom-fields/custom-values/components/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9o62WUa+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"col-xs-4\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"text-muted\"],[9],[1,[25,[\"customValue\",\"name\"]],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"col-xs-8\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"editable\"]]],null,{\"statements\":[[4,\"if\",[[25,[\"customValue\",\"multiple_value\"]]],null,{\"statements\":[[0,\"      \"],[5,\"custom-fields/custom-values/components/list/multi-value\",[],[[\"@customValue\",\"@saveCustomValue\"],[[23,\"customValue\"],[29,\"action\",[[24,0,[]],\"saveCustomValue\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"custom-fields/custom-values/components/list/single-value\",[],[[\"@customValue\",\"@afterChange\"],[[23,\"customValue\"],[29,\"action\",[[24,0,[]],\"saveCustomValue\"],null]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,[\"customValue\",\"value\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/custom-fields/custom-values/components/list/template.hbs"
    }
  });

  _exports.default = _default;
});