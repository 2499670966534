define("ntfrontend/components/custom-fields/custom-values/components/list/multi-value/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    emptyValues: Ember.computed.empty('customValue.value'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!this.originalCustomValues) {
        this._initializeCustomValues();
      }
    },
    actions: {
      finishEditing: function finishEditing() {
        this.set('customValue.value', this.originalCustomValues);
        this.saveCustomValue();

        this._disableEditing();
      },
      cancelEditing: function cancelEditing() {
        this._initializeCustomValues();

        this._disableEditing();
      },
      enableEditing: function enableEditing() {
        this.set('isEditing', true);
      }
    },
    _initializeCustomValues: function _initializeCustomValues() {
      this.set('originalCustomValues', this.get('customValue.value'));
    },
    _disableEditing: function _disableEditing() {
      this.set('isEditing', false);
    }
  });

  _exports.default = _default;
});