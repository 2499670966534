define("ntfrontend/components/shared/calendar-deadline-date/component", ["exports", "ntfrontend/mixins/components/tasks/task-deadline-class", "ntfrontend/components/shared/calendar-date/component"], function (_exports, _taskDeadlineClass, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_taskDeadlineClass.default, {
    date: Ember.computed.alias('model.deadlineAt')
  });

  _exports.default = _default;
});