define("ntfrontend/controllers/projects/project/requests/index", ["exports", "ember-parachute", "ntfrontend/mixins/controllers/refresh-model", "ntfrontend/mixins/controllers/pagination-utils", "ntfrontend/query-params/shared/pagination", "ntfrontend/query-params/shared/custom-fields"], function (_exports, _emberParachute, _refreshModel, _paginationUtils, _pagination, _customFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.queryParams = void 0;
  var localQueryParams = {
    archived: {
      defaultValue: 'false',
      refresh: true
    },
    ownerId: {
      defaultValue: null,
      refresh: true
    },
    requesterId: {
      defaultValue: null,
      refresh: true
    },
    sort: {
      defaultValue: '-createdAt',
      refresh: true
    },
    q: {
      defaultValue: null,
      refresh: true
    },
    status: {
      defaultValue: null,
      refresh: true
    },
    due: {
      defaultValue: null,
      refresh: true
    },
    workPackageId: {
      defaultValue: null,
      refresh: true
    },
    milestoneId: {
      defaultValue: null,
      refresh: true
    },
    typeName: {
      defaultValue: null,
      refresh: true
    }
  };
  var queryParams = new _emberParachute.default(localQueryParams, _pagination.default, _customFields.default);
  _exports.queryParams = queryParams;

  var _default = Ember.Controller.extend(_paginationUtils.default, queryParams.Mixin, _refreshModel.default, {
    displayKanban: false,
    column: {
      name: 'status',
      label: 'kanban.labels.status'
    },
    queryParamsDidChange: function queryParamsDidChange(parachuteEvent) {
      this.setFirstPage(parachuteEvent);

      this._refreshModel();
    }
  });

  _exports.default = _default;
});