define("ntfrontend/components/members/add-member/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['new-member-box'],
    includeCollaborators: true,
    includeOrigin: true,
    createNew: true,
    actions: {
      inviteUser: function inviteUser(data) {
        if (!data) {
          this.notify.error('validation.errors.email');
          return false;
        }

        this._inviteUser(Number(data) ? {
          userId: data
        } : data);
      }
    },
    _inviteUser: function _inviteUser(data) {
      var _this = this;

      this.model.invite({
        data: data
      }).then(function () {
        _this.notify.success('notify.user.accountInvite', null, {
          withoutTitle: true
        });

        if (_this.refreshMembers) {
          _this.refreshMembers();
        }
      });
    }
  });

  _exports.default = _default;
});