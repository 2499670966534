define("ntfrontend/components/pagination-list/next/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['isDisabled:disabled'],
    isDisabled: Ember.computed('page', 'currentPage', function () {
      return this.currentPage >= this.totalPages;
    }),
    click: function click() {
      if (this.isDisabled) {
        return false;
      }

      this.changePageNumber(this.currentPage + 1);
    }
  });

  _exports.default = _default;
});