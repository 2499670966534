define("ntfrontend/components/dashboard/tasks-preview/empty-state/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XlUeE9Sh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"empty-state\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"class\",\"empty-state__icon -tasks\"],[11,\"src\",\"/assets/images/notasks-dash.svg\"],[12,\"alt\",[23,\"noTasksLabel\"]],[9],[10],[0,\"\\n\\n  \"],[7,\"h4\"],[11,\"class\",\"empty-state__header -tasks\"],[9],[0,\"\\n    \"],[1,[23,\"noTasksLabel\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/dashboard/tasks-preview/empty-state/template.hbs"
    }
  });

  _exports.default = _default;
});