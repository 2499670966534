define("ntfrontend/routes/projects/project/scenarios", ["exports", "ntfrontend/routes/org-auth"], function (_exports, _orgAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend({
    model: function model() {
      return this.modelFor('projects.project');
    }
  });

  _exports.default = _default;
});