define("ntfrontend/templates/projects/project/work/plan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HMpsO029",
    "block": "{\"symbols\":[],\"statements\":[[5,\"works/plan-tab\",[],[[\"@model\",\"@filterTimeScope\",\"@milestoneId\",\"@workPackageId\",\"@ownerId\",\"@q\",\"@refreshModel\"],[[24,0,[\"model\"]],[23,\"filterTimeScope\"],[23,\"milestoneId\"],[23,\"workPackageId\"],[23,\"ownerId\"],[23,\"q\"],[29,\"action\",[[24,0,[]],\"refreshModel\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/projects/project/work/plan.hbs"
    }
  });

  _exports.default = _default;
});