define("ntfrontend/components/projects/project-card/project-sneak-peek/sub-resources/document-templates/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var iconsClasses = {
    issue: '-issue icon-issues',
    memo: '-memo icon-memos',
    request: '-request icon-requests',
    scenario: '-scenario icon-scenarios'
  };

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    documentType: Ember.computed.alias('documentTemplate.document_type'),
    iconClass: Ember.computed('documentType', function () {
      var documentType = this.documentType;
      return iconsClasses[documentType.toLowerCase()];
    }),
    iconTooltip: Ember.computed('documentType', function () {
      var documentType = this.documentType,
          intl = this.intl;
      return intl.t("shared.labels.".concat(documentType.toLowerCase()));
    })
  });

  _exports.default = _default;
});