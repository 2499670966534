define("ntfrontend/components/work-logs/empty-state/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wlAbXgFD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"empty-state\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"src\",\"/assets/images/nowork.svg\"],[11,\"class\",\"empty-state__icon\"],[12,\"alt\",[29,\"t\",[\"work.alts.noWorkLogsIcon\"],null]],[9],[10],[0,\"\\n  \"],[7,\"h4\"],[11,\"class\",\"empty-state__header\"],[9],[0,\"\\n    \"],[1,[29,\"t\",[\"work.placeholders.noWorkLogsHeader\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"empty-state__label\"],[9],[0,\"\\n    \"],[1,[29,\"t\",[\"work.placeholders.noWorkLogsContent\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/work-logs/empty-state/template.hbs"
    }
  });

  _exports.default = _default;
});