define("ntfrontend/components/transports/transport-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cUu5m7Cy",
    "block": "{\"symbols\":[\"transport\",\"@fetchTransportsTask\",\"@transports\"],\"statements\":[[5,\"shared/loading-state/block\",[],[[\"@task\",\"@onlyFirstFetch\",\"@customClass\"],[[24,2,[]],true,\"loader-wrapper -center-content\"]],{\"statements\":[[0,\"\\n  \"],[7,\"table\"],[11,\"class\",\"table table--transports\"],[9],[0,\"\\n    \"],[7,\"thead\"],[9],[0,\"\\n      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[5,\"shared/header-sort\",[],[[\"@label\",\"@sort\",\"@name\"],[\"shared.labels.id\",[23,\"sort\"],\"textId\"]]],[0,\"\\n        \"],[7,\"th\"],[9],[1,[29,\"t\",[\"shared.labels.description\"],null],false],[10],[0,\"\\n        \"],[7,\"th\"],[9],[1,[29,\"t\",[\"shared.labels.comment\"],null],false],[10],[0,\"\\n        \"],[7,\"th\"],[9],[1,[29,\"t\",[\"shared.labels.documents\"],null],false],[10],[0,\"\\n        \"],[5,\"shared/header-sort\",[],[[\"@label\",\"@sort\",\"@name\"],[\"shared.labels.creator\",[23,\"sort\"],\"creator\"]]],[0,\"\\n        \"],[5,\"shared/header-sort\",[],[[\"@label\",\"@sort\",\"@name\"],[\"shared.labels.createdAt\",[23,\"sort\"],\"createdAt\"]]],[0,\"\\n        \"],[7,\"th\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[24,3,[]]],null,{\"statements\":[[0,\"        \"],[5,\"transports/transport-list/item\",[],[[\"@model\"],[[24,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"unless\",[[24,3,[]]],null,{\"statements\":[[0,\"    \"],[5,\"transports/transport-list/empty-state\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/transports/transport-list/template.hbs"
    }
  });

  _exports.default = _default;
});