define("ntfrontend/components/custom-fields/document-list/item-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mLE2BRmh",
    "block": "{\"symbols\":[\"customValue\"],\"statements\":[[4,\"each\",[[25,[\"sortedValues\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"custom-fields/document-list/item-row/item\",null,[[\"customValue\",\"editable\",\"afterChange\"],[[29,\"changeset\",[[24,1,[]]],null],[25,[\"editable\"]],[29,\"action\",[[24,0,[]],\"saveCustomField\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/custom-fields/document-list/item-row/template.hbs"
    }
  });

  _exports.default = _default;
});