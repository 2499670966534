define("ntfrontend/components/templates/document-templates/document-sneak-peek/button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    paneManager: Ember.inject.service(),
    tagName: 'a',
    click: function click() {
      this.set('paneManager.showPane', true);
      this.set('paneManager.sneakPeekDocumentTemplate', this.model);
      this.set('paneManager.selectedSneak', 'documentTemplate');
    }
  });

  _exports.default = _default;
});