define("ntfrontend/components/shared/transport-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "prew0efm",
    "block": "{\"symbols\":[\"transportDocument\"],\"statements\":[[4,\"each\",[[24,0,[\"sortedTransportDocuments\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"transport-item\"],[9],[0,\"\\n    \"],[5,\"users/profile-box/picture\",[],[[\"@user\"],[[24,1,[\"transport\",\"creator\"]]]]],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"transport-item__description\"],[9],[0,\"\\n      \"],[5,\"transports/transport-system\",[],[[\"@model\"],[[24,1,[\"transport\"]]]],{\"statements\":[[0,\"\\n        \"],[1,[24,1,[\"transport\",\"textId\"]],false],[0,\" - \"],[1,[24,1,[\"transport\",\"description\"]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"if\",[[29,\"can\",[\"create transports in account\",[24,0,[\"currentSession\",\"account\"]]],null]],null,{\"statements\":[[0,\"      \"],[5,\"shared/confirm-remove-document\",[],[[\"@transportDocument\"],[[24,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/transport-list/template.hbs"
    }
  });

  _exports.default = _default;
});