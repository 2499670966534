define("ntfrontend/components/accounts/accounts-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zie3FlVb",
    "block": "{\"symbols\":[\"account\"],\"statements\":[[5,\"shared/loading-state/block\",[],[[\"@model\"],[[23,\"accounts\"]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,[\"accounts\"]]],null,{\"statements\":[[4,\"each\",[[25,[\"accounts\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"organization-switch\"],[9],[0,\"\\n        \"],[7,\"a\"],[12,\"href\",[24,1,[\"url\"]]],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"actions\"],[9],[0,\"\\n          \"],[5,\"tasks/overdue-indicator\",[],[[\"@value\",\"@userScoped\"],[[24,1,[\"myOverdueTasks\"]],true]]],[0,\"\\n          \"],[5,\"tasks/overdue-indicator\",[],[[\"@value\"],[[24,1,[\"overdueTasks\"]]]]],[0,\"\\n          \"],[7,\"span\"],[12,\"class\",[30,[\"label \",[29,\"role-indicator\",[[24,1,[\"roles\",\"firstObject\"]]],null]]]],[9],[1,[29,\"role-name\",[[24,1,[\"roles\",\"firstObject\"]]],null],false],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"text-center text-muted\"],[9],[0,\"\\n      \"],[1,[29,\"t\",[\"organization.placeholders.noAccounts\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/accounts/accounts-list/template.hbs"
    }
  });

  _exports.default = _default;
});