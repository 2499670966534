define("ntfrontend/components/templates/new-template/modal-body/input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    placeholder: 'organization.documentTemplate.placeholders.name'
  });

  _exports.default = _default;
});