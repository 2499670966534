define("ntfrontend/components/sidebar-menu/secondary-links/support/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'a',
    zendesk: Ember.inject.service(),
    click: function click() {
      this.zendesk.regenerateSuggestions();
      this.zendesk.activate();
    }
  });

  _exports.default = _default;
});