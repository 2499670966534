define("ntfrontend/serializers/project-template", ["exports", "ntfrontend/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      projectTypes: {
        serialize: false
      },
      documentTemplates: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});