define("ntfrontend/session-stores/application", ["exports", "ember-simple-auth/session-stores/cookie", "ntfrontend/utils/url-maker"], function (_exports, _cookie, _urlMaker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var storage = _cookie.default.extend({
    cookieDomain: ".".concat(_urlMaker.default.hostNameOnly())
  });

  var _default = storage;
  _exports.default = _default;
});