define("ntfrontend/routes/auth/password", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      var token = transition.to.queryParams.token;

      if (token) {
        this.set('token', token);
      } else {
        this.transitionTo('index');
      }
    },
    model: function model() {
      return this.token;
    }
  });

  _exports.default = _default;
});