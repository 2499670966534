define("ntfrontend/components/documents/documents-list/inplace-due-date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HQ4/WdAY",
    "block": "{\"symbols\":[],\"statements\":[[5,\"shared/inplace-datepicker\",[],[[\"@afterChange\",\"@editable\",\"@value\"],[[29,\"action\",[[24,0,[]],\"afterChange\"],null],[23,\"editable\"],[25,[\"model\",\"dueDate\"]]]],{\"statements\":[[0,\"\\n  \"],[5,\"documents/details/due-date-label\",[],[[\"@dueDate\"],[[25,[\"model\",\"dueDate\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,[\"model\",\"dueDate\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"format-date\",[[25,[\"model\",\"dueDate\"]]],[[\"format\"],[\"long\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"text-muted\"],[9],[1,[29,\"t\",[\"shared.labels.notSpecified\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/documents/documents-list/inplace-due-date/template.hbs"
    }
  });

  _exports.default = _default;
});