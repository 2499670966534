define("ntfrontend/models/account-list", ["exports", "ember-data", "ntfrontend/utils/url-maker"], function (_exports, _emberData, _urlMaker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    name: attr('string'),
    domain: attr('string'),
    myOverdueTasks: attr('number'),
    overdueTasks: attr('number'),
    roles: attr(),
    url: Ember.computed('domain', function () {
      return _urlMaker.default.subdomainUrl(this.domain);
    })
  });

  _exports.default = _default;
});