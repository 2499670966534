define("ntfrontend/query-params/shared/pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var queryParams = {
    page: {
      defaultValue: 1,
      refresh: true
    },
    perPage: {
      defaultValue: 25,
      refresh: true
    }
  };
  var _default = queryParams;
  _exports.default = _default;
});