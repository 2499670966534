define("ntfrontend/mixins/controllers/refresh-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.create({
    _refreshModel: function _refreshModel() {
      var currentRouteName = this.get('target.currentRouteName');
      var route = Ember.getOwner(this).lookup("route:".concat(currentRouteName));
      return route.refresh();
    }
  });

  _exports.default = _default;
});