define("ntfrontend/components/projects/dashboard/state-status/label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PXgt/0fz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"project__status__label\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"text-muted\"],[9],[1,[29,\"t\",[[25,[\"labelKey\"]]],null],false],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"description\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"tooltip--nowrap\"],[12,\"tooltip_top\",[23,\"description\"]],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"icon-bubble\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/dashboard/state-status/label/template.hbs"
    }
  });

  _exports.default = _default;
});