define("ntfrontend/mixins/components/lists/hidden-custom-values-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    customFieldColumns: Ember.computed.alias('model.project.projectSetting.customFieldColumns'),
    customValues: Ember.computed('model.customValues', 'customFieldColumns', function () {
      var collectionType = this.customValuesCollectionType;
      var visibleColumnsObjects = this.customFieldColumns[collectionType];
      var visibleColumns = visibleColumnsObjects.map(function (visibleColumnObject) {
        return visibleColumnObject.name;
      });
      var availableValues = this.get('model.customValues');
      return availableValues.filter(function (customValue) {
        return visibleColumns.includes(customValue.get('name'));
      });
    })
  });

  _exports.default = _default;
});