define("ntfrontend/components/templates/new-template/modal-body/dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vcTSkBYi",
    "block": "{\"symbols\":[\"dd\",\"menu\",\"name\"],\"statements\":[[5,\"bs-dropdown\",[],[[\"@class\"],[\"dropdown dropdown--bordered form-control select\"]],{\"statements\":[[0,\"\\n  \"],[6,[24,1,[\"toggle\"]],[],[[\"@tagName\"],[\"div\"]],{\"statements\":[[0,\"\\n    \"],[1,[23,\"type\"],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[6,[24,1,[\"menu\"]],[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[25,[\"documentsNames\"]]],null,{\"statements\":[[0,\"      \"],[6,[24,2,[\"item\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[7,\"a\"],[9],[1,[24,3,[]],false],[3,\"action\",[[24,0,[]],[25,[\"onChange\"]],[24,3,[]]]],[10],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/templates/new-template/modal-body/dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});