define("ntfrontend/components/shared/excel-button/component", ["exports", "ember-inflector", "ntfrontend/helpers/model-type-name"], function (_exports, _emberInflector, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    endpoint: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    router: Ember.inject.service(),
    can: Ember.inject.service(),
    hasItems: Ember.computed.bool('resourcesCount'),
    displayButton: Ember.computed('hasItems', function () {
      return this.hasItems && this.can.can('createExcel for project', this.model);
    }),
    excelUrl: function excelUrl() {
      var _this$currentSession = this.currentSession,
          token = _this$currentSession.authToken,
          email = _this$currentSession.user.email,
          collection = this.collection,
          model = this.model;

      var _this$router$currentU = this.router.currentURL.split('?'),
          _this$router$currentU2 = _slicedToArray(_this$router$currentU, 2),
          params = _this$router$currentU2[1];

      var baseQueryParams = "user[token]=".concat(token, "&user[email]=").concat(email, "&collection=").concat(collection);

      var additionalQueryParams = this._processQueryParams(params);

      var modelPath = (0, _emberInflector.pluralize)((0, _modelTypeName.modelTypeName)([model]).dasherize());
      return "".concat(this.get('endpoint.baseAPI'), "/").concat(modelPath, "/").concat(this.get('model.id'), "/excel?").concat(baseQueryParams, "&").concat(additionalQueryParams);
    },
    actions: {
      downloadExcel: function downloadExcel() {
        window.location.replace(this.excelUrl());
      }
    },
    _processQueryParams: function _processQueryParams() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var splittedQueryParams = queryParams.split('&');
      return splittedQueryParams.reduce(function (acc, param, idx) {
        var _param$split = param.split('='),
            _param$split2 = _slicedToArray(_param$split, 2),
            property = _param$split2[0],
            value = _param$split2[1];

        var processed = acc.concat("qp[".concat(property, "]=").concat(value));
        return splittedQueryParams.length - 1 === idx ? processed : "".concat(processed, "&");
      }, '');
    }
  });

  _exports.default = _default;
});