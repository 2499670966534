define("ntfrontend/components/custom-fields/custom-values/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    sorting: ['name'],
    customValues: Ember.computed.sort('model.customValues', 'sorting'),
    editable: Ember.computed(function () {
      return this.can.can('update custom values on document', this.model);
    }),
    actions: {
      saveCustomValue: function saveCustomValue() {
        if (this.get('customValue.isDirty')) {
          this.customValue.execute();
          this.afterChange();
        }
      }
    }
  });

  _exports.default = _default;
});