define("ntfrontend/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6hYDcd/4",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"page-title\",[[29,\"t\",[\"shared.dashboard\"],null]],null],false],[0,\"\\n\\n\"],[5,\"accounts/call-to-create\",[],[[],[]]],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"logo\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"src\",\"/assets/images/9teams-logo-white.png\"],[12,\"alt\",[29,\"t\",[\"shared.labels.9teamsLogo\"],null]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/index.hbs"
    }
  });

  _exports.default = _default;
});