define("ntfrontend/components/projects/dashboard/milestones/milestones-list/status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var styles = {
    completed: 'icon-checkmark done',
    slightlyBehindTrack: 'icon-busy almost-done',
    behindTrack: 'icon-busy waiting',
    onTrack: 'icon-time in-progress',
    future: 'icon-calendar2 scheduled'
  };

  var _default = Ember.Component.extend({
    statusStyle: Ember.computed('status', function () {
      return styles[this.status];
    }),
    statusTooltip: Ember.computed('status', function () {
      return "milestone.labels.statuses.".concat(this.status);
    })
  });

  _exports.default = _default;
});