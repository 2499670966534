define("ntfrontend/components/work-logs/summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YbWLJoqT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"number -primary\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"number__number\"],[9],[1,[29,\"time-scope\",[[24,0,[\"hoursWorked\"]],[25,[\"scope\"]]],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"number__name\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"number__name-color\"],[9],[1,[29,\"t\",[\"work.labels.timeScope\"],[[\"scope\"],[[25,[\"scope\"]]]]],false],[10],[0,\"\\n    \"],[1,[29,\"t\",[\"work.labels.worked\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/work-logs/summary/template.hbs"
    }
  });

  _exports.default = _default;
});