define("ntfrontend/components/accounts/sub-menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentSession: Ember.inject.service(),
    router: Ember.inject.service(),
    features: Ember.inject.service(),
    classNames: 'sub-nav',
    isTemplateRoute: Ember.computed('router.currentURL', function () {
      return this.get('router.currentURL').includes('/account/templates');
    }),
    projectTemplatesEnabled: Ember.computed('features', function () {
      return this.features.isEnabled('project-templates');
    })
  });

  _exports.default = _default;
});