define("ntfrontend/components/shared/kanban-view/tooltip/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    translationText: Ember.computed('displayKanban', function () {
      return this.intl.t("kanban.tooltip.".concat(this.displayKanban));
    })
  });

  _exports.default = _default;
});