define("ntfrontend/routes/projects/index", ["exports", "ntfrontend/routes/org-auth", "ntfrontend/utils/ajaxer"], function (_exports, _orgAuth, _ajaxer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend({
    model: function model(params) {
      var data = {
        include: 'owner',
        filter: this._filters(params),
        page: {
          size: params.perPage,
          number: params.page
        },
        sort: params.sort
      };
      return this.store.query('project-list', data);
    },
    _filters: function _filters(params) {
      var filters = {
        status: params.statusId,
        requesterId: params.requesterId,
        ownerId: params.ownerId,
        archived: params.archived,
        accountId: this.get('currentSession.account.id'),
        q: params.q
      };
      return _ajaxer.default.reduceObject(filters);
    }
  });

  _exports.default = _default;
});