define("ntfrontend/components/projects/project-details/members/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P4QT9ic1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-sm-8\"],[9],[0,\"\\n    \"],[7,\"h3\"],[9],[1,[29,\"t\",[\"shared.labels.members\"],null],false],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"isLoading\"]]],null,{\"statements\":[[0,\"      \"],[5,\"shared/loading-indicator\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"projects/project-details/members/list\",[],[[\"@members\",\"@showCollaborators\",\"@showEmails\",\"@showRoles\",\"@showEdit\",\"@project\"],[[23,\"members\"],false,true,false,false,[23,\"project\"]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-sm-4\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"can\",[\"create members in project\",[25,[\"project\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"h3\"],[9],[1,[29,\"t\",[\"project.labels.addMembers\"],null],false],[10],[0,\"\\n      \"],[5,\"members/add-member\",[],[[\"@model\",\"@includeCollaborators\",\"@includeOrigin\",\"@createNew\",\"@refreshMembers\"],[[23,\"project\"],false,false,false,[23,\"refreshMembers\"]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/project-details/members/template.hbs"
    }
  });

  _exports.default = _default;
});