define("ntfrontend/routes/auth/login", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    beforeModel: function beforeModel(transition) {
      var _transition$to$queryP = transition.to.queryParams;
      _transition$to$queryP = _transition$to$queryP === void 0 ? {} : _transition$to$queryP;
      var _transition$to$queryP2 = _transition$to$queryP.redirectPath,
          redirectPath = _transition$to$queryP2 === void 0 ? undefined : _transition$to$queryP2;

      if (redirectPath) {
        this.set('routeIfAlreadyAuthenticated', redirectPath);
      }

      if (transition.to.queryParams.authToken) {
        this.set('session.redirect', redirectPath);
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});