define("ntfrontend/components/custom-fields/document-list/item-row/item/richtext-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cy42/NAM",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"customValue\",\"value\"]]],null,{\"statements\":[[0,\"    \\n  \"],[5,\"custom-fields/custom-values/components/richtext/modal\",[],[[\"@model\",\"@allowEditing\",\"@showModal\",\"@afterChange\"],[[23,\"customValue\"],[23,\"editable\"],[23,\"showModal\"],[23,\"afterChange\"]]],{\"statements\":[[0,\"\\n    \"],[5,\"shared/tooltip-block\",[],[[\"@tooltipTitle\"],[[29,\"t\",[\"shared.labels.showMore\"],null]]],{\"statements\":[[0,\"\\n      \"],[7,\"span\"],[12,\"class\",[29,\"if\",[[25,[\"editable\"]],\"cursor-on-hover\"],null]],[9],[1,[23,\"preview\"],false],[10],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"custom-fields/custom-values/components/richtext/modal\",[],[[\"@model\",\"@allowEditing\",\"@showModal\",\"@afterChange\"],[[23,\"customValue\"],[23,\"editable\"],[23,\"showModal\"],[23,\"afterChange\"]]],{\"statements\":[[0,\"\\n    \"],[7,\"span\"],[12,\"class\",[30,[\"placeholder \",[29,\"if\",[[25,[\"editable\"]],\"cursor-on-hover\"],null]]]],[9],[1,[29,\"t\",[\"shared.labels.notDefined\"],null],false],[10],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/custom-fields/document-list/item-row/item/richtext-field/template.hbs"
    }
  });

  _exports.default = _default;
});