define("ntfrontend/components/memos/memo-details/component", ["exports", "ntfrontend/mixins/components/model-saving", "ntfrontend/mixins/components/document-show", "ntfrontend/mixins/components/label-editing"], function (_exports, _modelSaving, _documentShow, _labelEditing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, _documentShow.default, _labelEditing.default, {
    can: Ember.inject.service(),
    introIsRunning: false,
    projectId: Ember.computed.readOnly('model.project.id'),
    filterUsers: Ember.computed.collect('model.owner'),
    editable: Ember.computed('model.ability', function () {
      return this.can.can('edit memo', this.model);
    })
  });

  _exports.default = _default;
});