define("ntfrontend/utils/comma-to-dot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.commaToDot = commaToDot;
  _exports.default = void 0;

  function commaToDot(hours) {
    return parseFloat(String(hours).replace(',', '.'));
  }

  var _default = Ember.Helper.helper(commaToDot);

  _exports.default = _default;
});