define("ntfrontend/components/shared/filters/due-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['filter', 'dropdown'],
    dues: Ember.computed(function () {
      var _this = this;

      return ['today', 'thisWeek', 'nextWeek', 'thisMonth', 'nextMonth', 'overdue', 'inFuture'].map(function (name) {
        return _this.buildDueItem(name);
      });
    }),
    currentDueName: Ember.computed('due', function () {
      if (this.due) {
        return this.dues.findBy('name', this.due).label;
      } else {
        return 'All';
      }
    }),
    buildDueItem: function buildDueItem(name) {
      return {
        name: name,
        label: this.intl.t("shared.filters.due.".concat(name))
      };
    },
    actions: {
      changeDue: function changeDue(dueName) {
        this.set('due', dueName);
      }
    }
  });

  _exports.default = _default;
});