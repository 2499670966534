define("ntfrontend/templates/account/users/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J22625Vg",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"page-title\",[[29,\"t\",[\"shared.labels.users\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"page-header\"],[9],[0,\"\\n  \"],[5,\"shared/header-with-total/members-count\",[],[[\"@labelKey\",\"@total\"],[\"shared.labels.users\",[25,[\"model\",\"members\",\"meta\",\"total\"]]]]],[0,\"\\n  \"],[5,\"members/managers-count\",[],[[\"@model\"],[[25,[\"model\",\"resource\"]]]]],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-header__menu\"],[9],[0,\"\\n    \"],[5,\"members/add-member-button\",[],[[\"@model\"],[[25,[\"model\",\"resource\"]]]]],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[5,\"members/member-list\",[],[[\"@members\"],[[25,[\"model\",\"members\"]]]]],[0,\"\\n\"],[5,\"pagination-list\",[],[[\"@model\",\"@currentPage\",\"@perPage\",\"@changePage\"],[[25,[\"model\",\"members\"]],[23,\"page\"],[23,\"perPage\"],[29,\"action\",[[24,0,[]],\"changePage\"],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/account/users/index.hbs"
    }
  });

  _exports.default = _default;
});