define("ntfrontend/components/shared/filters/custom-fields/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    tagName: '',
    customFilters: Ember.computed('settings', 'collectionType', 'param', function () {
      var settings = this.settings,
          collectionType = this.collectionType,
          param = this.param;
      var availableFields = settings[collectionType];
      var listFields = availableFields.filter(function (field) {
        return field.type === 'list';
      });
      return listFields.map(function (field) {
        if (param) {
          Ember.set(field, 'value', param[field.id]);
        } else {
          Ember.set(field, 'value', null);
        }

        return field;
      });
    }),
    actions: {
      updateFilter: function updateFilter(key, value) {
        var param = this.param;
        var newParam = Ember.assign({}, param, _defineProperty({}, key, value));
        this.set('param', newParam);
      },
      removeFilter: function removeFilter(key) {
        var param = this.param;
        delete param[key];
        this.set('param', Ember.assign({}, param));
      }
    }
  });

  _exports.default = _default;
});