define("ntfrontend/components/works/sneak-peek/sneak-peek-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    paneManager: Ember.inject.service(),
    classNames: null,
    tagName: '',
    actions: {
      openSneakPeek: function openSneakPeek(model, project, date) {
        this.set('paneManager.showPane', true);

        if (this.isCreated) {
          this.set('paneManager.selectedSneak', 'createWorkLog');
          this.set('paneManager.sneakPeekProject', project);
          this.set('paneManager.sneakPeekWorkLogDate', date);
          this.set('paneManager.sneakWorkItem', model);
        } else if (this.isUpdated) {
          this.set('paneManager.selectedSneak', 'updateWorkLog');
          this.set('paneManager.sneakPeekProject', project);
          this.set('paneManager.sneakPeekWorkLog', model);
        } else {
          this.set('paneManager.selectedSneak', 'workLog');
          this.set('paneManager.sneakPeekWorkLog', model);
        }
      }
    }
  });

  _exports.default = _default;
});