define("ntfrontend/components/shared/modal-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gQu6ERT8",
    "block": "{\"symbols\":[\"modal\",\"&default\"],\"statements\":[[4,\"if\",[[25,[\"shouldRender\"]]],null,{\"statements\":[[0,\"  \"],[5,\"ember-wormhole\",[],[[\"@to\"],[\"modal-dialog-wrapper\"]],{\"statements\":[[0,\"\\n    \"],[5,\"shared/modal-dialog/modal\",[],[[\"@showModal\",\"@onClose\",\"@onSuccess\",\"@onOpen\",\"@title\",\"@customModal\",\"@class\",\"@options\",\"@closeAfterSuccess\"],[[23,\"showModal\"],[23,\"onClose\"],[23,\"onSuccess\"],[23,\"onOpen\"],[23,\"title\"],[23,\"customModal\"],[23,\"modalClass\"],[23,\"modalOptions\"],[23,\"closeAfterSuccess\"]]],{\"statements\":[[0,\"\\n      \"],[15,2,[[29,\"hash\",null,[[\"onSuccess\"],[[24,1,[\"onSuccess\"]]]]]]],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/modal-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});