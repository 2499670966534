define("ntfrontend/components/shared/sneak-peek-pane/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    paneManager: Ember.inject.service(),
    router: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.closePaneObserver();
    },
    tagName: '',
    bodyClass: 'sidebar-open',
    showPane: Ember.computed.alias('paneManager.showPane'),
    currentRoute: Ember.computed.alias('router.currentRouteName'),
    paneName: Ember.computed('pane', function () {
      return "".concat(this.pane, "-pane");
    }),
    showPaneObserver: Ember.observer('showPane', function () {
      var bodyElement = document.querySelector('body');
      var bodyClass = this.bodyClass;

      if (this.showPane) {
        bodyElement.classList.add(bodyClass);
      } else {
        bodyElement.classList.remove(bodyClass);
      }
    }),
    closePaneObserver: Ember.observer('currentRoute', function () {
      if (this.currentRoute) {
        this.paneManager.set('showPane', false);
      }
    })
  });

  _exports.default = _default;
});