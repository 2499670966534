define("ntfrontend/components/search-box/previews/task/component", ["exports", "ember-inflector", "ntfrontend/helpers/model-type-name"], function (_exports, _emberInflector, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: 'task-search-preview',
    typeName: Ember.computed.readOnly('result.resourceType'),
    model: Ember.computed('result.resource.id', 'typeName', function () {
      var id = this.result.belongsTo('resource').id();
      var data = {
        include: 'resource.document.project,creator'
      };
      return this.store.findRecord(this.typeName, id, data);
    }),
    documentType: Ember.computed('model.document', function () {
      return (0, _modelTypeName.modelTypeName)([this.get('model.document')]);
    }),
    documentRoute: Ember.computed('documentType', function () {
      var type = this.documentType;
      return "".concat((0, _emberInflector.pluralize)(type), ".").concat(type).toLowerCase();
    }),
    onClose: function onClose() {},
    actions: {
      redirect: function redirect() {
        this.onClose();
        var urlToRedirect = this.router.urlFor(this.get('result.resourceRoute'), this.get('result.slug'));
        this.router.transitionTo(urlToRedirect);
      },
      redirectTo: function redirectTo(route, id) {
        this.onClose();
        var urlToRedirect = this.router.urlFor(route, id);
        this.router.transitionTo(urlToRedirect);
      }
    }
  });

  _exports.default = _default;
});