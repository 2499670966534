define("ntfrontend/components/custom-fields/document-list/item-row/item/user-field/modal/component", ["exports", "ntfrontend/mixins/components/force-modal-close"], function (_exports, _forceModalClose) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_forceModalClose.default, {
    newContact: '',
    click: function click() {
      if (this.editable) {
        this.set('showModal', true);
      }
    },
    actions: {
      setContact: function setContact(value) {
        if (value) {
          this.set('newContact', value.get('id'));
        } else {
          this.set('newContact', value);
        }

        this.set('selected', value);
      },
      rollback: function rollback() {
        this.set('newContact', '');
        this.set('showModal', false);
        this.set('selected', null);
      }
    }
  });

  _exports.default = _default;
});