define("ntfrontend/components/shared/filters/switcher-filter/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: 'bootstrap-switch-sm',
    firstState: 'false',
    secondState: 'true',
    activeLabelKey: 'shared.switcher.active',
    checkedObserver: Ember.observer('value', function () {
      (0, _jquery.default)('input', this.element).bootstrapSwitch('state', this.value, true);
    }),
    value: Ember.computed('state', function () {
      return this.state === this.firstState;
    }),
    translationText: Ember.computed('documentName', function () {
      return this.intl.t("shared.labels.".concat(this.documentName));
    }),
    didInsertElement: function didInsertElement() {
      this._setupSwitcher();
    },
    _setupSwitcher: function _setupSwitcher() {
      var _this = this;

      (0, _jquery.default)('input', this.element).bootstrapSwitch(this._options());
      (0, _jquery.default)('input', this.element).on('switchChange.bootstrapSwitch', function () {
        var newValue = _this.state === _this.firstState ? _this.secondState : _this.firstState;

        _this.set('state', newValue);
      });
    },
    _options: function _options() {
      return {
        onColor: 'primary',
        offColor: 'primary',
        onText: this.intl.t(this.activeLabelKey),
        offText: this.intl.t('shared.switcher.all')
      };
    }
  });

  _exports.default = _default;
});