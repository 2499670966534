define("ntfrontend/components/works/plan-tab/milestone-list/work-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNames: 'plan-work__work-items'
  });

  _exports.default = _default;
});