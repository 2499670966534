define("ntfrontend/templates/memos/memo/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SG6Yiedu",
    "block": "{\"symbols\":[],\"statements\":[[5,\"memos/memo-details\",[],[[\"@model\"],[[23,\"model\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/memos/memo/index.hbs"
    }
  });

  _exports.default = _default;
});