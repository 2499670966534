define("ntfrontend/components/shared/loading-dots/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "89NsEg71",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"class\",\"loading-dots\"],[11,\"height\",\"10\"],[11,\"width\",\"15\"],[9],[0,\"\\n  \"],[7,\"circle\"],[11,\"class\",\"dot\"],[11,\"cx\",\"2\"],[11,\"cy\",\"6\"],[11,\"r\",\"2\"],[9],[10],[0,\"\\n  \"],[7,\"circle\"],[11,\"class\",\"dot\"],[11,\"cx\",\"7\"],[11,\"cy\",\"6\"],[11,\"r\",\"2\"],[9],[10],[0,\"\\n  \"],[7,\"circle\"],[11,\"class\",\"dot\"],[11,\"cx\",\"12\"],[11,\"cy\",\"6\"],[11,\"r\",\"2\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/loading-dots/template.hbs"
    }
  });

  _exports.default = _default;
});