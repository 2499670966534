define("ntfrontend/helpers/generate-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateColor = generateColor;
  _exports.default = void 0;

  var _hashCode = function _hashCode(str) {
    if (Array.isArray(str)) {
      str = str[0];
    }

    var hash = 0;

    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    return Math.pow(hash, 2) - hash;
  };

  var _intToARGB = function _intToARGB(i) {
    return (i >> 24 & 0xFF).toString(16) + (i >> 16 & 0xFF).toString(16) + (i >> 8 & 0xFF).toString(16);
  };

  function generateColor(name) {
    if (Array.isArray(name) && name[0] === undefined) {
      return 'ffffff';
    }

    var color = _intToARGB(_hashCode(name));

    return "".concat(color.length === 6 ? "".concat(color, "80") : "".concat(color, "680"));
  }

  var _default = Ember.Helper.helper(generateColor);

  _exports.default = _default;
});