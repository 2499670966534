define("ntfrontend/components/tasks/task-switcher/component", ["exports", "ntfrontend/mixins/components/model-saving", "ntfrontend/mixins/components/tasks/task-switcher", "jquery", "ntfrontend/utils/date-utils"], function (_exports, _modelSaving, _taskSwitcher, _jquery, _dateUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, _taskSwitcher.default, {
    classNames: 'inplace-inline',
    state: 'completed',
    classNameBindings: ['isOverdue:task-switcher-red'],
    tooltipTitle: Ember.computed('model.completed', function () {
      var completed = this.model.completed;
      var label = completed ? 'markAsTodo' : 'markAsDone';
      return this.intl.t("shared.switcher.".concat(label));
    }),
    didInsertElement: function didInsertElement() {
      this._setupSwitcher();
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      var deadlineAt = this.model.deadlineAt;
      this.set('isOverdue', (0, _dateUtils.pastDate)(deadlineAt));
    },
    _setupSwitcher: function _setupSwitcher() {
      var _this = this;

      (0, _jquery.default)('input', this.element).bootstrapSwitch(this._options());
      (0, _jquery.default)('input', this.element).on('switchChange.bootstrapSwitch', function () {
        if (_this.disabled) {
          (0, _jquery.default)('input', _this.element).bootstrapSwitch('toggleState', true);
        } else {
          _this.toggleState();
        }
      });
    },
    _options: function _options() {
      return {
        onColor: 'success',
        offColor: 'warning',
        disabled: this.disabledInput,
        onText: this.intl.t('shared.switcher.done'),
        offText: this.intl.t('shared.switcher.todo')
      };
    },
    _afterSaveError: function _afterSaveError() {
      this._super.apply(this, arguments);

      (0, _jquery.default)('input', this.element).bootstrapSwitch('toggleState', true);
    }
  });

  _exports.default = _default;
});