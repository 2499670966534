define("ntfrontend/components/memos/memo-filters/status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['filter', 'dropdown'],
    statuses: Ember.computed(function () {
      var _this = this;

      return ['all', 'false', 'true'].map(function (name) {
        return _this.buildStatusItem(name);
      });
    }),
    currentStatusName: Ember.computed('projectStatus', function () {
      return this.statuses.findBy('name', this.projectStatus).label;
    }),
    buildStatusItem: function buildStatusItem(name) {
      return {
        name: name,
        label: this.intl.t("document.labels.archived_statuses.".concat(name))
      };
    },
    actions: {
      changeStatus: function changeStatus(statusName) {
        this.set('projectStatus', statusName);
      }
    }
  });

  _exports.default = _default;
});