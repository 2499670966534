define("ntfrontend/components/projects/dashboard/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TgBUGle0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"project__progress-wrapper\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"project__status__label\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"text-muted\"],[9],[1,[29,\"t\",[\"project.dashboard.progress\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"a\"],[12,\"class\",[29,\"if\",[[25,[\"editable\"]],\"inplace-editing\"],null]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"progress\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"progress__bar\"],[12,\"style\",[23,\"progressBarStyle\"]],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"progress__number\"],[9],[1,[25,[\"model\",\"progress\"]],false],[7,\"small\"],[9],[0,\"%\"],[10],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"redirectToWorkView\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/dashboard/progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});