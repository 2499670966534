define("ntfrontend/components/work-logs/kanban-list/column-summary/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    totalHours: Ember.computed('records', function () {
      var records = this.records;

      if (records.length) {
        return records.reduce(function (acc, _ref) {
          var hoursWorked = _ref.hoursWorked;
          return acc + hoursWorked;
        }, 0);
      } else {
        return 0;
      }
    })
  });

  _exports.default = _default;
});