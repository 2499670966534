define("ntfrontend/components/templates/document-templates/edit/project-types/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentSession: Ember.inject.service(),
    projectTypes: Ember.computed.alias('currentSession.account.projectTypes'),
    modelProjectTypesEmpty: Ember.computed.empty('model'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!this.originalprojectTypes) {
        this._initializeprojectTypes();
      }
    },
    actions: {
      finishEditing: function finishEditing() {
        this.set('model', this.originalprojectTypes);
        this.saveModel();

        this._disableEditing();
      },
      cancelEditing: function cancelEditing() {
        this._initializeprojectTypes();

        this._disableEditing();
      },
      enableEditing: function enableEditing() {
        this.set('isEditing', true);
      }
    },
    _initializeprojectTypes: function _initializeprojectTypes() {
      this.set('originalprojectTypes', this.model);
    },
    _disableEditing: function _disableEditing() {
      this.set('isEditing', false);
    }
  });

  _exports.default = _default;
});