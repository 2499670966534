define("ntfrontend/templates/projects/project/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J4AUINgR",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"page-title\",[[29,\"t\",[\"shared.dashboard\"],null]],null],false],[0,\"\\n\\n\"],[7,\"section\"],[11,\"class\",\"section\"],[9],[0,\"\\n  \"],[5,\"projects/dashboard/overall-status\",[],[[\"@model\"],[[25,[\"model\",\"dashboard\"]]]]],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"section\"],[11,\"class\",\"section\"],[9],[0,\"\\n  \"],[5,\"projects/dashboard/milestones/milestones-list\",[],[[\"@project\"],[[25,[\"model\",\"project\"]]]]],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"section\"],[11,\"class\",\"section\"],[9],[0,\"\\n  \"],[5,\"projects/dashboard/work-packages/list\",[],[[\"@project\"],[[25,[\"model\",\"project\"]]]]],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"section\"],[11,\"class\",\"section\"],[9],[0,\"\\n  \"],[5,\"projects/dashboard/task-assignments\",[],[[\"@model\"],[[25,[\"model\",\"dashboard\"]]]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/projects/project/dashboard.hbs"
    }
  });

  _exports.default = _default;
});