define("ntfrontend/mixins/components/tasks/task-deadline-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    classNameBindings: ['deadlineClass'],
    deadlineClass: Ember.computed('model.completed', 'past', function () {
      var klass;

      if (this.get('model.completed')) {
        klass = 'task-done';
      } else if (this.past) {
        klass = 'task-overdue';
      } else {
        klass = 'task-pending';
      }

      return "calendar-deadline-date--".concat(klass);
    })
  });

  _exports.default = _default;
});