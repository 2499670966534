define("ntfrontend/components/works/plan-tab/actions/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'button-wrapper',
    hasNoMilestones: Ember.computed.not('project.milestones.length'),
    hasNoWorkPackages: Ember.computed('hasNoMilestones', 'project.workPackages.length', function () {
      var hasNoMilestones = this.hasNoMilestones,
          project = this.project;
      return !hasNoMilestones && !project.get('workPackages.length');
    }),
    hasNoWorkItems: Ember.computed('hasNoWorkPackages', 'project.workPackages.length', 'project.workItems.length', function () {
      var hasNoMilestones = this.hasNoMilestones,
          project = this.project;
      return !hasNoMilestones && project.get('workPackages.length') && project.get('workItems.length') == 0;
    })
  });

  _exports.default = _default;
});