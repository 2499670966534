define("ntfrontend/components/custom-fields/assignments/assignment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gVM8dSu9",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"assignmentStatus\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"switcher-small icon-checkmark switcher-small--done\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"switcher-small icon-checkmark\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/custom-fields/assignments/assignment/template.hbs"
    }
  });

  _exports.default = _default;
});