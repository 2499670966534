define("ntfrontend/components/tasks/overdue-indicator/component", ["exports", "ntfrontend/mixins/components/tooltip"], function (_exports, _tooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tooltip.default, {
    tagName: 'span',
    classNames: ['overdue-tasks-state'],
    classNameBindings: ['value::hidden'],
    attributeBindings: ['data-value'],
    'data-value': Ember.computed.readOnly('value'),
    intl: Ember.inject.service(),
    tooltipTitle: Ember.computed('userScoped', function () {
      var key = this.userScoped ? 'myOverdue' : 'allOverdue';
      return this.intl.t("task.overdue.".concat(key));
    })
  });

  _exports.default = _default;
});