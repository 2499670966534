define("ntfrontend/components/steps/step-list/component", ["exports", "jquery", "ntfrontend/mixins/components/tooltip", "ntfrontend/mixins/components/query-params", "ntfrontend/helpers/model-type-name"], function (_exports, _jquery, _tooltip, _queryParams, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tooltip.default, _queryParams.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    states: Ember.inject.service(),
    notify: Ember.inject.service('notify-alert'),
    classNames: ['steps-list-component'],
    classNameBindings: ['agendaMode'],
    progress: true,
    agendaMode: true,
    tooltipEnabled: false,
    stepsSorting: ['position:asc'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('queryParams.task')) {
        this.set('agendaMode', false);
      }

      this.createTooltip('.icon-unordered-list', {
        title: this.intl.t('step.labels.agendaMode')
      });
      this.createTooltip('.icon-full-mode', {
        title: this.intl.t('step.labels.fullMode')
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.destroyTooltip('.icon-unordered-list');
      this.destroyTooltip('.icon-full-mode');

      this._super.apply(this, arguments);
    },
    sortedSteps: Ember.computed.sort('steps', 'stepsSorting'),
    showReminder: Ember.computed('steps.isFulfilled', 'steps.isLoaded', 'introIsRunning', function () {
      return (this.get('steps.isFulfilled') || this.get('steps.isLoaded')) && !this.introIsRunning;
    }),
    hasSteps: Ember.computed('steps.length', function () {
      return Ember.isPresent(this.steps);
    }),
    steps: Ember.computed('model', function () {
      return this.store.query('step', {
        filter: {
          documentId: this.get('model.id'),
          documentType: (0, _modelTypeName.modelTypeName)([this.model])
        },
        include: 'ability,tasks.owner,related-tasks.owner,owner'
      });
    }),
    scrollObserver: Ember.observer('steps.length', 'states.scrollTo', function () {
      var _this = this;

      var anchor = this.get('states.scrollTo');

      if (this.get('steps.isFulfilled') && anchor) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          if ((0, _jquery.default)("#".concat(anchor)).length) {
            (0, _jquery.default)('.page-content').animate({
              scrollTop: (0, _jquery.default)("#".concat(anchor)).offset().top
            }, 1000);
          }

          _this.states.set('scrollTo', null);
        });
      }
    }),
    reorderSteps: function reorderSteps(steps, draggedModel) {
      var prevPosition = draggedModel.position;
      steps.forEach(function (model, index) {
        model.set('position', index + 1);
      });

      this._afterReorder(draggedModel, prevPosition);

      this.set('model.steps', steps);
    },
    actions: {
      reorderItems: function reorderItems(itemModels, draggedModel) {
        if (this.editable) {
          this.reorderSteps(itemModels, draggedModel);
        }
      },
      displayAgenda: function displayAgenda(agendaMode) {
        this.set('agendaMode', agendaMode);
      },
      expandStep: function expandStep(stepId) {
        this.set('agendaMode', false);
        this.set('states.scrollTo', stepId);
      },
      refreshSteps: function refreshSteps() {
        this.notifyPropertyChange('steps');
      }
    },
    _afterReorder: function _afterReorder(draggedModel, prevPosition) {
      var _this2 = this;

      if (draggedModel.position != prevPosition) {
        draggedModel.save().then(function () {
          _this2.notify.success('notify.step.reordered');
        });
      }
    }
  });

  _exports.default = _default;
});