define("ntfrontend/components/comments/comment-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wwJcu/BF",
    "block": "{\"symbols\":[\"comment\"],\"statements\":[[5,\"shared/loading-state/block\",[],[[\"@model\",\"@tagName\"],[[25,[\"thread\",\"comments\"]],\"\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[25,[\"sortedComments\"]]],null,{\"statements\":[[4,\"unless\",[[24,1,[\"isNew\"]]],null,{\"statements\":[[0,\"      \"],[5,\"comments/comment-item\",[],[[\"@model\"],[[24,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/comments/comment-list/template.hbs"
    }
  });

  _exports.default = _default;
});