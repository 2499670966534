define("ntfrontend/components/works/sneak-peek/sneak-peek-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dOyeMb1L",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"a\"],[12,\"class\",[23,\"classNames\"]],[9],[0,\"\\n\"],[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"    \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"unless\",[[25,[\"isCreated\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,[\"model\",\"workItem\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[3,\"action\",[[24,0,[]],\"openSneakPeek\",[25,[\"model\"]],[25,[\"project\"]],[25,[\"date\"]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/works/sneak-peek/sneak-peek-button/template.hbs"
    }
  });

  _exports.default = _default;
});