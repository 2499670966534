define("ntfrontend/components/works/plan-tab/milestone-list/workpackage-description/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'table',
    classNames: 'plan-work__table -description'
  });

  _exports.default = _default;
});