define("ntfrontend/components/custom-fields/options/multiple-value/component", ["exports", "ntfrontend/components/shared/state-switcher/component", "ntfrontend/components/shared/state-switcher/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    intl: Ember.inject.service(),
    _options: function _options() {
      return {
        onColor: 'success',
        offColor: 'primary',
        onText: this.intl.t('organization.customField.labels.multipleValues'),
        offText: this.intl.t('organization.customField.labels.singleValue')
      };
    }
  });

  _exports.default = _default;
});