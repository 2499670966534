define("ntfrontend/components/shared/kanban-view/switcher/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UrBbY8xe",
    "block": "{\"symbols\":[],\"statements\":[[5,\"input\",[],[[\"@type\",\"@checked\"],[\"checkbox\",[23,\"displayKanban\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/kanban-view/switcher/template.hbs"
    }
  });

  _exports.default = _default;
});