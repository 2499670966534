define("ntfrontend/components/shared/copy-to-clipboard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "33Uo3U8V",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,0,[\"propertyCopied\"]]],null,{\"statements\":[[0,\"  \"],[15,1,[[29,\"hash\",null,[[\"copied\"],[[29,\"component\",[\"shared/copy-to-clipboard/copied\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[15,1,[[29,\"hash\",null,[[\"toCopy\"],[[29,\"component\",[\"shared/copy-to-clipboard/to-copy\"],[[\"copyProperty\"],[[29,\"action\",[[24,0,[]],\"copyProperty\"],null]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/copy-to-clipboard/template.hbs"
    }
  });

  _exports.default = _default;
});