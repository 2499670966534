define("ntfrontend/components/works/create-tab/details/date-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BgcoG4KI",
    "block": "{\"symbols\":[\"@inputId\"],\"statements\":[[5,\"shared/material-labels\",[],[[\"@labelFor\",\"@placeholder\",\"@hasValue\"],[[24,1,[]],[29,\"t\",[\"work.placeholders.date\"],null],true]],{\"statements\":[[0,\"\\n  \"],[5,\"shared/inplace-datepicker\",[],[[\"@id\",\"@value\",\"@visibleDashedStyle\",\"@afterChange\",\"@format\"],[[24,1,[]],[23,\"date\"],true,[29,\"action\",[[24,0,[]],\"setWorkedHours\"],null],\"DD-MM-YYYY\"]],{\"statements\":[[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"icon-calendar\"],[9],[0,\"\\n      \"],[1,[29,\"format-date\",[[25,[\"date\"]]],[[\"format\"],[\"short\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/works/create-tab/details/date-select/template.hbs"
    }
  });

  _exports.default = _default;
});