define("ntfrontend/templates/projects/project/tasks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9c8mvQje",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"page-title\",[[29,\"t\",[\"shared.labels.tasks\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"page-header\"],[9],[0,\"\\n  \"],[5,\"shared/header-with-total\",[],[[\"@labelKey\",\"@total\"],[\"shared.labels.tasks\",[24,0,[\"model\",\"tasks\",\"meta\",\"total\"]]]]],[0,\"\\n  \"],[5,\"shared/search-input\",[],[[\"@value\"],[[24,0,[\"q\"]]]]],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-header__menu\"],[9],[0,\"\\n    \"],[5,\"shared/filters/work-package\",[],[[\"@workPackageId\",\"@scope\"],[[24,0,[\"workPackageId\"]],[24,0,[\"model\",\"project\"]]]]],[0,\"\\n    \"],[5,\"shared/filters/milestone-filter\",[],[[\"@milestoneId\",\"@scope\"],[[24,0,[\"milestoneId\"]],[24,0,[\"model\",\"project\"]]]]],[0,\"\\n    \"],[5,\"shared/list-filter\",[],[[\"@model\",\"@type\",\"@translationKey\",\"@scope\"],[[24,0,[\"ownerId\"]],\"user\",\"shared.labels.owner\",[24,0,[\"currentSession\",\"account\"]]]]],[0,\"\\n    \"],[5,\"shared/list-filter\",[],[[\"@model\",\"@type\",\"@translationKey\",\"@scope\"],[[24,0,[\"requesterId\"]],\"user\",\"shared.labels.requester\",[24,0,[\"currentSession\",\"account\"]]]]],[0,\"\\n    \"],[5,\"tasks/filters/status-filter\",[],[[\"@status\"],[[24,0,[\"completed\"]]]]],[0,\"\\n    \"],[5,\"shared/filters/due-filter\",[],[[\"@due\"],[[24,0,[\"due\"]]]]],[0,\"\\n    \"],[5,\"shared/excel-button\",[],[[\"@model\",\"@collection\",\"@resourcesCount\"],[[24,0,[\"model\",\"project\"]],\"tasks\",[24,0,[\"model\",\"tasks\",\"length\"]]]]],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[5,\"tasks/task-list\",[],[[\"@tasks\",\"@sort\",\"@class\"],[[24,0,[\"model\",\"tasks\"]],[24,0,[\"sort\"]],\"table__scroller\"]]],[0,\"\\n\"],[5,\"pagination-list\",[],[[\"@model\",\"@currentPage\",\"@perPage\",\"@changePage\"],[[24,0,[\"model\",\"tasks\"]],[24,0,[\"page\"]],[24,0,[\"perPage\"]],[29,\"action\",[[24,0,[]],\"changePage\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/projects/project/tasks.hbs"
    }
  });

  _exports.default = _default;
});