define("ntfrontend/components/works/plan-tab/milestone-list/work-item/dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WMFnCYsO",
    "block": "{\"symbols\":[\"@model\",\"@project\",\"@refreshWorkItems\",\"@filterTimeScope\"],\"statements\":[[5,\"shared/actions/more-actions-button\",[],[[],[]]],[0,\"\\n\\n\"],[4,\"if\",[[24,0,[\"openDropdown\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\"],[11,\"class\",\"dropdown-menu dropdown-menu-right\"],[9],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"\\n      \"],[5,\"works/plan-tab/work-item-modal\",[],[[\"@typeModal\",\"@model\",\"@project\",\"@refreshWorkItems\",\"@filterTimeScope\",\"@label\"],[\"update\",[24,1,[]],[24,2,[]],[24,3,[]],[24,4,[]],[29,\"t\",[\"shared.actions.edit\"],null]]]],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[29,\"can\",[\"remove workItem\",[24,1,[]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[5,\"shared/confirm-remove-link\",[],[[\"@afterConfirm\"],[[29,\"action\",[[24,0,[]],\"remove\"],null]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"li\"],[9],[5,\"documents/archive-button\",[],[[\"@model\"],[[24,1,[]]]]],[10],[0,\"\\n\"],[4,\"if\",[[29,\"can\",[\"complete work-item\",[24,1,[]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"complete\"],null]],[9],[0,\"\\n        \"],[7,\"a\"],[11,\"class\",\"remove-link\"],[9],[0,\"\\n          \"],[1,[29,\"t\",[\"shared.labels.completeWorkItemShort\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/works/plan-tab/milestone-list/work-item/dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});