define("ntfrontend/components/documents/participation-members/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pWDk9v0P",
    "block": "{\"symbols\":[\"member\"],\"statements\":[[4,\"each\",[[25,[\"members\"]]],null,{\"statements\":[[0,\"  \"],[5,\"users/profile-box\",[],[[\"@user\",\"@editable\"],[[24,1,[\"user\"]],false]],{\"statements\":[[0,\"\\n    \"],[5,\"documents/participation-members/dropdown\",[],[[\"@model\",\"@member\"],[[24,1,[\"resource\",\"content\"]],[24,1,[]]]]],[0,\"\\n    \"],[5,\"documents/participation-members/delivery-status\",[],[[\"@class\",\"@member\"],[\"label--clickable\",[24,1,[]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/documents/participation-members/list/template.hbs"
    }
  });

  _exports.default = _default;
});