define("ntfrontend/mixins/routes/only-for-app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    redirect: function redirect() {
      this._super.apply(this, arguments);

      if (this.get('urlChecker.subdomain') !== 'app') {
        this.transitionTo('index');
      }
    }
  });

  _exports.default = _default;
});