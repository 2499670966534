define("ntfrontend/components/projects/dashboard/state-status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['project__status-details'],
    tagName: 'span',
    editable: false,
    showModal: false,
    valueOnly: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.reopen({
        value: Ember.computed.alias("model.".concat(this.property))
      });
    },
    stateKey: Ember.computed('value', 'model.states', function () {
      return this.get('model.states')[this.value];
    }),
    actions: {
      changeState: function changeState(state) {
        if (this.editable) {
          this.set('showModal', true);
          this.set('value', state);
        }
      }
    }
  });

  _exports.default = _default;
});