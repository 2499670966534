define("ntfrontend/components/custom-fields/custom-values/components/user/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['row', 'flex--center'],
    selected: Ember.computed('fromSelect', 'customValue', function () {
      var customValue = this.customValue,
          fromSelect = this.fromSelect;

      if (!fromSelect && customValue.get('value')) {
        var currentUserId = this.get('currentSession.user.id');
        return customValue === 'me' ? this._findUser('user', currentUserId) : this._findUser('user', customValue.get('value'));
      } else {
        return fromSelect;
      }
    }),
    actions: {
      updateUserModel: function updateUserModel(value) {
        if (value) {
          this.set('customValue.value', value.get('id'));
        } else {
          this.set('customValue.value', value);
        }

        this.customValue.execute();
        this.afterChange();
        this.set('fromSelect', value);
      }
    },
    _findUser: function _findUser(type, userId) {
      return this.store.find(type, userId);
    }
  });

  _exports.default = _default;
});