define("ntfrontend/components/custom-fields/custom-values/components/user/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GiYQ+8Ua",
    "block": "{\"symbols\":[\"escapeSelect\"],\"statements\":[[7,\"div\"],[11,\"class\",\"col-xs-4\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"text-muted\"],[9],[1,[25,[\"customValue\",\"name\"]],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"col-xs-8\"],[9],[0,\"\\n  \"],[5,\"shared/inplace-select\",[],[[\"@value\",\"@editable\",\"@type\",\"@placeholder\"],[[25,[\"customValue\",\"value\"]],[23,\"editable\"],\"user\",\"organization.customField.placeholders.selectUser\"]],{\"statements\":[[0,\"\\n    \"],[5,\"users/select-user\",[],[[\"@allowClear\",\"@scope\",\"@value\",\"@onSelectionChanged\",\"@escapeSelect\"],[true,[25,[\"currentSession\",\"account\"]],[23,\"selected\"],[29,\"action\",[[24,0,[]],\"updateUserModel\"],null],[24,1,[]]]]],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/custom-fields/custom-values/components/user/template.hbs"
    }
  });

  _exports.default = _default;
});