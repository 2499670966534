define("ntfrontend/models/resource", ["exports", "ember-data", "ember-custom-actions"], function (_exports, _emberData, _emberCustomActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    roles: attr(),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    thread: belongsTo('thread', {
      inverse: 'resource'
    }),
    members: hasMany('member', {
      inverse: 'resource'
    }),
    userAccesses: hasMany('user-access', {
      inverse: 'resource'
    }),
    attachments: hasMany('attachment', {
      inverse: 'resource'
    }),
    invite: (0, _emberCustomActions.modelAction)('invite', {
      method: 'POST'
    }),
    defaultRole: 'member'
  });

  _exports.default = _default;
});