define("ntfrontend/routes/scenarios/index", ["exports", "ntfrontend/routes/org-auth"], function (_exports, _orgAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend({
    breadCrumb: null,
    redirect: function redirect() {
      this._super.apply(this, arguments);

      this.transitionTo('dashboard');
    }
  });

  _exports.default = _default;
});