define("ntfrontend/components/templates/project-templates/item/dropdown/component", ["exports", "ntfrontend/components/templates/templates-list/item/dropdown/component", "ntfrontend/components/templates/templates-list/item/dropdown/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    resourceName: 'projects'
  });

  _exports.default = _default;
});