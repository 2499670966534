define("ntfrontend/components/tasks/task-sneak-peek/deadline-date/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    taskDistributionNotifier: Ember.inject.service(),
    classNames: ['picker--small', 'table-input'],
    successCallback: function successCallback() {
      this.taskDistributionNotifier.notify(this.get('model.members'));
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.set('initialValue', this.get('model.deadlineAt'));
    },
    actions: {
      afterChange: function afterChange() {
        if (this.initialValue.getTime() !== this.get('model.deadlineAt').getTime()) {
          this.set('initialValue', this.get('model.deadlineAt'));
          this.send('saveModel');
        }
      }
    }
  });

  _exports.default = _default;
});