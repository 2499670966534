define("ntfrontend/templates/auth/register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HJos3Kxq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"col-xs-12 col-sm-6 auth-page\"],[9],[0,\"\\n  \"],[7,\"h2\"],[11,\"class\",\"text-center page-header\"],[9],[1,[29,\"t\",[\"auth.signUp\"],null],false],[10],[0,\"\\n  \"],[5,\"auth/register-form\",[],[[\"@email\"],[[25,[\"model\",\"email\"]]]]],[0,\"\\n  \"],[5,\"auth/auth-links\",[],[[],[]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/auth/register.hbs"
    }
  });

  _exports.default = _default;
});