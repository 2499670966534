define("ntfrontend/components/shared/actions/remove-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sweetAlert: Ember.inject.service('sweet-alert'),
    currentSession: Ember.inject.service(),
    visible: true,
    onlyIcon: true,
    click: function click() {
      var _this = this;

      this.sweetAlert.confirm(this.messageKey, this.titleKey, function () {
        _this.model.destroyRecord().then(function (model) {
          _this.afterRemove(model);

          _this.currentSession.account.reload();
        });
      });
    }
  });

  _exports.default = _default;
});