define("ntfrontend/components/search-box/dropdown-menu/results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k9+/8I0H",
    "block": "{\"symbols\":[\"group\",\"item\",\"&default\"],\"statements\":[[5,\"shared/loading-state/block\",[],[[\"@loaded\",\"@tagName\"],[[23,\"loaded\"],\"\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,[\"searchResults\"]]],null,{\"statements\":[[4,\"each\",[[25,[\"groups\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"dropdown-header\"],[9],[1,[29,\"t-scope\",[[24,1,[\"value\"]],\"search.types\"],null],false],[10],[0,\"\\n\\n\"],[4,\"each\",[[24,1,[\"items\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[12,\"class\",[29,\"if\",[[29,\"eq\",[[25,[\"activeItem\"]],[24,2,[]]],null],\"active\"],null]],[9],[0,\"\\n\"],[4,\"if\",[[26,3]],null,{\"statements\":[[0,\"            \"],[15,3,[[29,\"hash\",null,[[\"type\",\"result\"],[[24,1,[\"value\"]],[24,2,[]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"a\"],[12,\"class\",[29,\"search-group-icon\",[[24,1,[\"value\"]]],null]],[9],[1,[29,\"html-safe\",[[24,2,[\"name\"]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[3,\"action\",[[24,0,[]],\"selectItem\",[24,2,[]]]],[10],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[0,\"\\n    \"],[7,\"li\"],[11,\"class\",\"searchbar__protip\"],[9],[1,[29,\"t\",[\"shared.labels.searchMatchCount\"],[[\"count\"],[[25,[\"searchResults\",\"length\"]]]]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"li\"],[11,\"class\",\"searchbar__notfound\"],[9],[1,[29,\"t\",[\"shared.labels.noRecordsFound\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/search-box/dropdown-menu/results/template.hbs"
    }
  });

  _exports.default = _default;
});