define("ntfrontend/components/accounts/account-details/document-templates/import-template/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vPvIcflL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[11,\"class\",\"btn-success icon-plus-circle\"],[9],[1,[29,\"t\",[\"organization.documentTemplate.actions.import\"],null],false],[3,\"action\",[[24,0,[]],\"openModal\"]],[10],[0,\"\\n\\n\"],[5,\"shared/modal-dialog\",[],[[\"@showModal\",\"@onSuccess\",\"@title\"],[[23,\"showModal\"],[29,\"action\",[[24,0,[]],\"importTemplates\"],null],[29,\"t\",[\"organization.documentTemplate.labels.importDocumentTemplate\"],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-body\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-group select\"],[9],[0,\"\\n      \"],[5,\"accounts/account-details/document-templates/import-template/account-select\",[],[[\"@model\",\"@class\"],[[23,\"account\"],\"form-control\"]]],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"account\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"form-group select\"],[9],[0,\"\\n        \"],[5,\"accounts/account-details/document-templates/import-template/templates-select\",[],[[\"@selectedTemplates\",\"@account\",\"@class\"],[[23,\"templates\"],[23,\"account\"],\"form-control\"]]],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[5,\"shared/form-confirm-button\",[],[[\"@disabled\",\"@label\"],[[23,\"disabled\"],[29,\"t\",[\"shared.actions.import\"],null]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/accounts/account-details/document-templates/import-template/template.hbs"
    }
  });

  _exports.default = _default;
});