define("ntfrontend/components/tasks/breadcrumbs-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eI3Cq/zq",
    "block": "{\"symbols\":[],\"statements\":[[5,\"link-to\",[],[[\"@route\",\"@model\"],[[23,\"projectDocumentsRoute\"],[25,[\"project\",\"slug\"]]]],{\"statements\":[[1,[25,[\"project\",\"name\"]],false]],\"parameters\":[]}],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"icon-arrow-right icon--small\"],[9],[10],[0,\"\\n\"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[[23,\"documentRoute\"],[25,[\"document\",\"slug\"]]]],{\"statements\":[[1,[25,[\"document\",\"name\"]],false]],\"parameters\":[]}],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"icon-arrow-right icon--small\"],[9],[10],[0,\"\\n\"],[5,\"link-to\",[],[[\"@route\",\"@model\",\"@query\"],[[23,\"documentRoute\"],[25,[\"document\",\"slug\"]],[29,\"hash\",null,[[\"anchor\",\"task\"],[[29,\"type-anchor\",[[25,[\"resource\"]]],null],[25,[\"model\",\"slug\"]]]]]]],{\"statements\":[[1,[25,[\"resource\",\"name\"]],false]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/tasks/breadcrumbs-link/template.hbs"
    }
  });

  _exports.default = _default;
});