define("ntfrontend/components/shared/attachments/file-uploader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T8o8r4Vh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"files-section__uploader \",[29,\"if\",[[25,[\"taskUpload\"]],\"center-flex\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"    \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"taskUpload\"]]],null,{\"statements\":[[0,\"      \"],[7,\"i\"],[11,\"class\",\"icon-cloud-upload\"],[9],[0,\"\\n        \"],[1,[29,\"t\",[\"shared.placeholders.clickToUploadFiles\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"btn-add-attachment icon-attachment hidden-print\"],[9],[0,\"\\n        \"],[7,\"span\"],[9],[1,[29,\"t\",[\"shared.placeholders.addFiles\"],null],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"shared/attachments/file-loader\",[],[[\"@afterChange\",\"@multiple\"],[[29,\"action\",[[24,0,[]],\"uploadAttachments\"],null],true]]],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/attachments/file-uploader/template.hbs"
    }
  });

  _exports.default = _default;
});