define("ntfrontend/models/project-setting", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    project: belongsTo(),
    customFieldColumns: attr(),
    enabledMemos: attr('boolean'),
    enabledIssues: attr('boolean'),
    enabledRequests: attr('boolean'),
    enabledScenarios: attr('boolean'),
    memosCount: attr('number'),
    issuesCount: attr('number'),
    requestsCount: attr('number'),
    scenariosCount: attr('number'),
    workPackagesCount: attr('number'),
    milestonesCount: attr('number'),
    workItemsCount: attr('number'),
    hasDocuments: Ember.computed('memosCount', 'issuesCount', 'requestsCount', 'scenariosCount', function () {
      var resources = this.getProperties('memosCount', 'issuesCount', 'requestsCount', 'scenariosCount');
      var resourcesCount = Object.values(resources).reduce(function (acc, resource) {
        return acc + resource;
      });
      return Boolean(resourcesCount);
    }),
    didUpdate: function didUpdate() {
      this._super.apply(this, arguments);

      this.get('project.ability.content').reload();
    }
  });

  _exports.default = _default;
});