define("ntfrontend/abilities/resourcify", ["exports", "ntfrontend/abilities/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    canCreateMembers: Ember.computed.readOnly('model.ability.actions.createMembers'),
    canCreateTasks: Ember.computed.readOnly('model.ability.actions.createTasks'),
    canCreateAttachments: Ember.computed.readOnly('model.ability.actions.createAttachments'),
    canShowDetail: Ember.computed('detail', 'canEdit', function () {
      return !!(this.detail || this.canEdit);
    })
  });

  _exports.default = _default;
});