define("ntfrontend/components/topics/sub-menu-vertical/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'topic__tabs-navigation',
    attachments: Ember.computed.alias('model.attachments'),
    didInsertElement: function didInsertElement() {
      var el = this.element.querySelector('.tabs--vertical > li:first-child > a');

      if (el) {
        el.click();
      }
    },
    tasksCount: Ember.computed('model.tasks.length', 'model.relatedTasks.length', function () {
      return this.get('model.tasks.length') + this.get('model.relatedTasks.length');
    }),
    tasksDone: Ember.computed('model.tasks.@each.completed', 'model.relatedTasks.@each.completed', function () {
      var completedTasks = this.get('model.tasks').filterBy('completed').get('length');
      var completedRelatedTasks = this.get('model.relatedTasks').filterBy('completed').get('length');
      return completedTasks + completedRelatedTasks;
    })
  });

  _exports.default = _default;
});