define("ntfrontend/components/create-page/tabs-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vIUboJZu",
    "block": "{\"symbols\":[\"@projectSlug\",\"@transitionPath\"],\"statements\":[[4,\"if\",[[24,0,[\"isUserOrWorkTab\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"create-item__header-wrapper\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"create-item__content\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"create-item__header-wrapper\"],[9],[0,\"\\n        \"],[5,\"create-page/icons/resource-icon\",[],[[\"@icon\"],[[24,0,[\"bgClassName\"]]]]],[0,\"\\n        \"],[7,\"h1\"],[11,\"class\",\"create-item__header\"],[9],[0,\"\\n          \"],[1,[29,\"t\",[[24,0,[\"resourceName\"]]],null],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"create-item__content-body\"],[9],[0,\"\\n        \"],[1,[29,\"component\",[[24,0,[\"contentComponent\"]]],[[\"projectSlug\",\"resourceName\",\"resourceDescription\",\"transitionPath\"],[[24,1,[]],[24,0,[\"resourceName\"]],[24,0,[\"resourceDescription\"]],[24,2,[]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[29,\"component\",[[24,0,[\"contentComponent\"]]],[[\"projectSlug\",\"resourceName\",\"resourceDescription\"],[[24,1,[]],[24,0,[\"resourceName\"]],[24,0,[\"resourceDescription\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/create-page/tabs-content/template.hbs"
    }
  });

  _exports.default = _default;
});