define("ntfrontend/templates/account/fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1ZilSxAm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"page-header\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"h2\"],[9],[1,[29,\"t\",[\"shared.labels.customFields\"],null],false],[10],[0,\"\\n  \"],[5,\"custom-fields/new\",[],[[\"@account\",\"@class\"],[[23,\"model\"],\"page-header__menu\"]]],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[5,\"custom-fields/list\",[],[[\"@account\"],[[23,\"model\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/account/fields.hbs"
    }
  });

  _exports.default = _default;
});