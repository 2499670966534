define("ntfrontend/components/custom-fields/custom-values/components/richtext/modal/component", ["exports", "ntfrontend/mixins/components/force-modal-close"], function (_exports, _forceModalClose) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_forceModalClose.default, {
    click: function click() {
      if (this.get('model.value.length') > 0 || this.allowEditing) {
        this.set('showModal', true);
      }
    }
  });

  _exports.default = _default;
});