define("ntfrontend/components/projects/dashboard/milestones/milestones-list/status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MIYjVLNH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"tooltip--nowrap\"],[12,\"tooltip_top\",[29,\"t\",[[25,[\"statusTooltip\"]]],null]],[9],[0,\"\\n  \"],[7,\"span\"],[12,\"class\",[23,\"statusStyle\"]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/dashboard/milestones/milestones-list/status/template.hbs"
    }
  });

  _exports.default = _default;
});