define("ntfrontend/components/tasks/all-indicator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YzeDL209",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"icon-clock-three\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/tasks/all-indicator/template.hbs"
    }
  });

  _exports.default = _default;
});