define("ntfrontend/routes/tasks/index", ["exports", "ntfrontend/utils/ajaxer", "ntfrontend/routes/org-auth", "ntfrontend/helpers/document-id"], function (_exports, _ajaxer, _orgAuth, _documentId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend({
    model: function model(params) {
      var userId = this.get('currentSession.user.id');
      var parentResource = params.documentId || (0, _documentId.documentId)([this.get('currentSession.account')]);
      var filter = {
        parentResource: parentResource,
        q: params.q,
        projectId: params.projectId,
        ownerId: this.mapParam(params.ownerId, 'me', userId),
        requesterId: params.requesterId,
        reportedBy: this.mapParam(params.reportedBy, 'me', userId),
        completed: this.mapParam(params.completed, 'all', null),
        due: params.due
      };
      var data = {
        include: 'owner,requester,ability',
        filter: _ajaxer.default.reduceObject(filter),
        page: {
          size: params.perPage,
          number: params.page
        },
        sort: params.sort
      };
      return this.store.query('task', data);
    }
  });

  _exports.default = _default;
});