define("ntfrontend/components/members/add-member/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M3VcGv/N",
    "block": "{\"symbols\":[],\"statements\":[[5,\"members/add-member/invite-input\",[],[[\"@type\",\"@scope\",\"@onSelectionChanged\",\"@createNew\",\"@includeCollaborators\",\"@includeOrigin\"],[\"email\",[23,\"model\"],[29,\"action\",[[24,0,[]],\"inviteUser\"],null],[23,\"createNew\"],[23,\"includeCollaborators\"],[23,\"includeOrigin\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/members/add-member/template.hbs"
    }
  });

  _exports.default = _default;
});