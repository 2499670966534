define("ntfrontend/components/shared/confirm-remove-link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sweetAlert: Ember.inject.service('sweet-alert'),
    tagName: 'a',
    classNames: ['remove-link'],
    click: function click() {
      var _this = this;

      var titleKey = 'notify.default.confirm.title';
      var messageKey = 'notify.default.confirm.message';
      this.sweetAlert.confirm(messageKey, titleKey, function () {
        _this.afterConfirm();
      });
    }
  });

  _exports.default = _default;
});