define("ntfrontend/components/projects/project-settings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    collapsed: true,
    buttonLabel: Ember.computed('collapsed', function () {
      if (this.collapsed) {
        return 'shared.labels.showSettings';
      } else {
        return 'shared.labels.hideSettings';
      }
    }),
    settings: Ember.computed('model', function () {
      return this.get('model.projectSetting.content') || this.get('model.projectSetting');
    }),
    actions: {
      collapse: function collapse() {
        this.toggleProperty('collapsed');
      }
    }
  });

  _exports.default = _default;
});