define("ntfrontend/routes/requests/request", ["exports", "ntfrontend/routes/org-auth", "ntfrontend/mixins/routes/show-task-pane", "ntfrontend/mixins/routes/document-detailed-breadcrumb"], function (_exports, _orgAuth, _showTaskPane, _documentDetailedBreadcrumb) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend(_documentDetailedBreadcrumb.default, _showTaskPane.default, {
    currentSession: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      var includes = ['owner', 'creator', 'ability', 'requester', 'work-package', 'project.ability', 'project.project-setting', 'topics.tasks.ability', 'topics.transport-documents.transport.creator'];
      var filter = {
        slug: "".concat(params.request_slug, ",").concat(this.get('currentSession.account.id'))
      };
      var data = {
        include: includes.join(','),
        filter: filter
      };
      return this.store.query('request', data).then(function (request) {
        return _this.objectOrRedirect(request.get('firstObject'), 'requests');
      });
    }
  });

  _exports.default = _default;
});