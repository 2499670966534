define("ntfrontend/components/shared/inplace-select/component", ["exports", "ntfrontend/mixins/components/inplace/editable", "ntfrontend/mixins/components/inplace/focusable"], function (_exports, _editable, _focusable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_editable.default, _focusable.default, {
    store: Ember.inject.service('store'),
    tagName: 'div',
    placeholder: 'shared.labels.all',
    text: Ember.computed('value', 'type', function () {
      return this.store.find(this.type, this.value);
    }),
    focusOut: function focusOut() {},
    actions: {
      escapeSelect: function escapeSelect() {
        this.set('isEditing', false);
      }
    }
  });

  _exports.default = _default;
});