define("ntfrontend/components/custom-fields/custom-values/components/list/single-value/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      updateValue: function updateValue(option) {
        this.set('customValue.value', option);
        this.afterChange();
      }
    }
  });

  _exports.default = _default;
});