define("ntfrontend/components/dashboard/dashboard-container/intro-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aZmUQgkX",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[5,\"shared/modal-dialog\",[],[[\"@showModal\",\"@title\"],[[23,\"showModal\"],[29,\"t\",[\"intro.dashboard.modalTitle\"],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-body\"],[9],[0,\"\\n    \"],[1,[29,\"t\",[\"intro.dashboard.welcomeUser\"],[[\"userName\"],[[25,[\"user\",\"name\"]]]]],false],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"text-right\"],[11,\"style\",\"margin-top: 2rem\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn-success\"],[11,\"style\",\"margin-right: 1rem\"],[11,\"type\",\"button\"],[9],[0,\"\\n        \"],[1,[29,\"t\",[\"shared.actions.showIntro\"],null],false],[0,\"\\n      \"],[3,\"action\",[[24,0,[]],[25,[\"showIntro\"]]]],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn btn-default\"],[11,\"style\",\"margin-right: 2rem\"],[11,\"type\",\"button\"],[9],[0,\"\\n        \"],[1,[29,\"t\",[\"shared.actions.close\"],null],false],[0,\"\\n      \"],[3,\"action\",[[24,0,[]],[25,[\"closeModal\"]]]],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/dashboard/dashboard-container/intro-modal/template.hbs"
    }
  });

  _exports.default = _default;
});