define("ntfrontend/components/projects/project-filters/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: 'page-header__menu',
    selectedRequester: Ember.computed('requesterFromSelect', 'requesterId', function () {
      if (!this.requesterFromSelect && this.requesterId) {
        return this.store.find('user', this.requesterId);
      } else {
        return this.requesterFromSelect;
      }
    }),
    selectedOwner: Ember.computed('ownerFromSelect', 'ownerId', function () {
      if (!this.ownerFromSelect && this.ownerId) {
        return this.store.find('user', this.ownerId);
      } else {
        return this.ownerFromSelect;
      }
    }),
    actions: {
      filterRequester: function filterRequester(value) {
        if (value) {
          this.set('requesterId', value.get('id'));
        } else {
          this.set('requesterId', value);
        }

        this.set('requesterFromSelect', value);
      },
      filterOwner: function filterOwner(value) {
        if (value) {
          this.set('ownerId', value.get('id'));
        } else {
          this.set('ownerId', value);
        }

        this.set('ownerFromSelect', value);
      }
    }
  });

  _exports.default = _default;
});