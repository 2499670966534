define("ntfrontend/components/projects/project-card/-owner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/Lepl6u3",
    "block": "{\"symbols\":[],\"statements\":[[5,\"users/profile-box/picture\",[],[[\"@user\",\"@width\",\"@height\"],[[23,\"owner\"],\"30\",\"30\"]]],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"tile__overview-owner-name\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"text-muted\"],[9],[0,\"\\n    \"],[1,[29,\"t\",[\"shared.labels.owner\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[25,[\"owner\",\"name\"]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/project-card/-owner/template.hbs"
    }
  });

  _exports.default = _default;
});