define("ntfrontend/components/shared/filters/time-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mINwiao/",
    "block": "{\"symbols\":[\"value\",\"key\"],\"statements\":[[7,\"label\"],[11,\"class\",\"text-muted filter-name\"],[9],[0,\"\\n  \"],[1,[29,\"t\",[\"shared.labels.time\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"a\"],[11,\"class\",\"dropdown-toggle\"],[11,\"data-toggle\",\"dropdown\"],[11,\"aria-haspopup\",\"true\"],[11,\"aria-expanded\",\"true\"],[11,\"type\",\"button\"],[9],[0,\"\\n  \"],[1,[23,\"name\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"openDropdown\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\"],[11,\"class\",\"dropdown-menu\"],[9],[0,\"\\n\"],[4,\"each\",[[29,\"-each-in\",[[25,[\"staticStates\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[7,\"a\"],[9],[1,[29,\"t-scope\",[[24,1,[]],[25,[\"translationScope\"]]],null],false],[3,\"action\",[[24,0,[]],\"changeFilter\",[24,2,[]]]],[10],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/filters/time-filter/template.hbs"
    }
  });

  _exports.default = _default;
});