define("ntfrontend/components/shared/list-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vkeXZ5KM",
    "block": "{\"symbols\":[\"escapeSelect\"],\"statements\":[[7,\"label\"],[11,\"class\",\"text-muted filter-name\"],[9],[0,\"\\n  \"],[1,[29,\"t\",[[25,[\"translationKey\"]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[5,\"shared/inplace-select\",[],[[\"@value\",\"@type\"],[[23,\"modelId\"],[23,\"type\"]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[29,\"eq\",[[25,[\"type\"]],\"user\"],null]],null,{\"statements\":[[0,\"    \"],[5,\"users/select-user\",[],[[\"@allowClear\",\"@scope\",\"@value\",\"@onSelectionChanged\",\"@escapeSelect\"],[true,[23,\"scope\"],[23,\"selected\"],[29,\"action\",[[24,0,[]],\"filterModel\"],null],[24,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"shared/select-resource\",[],[[\"@allowClear\",\"@scope\",\"@type\",\"@value\",\"@onSelectionChanged\",\"@escapeSelect\"],[true,[23,\"scope\"],[23,\"type\"],[23,\"selected\"],[29,\"action\",[[24,0,[]],\"filterModel\"],null],[24,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/list-filter/template.hbs"
    }
  });

  _exports.default = _default;
});