define("ntfrontend/components/projects/add-new-project/loading-state/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lpm/TFW/",
    "block": "{\"symbols\":[],\"statements\":[[5,\"shared/loading-indicator\",[],[[\"@customClass\"],[\"custom-size-loading\"]]],[0,\"\\n\"],[7,\"h1\"],[11,\"class\",\"importing-information\"],[9],[0,\"\\n  \"],[1,[29,\"t\",[\"project.labels.importInformation\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"h1\"],[11,\"class\",\"importing-information\"],[9],[0,\"\\n  \"],[1,[29,\"t\",[\"project.labels.notificationInformation\"],null],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/add-new-project/loading-state/template.hbs"
    }
  });

  _exports.default = _default;
});