define("ntfrontend/components/shared/documents/quantity-checker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WlNuB6Wo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[29,\"gt\",[[25,[\"total\"]],0],null]],null,{\"statements\":[[0,\"  \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"usePlaceholder\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"empty-state\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"src\",\"/assets/images/nodocuments.svg\"],[11,\"class\",\"empty-state__icon\"],[12,\"alt\",[29,\"t\",[\"document.alts.noDocumentIcon\"],null]],[9],[10],[0,\"\\n      \"],[7,\"h4\"],[11,\"class\",\"empty-state__header\"],[9],[0,\"\\n        \"],[1,[29,\"t\",[\"document.placeholders.noDocumentsHeader\"],[[\"modelName\"],[[25,[\"modelName\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"empty-state__label\"],[9],[0,\"\\n        \"],[1,[29,\"t\",[\"document.placeholders.noDocumentText\"],[[\"modelName\"],[[25,[\"modelName\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/documents/quantity-checker/template.hbs"
    }
  });

  _exports.default = _default;
});