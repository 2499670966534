define("ntfrontend/initializers/current-session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(App) {
    App.inject('component', 'currentSession', 'service:current-session');
    App.inject('route', 'currentSession', 'service:current-session');
    App.inject('controller', 'currentSession', 'service:current-session');
  }

  var _default = {
    name: 'current-session',
    initialize: initialize
  };
  _exports.default = _default;
});