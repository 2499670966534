define("ntfrontend/components/memos/followup-memo/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sG9XwIoP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[11,\"class\",\"text-center page-header\"],[9],[1,[29,\"t\",[\"document.actions.createFollowup\"],null],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4\"],[9],[0,\"\\n  \"],[7,\"form\"],[11,\"class\",\"project-form\"],[9],[0,\"\\n    \"],[5,\"shared/form-input\",[],[[\"@errors\"],[[25,[\"newObject\",\"errors\",\"name\"]]]],{\"statements\":[[0,\"\\n      \"],[5,\"input\",[],[[\"@type\",\"@value\",\"@id\",\"@class\",\"@placeholder\",\"@required\",\"@autofocus\"],[\"text\",[25,[\"tempObject\",\"name\"]],\"input-name\",\"form-control\",[29,\"t\",[\"shared.placeholders.name\"],null],\"true\",\"true\"]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"shared/form-confirm-button\",[],[[\"@disabled\",\"@label\"],[[23,\"disabled\"],[29,\"t\",[\"shared.actions.create\"],null]]]],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"create\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/memos/followup-memo/template.hbs"
    }
  });

  _exports.default = _default;
});