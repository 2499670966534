define("ntfrontend/components/documents/details/transport/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NjF70Fy4",
    "block": "{\"symbols\":[\"transportDocument\"],\"statements\":[[4,\"if\",[[24,0,[\"transportDocuments\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[5,\"shared/labeled-row\",[],[[\"@label\",\"@wrapperLabelClass\",\"@labelClass\"],[\"shared.labels.transport\",\"transports-list\",\"transports-list__label\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,0,[\"transportDocuments\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"transports-list__document\"],[9],[0,\"\\n        \"],[7,\"div\"],[9],[0,\"\\n          \"],[5,\"transports/sneak-peek-button\",[],[[\"@model\"],[[24,1,[\"transport\"]]]],{\"statements\":[[0,\"\\n            \"],[1,[24,1,[\"transport\",\"description\"]],false],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/documents/details/transport/template.hbs"
    }
  });

  _exports.default = _default;
});