define("ntfrontend/components/transports/transport-modal/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    tagName: '',
    selectedTransports: [],
    disabledButton: Ember.computed.not('system'),
    title: Ember.computed('assignView', function () {
      var assignView = this.assignView,
          intl = this.intl;
      return intl.t(assignView ? 'shared.labels.assignTransport' : 'shared.labels.createATransport');
    }),
    successCallback: function successCallback() {
      var notify = this.notify,
          assignView = this.assignView,
          refreshModel = this.refreshModel;

      this._clearFields();

      if (refreshModel) {
        this.refreshModel();
      }

      return notify.success(assignView ? 'notify.transport.assigned' : 'notify.transport.created');
    },
    actions: {
      submit: function submit() {
        return this.assignView ? this._assignTransport() : this._createTransport();
      },
      openModal: function openModal() {
        this.set('showModal', true);

        this._setProposeDescription();
      },
      onClose: function onClose() {
        this._clearFields();
      },
      addTransport: function addTransport(item) {
        this.selectedTransports.addObject(item);
      },
      removeTransport: function removeTransport(item) {
        this.selectedTransports.removeObject(item);
      },
      switchToCreate: function switchToCreate() {
        this.set('assignView', false);
      },
      setSystem: function setSystem(system) {
        this.set('system', system);
      }
    },
    _createTransport: function _createTransport() {
      var _this = this;

      var textId = this.textId,
          description = this.description,
          comment = this.comment,
          system = this.system,
          _this$currentSession = this.currentSession,
          account = _this$currentSession.account,
          user = _this$currentSession.user;
      var data = {
        creator: user,
        account: account,
        textId: textId,
        description: description,
        comment: comment,
        system: system
      };
      this.set('model', this.store.createRecord('transport', data));

      if (this.documentItem) {
        return this.model.save().then(function () {
          return _this._assignDocumentItemToNewTransport();
        });
      }

      return this.saveModelTask.perform();
    },
    _assignTransport: function _assignTransport() {
      var _this2 = this;

      var selectedTransports = this.selectedTransports;
      var saveTransportDocuments = selectedTransports.map(function (transport) {
        _this2._createTransportDocument(transport).save();
      });
      return Ember.RSVP.all(saveTransportDocuments).then(function () {
        return _this2.successCallback();
      });
    },
    _assignDocumentItemToNewTransport: function _assignDocumentItemToNewTransport() {
      var _this3 = this;

      var transportDocument = this._createTransportDocument(this.model);

      return transportDocument.save().then(function () {
        return _this3.successCallback();
      });
    },
    _createTransportDocument: function _createTransportDocument(transport) {
      var documentItem = this.documentItem;
      return this.store.createRecord('transport-document', {
        documentItem: documentItem,
        transport: transport,
        document: documentItem.get('document')
      });
    },
    _clearFields: function _clearFields() {
      var _this4 = this;

      var fields = this.getProperties('textId', 'description', 'comment', 'resetTextId', 'resetDescription', 'resetComment');
      this.selectedTransports.clear();
      Object.keys(fields).forEach(function (property) {
        return _this4.set(property, null);
      });

      if (this.assignView != null) {
        this.set('assignView', true);
      }
    },
    _setProposeDescription: function _setProposeDescription() {
      if (this.documentItem) {
        var document = this.documentItem.get('document');
        var description = "".concat(document.get('symbolName'), ": ").concat(document.get('name'));
        this.set('description', description);
      }
    }
  });

  _exports.default = _default;
});