define("ntfrontend/components/members/select-role/role/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DZI18q9l",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\"],[12,\"src\",[23,\"imagePath\"]],[11,\"class\",\"role__image\"],[12,\"alt\",[25,[\"model\",\"name\"]]],[9],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"role__content\"],[9],[0,\"\\n  \"],[7,\"h3\"],[11,\"class\",\"page-header\"],[9],[0,\"\\n    \"],[1,[29,\"t\",[[29,\"concat\",[\"member.roles.\",[25,[\"model\",\"name\"]]],null]],null],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[1,[29,\"t\",[[29,\"concat\",[\"member.roles.description.\",[25,[\"model\",\"name\"]]],null]],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/members/select-role/role/template.hbs"
    }
  });

  _exports.default = _default;
});