define("ntfrontend/components/projects/project-dropdown/archive-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dwZYRuOT",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"model\",\"archived\"]]],null,{\"statements\":[[4,\"if\",[[29,\"can\",[\"unarchive project\",[25,[\"model\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"class\",\"icon-archive\"],[9],[1,[29,\"t\",[\"document.actions.unarchive\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"can\",[\"archive project\",[25,[\"model\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"class\",\"icon-archive\"],[9],[1,[29,\"t\",[\"document.actions.archive\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/project-dropdown/archive-link/template.hbs"
    }
  });

  _exports.default = _default;
});