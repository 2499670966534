define("ntfrontend/components/requests/request-details/dropdown/component", ["exports", "ntfrontend/mixins/components/model-saving", "ntfrontend/mixins/components/open-dropdown"], function (_exports, _modelSaving, _openDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, _openDropdown.default, {
    sweetAlert: Ember.inject.service('sweet-alert'),
    router: Ember.inject.service(),
    classNames: ['dropdown'],
    dropdownModel: Ember.computed.alias('model.ability'),
    actions: {
      remove: function remove() {
        var _this = this;

        var project = this.get('model.project');
        this.model.destroyRecord().then(function () {
          var urlToRedirect = _this.router.urlFor('projects.project.requests', project.get('slug'));

          _this.router.transitionTo(urlToRedirect);

          _this.notify.success('notify.request.removed');
        });
      },
      archive: function archive() {
        var _this2 = this;

        this.sweetAlert.confirm(null, null, function () {
          _this2.set('value', !_this2.value);

          _this2.send('saveModel');
        });
      }
    }
  });

  _exports.default = _default;
});