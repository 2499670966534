define("ntfrontend/models/project-list", ["exports", "ember-data", "ember-custom-actions", "ntfrontend/models/project"], function (_exports, _emberData, _emberCustomActions, _project) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    name: attr('string'),
    slug: attr('number'),
    progress: attr('number'),
    archived: attr('boolean'),
    description: attr('string'),
    statusDescription: attr('string'),
    memosCount: attr('number'),
    issuesCount: attr('number'),
    requestsCount: attr('number'),
    scenariosCount: attr('number'),
    openTasksCount: attr('number'),
    canCreateDocuments: attr('boolean'),
    overdueTasks: attr('number'),
    myOverdueTasks: attr('number'),
    owner: belongsTo('user'),
    projectType: belongsTo(),
    ability: belongsTo(),
    archive: (0, _emberCustomActions.modelAction)('archive', {
      method: 'POST'
    }),
    unarchive: (0, _emberCustomActions.modelAction)('unarchive', {
      method: 'POST'
    }),
    status: attr('number', {
      defaultValue: 4
    }),
    statuses: _project.statuses,
    statusKey: Ember.computed('statuses', function () {
      return this.statuses[this.status];
    })
  });

  _exports.default = _default;
});