define("ntfrontend/components/projects/project-card/component", ["exports", "ntfrontend/mixins/components/lists/mouse-over-async-abilities"], function (_exports, _mouseOverAsyncAbilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mouseOverAsyncAbilities.default, {
    router: Ember.inject.service(),
    classNames: ['tile__container', '-xl-columns-five', 'col-lg-3', 'col-md-4', 'col-sm-6'],
    customModelName: 'Project',
    model: Ember.computed.alias('project'),
    projectDetails: Ember.computed.alias('project.projectSetting'),
    disabled: Ember.computed.not('project.canCreateDocuments'),
    description: Ember.computed('project.description', function () {
      var div = document.createElement('div');
      div.innerHTML = "<div>".concat(this.get('project.description'), "</div>");
      return div.textContent.trim();
    }),
    actions: {
      redirectToCreateMemoPage: function redirectToCreateMemoPage() {
        this.router.transitionTo('create', {
          queryParams: {
            projectSlug: this.project.slug,
            tab: 'memo'
          }
        });
      }
    }
  });

  _exports.default = _default;
});