define("ntfrontend/components/documents/documents-list/component", ["exports", "ember-inflector", "ntfrontend/helpers/model-type-name"], function (_exports, _emberInflector, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'table',
    classNames: 'table',
    modelName: Ember.computed('objects.firstObject', function () {
      return (0, _emberInflector.pluralize)((0, _modelTypeName.modelTypeName)([this.get('objects.firstObject')]).toLowerCase());
    }),
    listComponent: Ember.computed('modelName', function () {
      return "".concat(this.modelName, "/list-item");
    })
  });

  _exports.default = _default;
});