define("ntfrontend/components/documents/templates/import-button/dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ic/wmvEl",
    "block": "{\"symbols\":[\"template\"],\"statements\":[[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"dropdown\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"aria-expanded\",\"true\"],[11,\"aria-haspopup\",\"true\"],[11,\"class\",\"static-value dropdown-toggle form-control select label__color\"],[11,\"data-toggle\",\"dropdown\"],[11,\"type\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"choosenTemplate\",\"name\"]]],null,{\"statements\":[[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"placeholder material-label__show\"],[9],[1,[29,\"t\",[\"document.placeholders.noTemplate\"],null],false],[10],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"create-page__input-data\"],[9],[0,\" \"],[1,[25,[\"choosenTemplate\",\"name\"]],false],[0,\" \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"span\"],[12,\"class\",[30,[\"placeholder label__font \",[29,\"if\",[[25,[\"showLabel\"]],\"material-label__show\"],null]]]],[9],[1,[29,\"t\",[\"document.placeholders.noTemplate\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"showDropdown\"]]],null,{\"statements\":[[0,\"      \"],[5,\"shared/loading-state/block\",[],[[\"@class\",\"@tagName\",\"@model\"],[\"dropdown-menu label__color\",\"ul\",[23,\"documentTemplates\"]]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[25,[\"documentTemplates\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\"],[9],[7,\"a\"],[9],[0,\" \"],[1,[24,1,[\"name\"]],false],[0,\" \"],[3,\"action\",[[24,0,[]],\"changeTemplate\",[24,1,[]]]],[10],[10],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"          \"],[7,\"span\"],[11,\"class\",\"placeholder\"],[9],[1,[23,\"noTemplatesDefinedFor\"],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/documents/templates/import-button/dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});