define("ntfrontend/components/projects/project-settings/type-set/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZANWaTXQ",
    "block": "{\"symbols\":[\"type\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row flex--center\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-4\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"text-muted\"],[9],[1,[29,\"t\",[\"project.settings.projectTemplate\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-8\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"inplace-editing\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"dropdown\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"static-value dropdown-toggle\"],[11,\"data-toggle\",\"dropdown\"],[11,\"aria-haspopup\",\"true\"],[11,\"aria-expanded\",\"true\"],[11,\"type\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"model\",\"projectType\",\"name\"]]],null,{\"statements\":[[0,\"            \"],[1,[25,[\"model\",\"projectType\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"span\"],[11,\"class\",\"placeholder\"],[9],[1,[29,\"t\",[\"organization.projectTemplate.placeholders.chooseTemplateSet\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"shownDropdown\"]]],null,{\"statements\":[[0,\"          \"],[5,\"shared/loading-state/block\",[],[[\"@model\",\"@tagName\",\"@class\"],[[23,\"projectTypes\"],\"ul\",\"dropdown-menu\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[25,[\"projectTypes\"]]],null,{\"statements\":[[0,\"              \"],[7,\"li\"],[9],[7,\"a\"],[9],[1,[24,1,[\"name\"]],false],[3,\"action\",[[24,0,[]],\"changeType\",[24,1,[]]]],[10],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"          \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/project-settings/type-set/template.hbs"
    }
  });

  _exports.default = _default;
});