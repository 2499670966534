define("ntfrontend/components/projects/dashboard/task-assignments/component", ["exports", "ntfrontend/mixins/components/refresh-relation"], function (_exports, _refreshRelation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_refreshRelation.default, {
    store: Ember.inject.service(),
    relationModel: Ember.computed.alias('model.project.taskAssignment'),
    maxTasksCount: Ember.computed.max('taskCounters'),
    taskCounters: Ember.computed.mapBy('relationModel.users', 'tasksCount'),
    sortSetting: ['overdueTasksCount:desc'],
    sortedUsers: Ember.computed.sort('relationModel.users', 'sortSetting')
  });

  _exports.default = _default;
});