define("ntfrontend/components/shared/filters/system-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['inplace-editing', 'system-filter'],
    triggerClass: 'static-value',
    selectedSystems: Ember.computed('systems', function () {
      if (this.systems) {
        return this.systems.split(',');
      }
    }),
    actions: {
      onChange: function onChange(systems) {
        this.set('systems', systems.length === 0 ? null : systems);
      },
      onCreate: function onCreate(system) {
        var selectedSystems = this.selectedSystems,
            systems = this.systems;
        this.set('systems', selectedSystems ? "".concat(systems, ",").concat(system) : system);
      }
    }
  });

  _exports.default = _default;
});