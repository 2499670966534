define("ntfrontend/components/shared/input-datefield/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K9IOrCdi",
    "block": "{\"symbols\":[],\"statements\":[[5,\"input\",[],[[\"@value\",\"@class\"],[[23,\"date\"],\"form-control\"]]],[0,\"\\n\\n\"],[7,\"span\"],[11,\"class\",\"input-group-addon hidden\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/input-datefield/template.hbs"
    }
  });

  _exports.default = _default;
});