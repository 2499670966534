define("ntfrontend/components/work-logs/summary/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'th',
    hoursWorked: Ember.computed('worklogs.@each.hoursWorked', function () {
      if (this.worklogs) {
        return this.worklogs.reduce(function (total, workLog) {
          return +total + +workLog.hoursWorked;
        }, 0);
      } else {
        return 0;
      }
    })
  });

  _exports.default = _default;
});