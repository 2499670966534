define("ntfrontend/models/step", ["exports", "ember-data", "ntfrontend/models/document-item", "ntfrontend/mixins/models/taskable"], function (_exports, _emberData, _documentItem, _taskable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = _documentItem.default.extend(_taskable.default, {
    outcome: attr(),
    status: attr('number', {
      defaultValue: 0
    }),
    document: belongsTo('models/stepable', {
      polymorphic: true
    }),
    owner: belongsTo('user')
  });

  _exports.default = _default;
});