define("ntfrontend/components/create-page/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var userCreatingRoles = ['owner', 'admin'];

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['create-page'],
    tabs: [],
    roleInOrganization: Ember.computed.reads('currentSession.user.roleInOrganization'),
    currentUserObserver: Ember.observer('currentSession.user', function () {
      this._modifyTabsForRole(this.roleInOrganization);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.tabs = ['project', 'memo', 'request', 'issue', 'scenario', 'work'];
      this.currentUserObserver();
    },
    willDestroyElement: function willDestroyElement() {
      this._resetQueryParams();

      this._super.apply(this, arguments);
    },
    actions: {
      tabChange: function tabChange(selectedResource) {
        this.set('activeTab', selectedResource);
      }
    },
    _modifyTabsForRole: function _modifyTabsForRole(role) {
      if (userCreatingRoles.includes(role)) {
        this.tabs.pushObject('user');
      }
    },
    _resetQueryParams: function _resetQueryParams() {
      this.set('projectSlug', null);
      this.set('activeTab', null);
    }
  });

  _exports.default = _default;
});