define("ntfrontend/initializers/intl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(App) {
    App.inject('route', 'intl', 'service:intl');
    App.inject('component', 'intl', 'service:intl');
    App.inject('controller', 'intl', 'service:intl');
  }

  var _default = {
    name: 'intl',
    initialize: initialize
  };
  _exports.default = _default;
});