define("ntfrontend/components/documents/participation-members/membership/component", ["exports", "ntfrontend/helpers/model-type-name", "ntfrontend/mixins/components/model-saving", "ntfrontend/mixins/components/automatic-changes-notifier"], function (_exports, _modelTypeName, _modelSaving, _automaticChangesNotifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, _automaticChangesNotifier.default, {
    can: Ember.inject.service(),
    includeCollaborators: false,
    sendEmailNotification: false,
    editable: Ember.computed('model.ability', function () {
      var modelName = (0, _modelTypeName.modelTypeName)([this.model]);
      return this.can.can("edit ".concat(modelName), this.model);
    }),
    member: Ember.computed('user.id', 'model.members', function () {
      var _this = this;

      return this.get('model.members').filter(function (member) {
        return member.get('user.id') === _this.get('user.id');
      }).get('firstObject');
    }),
    actions: {
      saveMembership: function saveMembership() {
        var _this2 = this;

        this.saveModelTask.perform().then(function () {
          _this2.model.hasMany('members').reload();
        });
      }
    }
  });

  _exports.default = _default;
});