define("ntfrontend/components/steps/step-item/task-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tj1MShew",
    "block": "{\"symbols\":[\"task\"],\"statements\":[[4,\"each\",[[25,[\"sortedTasks\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[\"step__task\",[29,\"if\",[[24,1,[\"completed\"]],\" step__task--done\"],null]]]],[9],[0,\"\\n    \"],[5,\"tasks/task-switcher-small\",[],[[\"@model\",\"@editable\"],[[24,1,[]],[29,\"can\",[\"edit task\",[24,1,[]]],null]]]],[0,\"\\n    \"],[5,\"users/profile-box/picture\",[],[[\"@user\"],[[24,1,[\"owner\"]]]]],[0,\"\\n\"],[4,\"if\",[[24,1,[\"deadlineAt\"]]],null,{\"statements\":[[0,\"      \"],[5,\"tasks/inline-deadline-date\",[],[[\"@model\"],[[24,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[5,\"tasks/sneak-peek-button\",[],[[\"@classNames\",\"@model\"],[\"step__task-name\",[24,1,[]]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/steps/step-item/task-list/template.hbs"
    }
  });

  _exports.default = _default;
});