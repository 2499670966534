define("ntfrontend/components/accounts/accounts-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    accounts: Ember.computed(function () {
      return this.store.query('account-list', {});
    })
  });

  _exports.default = _default;
});