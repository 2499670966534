define("ntfrontend/components/scenarios/list-item/progress/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'scenario-progress',
    default: 0,
    openSteps: Ember.computed.or('model.openStepsCount', 'default'),
    doneSteps: Ember.computed.or('model.doneStepsCount', 'default'),
    failedSteps: Ember.computed.or('model.failedStepsCount', 'default')
  });

  _exports.default = _default;
});