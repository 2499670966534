define("ntfrontend/components/custom-fields/custom-values/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gwSP5cnK",
    "block": "{\"symbols\":[\"customValue\"],\"statements\":[[4,\"each\",[[25,[\"customValues\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"component\",[[29,\"concat\",[\"custom-fields/custom-values/components/\",[24,1,[\"field_type\"]]],null]],[[\"afterChange\",\"editable\",\"customValue\"],[[25,[\"afterChange\"]],[25,[\"editable\"]],[29,\"changeset\",[[24,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/custom-fields/custom-values/template.hbs"
    }
  });

  _exports.default = _default;
});