define("ntfrontend/components/dashboard/dashboard-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ure0WpHh",
    "block": "{\"symbols\":[],\"statements\":[[5,\"shared/intro-js/invisible-intro\",[],[[\"@introScope\",\"@shouldStart\",\"@sidebarStepsIds\",\"@introIsRunning\"],[\"dashboard\",[23,\"showIntro\"],[23,\"sidebarStepsIds\"],[23,\"introIsRunning\"]]]],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"dashboard__sections\"],[9],[0,\"\\n  \"],[5,\"dashboard/projects-preview\",[],[[\"@account\",\"@user\",\"@accountDashboard\"],[[23,\"account\"],[23,\"user\"],[23,\"accountDashboard\"]]]],[0,\"\\n\\n  \"],[5,\"dashboard/user-profile\",[],[[\"@user\"],[[23,\"user\"]]]],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"dashboard__sections\"],[9],[0,\"\\n  \"],[5,\"dashboard/tasks-preview\",[],[[\"@account\",\"@user\",\"@accountDashboard\"],[[23,\"account\"],[23,\"user\"],[23,\"accountDashboard\"]]]],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"userHasPermission\"]]],null,{\"statements\":[[0,\"    \"],[5,\"dashboard/members-count\",[],[[\"@account\"],[[23,\"accountDashboard\"]]]],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[5,\"dashboard/dashboard-container/intro-modal\",[],[[\"@showModal\",\"@showIntro\",\"@closeModal\"],[[23,\"showModal\"],[29,\"action\",[[24,0,[]],\"showIntro\"],null],[29,\"action\",[[24,0,[]],\"closeModal\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/dashboard/dashboard-container/template.hbs"
    }
  });

  _exports.default = _default;
});