define("ntfrontend/components/create-page/tabs-content/template-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    heading: 'heading',
    description: 'description',
    withThumbnail: false,
    withFooter: false,
    previewParams: null,
    thumbnailUrl: Ember.computed('thumbnail', 'objectType', function () {
      if (this.thumbnail) {
        return this.thumbnail;
      }

      if (this.objectType) {
        return "/assets/images/template-".concat(this.objectType, ".svg");
      }

      return false;
    }),
    translatedStatus: Ember.computed('statusKey', function () {
      return this.intl.t("project.statuses.".concat(this.statusKey));
    }),
    safeDescription: Ember.computed('description', function () {
      var description = this.description;

      if (!description) {
        return '';
      }

      var div = document.createElement('div');
      div.innerHTML = "<div>".concat(description, "</div>");
      return div.textContent.trim();
    })
  });

  _exports.default = _default;
});