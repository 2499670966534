define("ntfrontend/components/shared/capterra-tracker/component", ["exports", "jquery", "ntfrontend/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    protocol: window.location.protocol,
    vid: _environment.default.capterra.vid,
    vkey: _environment.default.capterra.vkey,
    scriptUrl: Ember.computed('protocol', 'vid', 'vkey', function () {
      var protocol = this.protocol,
          vid = this.vid,
          vkey = this.vkey;
      return "".concat(protocol, "//ct.capterra.com/capterra_tracker.js?vid=").concat(vid, "&vkey=").concat(vkey);
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        _jquery.default.getScript(_this.scriptUrl);
      });
    }
  });

  _exports.default = _default;
});