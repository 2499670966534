define("ntfrontend/metrics-adapters/google-analytics-application", ["exports", "ember-metrics/metrics-adapters/google-analytics"], function (_exports, _googleAnalytics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _googleAnalytics.default.extend();

  _exports.default = _default;
});