define("ntfrontend/components/members/select-role/role/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'role',
    classNameBindings: ['isSelected:selected', 'isDisabled:disabled'],
    maxManagersCount: Ember.computed.alias('account.activeSubscription.maxManagersCount'),
    isSelected: Ember.computed('model.name', 'currentRole.name', function () {
      return this.get('model.name') === this.get('currentRole.name');
    }),
    imagePath: Ember.computed('model.name', function () {
      var modelName = this.get('model.name');
      var roleName = modelName === 'account_member' ? 'manager' : modelName;
      return "/assets/images/".concat(roleName, ".svg");
    }),
    isDisabled: Ember.computed('account.managersCount', 'maxManagersCount', 'model.name', function () {
      var maxManagersCount = this.maxManagersCount;
      return maxManagersCount ? this._isDisabledRole(maxManagersCount) : null;
    }),
    _isOverPlanLimit: function _isOverPlanLimit(maxManagersCount) {
      return this.get('account.managersCount') >= maxManagersCount;
    },
    _isDisabledRole: function _isDisabledRole(maxManagersCount) {
      return this._isOverPlanLimit(maxManagersCount) && this.get('model.name') !== 'collaborator';
    },
    click: function click() {
      var role = this.model;
      this.setRole(role);
    }
  });

  _exports.default = _default;
});