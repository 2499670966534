define("ntfrontend/components/shared/actions/remove-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hFQeJwUm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"visible\"]]],null,{\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"    \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"onlyIcon\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"class\",\"icon-remove\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"class\",\"icon-remove\"],[9],[1,[29,\"t\",[\"shared.actions.remove\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/actions/remove-button/template.hbs"
    }
  });

  _exports.default = _default;
});