define("ntfrontend/components/shared/document-task-redirect/component", ["exports", "ember-inflector", "ntfrontend/helpers/model-type-name"], function (_exports, _emberInflector, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    documentRoute: Ember.computed('model.document', function () {
      var documentName = (0, _modelTypeName.modelTypeName)([this.get('model.document')]).camelize();
      return "".concat((0, _emberInflector.pluralize)(documentName), ".").concat(documentName);
    }),
    documentSlug: Ember.computed('model.documentSlug', function () {
      return this.model.documentSlug;
    }),
    modelResource: Ember.computed('model.resourceClass', function () {
      return this.model.parentClass.toLowerCase();
    }),
    anchorLink: Ember.computed('modelResource', function () {
      return "".concat(this.modelResource, "-").concat(this.model.parentId);
    })
  });

  _exports.default = _default;
});