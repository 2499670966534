define("ntfrontend/components/auth/resend-form/component", ["exports", "ntfrontend/utils/ajaxer"], function (_exports, _ajaxer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('data', Ember.Object.create());
    },
    actions: {
      resend: function resend() {
        var _this = this;

        this.set('disabled', true);
        this.currentSession.resendActivationMail(this.get('data.email')).then(function () {
          _this._redirectToIndex();

          _this.set('disabled', false);
        }, function (error) {
          Ember.run(function () {
            _this.set('data.errors', (0, _ajaxer.errorize)(error.errors));

            _this.set('disabled', false);
          });
        });
      }
    },
    _redirectToIndex: function _redirectToIndex() {
      this.router.transitionTo('index');
    }
  });

  _exports.default = _default;
});