define("ntfrontend/components/documents/archived-label/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: 'inline-block label label-info'
  });

  _exports.default = _default;
});