define("ntfrontend/components/projects/project-card/project-sneak-peek/sub-resources/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eo9F79xk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\"],[9],[1,[29,\"t\",[\"shared.labels.info\"],null],false],[10],[0,\"\\n\"],[5,\"projects/project-card/project-sneak-peek/sub-resources/meta-data\",[],[[\"@model\",\"@projectTypePreview\"],[[23,\"selectedProjectType\"],[23,\"projectTypePreview\"]]]],[0,\"\\n\"],[7,\"h4\"],[9],[1,[29,\"t\",[\"shared.labels.documentTemplates\"],null],false],[10],[0,\"\\n\\n\"],[5,\"projects/project-card/project-sneak-peek/sub-resources/document-templates\",[],[[\"@projectTypePreview\"],[[23,\"projectTypePreview\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/project-card/project-sneak-peek/sub-resources/template.hbs"
    }
  });

  _exports.default = _default;
});