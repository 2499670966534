define("ntfrontend/components/shared/drop-zone/component", ["exports", "ntfrontend/mixins/components/prevent-default-dragging-events"], function (_exports, _preventDefaultDraggingEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_preventDefaultDraggingEvents.default, {
    overlayComponent: 'shared/drop-zone/-overlay',
    contentComponent: 'shared/drop-zone/-content',
    classNames: 'drop-zone__overlay-wrapper',
    isDragging: false,
    isDraggedOnOverlay: false,
    shouldReplaceContentOnDrag: false,
    isContentHidden: Ember.computed.and('shouldReplaceContentOnDrag', 'isDraggable'),
    isDraggable: Ember.computed('onDrop', 'isDragging', function () {
      var isDragging = this.isDragging,
          onDrop = this.onDrop;
      return isDragging && typeof onDrop === 'function';
    }),
    isClickable: Ember.computed.alias('onChange'),
    dragEnter: function dragEnter(e) {
      this.preventDefaultEvents(e);
      this.set('isDragging', true);
    },
    dragLeave: function dragLeave(e) {
      this.preventDefaultEvents(e);

      if (!this.isDraggedOnOverlay) {
        this.set('isDragging', false);
      }
    },
    drop: function drop(e) {
      this.actions.onFileDrop(e);
    },
    actions: {
      onFileDrop: function onFileDrop(e) {
        this.preventDefaultEvents(e);
        this.onDrop(e.dataTransfer.files);
        this.set('isDragging', false);
      },
      onInputChange: function onInputChange(e) {
        this.preventDefaultEvents(e);
        this.onChange(e.target.files);
        this.set('isDragging', false);
      },
      onDragLeave: function onDragLeave() {
        this.set('isDragging', false);
        this.set('isDraggedOnOverlay', false);
      }
    }
  });

  _exports.default = _default;
});