define("ntfrontend/components/shared/document-link/component", ["exports", "ntfrontend/helpers/model-type-name"], function (_exports, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    documentSorting: ['name:asc'],
    allAssignedItems: Ember.computed.mapBy('transport.transportDocuments', 'documentItem'),
    sortedAssignedItems: Ember.computed.sort('documentAssignedItems', 'documentSorting'),
    documentAssignedItems: Ember.computed('allAssignedItems.length', function () {
      var itemSymbolName = this.document.get('symbolName');
      return this.allAssignedItems.filter(function (documentItem) {
        return documentItem.get('document.symbolName') == itemSymbolName;
      });
    }),
    documentType: Ember.computed('document', function () {
      return (0, _modelTypeName.modelTypeName)([this.document]);
    }),
    actions: {
      chooseResult: function chooseResult(document) {
        var router = this.router;
        var slug = document.slug,
            resourdeId = document.resourdeId,
            resourceRoute = document.resourceRoute;
        var id = slug || resourdeId;
        var urlToRedirect = router.urlFor(resourceRoute, id);
        router.transitionTo(urlToRedirect);
      },
      redirect: function redirect() {
        var router = this.router,
            document = this.document;
        var resourceRoute = {
          Memo: 'memos.memo',
          Request: 'requests.request',
          Issue: 'issues.issue',
          Scenario: 'scenarios.scenario'
        };
        var urlToRedirect = router.urlFor(resourceRoute[this.documentType], document.get('slug'));
        router.transitionTo(urlToRedirect);
      }
    }
  });

  _exports.default = _default;
});