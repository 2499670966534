define("ntfrontend/routes/projects/project/transport", ["exports", "ntfrontend/routes/org-auth", "ntfrontend/mixins/routes/ember-can"], function (_exports, _orgAuth, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend(_emberCan.default, {
    currentSession: Ember.inject.service(),
    requireAbilities: Ember.computed('abilities', function () {
      this.set('abilities', {
        'visit in account': this.get('currentSession.account')
      });
    }),
    model: function model() {
      return Ember.RSVP.hash({
        project: this.modelFor('projects.project')
      });
    }
  });

  _exports.default = _default;
});