define("ntfrontend/models/document-item", ["exports", "ember-data", "ntfrontend/models/resource"], function (_exports, _emberData, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = _resource.default.extend({
    name: attr(),
    description: attr(),
    position: attr('number', {
      defaultValue: 0
    }),
    creator: belongsTo('user'),
    transportDocuments: hasMany(),
    relatedTasks: hasMany('task'),
    hasTasks: Ember.computed.or('tasks.length', 'relatedTasks.length'),
    hasTransportDocuments: Ember.computed.alias('transportDocuments.length')
  });

  _exports.default = _default;
});