define("ntfrontend/initializers/model-extend", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;

  function initialize() {
    Model.reopen({
      ability: belongsTo()
    });
  }

  var _default = {
    name: 'model-extend',
    initialize: initialize
  };
  _exports.default = _default;
});