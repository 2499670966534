define("ntfrontend/components/pagination-list/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5/Y01acW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[9],[1,[23,\"page\"],false],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/pagination-list/item/template.hbs"
    }
  });

  _exports.default = _default;
});