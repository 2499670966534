define("ntfrontend/components/shared/calendar-deadline-date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rFP1eQPN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"calendar-date__year\"],[9],[0,\"\\n  \"],[1,[23,\"formattedYear\"],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"calendar-date__month\"],[9],[0,\"\\n  \"],[1,[23,\"formattedMonth\"],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"calendar-date__day\"],[9],[0,\"\\n  \"],[1,[23,\"formattedDayOfMonth\"],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"calendar-date__time\"],[9],[0,\"\\n  \"],[1,[29,\"format-time\",[[25,[\"date\"]]],[[\"format\"],[\"hhmm\"]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/calendar-deadline-date/template.hbs"
    }
  });

  _exports.default = _default;
});