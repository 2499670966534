define("ntfrontend/components/documents/documents-list/inplace-due-date/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    can: Ember.inject.service(),
    classNames: ['picker--small', 'table-input'],
    init: function init() {
      this._super.apply(this, arguments);

      var dueDate = this.get('model.dueDate') || new Date();
      this.set('initialValue', dueDate);
    },
    actions: {
      afterChange: function afterChange() {
        if (this.initialValue.getTime() !== this.get('model.dueDate').getTime()) {
          this.set('initialValue', this.get('model.dueDate'));
          this.send('saveModel');
        }
      }
    }
  });

  _exports.default = _default;
});