define("ntfrontend/components/topics/topic-list/component", ["exports", "jquery", "ntfrontend/mixins/components/tooltip", "ntfrontend/helpers/model-type-name", "ntfrontend/mixins/components/query-params"], function (_exports, _jquery, _tooltip, _modelTypeName, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tooltip.default, _queryParams.default, {
    intl: Ember.inject.service(),
    notify: Ember.inject.service('notify-alert'),
    store: Ember.inject.service(),
    states: Ember.inject.service(),
    classNames: ['topics-list-component'],
    classNameBindings: ['agendaMode'],
    tooltipEnabled: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('queryParams.task')) {
        this.set('agendaMode', false);
      }

      this.createTooltip('.icon-unordered-list', {
        title: this.intl.t('topic.labels.agendaMode')
      });
      this.createTooltip('.icon-full-mode', {
        title: this.intl.t('topic.labels.fullMode')
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.destroyTooltip('.icon-unordered-list');
      this.destroyTooltip('.icon-full-mode');

      this._super.apply(this, arguments);
    },
    topics: Ember.computed('model', function () {
      return this.store.query('topic', {
        filter: {
          documentId: this.get('model.id'),
          documentType: (0, _modelTypeName.modelTypeName)([this.model])
        },
        include: 'ability,tasks.owner,related-tasks.owner,attachments,attachments.creator'
      });
    }),
    showReminder: Ember.computed('topics.isFulfilled', 'topics.isLoaded', 'introIsRunning', function () {
      return (this.get('topics.isFulfilled') || this.get('topics.isLoaded')) && !this.introIsRunning;
    }),
    hasTopics: Ember.computed('topics.length', function () {
      return Ember.isPresent(this.topics);
    }),
    topicsSorting: ['position:asc'],
    sortedTopics: Ember.computed.sort('topics', 'topicsSorting'),
    scrollObserver: Ember.observer('topics.length', 'states.scrollTo', function () {
      var _this = this;

      var anchor = this.get('states.scrollTo');

      if (this.get('topics.isFulfilled') && anchor) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          if ((0, _jquery.default)("#".concat(anchor)).length) {
            (0, _jquery.default)('.page-content').animate({
              scrollTop: (0, _jquery.default)("#".concat(anchor)).offset().top
            }, 1000);
          }

          _this.states.set('scrollTo', null);
        });
      }
    }),
    reorderTopics: function reorderTopics(topics, draggedModel) {
      var prevPosition = draggedModel.position;
      topics.forEach(function (model, index) {
        model.set('position', index + 1);
      });

      this._afterReorder(draggedModel, prevPosition);

      this.set('model.topics', topics);
    },
    actions: {
      reorderItems: function reorderItems(itemModels, draggedModel) {
        this.reorderTopics(itemModels, draggedModel);
      },
      displayAgenda: function displayAgenda(agendaMode) {
        this.set('agendaMode', agendaMode);
      },
      expandTopic: function expandTopic(topicId) {
        this.set('agendaMode', false);
        this.set('states.scrollTo', topicId);
      },
      refreshTopics: function refreshTopics() {
        this.notifyPropertyChange('topics');
      }
    },
    _afterReorder: function _afterReorder(draggedModel, prevPosition) {
      var _this2 = this;

      if (draggedModel.position != prevPosition) {
        draggedModel.save().then(function () {
          _this2.notify.success('notify.topic.reordered');
        });
      }
    }
  });

  _exports.default = _default;
});