define("ntfrontend/components/shared/modal-dialog/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.showModalChanged();
    },
    showModalChanged: Ember.observer('showModal', function () {
      var _this = this;

      Ember.run.later(function () {
        if (_this.isDestroyed) {
          return;
        } else {
          _this.set('shouldRender', _this.showModal);
        }
      });
    })
  });

  _exports.default = _default;
});