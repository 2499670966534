define("ntfrontend/routes/topics/topic", ["exports", "ember-inflector", "ntfrontend/routes/org-auth", "ntfrontend/helpers/model-type-name"], function (_exports, _emberInflector, _orgAuth, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend({
    paneManager: Ember.inject.service(),
    redirect: function redirect(topic, transition) {
      var _this = this;

      this._super.apply(this, arguments);

      topic.get('document').then(function (model) {
        _this.transitionTo(_this.documentShowPath(model), model.get('slug'));

        _this.paneManager.loadTask(transition.to.queryParams.task);
      });
    },
    documentShowPath: function documentShowPath(model) {
      var name = (0, _modelTypeName.modelTypeName)([model]).toLowerCase();
      return "".concat((0, _emberInflector.pluralize)(name), ".").concat(name);
    }
  });

  _exports.default = _default;
});