define("ntfrontend/components/templates/project-templates/new/modal-body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "535xA2s6",
    "block": "{\"symbols\":[\"drop-zone\"],\"statements\":[[5,\"templates/new-template/modal-body/input\",[],[[\"@autofocus\",\"@name\",\"@onChange\"],[true,[25,[\"model\",\"name\"]],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"model\",\"name\"]]],null]],null]]]],[0,\"\\n\\n\"],[5,\"shared/inplace-editor\",[],[[\"@isFormControl\",\"@isInplaceEditing\",\"@placeholderKey\",\"@value\"],[true,false,\"organization.documentTemplate.placeholders.description\",[25,[\"model\",\"description\"]]]]],[0,\"\\n\\n\"],[5,\"shared/drop-zone\",[],[[\"@shouldReplaceContentOnDrag\",\"@onDrop\",\"@onChange\"],[true,[29,\"action\",[[24,0,[]],\"updateThumbnail\"],null],[29,\"action\",[[24,0,[]],\"updateThumbnail\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,[\"model\",\"thumbnail\"]]],null,{\"statements\":[[0,\"    \"],[6,[24,1,[\"content\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"img\"],[11,\"class\",\"project-template-thumbnail --bordered\"],[12,\"src\",[25,[\"model\",\"thumbnail\"]]],[12,\"alt\",[25,[\"model\",\"name\"]]],[9],[10],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[24,1,[\"content\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[24,1,[\"overlay\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"],[5,\"shared/form-confirm-button\",[],[[\"@disabled\",\"@label\"],[[29,\"readonly\",[[25,[\"disabled\"]]],null],[29,\"t\",[\"shared.actions.create\"],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/templates/project-templates/new/modal-body/template.hbs"
    }
  });

  _exports.default = _default;
});