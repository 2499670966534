define("ntfrontend/components/custom-fields/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+/qfMx5T",
    "block": "{\"symbols\":[],\"statements\":[[5,\"documents/documents-list\",[],[[\"@class\",\"@objects\",\"@itemsInTbody\",\"@options\"],[\"table--custom-fields\",[23,\"filteredCustomFields\"],true,[23,\"options\"]]],{\"statements\":[[0,\"\\n  \"],[5,\"shared/header-sort\",[],[[\"@label\",\"@sort\",\"@name\"],[\"shared.labels.name\",[23,\"sort\"],\"name\"]]],[0,\"\\n  \"],[7,\"th\"],[9],[1,[29,\"t\",[\"organization.customField.labels.fieldType\"],null],false],[10],[0,\"\\n  \"],[7,\"th\"],[9],[1,[29,\"t\",[\"organization.customField.labels.visibleOnList\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/custom-fields/list/template.hbs"
    }
  });

  _exports.default = _default;
});