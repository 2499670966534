define("ntfrontend/components/pagination-list/next/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xo6cjJXL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[12,\"aria-label\",[29,\"t\",[\"shared.actions.next\"],null]],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"aria-hidden\",\"true\"],[11,\"class\",\"icon-arrow-right\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/pagination-list/next/template.hbs"
    }
  });

  _exports.default = _default;
});