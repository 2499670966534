define("ntfrontend/components/projects/add-new-project/form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['formClass'],
    formClass: Ember.computed('formClassNames', function () {
      return !this.formClassNames ? 'col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4' : this.formClassNames;
    })
  });

  _exports.default = _default;
});