define("ntfrontend/components/shared/intro-js/button/component", ["exports", "ntfrontend/mixins/components/intro-component"], function (_exports, _introComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_introComponent.default, {
    classNames: ['introjs-startbutton'],
    classNameBindings: 'showIcon:icon-help',
    showIcon: true
  });

  _exports.default = _default;
});