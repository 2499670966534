define("ntfrontend/models/attachment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    name: attr(),
    format: attr(),
    url: attr(),
    size: attr(),
    createdAt: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    creator: belongsTo('user'),
    resource: belongsTo('resource', {
      polymorphic: true,
      inverse: 'attachments'
    })
  });

  _exports.default = _default;
});