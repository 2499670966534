define("ntfrontend/components/shared/inplace-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S3umNHR/",
    "block": "{\"symbols\":[\"@inputId\",\"@onChange\"],\"statements\":[[4,\"if\",[[25,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[11,\"class\",\"text-muted\"],[9],[1,[29,\"t\",[[24,0,[\"label\"]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"label\"],[12,\"for\",[24,1,[]]],[11,\"class\",\"checkbox normal-weight\"],[9],[0,\"\\n  \"],[5,\"input\",[[13,\"class\",\"checkbox__input\"]],[[\"@id\",\"@change\",\"@type\"],[[24,1,[]],[24,2,[]],\"checkbox\"]]],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"checkbox__icon\"],[9],[10],[0,\"\\n  \"],[1,[29,\"t\",[[24,0,[\"description\"]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/inplace-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});