define("ntfrontend/components/documents/actions/duplicate-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7chKruXZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[11,\"class\",\"icon-copy\"],[9],[1,[29,\"t\",[\"document.actions.duplicate\"],null],false],[3,\"action\",[[24,0,[]],\"openModal\"]],[10],[0,\"\\n\"],[5,\"shared/modal-dialog\",[],[[\"@showModal\",\"@modalClass\",\"@onSuccess\",\"@title\",\"@tagName\"],[[23,\"showModal\"],\"modal\",[29,\"action\",[[24,0,[]],\"clone\"],null],[29,\"t\",[\"document.actions.duplicate\"],null],\"\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-body\"],[9],[0,\"\\n    \"],[5,\"shared/form-input\",[],[[\"@errors\"],[[25,[\"model\",\"errors\",\"name\"]]]],{\"statements\":[[0,\"\\n      \"],[5,\"input\",[],[[\"@type\",\"@value\",\"@class\",\"@placeholder\",\"@required\"],[\"text\",[23,\"newName\"],\"form-control\",[29,\"t\",[\"shared.labels.name\"],null],\"true\"]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[7,\"label\"],[11,\"class\",\"check form-group\"],[9],[0,\"\\n      \"],[5,\"input\",[],[[\"@type\",\"@checked\"],[\"checkbox\",[23,\"cloneTasks\"]]]],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"check__button icon-checkmark\"],[9],[10],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"normal-weight\"],[9],[1,[29,\"t\",[\"document.labels.cloneTasks\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[5,\"shared/form-confirm-button\",[],[[\"@label\",\"@disabled\"],[[29,\"t\",[\"document.actions.duplicate\"],null],[23,\"disabled\"]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/documents/actions/duplicate-button/template.hbs"
    }
  });

  _exports.default = _default;
});