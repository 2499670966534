define("ntfrontend/mixins/routes/project-breadcrumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    breadCrumb: Ember.computed('projectName', function () {
      var projectName = Ember.get(this, 'projectName');
      var title = Ember.get(this, 'breadCrumbTitle');
      var crumb = {
        title: title,
        injection: [{
          title: projectName,
          offset: -1
        }]
      };
      return crumb;
    }),
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      var projectName = Ember.get(model, 'project.name');
      Ember.set(this, 'projectName', projectName);
    }
  });

  _exports.default = _default;
});