define("ntfrontend/mixins/components/inplace/focusable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isEditingDidChange: Ember.observer('isEditing', function () {
      if (this.isEditing) {
        Ember.run.scheduleOnce('afterRender', this, this.focusField);
      }
    }),
    focusOut: function focusOut() {
      this.send('doneEditing');
    },
    focusField: function focusField() {
      var formInput = this.element.querySelector('.form-control');

      if (formInput) {
        formInput.focus();
      }
    }
  });

  _exports.default = _default;
});