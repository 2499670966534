define("ntfrontend/routes/projects/project/index", ["exports", "ntfrontend/routes/org-auth"], function (_exports, _orgAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend({
    currentSession: Ember.inject.service(),
    model: function model() {
      var _this = this;

      var params = this.paramsFor('projects.project');
      var data = {
        include: 'owner,requester,creator',
        filter: {
          slug: "".concat(params.project_slug, ",").concat(this.get('currentSession.account.id'))
        }
      };
      return this.store.query('project', data).then(function (project) {
        return _this.objectOrRedirect(project, 'projects');
      });
    }
  });

  _exports.default = _default;
});