define("ntfrontend/components/shared/filters/dropdown-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sqG10P6K",
    "block": "{\"symbols\":[\"( unused value )\",\"element\",\"&default\"],\"statements\":[[7,\"label\"],[11,\"class\",\"text-muted filter-name\"],[9],[0,\"\\n  \"],[1,[29,\"t\",[[25,[\"labelKey\"]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"a\"],[11,\"class\",\"dropdown-toggle\"],[11,\"data-toggle\",\"dropdown\"],[11,\"aria-haspopup\",\"true\"],[11,\"aria-expanded\",\"true\"],[11,\"type\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"property\"]]],null,{\"statements\":[[0,\"    \"],[15,3,[[25,[\"property\"]],[25,[\"list\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[29,\"t\",[\"shared.filters.all\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\\n\"],[7,\"ul\"],[11,\"class\",\"dropdown-menu\"],[9],[0,\"\\n  \"],[7,\"li\"],[9],[7,\"a\"],[9],[1,[29,\"t\",[\"shared.filters.all\"],null],false],[3,\"action\",[[24,0,[]],\"changeProperty\",null]],[10],[10],[0,\"\\n\\n\"],[4,\"each\",[[29,\"-each-in\",[[25,[\"list\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[7,\"a\"],[9],[15,3,[[24,2,[]],[25,[\"list\"]]]],[3,\"action\",[[24,0,[]],\"changeProperty\",[24,2,[]]]],[10],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/filters/dropdown-filter/template.hbs"
    }
  });

  _exports.default = _default;
});