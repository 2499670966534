define("ntfrontend/components/shared/state-switcher/component", ["exports", "ntfrontend/mixins/components/switcher", "jquery"], function (_exports, _switcher, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switcher.default, {
    didInsertElement: function didInsertElement() {
      this._setupSwitcher();
    },
    _setupSwitcher: function _setupSwitcher() {
      var _this = this;

      (0, _jquery.default)('input', this.element).bootstrapSwitch(this._options());
      (0, _jquery.default)('input', this.element).on('switchChange.bootstrapSwitch', function () {
        if (_this.disabled) {
          (0, _jquery.default)('input', _this.element).bootstrapSwitch('toggleState', true);
        } else {
          _this.toggleState();
        }
      });
    },
    _options: function _options() {
      return {
        onColor: 'success',
        offColor: 'danger',
        disabled: this.disabledInput,
        onText: this.intl.t('shared.switcher.on'),
        offText: this.intl.t('shared.switcher.off')
      };
    }
  });

  _exports.default = _default;
});