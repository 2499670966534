define("ntfrontend/components/custom-fields/document-list/item-row/item/list-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cZQ+154d",
    "block": "{\"symbols\":[\"dd\",\"menu\",\"option\"],\"statements\":[[4,\"if\",[[29,\"and\",[[25,[\"editable\"]],[29,\"not\",[[25,[\"customValue\",\"multiple_value\"]]],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"inplace-editing table-input\"],[9],[0,\"\\n    \"],[5,\"bs-dropdown\",[],[[\"@class\"],[\"static-value dropdown-toggle\"]],{\"statements\":[[0,\"\\n      \"],[6,[24,1,[\"toggle\"]],[],[[\"@tagName\"],[\"div\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,[\"customValue\",\"value\"]]],null,{\"statements\":[[0,\"          \"],[1,[25,[\"customValue\",\"value\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"span\"],[11,\"class\",\"placeholder\"],[9],[1,[29,\"t\",[\"organization.customField.labels.selectOption\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"]],\"parameters\":[]}],[0,\"\\n\\n      \"],[6,[24,1,[\"menu\"]],[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[25,[\"customValue\",\"options\"]]],null,{\"statements\":[[0,\"          \"],[6,[24,2,[\"item\"]],[],[[],[]],{\"statements\":[[0,\"\\n            \"],[7,\"a\"],[9],[1,[24,3,[]],false],[3,\"action\",[[24,0,[]],\"updateValue\",[24,3,[]]]],[10],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"      \"]],\"parameters\":[2]}],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"custom-fields/document-list/item-row/item/value\",[],[[\"@value\"],[[25,[\"customValue\",\"value\"]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/custom-fields/document-list/item-row/item/list-field/template.hbs"
    }
  });

  _exports.default = _default;
});