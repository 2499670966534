define("ntfrontend/components/members/remove-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eaajziMw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[29,\"can\",[\"remove member\",[25,[\"member\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"shared/actions/remove-button\",[],[[\"@model\",\"@afterRemove\"],[[23,\"member\"],[29,\"action\",[[24,0,[]],\"afterRemove\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/members/remove-link/template.hbs"
    }
  });

  _exports.default = _default;
});