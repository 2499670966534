define("ntfrontend/components/auth/forgot-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h0xhjSKY",
    "block": "{\"symbols\":[\"mat-label\"],\"statements\":[[7,\"form\"],[9],[0,\"\\n  \"],[5,\"shared/form-input\",[],[[\"@errors\"],[[25,[\"data\",\"errors\",\"email\"]]]],{\"statements\":[[0,\"\\n    \"],[5,\"shared/material-labels\",[],[[\"@key\",\"@labelFor\",\"@placeholder\"],[\"hasEmail\",\"inputEmail\",[29,\"t\",[\"auth.email.placeholder\"],null]]],{\"statements\":[[0,\"\\n      \"],[5,\"input\",[],[[\"@autofocus\",\"@class\",\"@id\",\"@required\",\"@value\",\"@key-up\"],[\"true\",\"form-control input-label\",\"inputEmail\",\"true\",[25,[\"data\",\"email\"]],[24,1,[\"changeValue\"]]]]],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"shared/form-confirm-button\",[],[[\"@disabled\",\"@label\"],[[23,\"disableButton\"],[29,\"t\",[\"auth.passwordReset\"],null]]]],[0,\"\\n\"],[3,\"action\",[[24,0,[]],[29,\"perform\",[[25,[\"reset\"]]],null]],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/auth/forgot-form/template.hbs"
    }
  });

  _exports.default = _default;
});