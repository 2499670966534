define("ntfrontend/components/templates/project-templates/import-templates/component", ["exports", "ntfrontend/components/accounts/account-details/document-templates/import-template/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    accountsNames: Ember.computed('projectTypes.[]', function () {
      var projectTypes = this.projectTypes;

      if (projectTypes) {
        return projectTypes.mapBy('accountName').uniq();
      }
    }),
    filteredProjectTypes: Ember.computed('account', function () {
      var account = this.account;

      if (account) {
        return this.projectTypes.filterBy('accountName', account);
      }
    }),
    actions: {
      getProjectTypes: function getProjectTypes(projectTypes) {
        this.set('projectTypes', projectTypes);
      }
    },
    _importData: function _importData() {
      return {
        templatesIds: this.templates.map(function (template) {
          return template.id;
        }),
        importDocumentTemplates: false
      };
    },
    _afterImportNotification: function _afterImportNotification() {
      this.notify.success('notify.template.importedTemplates');
      this.notify.info('notify.template.importResources');
    }
  });

  _exports.default = _default;
});