define("ntfrontend/components/shared/modal-dialog/modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S2GOY+ik",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"showModal\"]]],null,{\"statements\":[[4,\"if\",[[25,[\"customModal\"]]],null,{\"statements\":[[0,\"    \"],[15,1,[[29,\"hash\",null,[[\"onSuccess\"],[[29,\"action\",[[24,0,[]],\"onSuccess\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"modal-dialog\"],[11,\"role\",\"document\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"modal-content\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n          \"],[7,\"h4\"],[11,\"class\",\"modal-title\"],[9],[1,[23,\"title\"],false],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"form\"],[9],[0,\"\\n          \"],[15,1],[0,\"\\n        \"],[3,\"action\",[[24,0,[]],\"onSuccess\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/modal-dialog/modal/template.hbs"
    }
  });

  _exports.default = _default;
});