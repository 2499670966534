define("ntfrontend/components/shared/labeled-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lRcXs+gj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"row flex--center \",[23,\"wrapperLabelClass\"]]]],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[30,[\"col-xs-4 \",[23,\"labelClass\"]]]],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"text-muted\"],[9],[1,[29,\"t\",[[25,[\"label\"]]],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-8\"],[9],[0,\"\\n    \"],[15,1],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/labeled-row/template.hbs"
    }
  });

  _exports.default = _default;
});