define("ntfrontend/components/shared/list-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['filter'],
    translationKey: 'shared.labels.filter',
    selected: Ember.computed('fromSelect', 'model', function () {
      var currentUserId = this.currentSession.user.id,
          model = this.model,
          fromSelect = this.fromSelect,
          type = this.type;

      if (!fromSelect && model) {
        return model === 'me' ? this._findUser(type, currentUserId) : this._findUser(type, model);
      } else {
        return fromSelect;
      }
    }),
    modelId: Ember.computed('model', function () {
      var type = this.type,
          model = this.model;

      if (type === 'user' && model === 'me') {
        return this.get('currentSession.user.id');
      }

      return model;
    }),
    actions: {
      filterModel: function filterModel(value) {
        if (value) {
          this.set('model', value.get('id'));
        } else {
          this.set('model', value);
        }

        this.set('fromSelect', value);
      }
    },
    _findUser: function _findUser(type, userId) {
      return this.store.find(type, userId);
    }
  });

  _exports.default = _default;
});