define("ntfrontend/components/projects/dashboard/milestones/milestones-list/dropdown/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dropdown'],
    actions: {
      removeNotify: function removeNotify() {
        this.notify.success('notify.milestone.removed');
      },
      openModal: function openModal() {
        this.set('showModal', true);
      }
    }
  });

  _exports.default = _default;
});