define("ntfrontend/components/tasks/new-task/step/component", ["exports", "ntfrontend/components/tasks/new-task/component", "ntfrontend/components/tasks/new-task/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    _initModal: function _initModal() {
      this._super.apply(this, arguments);

      var task = this.task,
          resource = this.resource;
      var owner = resource.get('owner.email') ? resource.get('owner') : this.get('currentSession.user');

      if (this.forStep) {
        task.set('owner', owner);
        task.set('name', resource.get('name'));
        task.set('description', resource.get('description'));
      }
    }
  });

  _exports.default = _default;
});