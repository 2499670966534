define("ntfrontend/components/shared/form-cancel-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jmyPOtL5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[12,\"disabled\",[23,\"disabled\"]],[11,\"class\",\"btn btn-block btn-danger\"],[11,\"type\",\"button\"],[9],[1,[23,\"label\"],false],[3,\"action\",[[24,0,[]],[25,[\"cancelAction\"]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/form-cancel-button/template.hbs"
    }
  });

  _exports.default = _default;
});