define("ntfrontend/components/auth/register-form/country-select/selected/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bIG1zCd2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"country-flag\"],[9],[0,\"\\n  \"],[7,\"img\"],[12,\"src\",[23,\"countryFlag\"]],[12,\"alt\",[25,[\"option\",\"name\"]]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"country-dial-code\"],[9],[0,\"\\n  (\"],[1,[25,[\"option\",\"dialCode\"]],false],[0,\")\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/auth/register-form/country-select/selected/template.hbs"
    }
  });

  _exports.default = _default;
});