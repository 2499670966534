define("ntfrontend/components/custom-fields/custom-values/components/number/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "91QcJaqv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"col-xs-4\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"text-muted\"],[9],[1,[25,[\"customValue\",\"name\"]],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"col-xs-8\"],[9],[0,\"\\n  \"],[5,\"shared/inplace-input\",[],[[\"@afterChange\",\"@editable\",\"@value\",\"@type\",\"@required\",\"@placeholderKey\"],[[29,\"action\",[[24,0,[]],\"saveCustomValue\"],null],[23,\"editable\"],[25,[\"customValue\",\"value\"]],[23,\"type\"],false,\"organization.customField.placeholders.setValue\"]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/custom-fields/custom-values/components/number/template.hbs"
    }
  });

  _exports.default = _default;
});