define("ntfrontend/components/shared/attachments/image-preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1Vn1rkOV",
    "block": "{\"symbols\":[],\"statements\":[[5,\"shared/modal-dialog\",[],[[\"@showModal\",\"@customModal\",\"@modalClass\"],[[23,\"showModal\"],true,\"image-preview-modal\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-notice\"],[9],[1,[29,\"t\",[\"shared.actions.escToCloseModal\"],null],false],[10],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"close\"],[11,\"data-dismiss\",\"modal\"],[11,\"type\",\"button\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"icon-clear\"],[9],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-dialog\"],[9],[0,\"\\n    \"],[7,\"img\"],[11,\"class\",\"image-preview\"],[12,\"src\",[23,\"url\"]],[12,\"alt\",[29,\"t\",[\"shared.labels.preview\"],null]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/attachments/image-preview/template.hbs"
    }
  });

  _exports.default = _default;
});