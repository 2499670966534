define("ntfrontend/components/projects/project-card/project-sneak-peek/sub-resources/document-templates/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u2m9K6GN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"tr\"],[9],[0,\"\\n  \"],[7,\"td\"],[12,\"class\",[30,[\"table__type \",[23,\"iconClass\"]]]],[9],[0,\"\\n    \"],[5,\"bs-tooltip\",[],[[\"@renderInPlace\"],[true]],{\"statements\":[[0,\"\\n      \"],[1,[23,\"iconTooltip\"],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"td\"],[9],[1,[25,[\"documentTemplate\",\"name\"]],false],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/project-card/project-sneak-peek/sub-resources/document-templates/item/template.hbs"
    }
  });

  _exports.default = _default;
});