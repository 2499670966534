define("ntfrontend/components/users/profile-box/picture/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v8VoQ0Ge",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"tooltipEnabled\"]]],null,{\"statements\":[[0,\"  \"],[5,\"bs-popover\",[],[[\"@triggerEvents\",\"@autoPlacement\",\"@placement\",\"@renderInPlace\"],[\"hover focus\",true,\"top\",false]],{\"statements\":[[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flexbox flex--center\"],[9],[0,\"\\n      \"],[5,\"users/profile-box/picture\",[],[[\"@user\",\"@width\",\"@height\",\"@tooltipEnabled\"],[[23,\"user\"],\"50\",\"50\",false]]],[0,\"\\n      \"],[7,\"div\"],[9],[0,\"\\n        \"],[7,\"div\"],[9],[1,[25,[\"user\",\"name\"]],false],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"text-muted\"],[9],[1,[25,[\"user\",\"email\"]],false],[10],[0,\"\\n        \"],[7,\"span\"],[12,\"class\",[30,[\"label \",[29,\"role-indicator\",[[25,[\"user\",\"roleInOrganization\"]]],null]]]],[9],[0,\"\\n          \"],[1,[29,\"role-name\",[[25,[\"user\",\"roleInOrganization\"]]],null],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[29,\"ember-initials\",null,[[\"class\",\"name\",\"seedText\",\"width\",\"height\",\"fontSize\",\"fontWeight\",\"fontFamily\"],[\"img-circle\",[25,[\"user\",\"displayName\"]],[25,[\"user\",\"email\"]],[25,[\"width\"]],[25,[\"height\"]],[25,[\"fontSize\"]],[25,[\"fontWeight\"]],[25,[\"fontFamily\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/users/profile-box/picture/template.hbs"
    }
  });

  _exports.default = _default;
});