define("ntfrontend/components/shared/form-phone-number/component", ["exports", "ntfrontend/utils/countries"], function (_exports, _countries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var isNumberChar = /^\d$/;

  var _default = Ember.Component.extend({
    phoneNumber: null,
    country: '',
    init: function init() {
      this._super.apply(this, arguments);

      if (this.user && this.user.phoneNumber) {
        this._setUserPhoneNumber();
      }
    },
    disabledPhoneInput: Ember.computed.empty('country'),
    actions: {
      validatePhoneInput: function validatePhoneInput(event) {
        var pressedKey = event.key;

        if (!isNumberChar.test(pressedKey)) {
          event.preventDefault();
          return false;
        }
      },
      changePhoneNumber: function changePhoneNumber(event) {
        this.set('phoneNumber', event.target.value);

        this._updateUserPhoneNumber();
      },
      changeCountry: function changeCountry(country) {
        this.set('country', country);

        this._updateUserPhoneNumber();
      }
    },
    _setUserPhoneNumber: function _setUserPhoneNumber() {
      var phoneNumber = this.user.phoneNumber;

      var _this$_normalizePhone = this._normalizePhoneNumber(phoneNumber),
          _this$_normalizePhone2 = _slicedToArray(_this$_normalizePhone, 2),
          normalizedDialCode = _this$_normalizePhone2[0],
          normalizedPhoneNumber = _this$_normalizePhone2[1];

      var country = _countries.default.find(function (_ref) {
        var dialCode = _ref.dialCode;
        return dialCode === normalizedDialCode;
      });

      this.set('country', country);
      this.set('phoneNumber', normalizedPhoneNumber);
    },
    _updateUserPhoneNumber: function _updateUserPhoneNumber() {
      var concatPhoneNumber = "(".concat(this.country.dialCode, ") ").concat(this.phoneNumber);
      this.changePhoneNumber(concatPhoneNumber);
    },
    _normalizePhoneNumber: function _normalizePhoneNumber(phoneNumber) {
      var _phoneNumber$split = phoneNumber.split(')'),
          _phoneNumber$split2 = _slicedToArray(_phoneNumber$split, 2),
          dialCode = _phoneNumber$split2[0],
          dirtyPhoneNumber = _phoneNumber$split2[1];

      var countryDialCode = dialCode.replace('(', '');
      var purePhoneNumber = dirtyPhoneNumber.trimStart();
      return [countryDialCode, purePhoneNumber];
    }
  });

  _exports.default = _default;
});