define("ntfrontend/models/document", ["exports", "ember-data", "ntfrontend/models/resource", "ntfrontend/mixins/models/custom-valuable", "ember-custom-actions", "ntfrontend/helpers/model-type-name"], function (_exports, _emberData, _resource, _customValuable, _emberCustomActions, _modelTypeName3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.documentsNames = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var documentsNames = ['Memo', 'Issue', 'Request', 'Scenario'];
  _exports.documentsNames = documentsNames;

  var _default = _resource.default.extend(_customValuable.default, {
    slug: attr('number'),
    name: attr(),
    typeName: attr(),
    archived: attr('boolean'),
    overdueTasks: attr('number'),
    myOverdueTasks: attr('number'),
    openTasksCount: attr('number'),
    project: belongsTo(),
    owner: belongsTo('user'),
    creator: belongsTo('user'),
    milestone: belongsTo('milestone'),
    workPackage: belongsTo('work-package'),
    workPackageList: belongsTo('work-package-list'),
    clone: (0, _emberCustomActions.modelAction)('clone', {
      method: 'POST'
    }),
    archive: (0, _emberCustomActions.modelAction)('archive', {
      method: 'POST'
    }),
    unarchive: (0, _emberCustomActions.modelAction)('unarchive', {
      method: 'POST'
    }),
    distribute: (0, _emberCustomActions.modelAction)('distribute', {
      method: 'POST'
    }),
    importTemplate: (0, _emberCustomActions.modelAction)('import-template', {
      method: 'PATCH'
    }),
    availableMembers: Ember.computed('members.@each.role', function () {
      return this.members.filter(function (member) {
        return member.get('role') !== 'collaborator';
      }).sortBy('deliveryStatus', 'user.name');
    }),
    symbolName: Ember.computed('slug', function () {
      var _modelTypeName = (0, _modelTypeName3.modelTypeName)([this]),
          _modelTypeName2 = _slicedToArray(_modelTypeName, 1),
          firstLetterModelName = _modelTypeName2[0];

      return firstLetterModelName.concat(this.slug);
    })
  });

  _exports.default = _default;
});