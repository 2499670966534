define("ntfrontend/authenticators/devise", ["exports", "ember-simple-auth/authenticators/devise"], function (_exports, _devise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _devise.default.extend({
    endpoint: Ember.inject.service(),
    fetcher: Ember.inject.service(),
    invalidate: function invalidate(user) {
      return this.fetcher.delete(this.endpoint.logout, {
        data: {
          user: user
        }
      });
    },
    authenticate: function authenticate(identification, password) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var resourceName = _this.resourceName,
            identificationAttributeName = _this.identificationAttributeName,
            tokenAttributeName = _this.tokenAttributeName;
        var data = {};
        data[resourceName] = {
          password: password
        };
        data[resourceName][identificationAttributeName] = identification;

        _this.makeRequest(data).then(function (response) {
          if (_this._validate(response)) {
            Ember.run(null, resolve, response);
          } else {
            Ember.run(null, reject, "Check that server response includes ".concat(tokenAttributeName, " and ").concat(identificationAttributeName));
          }
        }).catch(function (error) {
          return Ember.run(null, reject, error);
        });
      });
    },
    _validate: function _validate(data) {
      var tokenAttributeName = this.tokenAttributeName,
          identificationAttributeName = this.identificationAttributeName;
      return !Ember.isEmpty(data[tokenAttributeName]) && !Ember.isEmpty(data[identificationAttributeName]);
    },
    makeRequest: function makeRequest(data) {
      data.user.timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
      return this.fetcher.post(this.endpoint.login, {
        data: data
      });
    }
  });

  _exports.default = _default;
});