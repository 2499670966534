define("ntfrontend/components/projects/dashboard/work-packages/list/item/dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mJK7PdRf",
    "block": "{\"symbols\":[],\"statements\":[[5,\"shared/actions/more-actions-button\",[],[[],[]]],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"openDropdown\"]]],null,{\"statements\":[[0,\"  \"],[5,\"shared/loading-state/block\",[],[[\"@model\",\"@tagName\",\"@class\"],[[25,[\"model\",\"ability\"]],\"ul\",\"dropdown-menu dropdown-menu-right\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[29,\"can\",[\"edit workPackage\",[25,[\"model\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[7,\"a\"],[9],[1,[29,\"t\",[\"dashboard.actions.edit\"],null],false],[3,\"action\",[[24,0,[]],\"openModal\"]],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[29,\"can\",[\"remove workPackage\",[25,[\"model\"]]],null]],null,{\"statements\":[[0,\"      \"],[5,\"shared/actions/remove-button\",[],[[\"@model\",\"@afterRemove\",\"@tagName\"],[[23,\"model\"],[29,\"action\",[[24,0,[]],\"successRemoveNotify\"],null],\"li\"]],{\"statements\":[[0,\"\\n        \"],[7,\"a\"],[9],[1,[29,\"t\",[\"shared.actions.remove\"],null],false],[10],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[5,\"projects/dashboard/work-packages/edit\",[],[[\"@model\",\"@showModal\"],[[23,\"model\"],[23,\"showModal\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/dashboard/work-packages/list/item/dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});