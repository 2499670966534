define("ntfrontend/components/members/edit-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zjHk98Dm",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[29,\"can\",[\"edit member\",[25,[\"member\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"link-to\",[],[[\"@route\",\"@model\",\"@class\"],[\"account.users.edit\",[23,\"member\"],\"edit-member icon-pencil\"]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/members/edit-link/template.hbs"
    }
  });

  _exports.default = _default;
});