define("ntfrontend/components/transports/transport-list/documents-wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iPZScDWS",
    "block": "{\"symbols\":[\"document\",\"@model\"],\"statements\":[[7,\"div\"],[11,\"class\",\"documents-wrapper\"],[9],[0,\"\\n\"],[4,\"each\",[[24,0,[\"firstTwoDocumentsForTransport\"]]],null,{\"statements\":[[0,\"    \"],[5,\"shared/document-link\",[],[[\"@document\",\"@transport\"],[[24,1,[]],[24,2,[]]]]],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[1,[29,\"documents-separator\",[[24,0,[\"firstTwoDocumentsForTransport\"]],[24,0,[\"hiddenDocumentsLength\"]],[24,1,[]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[24,0,[\"hiddenDocumentsLength\"]]],null,{\"statements\":[[0,\"    \"],[5,\"transports/sneak-peek-button\",[],[[\"@model\",\"@classNames\"],[[24,2,[]],\"documents-couter-circle\"]],{\"statements\":[[0,\"\\n      \"],[5,\"bs-tooltip\",[],[[],[]],{\"statements\":[[0,\"\\n        \"],[7,\"div\"],[9],[1,[24,0,[\"hiddenDocumentsLength\"]],false],[0,\" \"],[1,[29,\"t\",[\"transport.tooltips.moreDocuments\"],null],false],[10],[0,\"\\n        \"],[7,\"div\"],[9],[1,[29,\"t\",[\"transport.tooltips.showListDocuments\"],null],false],[10],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[1,[24,0,[\"hiddenDocumentsLength\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/transports/transport-list/documents-wrapper/template.hbs"
    }
  });

  _exports.default = _default;
});