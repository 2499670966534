define("ntfrontend/components/issues/issue-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hed//oqK",
    "block": "{\"symbols\":[],\"statements\":[[5,\"shared/actions/more-actions-button\",[],[[],[]]],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"openDropdown\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\"],[11,\"class\",\"dropdown-menu dropdown-menu-right\"],[9],[0,\"\\n    \"],[5,\"shared/loading-state/block\",[],[[\"@model\",\"@tagName\"],[[25,[\"model\",\"ability\"]],\"\"]],{\"statements\":[[0,\"\\n\\n      \"],[7,\"li\"],[9],[5,\"link-to\",[],[[\"@route\",\"@model\",\"@class\"],[\"issues.issue\",[25,[\"model\",\"slug\"]],\"icon-help\"]],{\"statements\":[[1,[29,\"t\",[\"document.labels.details\"],null],false]],\"parameters\":[]}],[10],[0,\"\\n\\n\"],[4,\"if\",[[29,\"can\",[\"remove issue\",[25,[\"model\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[5,\"shared/confirm-remove-link\",[],[[\"@afterConfirm\",\"@class\"],[[29,\"action\",[[24,0,[]],\"remove\"],null],\"icon-remove\"]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[29,\"can\",[\"clone memo\",[25,[\"model\"]]],null]],null,{\"statements\":[[0,\"        \"],[5,\"documents/actions/duplicate-button\",[],[[\"@model\"],[[23,\"model\"]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[5,\"documents/archive-button\",[],[[\"@model\"],[[23,\"model\"]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/issues/issue-dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});