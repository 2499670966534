define("ntfrontend/components/shared/power-select-with-placeholder/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aA+IQe5F",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[5,\"shared/material-labels\",[],[[\"@placeholder\",\"@hasValue\"],[[24,0,[\"placeholder\"]],[23,\"isNotEmpty\"]]],{\"statements\":[[0,\"\\n  \"],[5,\"power-select\",[],[[\"@options\",\"@selected\",\"@triggerClass\",\"@searchField\",\"@onfocus\",\"@onclose\",\"@disabled\",\"@value\",\"@onchange\"],[[24,0,[\"items\"]],[24,0,[\"item\"]],[24,0,[\"triggerClass\"]],[24,0,[\"searchField\"]],[29,\"action\",[[24,0,[]],\"onfocus\"],null],[29,\"action\",[[24,0,[]],\"onclose\"],null],[23,\"disabled\"],[23,\"value\"],[29,\"action\",[[24,0,[]],\"onchange\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[29,\"eq\",[[24,0,[\"objectType\"]],\"user\"],null]],null,{\"statements\":[[0,\"      \"],[5,\"users/profile-box/picture\",[],[[\"@user\"],[[24,1,[]]]]],[0,\" \"],[1,[24,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"eq\",[[24,0,[\"objectType\"]],\"member\"],null]],null,{\"statements\":[[0,\"      \"],[5,\"users/profile-box/picture\",[],[[\"@user\"],[[24,1,[\"user\"]]]]],[0,\" \"],[1,[24,1,[\"user\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"eq\",[[24,0,[\"objectType\"]],\"system\"],null]],null,{\"statements\":[[0,\"      \"],[1,[24,1,[]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[24,1,[\"name\"]],false],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/power-select-with-placeholder/template.hbs"
    }
  });

  _exports.default = _default;
});