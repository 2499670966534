define("ntfrontend/components/documents/new-document/select-project/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    addClass: null,
    classes: Ember.computed('addClass', function () {
      return "form-wrapper ".concat(this.addClass);
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.projectId) {
        var predefinedProject = this.projects.filterBy('slug', +this.projectId).firstObject;
        this.actions.selectProject.bind(this)(predefinedProject);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      if (this.projectId) {
        this.set('addClass', 'project__selected');
      }
    },
    actions: {
      change: function change(value) {
        return value ? this.set('addClass', 'project__selected') : this._setClassesByProject();
      },
      selectProject: function selectProject(selectedProject) {
        this.set('project', selectedProject);
        this.onChange(selectedProject);
      }
    },
    _setClassesByProject: function _setClassesByProject() {
      return this.set('addClass', this.project ? 'project__selected project__closed' : 'notSelected');
    }
  });

  _exports.default = _default;
});