define("ntfrontend/components/documents/distribute-document/distribute-button/component", ["exports", "ember-inflector", "ntfrontend/helpers/model-type-name"], function (_exports, _emberInflector, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    memberId: null,
    disabled: false,
    modelName: Ember.computed('document', function () {
      return (0, _modelTypeName.modelTypeName)([this.document]).toLowerCase();
    }),
    distributePath: Ember.computed('modelName', function () {
      var modelName = this.modelName;
      return "".concat((0, _emberInflector.pluralize)(modelName), ".").concat(modelName, ".distribute");
    }),
    distributeHeader: Ember.computed('modelName', function () {
      return "".concat(this.modelName, ".share.shareHeader");
    }),
    buttonLabel: Ember.computed('disabled', 'distributeHeader', function () {
      return this.disabled ? 'document.placeholders.noFollowers' : this.distributeHeader;
    }),
    distributeAbility: Ember.computed('modelName', function () {
      return "distribute ".concat(this.modelName);
    }),
    actions: {
      openModal: function openModal() {
        this.set('showModal', true);
      }
    }
  });

  _exports.default = _default;
});