define("ntfrontend/components/sidebar-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5vXpXQMC",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"currentSession\",\"loggedIn\"]]],null,{\"statements\":[[0,\"  \"],[5,\"sidebar-menu/secondary-links\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/sidebar-menu/template.hbs"
    }
  });

  _exports.default = _default;
});