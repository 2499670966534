define("ntfrontend/components/projects/dashboard/work-packages/list/item/dropdown/component", ["exports", "ntfrontend/mixins/components/open-dropdown"], function (_exports, _openDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_openDropdown.default, {
    classNames: ['dropdown'],
    dropdownModel: Ember.computed.alias('model.ability'),
    actions: {
      successRemoveNotify: function successRemoveNotify() {
        this.notify.success('notify.workPackage.removed');
      },
      openModal: function openModal() {
        this.set('showModal', true);
      }
    }
  });

  _exports.default = _default;
});