define("ntfrontend/components/tasks/task-switcher-small/component", ["exports", "ntfrontend/mixins/components/model-saving", "ntfrontend/mixins/components/tasks/task-switcher", "ntfrontend/mixins/components/tooltip"], function (_exports, _modelSaving, _taskSwitcher, _tooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, _taskSwitcher.default, _tooltip.default, {
    state: 'completed',
    classNames: 'switcher-small__wrapper',
    tooltipTitle: Ember.computed('model.completed', function () {
      var label = this.get('model.completed') ? 'markAsTodo' : 'markAsDone';
      return this.intl.t("shared.switcher.".concat(label));
    }),
    click: function click() {
      if (this.editable && !this.disabled) {
        this.toggleState();
      }
    }
  });

  _exports.default = _default;
});