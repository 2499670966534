define("ntfrontend/components/shared/filters/dropdown-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['filter', 'dropdown'],
    actions: {
      changeProperty: function changeProperty(value) {
        this.set('property', value);
      }
    }
  });

  _exports.default = _default;
});