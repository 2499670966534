define("ntfrontend/mixins/components/switcher", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    notify: Ember.inject.service('notify-alert'),
    editable: true,
    disabled: false,
    save: true,
    disabledInput: Ember.computed.not('editable'),
    disabledInputChanged: Ember.observer('disabledInput', function () {
      (0, _jquery.default)('input', this.element).bootstrapSwitch('disabled', this.disabledInput);
    }),
    checkedObserver: Ember.observer('value', function () {
      (0, _jquery.default)('input', this.element).bootstrapSwitch('state', this.value, true);
    }),
    toggleState: function toggleState() {
      var _this = this;

      this.model.toggleProperty(this.state);

      if (this.save) {
        this.set('disabled', true);
        this.model.save().then(function () {
          _this._afterSaveSuccess();
        }, function () {
          _this._afterSaveError();
        }).finally(function () {
          _this.set('disabled', false);
        });
      }
    },
    _afterSaveSuccess: function _afterSaveSuccess() {
      this.notify.success('notify.default.saved');
    },
    _afterSaveError: function _afterSaveError() {
      this.model.rollbackAttributes();
    }
  });

  _exports.default = _default;
});