define("ntfrontend/components/shared/activity-log/modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "46FdPr6r",
    "block": "{\"symbols\":[\"@task\"],\"statements\":[[7,\"a\"],[11,\"class\",\"icon-activity icon-hover-animation\"],[9],[1,[29,\"t\",[\"activityLog.labels.activityLog\"],null],false],[10],[0,\"\\n\"],[5,\"shared/modal-dialog\",[],[[\"@showModal\",\"@customModal\"],[[24,0,[\"showModal\"]],true]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-dialog\"],[11,\"role\",\"document\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"modal-content\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n        \"],[7,\"h4\"],[11,\"class\",\"modal-title\"],[9],[1,[29,\"t\",[\"activityLog.labels.activityLog\"],null],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"modal-body\"],[9],[0,\"\\n        \"],[5,\"shared/activity-log\",[],[[\"@resource\"],[[24,1,[]]]]],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/activity-log/modal/template.hbs"
    }
  });

  _exports.default = _default;
});