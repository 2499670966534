define("ntfrontend/components/custom-fields/document-list/item-row/item/inline-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    defaultType: 'text',
    fieldType: Ember.computed.alias('customValue.field_type'),
    inputType: Ember.computed(function () {
      return this.fieldType == 'number' ? this.fieldType : this.defaultType;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var value = this.get('customValue.value');
      var newValue = value.length > 5 ? "".concat(value.substr(0, 5), "...") : value;
      this.set('value', newValue);
    }
  });

  _exports.default = _default;
});