define("ntfrontend/components/sidebar-menu/secondary-links/tasks/component", ["exports", "ntfrontend/mixins/components/tooltip"], function (_exports, _tooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tooltip.default, {
    currentSession: Ember.inject.service(),
    tooltipElement: '.dropdown-toggle',
    tooltipPlacement: 'right',
    tooltipTitle: Ember.computed(function () {
      return this.intl.t('shared.labels.tasks');
    }),
    tagName: 'li',
    classNames: ['dropdown'],
    myOverdueTasksCount: Ember.computed.reads('currentSession.account.myOverdueTasks')
  });

  _exports.default = _default;
});