define("ntfrontend/components/shared/transport-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    paneManager: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    classNames: ['transport-list'],
    transportSorting: ['transport.description'],
    sortedTransportDocuments: Ember.computed.sort('model.transportDocuments', 'transportSorting')
  });

  _exports.default = _default;
});