define("ntfrontend/models/milestone", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    name: attr(),
    description: attr(),
    memosCount: attr('number'),
    overdueMemosCount: attr('number'),
    issuesCount: attr('number'),
    overdueIssuesCount: attr('number'),
    requestsCount: attr('number'),
    overdueRequestsCount: attr('number'),
    scenariosCount: attr('number'),
    overdueScenariosCount: attr('number'),
    status: attr('string'),
    progress: attr('number', {
      defaultValue: 0
    }),
    variance: attr('number'),
    startAt: attr('date'),
    deadlineAt: attr('date'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    project: belongsTo(),
    workItems: hasMany('work-item')
  });

  _exports.default = _default;
});