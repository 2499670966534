define("ntfrontend/components/templates/document-templates/edit/component", ["exports", "ntfrontend/mixins/components/model-saving", "ntfrontend/models/document"], function (_exports, _modelSaving, _document) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    can: Ember.inject.service(),
    resourceNames: _document.documentsNames,
    hasSteps: Ember.computed('model.documentType', function () {
      return this.get('model.documentType') === 'Scenario';
    }),
    editable: Ember.computed('model.ability', function () {
      return this.can.can('edit document-template', this.model);
    }),
    actions: {
      selectionsChanged: function selectionsChanged(resource) {
        this.set('model.documentType', resource);
        this.saveModelTask.perform();
      },
      saveModel: function saveModel() {
        this.saveModelTask.perform();
      }
    }
  });

  _exports.default = _default;
});