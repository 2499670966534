define("ntfrontend/helpers/file-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (type) {
    var result = '';
    var formats = {
      'image': /^image[/].+/,
      'pdf': /^application[/](.+|)pdf/,
      'other': ''
    };
    Object.keys(formats).forEach(function (key) {
      var regExp = new RegExp(formats[key]).test(type);

      if (regExp && !result) {
        result = key;
      }
    });
    return "icon-".concat(result);
  });

  _exports.default = _default;
});