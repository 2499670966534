define("ntfrontend/components/templates/document-templates/edit/project-types/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pALWQomq",
    "block": "{\"symbols\":[\"projectType\"],\"statements\":[[4,\"if\",[[29,\"or\",[[25,[\"isEditing\"]],[25,[\"modelProjectTypesEmpty\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"shared/multi-select\",[],[[\"@options\",\"@selected\",\"@placeholder\",\"@onChange\",\"@resourceAsObject\"],[[23,\"projectTypes\"],[23,\"originalprojectTypes\"],[29,\"t\",[\"shared.labels.projectTemplatesPlaceholder\"],null],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"originalprojectTypes\"]]],null]],null],true]]],[0,\"\\n\\n  \"],[5,\"bs-button\",[],[[\"@type\",\"@class\",\"@onClick\"],[\"success\",\"btn-sm\",[29,\"action\",[[24,0,[]],\"finishEditing\"],null]]],{\"statements\":[[0,\"\\n    \"],[1,[29,\"t\",[\"shared.actions.save\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"bs-button\",[],[[\"@type\",\"@class\",\"@onClick\"],[\"warning\",\"btn-sm\",[29,\"action\",[[24,0,[]],\"cancelEditing\"],null]]],{\"statements\":[[0,\"\\n    \"],[1,[29,\"t\",[\"shared.actions.close\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[29,\"if\",[[25,[\"model\"]],\"labels__show\",\"\"],null]],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"inline-block label label-info\"],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[3,\"action\",[[24,0,[]],\"enableEditing\"]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/templates/document-templates/edit/project-types/template.hbs"
    }
  });

  _exports.default = _default;
});