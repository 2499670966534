define("ntfrontend/models/activity-log", ["exports", "ember-data", "ntfrontend/mixins/models/logable"], function (_exports, _emberData, _logable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend(_logable.default, {
    action: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    activityChanges: attr(),
    creator: belongsTo('user'),
    resource: belongsTo('models/logable', {
      polymorphic: true
    })
  });

  _exports.default = _default;
});