define("ntfrontend/models/thread", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    resource: belongsTo('resource', {
      polymorphic: true,
      inverse: 'thread'
    }),
    comments: hasMany(),
    documentTemplates: hasMany()
  });

  _exports.default = _default;
});