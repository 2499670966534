define("ntfrontend/components/users/profile-box/picture/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'inline-block',
    tooltipEnabled: true,
    renderInPlace: true,
    height: 24,
    width: 24,
    fontSize: 40,
    fontWeight: 300,
    fontFamily: 'Source Sans Pro, sans-serif'
  });

  _exports.default = _default;
});