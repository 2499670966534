define("ntfrontend/components/shared/inplace-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bfaVYMoY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"isEditing\"]]],null,{\"statements\":[[0,\"  \"],[15,1,[[29,\"action\",[[24,0,[]],\"escapeSelect\"],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"static-value\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"value\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,[\"text\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"placeholder\"],[9],[1,[29,\"t\",[[25,[\"placeholder\"]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[3,\"action\",[[24,0,[]],\"toggleEditing\"]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/inplace-select/template.hbs"
    }
  });

  _exports.default = _default;
});