define("ntfrontend/components/shared/kanban-view/tiles/scenario/progress/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5R/R44sJ",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"badge badge-primary\"],[12,\"style\",[29,\"kanban-progress\",[[25,[\"openSteps\"]]],null]],[9],[0,\"\\n  \"],[1,[23,\"openSteps\"],false],[0,\"\\n  \"],[5,\"bs-tooltip\",[],[[],[]],{\"statements\":[[1,[29,\"t\",[\"scenario.labels.stepProgress.open\"],null],false]],\"parameters\":[]}],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"badge badge-danger\"],[12,\"style\",[29,\"kanban-progress\",[[25,[\"failedSteps\"]]],null]],[9],[0,\"\\n  \"],[1,[23,\"failedSteps\"],false],[0,\"\\n  \"],[5,\"bs-tooltip\",[],[[],[]],{\"statements\":[[1,[29,\"t\",[\"scenario.labels.stepProgress.failed\"],null],false]],\"parameters\":[]}],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"badge badge-success\"],[12,\"style\",[29,\"kanban-progress\",[[25,[\"doneSteps\"]]],null]],[9],[0,\"\\n  \"],[1,[23,\"doneSteps\"],false],[0,\"\\n  \"],[5,\"bs-tooltip\",[],[[],[]],{\"statements\":[[1,[29,\"t\",[\"scenario.labels.stepProgress.done\"],null],false]],\"parameters\":[]}],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/kanban-view/tiles/scenario/progress/template.hbs"
    }
  });

  _exports.default = _default;
});