define("ntfrontend/components/search-box/dropdown-menu/results/component", ["exports", "ntfrontend/utils/group-by", "ember-keyboard"], function (_exports, _groupBy, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberKeyboard.EKMixin, {
    tagName: '',
    groups: (0, _groupBy.default)('searchResults', 'resourceType'),
    allowSelect: false,
    onChoose: function onChoose() {},
    onSelect: function onSelect() {},
    keyboardActivated: true,
    arrowDownShortcut: Ember.on((0, _emberKeyboard.keyDown)('ArrowDown'), function (e) {
      this._moveSelected(1);

      e.preventDefault();
    }),
    arrowUpShortcut: Ember.on((0, _emberKeyboard.keyDown)('ArrowUp'), function (e) {
      this._moveSelected(-1);

      e.preventDefault();
    }),
    enterShortcut: Ember.on((0, _emberKeyboard.keyDown)('Enter'), function () {
      var activeItem = this.activeItem;

      if (activeItem) {
        this.onChoose(activeItem);
      }
    }),
    resultsList: Ember.computed('groups.[]', function () {
      return this.groups.map(function (group) {
        return group.items;
      }).reduce(function (list, item) {
        return list.concat(item);
      });
    }),
    actions: {
      selectItem: function selectItem(item) {
        var activeItem = this.activeItem,
            allowSelect = this.allowSelect;

        if (item == activeItem || !allowSelect) {
          this.onChoose(item);
        } else {
          this.set('activeItem', item);
          this.onSelect(activeItem);
        }
      }
    },
    // Private
    _moveSelected: function _moveSelected(direction) {
      var resultsList = this.resultsList,
          activeItem = this.activeItem;
      var maxIndex = resultsList.length - 1;
      var activeIndex = resultsList.indexOf(activeItem);
      var newIndex = activeIndex + direction;
      var nextIndex = newIndex;

      if (newIndex < 0) {
        nextIndex = maxIndex;
      } else if (newIndex > maxIndex) {
        nextIndex = 0;
      }

      var nextItem = resultsList[nextIndex];
      this.set('activeItem', nextItem);
      this.onSelect(this.activeItem);
    }
  });

  _exports.default = _default;
});