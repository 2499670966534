define("ntfrontend/components/templates/project-templates/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RxDEKgfH",
    "block": "{\"symbols\":[\"template\"],\"statements\":[[7,\"div\"],[11,\"class\",\"page-header\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[1,[29,\"t\",[\"shared.labels.projectTemplates\"],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-header__menu\"],[9],[0,\"\\n    \"],[5,\"templates/project-templates/new\",[],[[\"@task\",\"@refreshProjectTemplates\"],[[23,\"getTemplates\"],[29,\"action\",[[24,0,[]],\"refreshProjectTemplates\"],null]]]],[0,\"\\n    \"],[5,\"templates/project-templates/import-templates\",[],[[],[]]],[0,\"\\n    \"],[5,\"shared/filters/switcher-filter\",[],[[\"@state\",\"@secondState\",\"@firstState\",\"@documentName\"],[[23,\"published\"],null,true,\"templates\"]]],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"table\"],[11,\"class\",\"table -project-templates\"],[9],[0,\"\\n  \"],[7,\"thead\"],[9],[0,\"\\n    \"],[7,\"th\"],[9],[1,[29,\"t\",[\"shared.labels.creator\"],null],false],[10],[0,\"\\n    \"],[5,\"shared/header-sort\",[],[[\"@label\",\"@sort\",\"@name\"],[\"shared.labels.name\",[23,\"sort\"],\"name\"]]],[0,\"\\n    \"],[5,\"shared/header-sort\",[],[[\"@label\",\"@sort\",\"@name\"],[\"shared.labels.createdAt\",[23,\"sort\"],\"createdAt\"]]],[0,\"\\n    \"],[5,\"shared/header-sort\",[],[[\"@label\",\"@sort\",\"@name\"],[\"shared.labels.thumbnail\",[23,\"sort\"],\"thumbnail\"]]],[0,\"\\n    \"],[5,\"shared/header-sort\",[],[[\"@label\",\"@sort\",\"@name\"],[\"shared.labels.description\",[23,\"sort\"],\"description\"]]],[0,\"\\n    \"],[7,\"th\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"templates\"]]],null,{\"statements\":[[0,\"      \"],[5,\"templates/project-templates/item\",[],[[\"@model\"],[[24,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/templates/project-templates/template.hbs"
    }
  });

  _exports.default = _default;
});