define("ntfrontend/components/works/sneak-peek/create-work-log-sneak-peek/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l7QOXpCa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"sidebar__content\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"sidebar__header\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"page-header\"],[9],[0,\"\\n      \"],[7,\"h3\"],[11,\"class\",\"h3\"],[9],[0,\"\\n        \"],[1,[29,\"t\",[\"work.labels.newWorkLog\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"icon-clear sidebar__close\"],[9],[3,\"action\",[[24,0,[]],\"hidePane\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"h4\"],[9],[1,[29,\"t\",[\"shared.labels.workItem\"],null],false],[10],[0,\"\\n  \"],[5,\"works/create-tab/create-work\",[],[[\"@isSeakPeek\",\"@selectedProject\",\"@date\",\"@workItem\",\"@hidePane\",\"@refreshWorkItems\"],[true,[24,0,[\"selectedProject\"]],[24,0,[\"workDate\"]],[24,0,[\"workItem\"]],[29,\"action\",[[24,0,[]],\"hidePane\"],null],[29,\"action\",[[24,0,[]],\"refreshWorkItems\"],null]]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/works/sneak-peek/create-work-log-sneak-peek/template.hbs"
    }
  });

  _exports.default = _default;
});