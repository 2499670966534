define("ntfrontend/components/documents/new-document/document-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9ifENWW/",
    "block": "{\"symbols\":[],\"statements\":[[5,\"shared/form-input\",[],[[\"@errors\"],[[25,[\"newObject\",\"errors\",\"name\"]]]],{\"statements\":[[0,\"\\n  \"],[5,\"shared/material-labels\",[],[[\"@labelFor\",\"@value\",\"@placeholder\"],[\"input-name\",[25,[\"tempObject\",\"name\"]],[29,\"t\",[\"shared.placeholders.name\"],null]]],{\"statements\":[[0,\"\\n    \"],[1,[29,\"input\",null,[[\"autofocus\",\"class\",\"id\",\"required\",\"type\",\"value\"],[\"true\",\"form-control input-label\",\"input-name\",\"true\",\"text\",[25,[\"tempObject\",\"name\"]]]]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/documents/new-document/document-name/template.hbs"
    }
  });

  _exports.default = _default;
});