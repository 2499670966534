define("ntfrontend/components/dashboard/user-profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0d66mSbv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"dashboard__profile-info\"],[9],[0,\"\\n  \"],[5,\"link-to\",[],[[\"@route\",\"@class\"],[\"user.edit\",\"dashboard__profile-edit\"]],{\"statements\":[[0,\"\\n    \"],[5,\"users/profile-box/picture\",[],[[\"@user\",\"@height\",\"@width\"],[[23,\"user\"],\"80\",\"80\"]]],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"dashboard__profile-edit-overlay\"],[9],[7,\"span\"],[11,\"class\",\"dashboard__profile-edit-icon icon-pencil\"],[9],[10],[10],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"h1\"],[11,\"class\",\"dashboard__profile-name\"],[9],[0,\"\\n    \"],[1,[25,[\"user\",\"name\"]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"text-muted\"],[9],[0,\"\\n    \"],[1,[25,[\"user\",\"email\"]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"span\"],[12,\"class\",[30,[\"dashboard__detail label \",[29,\"role-indicator\",[[25,[\"user\",\"roleInOrganization\"]]],null]]]],[9],[0,\"\\n    \"],[1,[29,\"role-name\",[[25,[\"user\",\"roleInOrganization\"]]],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/dashboard/user-profile/template.hbs"
    }
  });

  _exports.default = _default;
});