define("ntfrontend/components/projects/project-filters/status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PHA7+iRr",
    "block": "{\"symbols\":[\"( unused value )\",\"statusValue\"],\"statements\":[[7,\"label\"],[11,\"class\",\"text-muted filter-name\"],[9],[0,\"\\n  \"],[1,[29,\"t\",[\"shared.labels.status\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"dropdown inplace-editing\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"static-value\"],[11,\"data-toggle\",\"dropdown\"],[11,\"aria-haspopup\",\"true\"],[11,\"aria-expanded\",\"true\"],[11,\"type\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"value\"]]],null,{\"statements\":[[0,\"      \"],[5,\"shared/property-label\",[],[[\"@list\",\"@property\",\"@translationScope\",\"@coloredStatus\"],[[23,\"statuses\"],[23,\"value\"],\"project.statuses\",true]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[29,\"t\",[\"shared.labels.all\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"dropdown-menu\"],[9],[0,\"\\n    \"],[7,\"li\"],[9],[7,\"a\"],[9],[1,[29,\"t\",[\"shared.labels.all\"],null],false],[3,\"action\",[[24,0,[]],\"resetFilter\"]],[10],[10],[0,\"\\n\"],[4,\"each\",[[29,\"-each-in\",[[25,[\"statuses\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[0,\"\\n        \"],[7,\"a\"],[9],[0,\"\\n          \"],[5,\"shared/property-label\",[],[[\"@list\",\"@property\",\"@translationScope\"],[[23,\"statuses\"],[24,2,[]],\"project.statuses\"]]],[0,\"\\n        \"],[3,\"action\",[[24,0,[]],\"changeStatus\",[24,2,[]]]],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/project-filters/status/template.hbs"
    }
  });

  _exports.default = _default;
});