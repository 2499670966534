define("ntfrontend/helpers/human-file-size", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (bytes, si) {
    var thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return "".concat(bytes, "B");
    }

    var siTable = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var nonSiTable = ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    var units = si ? siTable : nonSiTable;
    var u = -1;

    while (true) {
      bytes /= thresh;
      ++u;

      if (!(Math.abs(bytes) >= thresh && u < units.length - 1)) {
        break;
      }
    }

    return "".concat(bytes.toFixed(1)).concat(units[u]);
  });

  _exports.default = _default;
});