define("ntfrontend/components/shared/actions/more-actions-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VygSeKE5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"icon-menu dropdown-toggle actions-menu\"],[11,\"data-toggle\",\"dropdown\"],[11,\"aria-haspopup\",\"true\"],[11,\"aria-expanded\",\"true\"],[11,\"type\",\"button\"],[9],[0,\"\\n  \"],[5,\"bs-tooltip\",[],[[\"@title\"],[[29,\"t\",[\"shared.actions.moreActions\"],null]]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/actions/more-actions-button/template.hbs"
    }
  });

  _exports.default = _default;
});