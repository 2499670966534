define("ntfrontend/components/shared/filters/project-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2QiEv3WF",
    "block": "{\"symbols\":[\"type\"],\"statements\":[[4,\"if\",[[25,[\"projectTypes\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[11,\"class\",\"text-muted filter-name\"],[9],[0,\"\\n    \"],[1,[29,\"t\",[\"shared.labels.projectType\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"a\"],[11,\"class\",\"dropdown-toggle\"],[11,\"data-toggle\",\"dropdown\"],[11,\"aria-haspopup\",\"true\"],[11,\"aria-expanded\",\"true\"],[11,\"type\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"projectTypes\",\"isFulfilled\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,\"currentName\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"openDropdown\"]]],null,{\"statements\":[[0,\"    \"],[7,\"ul\"],[11,\"class\",\"dropdown-menu\"],[9],[0,\"\\n      \"],[5,\"shared/loading-state/block\",[],[[\"@model\",\"@tagName\"],[[23,\"projectTypes\"],\"\"]],{\"statements\":[[0,\"\\n        \"],[7,\"li\"],[9],[7,\"a\"],[9],[1,[29,\"t\",[\"shared.filters.all\"],null],false],[3,\"action\",[[24,0,[]],\"changeFilter\",null]],[10],[10],[0,\"\\n\"],[4,\"each\",[[25,[\"projectTypes\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\"],[9],[7,\"a\"],[9],[1,[24,1,[\"name\"]],false],[3,\"action\",[[24,0,[]],\"changeFilter\",[24,1,[\"id\"]]]],[10],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/filters/project-type/template.hbs"
    }
  });

  _exports.default = _default;
});