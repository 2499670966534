define("ntfrontend/components/shared/inplace-input/component", ["exports", "ntfrontend/mixins/components/inplace/editable", "ntfrontend/mixins/components/inplace/focusable", "ntfrontend/mixins/components/inplace/placeholder"], function (_exports, _editable, _focusable, _placeholder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_editable.default, _focusable.default, _placeholder.default, {
    required: true,
    type: 'text',
    actions: {
      submitFocusOut: function submitFocusOut() {
        if (window.navigator.userAgent.indexOf('Firefox') !== -1) {
          this.send('doneEditing');
        }
      }
    }
  });

  _exports.default = _default;
});