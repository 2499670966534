define("ntfrontend/mixins/components/intro-component", ["exports", "jquery", "ember-introjs/intro-js"], function (_exports, _jquery, _introJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    tagName: 'a',
    shouldStart: true,
    intro: Ember.computed('currentSession.user.intro', function () {
      return this.get('currentSession.user.intro') || Ember.RSVP.resolve();
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.shouldStart && this.introObserver() && this.shouldStartObserver();
    },
    introObserver: Ember.observer('intro', function () {
      var _this = this;

      this.intro.then(function (intro) {
        if (intro && intro.get(_this.introScope)) {
          Ember.run.scheduleOnce('afterRender', _this, function () {
            _this._showIntro();
          });
        }
      });
    }),
    shouldStartObserver: Ember.observer('shouldStart', function () {
      var _this2 = this;

      this.intro.then(function (intro) {
        if (intro && intro.get(_this2.introScope) && _this2.shouldStart) {
          Ember.run.scheduleOnce('afterRender', _this2, function () {
            _this2._showIntro();
          });
        }
      });
    }),
    click: function click() {
      this._showIntro();
    },
    onExit: function onExit() {
      this.set('introIsRunning', false);
    },
    bindIntroStyle: function bindIntroStyle() {},
    _showIntro: function _showIntro() {
      var _this3 = this;

      var intro = (0, _introJs.default)();
      intro.setOption('doneLabel', this.intl.t('intro.done'));
      intro.setOption('skipLabel', this.intl.t('intro.skip'));
      intro.onexit(Ember.run.bind(this, this.onExit));
      this.bindIntroStyle(intro);
      intro.start();
      this.set('introIsRunning', true);
      this.set('introInstance', intro);
      this.set('gotItButton', (0, _jquery.default)('.introjs-skipbutton, .introjs-donebutton'));
      this.gotItButton.on('click', function () {
        _this3._disableIntro();
      });
    },
    willDestroyElement: function willDestroyElement() {
      if (this.gotItButton) {
        this.gotItButton.off('click');
      }

      if (this.introInstance) {
        this.introInstance.exit();
      }

      this._super.apply(this, arguments);
    },
    _disableIntro: function _disableIntro() {
      var _this4 = this;

      this.intro.then(function (intro) {
        if (intro && intro.get(_this4.introScope)) {
          intro.set(_this4.introScope, false);
          intro.save();
        }
      });
    }
  });

  _exports.default = _default;
});