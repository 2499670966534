define("ntfrontend/components/auth/auth-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eKRhXWrY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"currentSession\",\"loggedIn\"]]],null,{\"statements\":[[0,\"  \"],[5,\"link-to\",[],[[\"@route\"],[\"auth.logout\"]],{\"statements\":[[1,[29,\"t\",[\"auth.logout\"],null],false]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"link-to\",[],[[\"@route\"],[\"auth.login\"]],{\"statements\":[[1,[29,\"t\",[\"auth.signIn\"],null],false]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/auth/auth-button/template.hbs"
    }
  });

  _exports.default = _default;
});