define("ntfrontend/components/transports/sneak-peek-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    paneManager: Ember.inject.service(),
    classNames: null,
    tagName: '',
    actions: {
      openSneakPeek: function openSneakPeek(transport) {
        this.set('paneManager.showPane', true);
        this.set('paneManager.sneakPeekTransport', transport);
        this.set('paneManager.selectedSneak', 'transport');
      }
    }
  });

  _exports.default = _default;
});