define("ntfrontend/components/documents/distribute-document/distribute-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jTSk4j8V",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[29,\"can\",[[25,[\"distributeAbility\"]],[25,[\"document\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[12,\"class\",[30,[\"btn-success btn-block text-center icon-plane distribute-memo \",[29,\"if\",[[25,[\"disabled\"]],\"disabled\"],null]]]],[9],[0,\"\\n    \"],[1,[29,\"t\",[[25,[\"buttonLabel\"]]],null],false],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"openModal\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[5,\"documents/distribute-document\",[],[[\"@document\",\"@showModal\"],[[23,\"document\"],[23,\"showModal\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/documents/distribute-document/distribute-button/template.hbs"
    }
  });

  _exports.default = _default;
});