define("ntfrontend/components/shared/activity-log/changes/status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jKjjkpJa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h5\"],[9],[1,[29,\"t-scope\",[[25,[\"changeName\"]],\"activityLog.fields\"],null],false],[0,\":\"],[10],[0,\"\\n\"],[7,\"div\"],[9],[7,\"span\"],[11,\"class\",\"text-muted\"],[9],[1,[29,\"t\",[\"activityLog.timePeriods.from\"],null],false],[0,\":\"],[10],[0,\" \"],[7,\"b\"],[9],[1,[29,\"t-scope\",[[25,[\"changes\",\"old\"]],\"activityLog.status\"],null],false],[10],[10],[0,\"\\n\"],[7,\"div\"],[9],[7,\"span\"],[11,\"class\",\"text-muted\"],[9],[1,[29,\"t\",[\"activityLog.timePeriods.to\"],null],false],[0,\":\"],[10],[0,\" \"],[7,\"b\"],[9],[1,[29,\"t-scope\",[[25,[\"changes\",\"new\"]],\"activityLog.status\"],null],false],[10],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/activity-log/changes/status/template.hbs"
    }
  });

  _exports.default = _default;
});