define("ntfrontend/utils/date-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pastDate = pastDate;
  _exports.currentYear = currentYear;
  _exports.isMidnight = isMidnight;

  function pastDate(date) {
    return moment(new Date(date)).isBefore(new Date());
  }

  function currentYear(date) {
    return moment(new Date(date)).get('year') === moment().get('year');
  }

  function isMidnight(date) {
    return moment(new Date(date)).hour() === 0;
  }
});