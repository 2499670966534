define("ntfrontend/components/projects/dashboard/task-assignments/assignment-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    overdueWidth: Ember.computed('model.overdueTasksCount', function () {
      return Ember.String.htmlSafe("width: ".concat(this._percentageFor('overdueTasksCount'), "%"));
    }),
    inProgressWidth: Ember.computed('model.inProgressTasksCount', function () {
      return Ember.String.htmlSafe("width: ".concat(this._percentageFor('inProgressTasksCount'), "%"));
    }),
    _percentageFor: function _percentageFor(property) {
      if (this.max > 0) {
        return parseInt(this.get("model.".concat(property)) / this.max * 100);
      }
    }
  });

  _exports.default = _default;
});