define("ntfrontend/templates/account/templates/documents/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6vS6sdQG",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"page-title\",[[29,\"t\",[\"shared.labels.templates\"],null]],null],false],[0,\"\\n\\n\"],[5,\"templates/document-templates/edit\",[],[[\"@model\"],[[23,\"model\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/account/templates/documents/edit.hbs"
    }
  });

  _exports.default = _default;
});