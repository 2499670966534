define("ntfrontend/components/tasks/filters/status-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['filter', 'dropdown'],
    statuses: Ember.computed(function () {
      var _this = this;

      return ['all', 'true', 'false'].map(function (name) {
        return _this.buildStatusItem(name);
      });
    }),
    currentStatusName: Ember.computed('status', function () {
      if (this.status) {
        return this.statuses.findBy('name', this.status).label;
      } else {
        return 'All';
      }
    }),
    buildStatusItem: function buildStatusItem(name) {
      return {
        name: name,
        label: this.intl.t("task.labels.completed.".concat(name))
      };
    },
    actions: {
      changeStatus: function changeStatus(statusName) {
        this.set('status', statusName);
      }
    }
  });

  _exports.default = _default;
});