define("ntfrontend/components/projects/add-new-project/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gGfuSfpI",
    "block": "{\"symbols\":[],\"statements\":[[5,\"shared/form-input\",[],[[\"@errors\"],[[25,[\"newProject\",\"errors\",\"name\"]]]],{\"statements\":[[0,\"\\n  \"],[5,\"shared/material-labels\",[],[[\"@labelFor\",\"@placeholder\",\"@value\"],[\"inputProjectName\",[29,\"t\",[\"project.name.placeholder\"],null],[25,[\"project\",\"name\"]]]],{\"statements\":[[0,\"\\n    \"],[5,\"input\",[],[[\"@autofocus\",\"@class\",\"@id\",\"@placeholder\",\"@required\",\"@type\",\"@value\"],[\"true\",\"form-control input-label\",\"inputProjectName\",[29,\"t\",[\"project.name.placeholder\"],null],\"true\",\"text\",[25,[\"project\",\"name\"]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/add-new-project/form/template.hbs"
    }
  });

  _exports.default = _default;
});