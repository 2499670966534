define("ntfrontend/components/comments/new-comment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EQsDuQba",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[11,\"class\",\"flexbox\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex--grow inplace-bordered\"],[9],[0,\"\\n    \"],[5,\"shared/wysiwyg-editor\",[],[[\"@value\",\"@toolbar\",\"@disabled\",\"@placeholderKey\",\"@focus\"],[[23,\"body\"],false,[25,[\"addNewComment\",\"isRunning\"]],\"comment.placeholders.addComment\",false]]],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[5,\"shared/form-confirm-button\",[],[[\"@disabled\",\"@label\"],[[23,\"disabled\"],[29,\"t\",[\"comment.actions.create\"],null]]]],[0,\"\\n\"],[3,\"action\",[[24,0,[]],[29,\"perform\",[[25,[\"addNewComment\"]]],null]],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/comments/new-comment/template.hbs"
    }
  });

  _exports.default = _default;
});