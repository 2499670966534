define("ntfrontend/mixins/components/tooltip", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    tooltipTitle: '',
    tooltipPlacement: 'auto',
    tooltipTrigger: 'focus hover',
    tooltipElement: '',
    tooltipRefresh: true,
    tooltipEnabled: true,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.tooltipObserver();
    },
    willDestroyElement: function willDestroyElement() {
      if (this.tooltipEnabled) {
        this.destroyTooltip(this.tooltipElement);
      }

      this._super.apply(this, arguments);
    },
    tooltipInit: function tooltipInit() {
      this.createTooltip(this.tooltipElement, {
        placement: this.tooltipPlacement,
        trigger: this.tooltipTrigger,
        title: this.tooltipTitle
      });
    },
    tooltipObserver: Ember.observer('tooltipEnabled', function () {
      if (this.tooltipEnabled) {
        this.tooltipInit();
      } else {
        this.destroyTooltip(this.tooltipElement);
      }
    }),
    tooltipChanged: Ember.observer('tooltipTitle', function () {
      var tooltipElement = (0, _jquery.default)(this.tooltipElement, this.element);
      tooltipElement.attr('data-original-title', this.tooltipTitle);

      if (this.tooltipRefresh && (0, _jquery.default)(this.element).is(':hover')) {
        tooltipElement.tooltip('show');
      } else {
        tooltipElement.tooltip('show').tooltip('hide');
      }
    }),
    createTooltip: function createTooltip(selector, options) {
      (0, _jquery.default)(selector, this.element).tooltip(options);
    },
    destroyTooltip: function destroyTooltip(selector) {
      (0, _jquery.default)(selector, this.element).tooltip('destroy');
    }
  });

  _exports.default = _default;
});