define("ntfrontend/services/notify-alert", ["exports", "izitoast"], function (_exports, _izitoast) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service('intl'),
    success: function success(message, title) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var titleKey = title || 'notify.default.success.title';
      var messageKey = message || 'notify.default.success.message';

      if (!options.withoutTitle) {
        return this.notify('success', titleKey, messageKey);
      } else {
        return this.simpleNotify('success', messageKey);
      }
    },
    successWithUsers: function successWithUsers(message, title) {
      var members = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {
        type: 'info'
      };

      var users = this._membersUsers(members);

      var titleKey = title || 'notify.default.success.title';
      var messageKey = message || 'notify.default.success.message';

      var params = this._setLayoutParams(options.type);

      return _izitoast.default.show({
        color: params.color,
        closeOnClick: true,
        icon: params.icon,
        layout: 2,
        message: "".concat(this.intl.t(messageKey), "\n      <div class=\"notify-user-icons\">\n      ").concat(this._distributedUsersAvatars(users), "\n      </div>"),
        title: this.intl.t(titleKey)
      });
    },
    _setLayoutParams: function _setLayoutParams(type) {
      switch (type) {
        case 'info':
          return {
            color: 'blue',
            icon: 'icon-info'
          };

        case 'success':
          return {
            color: 'green',
            icon: 'icon-success'
          };

        case 'warning':
          return {
            color: 'yellow',
            icon: 'icon-warning'
          };

        case 'danger':
          return {
            color: 'red',
            icon: 'icon-danger'
          };
      }
    },
    error: function error(message, title) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var titleKey = title || 'notify.default.error.title';
      var messageKey = message || 'notify.default.error.message';
      return this.notify('danger', titleKey, messageKey, options);
    },
    info: function info(message, title) {
      var titleKey = title || 'notify.default.info.title';
      var messageKey = message || 'notify.default.info.message';
      return this.notify('info', titleKey, messageKey);
    },
    warning: function warning(message, title) {
      var titleKey = title || 'notify.default.warning.title';
      var messageKey = message || 'notify.default.warning.message';
      return this.notify('warning', titleKey, messageKey);
    },
    notify: function notify(notifyType, titleKey, messageKey) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

      var params = this._setLayoutParams(notifyType);

      return _izitoast.default.show({
        closeOnClick: true,
        color: params.color,
        icon: params.icon,
        layout: 2,
        message: this.intl.t(messageKey, options),
        title: this.intl.t(titleKey)
      });
    },
    simpleNotify: function simpleNotify(type, message) {
      var params = this._setLayoutParams(type);

      return _izitoast.default.show({
        closeOnClick: true,
        color: params.color,
        icon: params.icon,
        message: this.intl.t(message)
      });
    },
    upload: function upload(message, title) {
      var timeout = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2000;
      var titleKey = title || 'notify.default.upload.progress';

      var params = this._setLayoutParams('info');

      return _izitoast.default.show({
        timeout: timeout,
        closeOnClick: true,
        color: params.color,
        icon: params.icon,
        message: '',
        progressbar: true,
        title: this.intl.t(titleKey)
      });
    },
    destroyNotify: function destroyNotify() {
      _izitoast.default.destroy();
    },
    socketNotify: function socketNotify(response) {
      var type = response.type,
          title = response.title,
          message = response.message;

      var params = this._setLayoutParams(type);

      return _izitoast.default.show({
        title: title,
        message: message,
        closeOnClick: true,
        color: params.color,
        icon: params.icon,
        position: 'topRight'
      });
    },
    _distributedUsersAvatars: function _distributedUsersAvatars(users) {
      var _this = this;

      return users.slice(0, 5).map(function (user) {
        return "<img class=\"img-circle\" title=\"".concat(user.get('name'), "\" width=\"24\" height=\"24\" src=\"").concat(_this._generateAvatar(user), "\">");
      }).join(' ');
    },
    _generateAvatar: function _generateAvatar(user) {
      var component = Ember.getOwner(this).lookup('component:ember-initials');
      component.setProperties({
        name: user.get('displayName'),
        seedText: user.get('email'),
        size: 24,
        fontSize: 40,
        fontWeight: 300,
        fontFamily: 'Source Sans Pro, sans-serif'
      });
      return component.get('src');
    },
    _membersUsers: function _membersUsers(objects) {
      var members = Array.isArray(objects) ? objects : Array(objects);
      return members.getEach('user');
    }
  });

  _exports.default = _default;
});