define("ntfrontend/components/custom-fields/assignments/assignment/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'col-xs-1',
    store: Ember.inject.service(),
    assignment: null,
    assignmentStatus: Ember.computed.readOnly('assignment'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._findRecord();
    },
    click: function click() {
      if (this.assignment) {
        this.assignment.destroyRecord();
        this.set('assignment', null);
      } else {
        this.set('assignment', this.store.createRecord('customFieldAssignment', this._customFieldAssignmentOptions()));
      }
    },
    _customFieldAssignmentOptions: function _customFieldAssignmentOptions() {
      var customField = this.customField,
          projectType = this.projectType,
          resourceType = this.resourceType;
      return {
        customField: customField,
        resourceType: resourceType,
        projectType: projectType
      };
    },
    _findRecord: function _findRecord() {
      var _this = this;

      var params = {
        filter: {
          projectTypeId: this.get('projectType.id'),
          customFieldId: this.get('customField.id'),
          resourceType: this.resourceType
        }
      };

      if (this.get('customField.id')) {
        this.store.query('customFieldAssignment', params).then(function (assignments) {
          return _this.element && _this.set('assignment', assignments.get('firstObject'));
        });
      } else {
        this.set('assignment', null);
      }
    }
  });

  _exports.default = _default;
});