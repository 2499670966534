define("ntfrontend/components/works/plan-tab/milestone-list/workpackage-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    progress: Ember.computed('workPackageSummary.{hoursLogged,hoursEstimated}', function () {
      var _this$workPackageSumm = this.workPackageSummary,
          hoursLogged = _this$workPackageSumm.hoursLogged,
          hoursEstimated = _this$workPackageSumm.hoursEstimated;
      return Math.round(hoursLogged / hoursEstimated * 100);
    }),
    variance: Ember.computed('workPackageSummary.{hoursLogged,hoursEstimated}', function () {
      var _this$workPackageSumm2 = this.workPackageSummary,
          hoursPlanned = _this$workPackageSumm2.hoursPlanned,
          hoursEstimated = _this$workPackageSumm2.hoursEstimated;
      return Math.round((hoursEstimated - hoursPlanned) * 100 / hoursPlanned);
    })
  });

  _exports.default = _default;
});