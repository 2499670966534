define("ntfrontend/components/documents/documents-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wrQw22xT",
    "block": "{\"symbols\":[\"object\",\"object\",\"@hide\",\"&default\"],\"statements\":[[7,\"thead\"],[9],[0,\"\\n  \"],[7,\"tr\"],[9],[0,\"\\n    \"],[15,4],[0,\"\\n    \"],[7,\"th\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"itemsInTbody\"]]],null,{\"statements\":[[0,\"  \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"objects\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"component\",[[25,[\"listComponent\"]]],[[\"model\",\"options\"],[[24,2,[]],[25,[\"options\"]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[25,[\"objects\"]]],null,{\"statements\":[[4,\"if\",[[24,3,[]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"component\",[[25,[\"listComponent\"]]],[[\"model\",\"options\",\"filterTimeScope\",\"project\",\"class\"],[[24,1,[]],[25,[\"options\"]],[25,[\"filterTimeScope\"]],[25,[\"project\"]],\"hide-summary\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[29,\"component\",[[25,[\"listComponent\"]]],[[\"model\",\"options\",\"filterTimeScope\",\"project\"],[[24,1,[]],[25,[\"options\"]],[25,[\"filterTimeScope\"]],[25,[\"project\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/documents/documents-list/template.hbs"
    }
  });

  _exports.default = _default;
});