define("ntfrontend/components/work-logs/kanban-list/column-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XdWNBiz6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[9],[0,\"\\n  \"],[1,[29,\"t\",[\"work.kanban.total\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"span\"],[9],[0,\"\\n  \"],[1,[29,\"time-scope\",[[25,[\"totalHours\"]],[25,[\"filterTimeScope\"]]],null],false],[0,\" \"],[1,[29,\"t\",[\"work.labels.timeScope\"],[[\"scope\"],[[25,[\"filterTimeScope\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/work-logs/kanban-list/column-summary/template.hbs"
    }
  });

  _exports.default = _default;
});