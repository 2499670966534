define("ntfrontend/components/works/plan-tab/actions/static-tooltip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aW4354n7",
    "block": "{\"symbols\":[\"@shouldShowTooltip\",\"@translation\"],\"statements\":[[4,\"if\",[[24,1,[]]],null,{\"statements\":[[0,\"  \"],[5,\"bs-tooltip\",[],[[\"@visible\"],[[24,1,[]]]],{\"statements\":[[0,\"\\n    \"],[1,[29,\"t\",[[24,2,[]]],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/works/plan-tab/actions/static-tooltip/template.hbs"
    }
  });

  _exports.default = _default;
});