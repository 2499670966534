define("ntfrontend/abilities/application", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    canEdit: Ember.computed.readOnly('model.ability.actions.update'),
    canRemove: Ember.computed.readOnly('model.ability.actions.destroy')
  });

  _exports.default = _default;
});