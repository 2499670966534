define("ntfrontend/routes/account/templates/documents/edit", ["exports", "ntfrontend/routes/org-auth"], function (_exports, _orgAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend({
    model: function model(params) {
      return this.store.findRecord('documentTemplate', params.template_id, {
        include: 'ability,creator'
      });
    }
  });

  _exports.default = _default;
});