define("ntfrontend/components/shared/filters/system-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SFA9Xw0O",
    "block": "{\"symbols\":[\"systems\",\"@options\"],\"statements\":[[7,\"label\"],[11,\"class\",\"text-muted filter-name\"],[9],[0,\"\\n  \"],[1,[29,\"t\",[\"transport.placeholders.system\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[5,\"power-select-multiple-with-create\",[],[[\"@options\",\"@selected\",\"@placeholder\",\"@onchange\",\"@triggerClass\",\"@oncreate\"],[[24,2,[]],[24,0,[\"selectedSystems\"]],[29,\"t\",[\"shared.filters.all\"],null],[29,\"action\",[[24,0,[]],\"onChange\"],null],[24,0,[\"triggerClass\"]],[29,\"action\",[[24,0,[]],\"onCreate\"],null]]],{\"statements\":[[0,\"\\n  \"],[1,[24,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/filters/system-filter/template.hbs"
    }
  });

  _exports.default = _default;
});