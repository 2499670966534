define("ntfrontend/components/projects/project-details/detail-description/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SYxilxyt",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[29,\"can\",[\"show detail of project\",[25,[\"model\"]]],[[\"detail\"],[[25,[\"value\"]]]]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"text-muted\"],[9],[1,[29,\"t\",[[25,[\"labelKey\"]]],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[5,\"shared/inplace-editor\",[],[[\"@value\",\"@afterChange\",\"@editable\",\"@placeholderKey\"],[[23,\"value\"],[29,\"action\",[[24,0,[]],\"saveModel\"],null],[29,\"can\",[\"edit project\",[25,[\"model\"]]],null],[23,\"placeholderKey\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/project-details/detail-description/template.hbs"
    }
  });

  _exports.default = _default;
});