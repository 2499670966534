define("ntfrontend/mixins/components/prevent-default-dragging-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    dragEnter: function dragEnter(e) {
      this.preventDefaultEvents(e);
    },
    dragOver: function dragOver(e) {
      this.preventDefaultEvents(e);
    },
    dragLeave: function dragLeave(e) {
      this.preventDefaultEvents(e);
    },
    drop: function drop(e) {
      this.preventDefaultEvents(e);
    },
    dragEnd: function dragEnd(e) {
      this.preventDefaultEvents(e);
    },
    preventDefaultEvents: function preventDefaultEvents(e) {
      e.preventDefault();
      e.stopPropagation();
    }
  });

  _exports.default = _default;
});