define("ntfrontend/components/tasks/task-list/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+2V5lO41",
    "block": "{\"symbols\":[],\"statements\":[[7,\"tr\"],[12,\"class\",[29,\"if\",[[25,[\"taskSelected\"]],\"-highlighted\"],null]],[9],[0,\"\\n  \"],[7,\"td\"],[9],[5,\"tasks/task-sneak-peek/deadline-date\",[],[[\"@model\"],[[23,\"model\"]]]],[10],[0,\"\\n  \"],[7,\"td\"],[9],[5,\"tasks/task-switcher\",[],[[\"@model\",\"@class\",\"@editable\"],[[23,\"model\"],\"bootstrap-switch-sm\",[29,\"can\",[\"edit task\",[25,[\"model\"]]],null]]]],[10],[0,\"\\n  \"],[7,\"td\"],[9],[5,\"users/profile-box/picture\",[],[[\"@user\",\"@width\",\"@height\"],[[25,[\"model\",\"owner\"]],\"30\",\"30\"]]],[10],[0,\"\\n  \"],[7,\"td\"],[9],[5,\"users/profile-box/picture\",[],[[\"@user\",\"@width\",\"@height\"],[[25,[\"model\",\"requester\"]],\"30\",\"30\"]]],[10],[0,\"\\n  \"],[7,\"td\"],[9],[5,\"tasks/sneak-peek-button\",[],[[\"@model\"],[[23,\"model\"]]]],[10],[0,\"\\n  \"],[7,\"td\"],[9],[0,\"\\n    \"],[5,\"shared/document-task-redirect\",[],[[\"@model\",\"@documentRoute\"],[[23,\"model\"],[23,\"documentRoute\"]]],{\"statements\":[[0,\"\\n      \"],[7,\"div\"],[12,\"class\",[29,\"search-group-icon\",[[25,[\"model\",\"resourceDocumentType\"]]],null]],[9],[1,[25,[\"model\",\"resourceDocumentName\"]],false],[10],[0,\"\\n      \"],[1,[25,[\"model\",\"documentName\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/tasks/task-list/item/template.hbs"
    }
  });

  _exports.default = _default;
});