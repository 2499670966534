define("ntfrontend/components/transports/dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/Bn33vvq",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[5,\"shared/actions/more-actions-button\",[],[[],[]]],[0,\"\\n\\n\"],[4,\"if\",[[24,0,[\"openDropdown\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\"],[11,\"class\",\"dropdown-menu dropdown-menu-right\"],[9],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"\\n      \"],[5,\"transports/sneak-peek-button\",[],[[\"@model\"],[[24,1,[]]]],{\"statements\":[[0,\"\\n        \"],[1,[29,\"t\",[\"shared.labels.details\"],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"if\",[[29,\"can\",[\"create transports in account\",[24,0,[\"currentSession\",\"account\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[5,\"shared/confirm-remove-link\",[],[[\"@afterConfirm\"],[[29,\"action\",[[24,0,[]],\"remove\"],null]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"li\"],[9],[5,\"documents/archive-button\",[],[[\"@model\"],[[24,1,[]]]]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/transports/dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});