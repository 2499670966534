define("ntfrontend/abilities/document", ["exports", "ntfrontend/abilities/resourcify"], function (_exports, _resourcify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resourcify.default.extend({
    canClone: Ember.computed.readOnly('model.ability.actions.clone'),
    canArchive: Ember.computed.readOnly('model.ability.actions.archive'),
    canUnarchive: Ember.computed.readOnly('model.ability.actions.unarchive'),
    canCreatePdf: Ember.computed.readOnly('model.ability.actions.createPdf'),
    canDistribute: Ember.computed.readOnly('model.ability.actions.distribute'),
    canViewAccesses: Ember.computed.readOnly('model.ability.actions.distribute'),
    canCreateLabels: Ember.computed.readOnly('model.ability.actions.createLabels'),
    canImportTemplate: Ember.computed.readOnly('model.ability.actions.importTemplate'),
    canUpdateCustomValues: Ember.computed.readOnly('model.ability.actions.updateCustomValues')
  });

  _exports.default = _default;
});