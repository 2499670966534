define("ntfrontend/components/shared/filters/date-range-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2WR2/qSk",
    "block": "{\"symbols\":[\"dropdown\",\"calendar\"],\"statements\":[[7,\"label\"],[11,\"class\",\"text-muted filter-name\"],[9],[0,\"\\n  \"],[1,[29,\"t\",[\"shared.labels.period\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[5,\"basic-dropdown\",[],[[\"@onClose\"],[[29,\"action\",[[24,0,[]],\"close\"],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"date-range\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"inplace-editing\"],[9],[0,\"\\n      \"],[7,\"div\"],[12,\"data-ebd-id\",[30,[[24,1,[\"uniqueId\"]],\"-trigger\"]]],[11,\"class\",\"datepicker-input static-value\"],[12,\"onclick\",[24,1,[\"actions\",\"toggle\"]]],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"icon-calendar\"],[9],[0,\"\\n          \"],[1,[24,0,[\"dateRange\"]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[6,[24,1,[\"content\"]],[[13,\"class\",\"datepicker-dropdown\"]],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"power-calendar-range\",[],[[\"@class\",\"@center\",\"@selected\",\"@minRange\",\"@onCenterChange\",\"@onSelect\"],[\"calendar-small filter\",[24,0,[\"center\"]],[24,0,[\"range\"]],0,[29,\"action\",[[24,0,[]],[29,\"mut\",[[24,0,[\"center\"]]],null]],[[\"value\"],[\"date\"]]],[29,\"action\",[[24,0,[]],[29,\"mut\",[[24,0,[\"range\"]]],null]],[[\"value\"],[\"date\"]]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,0,[\"range\",\"start\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"date-range__clear-btn\"],[9],[3,\"action\",[[24,0,[]],\"resetRange\",[24,1,[\"actions\",\"toggle\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[6,[24,2,[\"nav\"]],[],[[],[]]],[0,\"\\n      \"],[6,[24,2,[\"days\"]],[],[[\"@startOfWeek\",\"@weekdayFormat\"],[1,\"min\"]]],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/filters/date-range-filter/template.hbs"
    }
  });

  _exports.default = _default;
});