define("ntfrontend/components/transports/transport-system/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vDn020hP",
    "block": "{\"symbols\":[\"@model\",\"&default\"],\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"system-list-item\"],[9],[0,\"\\n\"],[4,\"if\",[[24,1,[\"system\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[12,\"style\",[30,[\"background-color: #\",[29,\"generate-color\",[[24,1,[\"system\"]]],null]]]],[12,\"class\",[30,[\"system-indicator-without-hover \",[29,\"if\",[[24,1,[\"system\"]],\"system-indicator\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[24,1,[\"system\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"class\",\"system-description-container\"],[9],[0,\"\\n            \"],[1,[24,1,[\"system\"]],false],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"system-indicator-without-hover\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[7,\"div\"],[9],[0,\"\\n      \"],[5,\"transports/sneak-peek-button\",[],[[\"@model\"],[[24,1,[]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[26,2]],null,{\"statements\":[[0,\"          \"],[15,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[24,1,[\"textId\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/transports/transport-system/template.hbs"
    }
  });

  _exports.default = _default;
});