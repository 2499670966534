define("ntfrontend/components/shared/search-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vImND2Ik",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"search__wrapper\"],[9],[0,\"\\n  \"],[5,\"input\",[],[[\"@class\",\"@classNameBindings\",\"@value\",\"@placeholder\"],[\"search__field\",\"value:search__field--active\",[23,\"inputValue\"],[29,\"t\",[\"shared.placeholders.search\"],null]]]],[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"icon-clear\"],[9],[3,\"action\",[[24,0,[]],\"clearField\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/search-input/template.hbs"
    }
  });

  _exports.default = _default;
});