define("ntfrontend/components/templates/project-templates/edit/document-templates/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service('notify-alert'),
    actions: {
      moveObject: function moveObject(projectType, documentTemplate) {
        var _this = this;

        documentTemplate.get('projectTypes').pushObject(projectType).save().then(function () {
          _this.notify.success('notify.default.saved');
        });
      }
    }
  });

  _exports.default = _default;
});