define("ntfrontend/components/shared/sneak-peek-pane/pane/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['data-spy', 'data-offset-top', 'data-offset-bottom'],
    init: function init() {
      this._super.apply(this, arguments);

      this.elementId = this.paneName;
    },
    paneName: Ember.computed('name', function () {
      return "".concat(this.name, "-pane");
    })
  });

  _exports.default = _default;
});