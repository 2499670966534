define("ntfrontend/components/pagination-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6v5aAs4W",
    "block": "{\"symbols\":[\"value\",\"@currentPage\"],\"statements\":[[4,\"if\",[[25,[\"moreThanOnePage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\"],[11,\"class\",\"pagination\"],[9],[0,\"\\n    \"],[5,\"pagination-list/back\",[],[[\"@currentPage\",\"@totalPages\",\"@changePageNumber\"],[[24,2,[]],[24,0,[\"totalPages\"]],[29,\"action\",[[24,0,[]],\"changePageNumber\"],null]]]],[0,\"\\n\"],[4,\"each\",[[25,[\"pagesButtons\"]]],null,{\"statements\":[[0,\"      \"],[5,\"pagination-list/item\",[],[[\"@page\",\"@currentPage\",\"@changePageNumber\"],[[24,1,[]],[24,2,[]],[29,\"action\",[[24,0,[]],\"changePageNumber\"],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[5,\"pagination-list/next\",[],[[\"@currentPage\",\"@totalPages\",\"@changePageNumber\"],[[24,2,[]],[24,0,[\"totalPages\"]],[29,\"action\",[[24,0,[]],\"changePageNumber\"],null]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/pagination-list/template.hbs"
    }
  });

  _exports.default = _default;
});