define("ntfrontend/components/shared/power-select/suggested-option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IItiszvW",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"icon-plus-circle\"],[9],[10],[0,\" \"],[1,[25,[\"option\",\"__value__\"]],false],[0,\" \"],[7,\"span\"],[11,\"class\",\"pull-right collaborator-label\"],[9],[1,[25,[\"option\",\"text\"]],false],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/power-select/suggested-option/template.hbs"
    }
  });

  _exports.default = _default;
});