define("ntfrontend/components/comments/comment-item/dropdown/component", ["exports", "ntfrontend/mixins/components/open-dropdown"], function (_exports, _openDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_openDropdown.default, {
    classNames: ['dropdown'],
    actions: {
      afterRemove: function afterRemove() {
        this.afterRemove();
      }
    }
  });

  _exports.default = _default;
});