define("ntfrontend/abilities/thread", ["exports", "ntfrontend/abilities/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    canCreateComments: Ember.computed.readOnly('model.ability.actions.createComments')
  });

  _exports.default = _default;
});