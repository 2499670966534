define("ntfrontend/components/documents/details/due-date-label/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNameBindings: ['dueClass'],
    dueClass: Ember.computed('dueDate', function () {
      var dateNow = moment();
      var dueDate = moment(this.dueDate);
      return dateNow > dueDate ? 'brand--danger' : 'brand--warning';
    })
  });

  _exports.default = _default;
});