define("ntfrontend/initializers/body-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    var _includeRouteName = true;
    Ember.Route.reopen({
      classNames: [],
      bodyClasses: [],
      // Backwards compatibility
      _getRouteName: function _getRouteName() {
        var routerName = this.routeName.split('.').join('-');
        return "".concat(routerName, "-route");
      },
      addClasses: Ember.on('activate', function () {
        var body = document.querySelector('body');
        this.classNames.forEach(function (newClass) {
          body.classList.add(newClass);
        });

        if (_includeRouteName) {
          body.classList.add(this._getRouteName());
        }
      }),
      removeClasses: Ember.on('deactivate', function () {
        var body = document.querySelector('body');
        this.classNames.forEach(function (newClass) {
          body.classList.remove(newClass);
        });

        if (_includeRouteName) {
          body.classList.remove(this._getRouteName());
        }
      })
    });
  }

  var _default = {
    name: 'body-class',
    initialize: initialize
  };
  _exports.default = _default;
});