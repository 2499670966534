define("ntfrontend/routes/org-auth", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ntfrontend/mixins/routes/not-for-app", "ntfrontend/mixins/routes/scroll-to-anchor"], function (_exports, _authenticatedRouteMixin, _notForApp, _scrollToAnchor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _notForApp.default, _scrollToAnchor.default, {
    authenticationRoute: 'auth.login',
    objectOrRedirect: function objectOrRedirect(object, route) {
      if (Ember.isEmpty(object)) {
        this.transitionTo(route);
      } else {
        return object.get('firstObject') || object;
      }
    }
  });

  _exports.default = _default;
});