define("ntfrontend/components/accounts/account-details/info/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    intl: Ember.inject.service(),
    expiredAt: Ember.computed('model.{expiredAt,activeSubscription}', function () {
      var accountPlan = this.get('model.activeSubscription.expiredAt') || this.get('model.expiredAt');
      return moment(accountPlan).format('Do MMMM YYYY');
    }),
    anyDaysLeft: Ember.computed.gt('model.daysLeft', 0),
    status: Ember.computed('model.{activeSubscription,trialStartedAt}', function () {
      if (this.get('model.trialStartedAt') === null && !this._isActive()) {
        return this._textTranslation('subscription.freeAccount');
      }

      return this._isActive() || this._textTranslation('subscription.readOnly');
    }),
    _textTranslation: function _textTranslation(text) {
      return this.intl.t(text);
    },
    _hasActiveSubscription: function _hasActiveSubscription() {
      return this.get('model.activeSubscription.expiredAt') > new Date();
    },
    _isActive: function _isActive() {
      if (this._hasActiveSubscription()) {
        var subscriptionPlan = "subscription.plans.".concat(this.get('model.activeSubscription.plan'), ".header");
        return this._textTranslation(subscriptionPlan);
      }

      if (this.get('model.daysLeft') > 0) {
        return this._textTranslation('subscription.trial');
      }
    }
  });

  _exports.default = _default;
});