define("ntfrontend/components/shared/excel-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1glHtAgS",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"displayButton\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"pdf-button\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"text-muted filter-name\"],[9],[0,\"\\n      \"],[1,[29,\"t\",[\"shared.labels.excel\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[5,\"shared/tooltip-block\",[],[[\"@tooltipTitle\"],[[29,\"t\",[\"shared.actions.createExcel\"],null]]],{\"statements\":[[0,\"\\n      \"],[7,\"a\"],[11,\"class\",\"filter__icon icon-excel\"],[9],[3,\"action\",[[24,0,[]],\"downloadExcel\"]],[10],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/excel-button/template.hbs"
    }
  });

  _exports.default = _default;
});