define("ntfrontend/components/projects/project-card/-header/-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lt6uEugL",
    "block": "{\"symbols\":[\"( unused value )\",\"statusValue\"],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"dropdown table-input \",[29,\"if\",[[25,[\"editable\"]],\"inplace-editing\"],null]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"static-value dropdown-toggle\"],[12,\"data-toggle\",[23,\"dataToggle\"]],[11,\"aria-haspopup\",\"true\"],[11,\"aria-expanded\",\"true\"],[11,\"type\",\"button\"],[9],[0,\"\\n    \"],[5,\"shared/property-label\",[],[[\"@tagName\",\"@list\",\"@property\",\"@translationScope\",\"@coloredStatus\"],[\"span\",[25,[\"model\",\"statuses\"]],[25,[\"model\",\"status\"]],[23,\"translationScope\"],true]]],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"editable\"]]],null,{\"statements\":[[0,\"    \"],[7,\"ul\"],[11,\"class\",\"table-input__dropdown dropdown-menu -no-text-transform\"],[9],[0,\"\\n\"],[4,\"each\",[[29,\"-each-in\",[[25,[\"model\",\"statuses\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[0,\"\\n          \"],[7,\"a\"],[9],[0,\"\\n            \"],[5,\"shared/property-label\",[],[[\"@list\",\"@property\",\"@translationScope\"],[[25,[\"model\",\"statuses\"]],[24,2,[]],[23,\"translationScope\"]]]],[0,\"\\n          \"],[3,\"action\",[[24,0,[]],\"changeStatus\",[24,2,[]]]],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\\n\"],[5,\"documents/details/status-select/modal\",[],[[\"@model\",\"@property\",\"@showModal\"],[[23,\"model\"],\"status\",[23,\"showModal\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/project-card/-header/-status/template.hbs"
    }
  });

  _exports.default = _default;
});