define("ntfrontend/components/search-box/empty-state/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3WOjZGQ+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\"],[11,\"class\",\"searchbar__title\"],[9],[1,[29,\"t\",[\"shared.labels.searchPlaceholder\"],null],false],[10],[0,\"\\n\\n\"],[7,\"li\"],[11,\"class\",\"searchbar__blank\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"icon-memos\"],[9],[1,[29,\"t\",[\"shared.labels.memos\"],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"icon-warning\"],[9],[1,[29,\"t\",[\"shared.labels.issues\"],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"icon-requests\"],[9],[1,[29,\"t\",[\"shared.labels.requests\"],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"icon-tasks\"],[9],[1,[29,\"t\",[\"shared.labels.tasks\"],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"icon-projects\"],[9],[1,[29,\"t\",[\"shared.labels.projects\"],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"icon-scenarios\"],[9],[1,[29,\"t\",[\"shared.labels.scenarios\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"li\"],[11,\"class\",\"searchbar__protip\"],[9],[1,[29,\"t\",[\"shared.labels.searchProTip\"],[[\"htmlSafe\"],[true]]],false],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/search-box/empty-state/template.hbs"
    }
  });

  _exports.default = _default;
});