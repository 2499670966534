define("ntfrontend/models/document-template", ["exports", "ember-data", "ntfrontend/mixins/models/topicable", "ntfrontend/mixins/models/stepable", "ember-custom-actions"], function (_exports, _emberData, _topicable, _stepable, _emberCustomActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  var Model = _emberData.default.Model;

  var _default = Model.extend(_topicable.default, _stepable.default, {
    name: attr(),
    description: attr(),
    documentType: attr(),
    published: attr('boolean'),
    autoClone: attr('boolean'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    account: belongsTo(),
    creator: belongsTo('user'),
    ability: belongsTo(),
    thread: belongsTo(),
    projectTypes: hasMany(),
    roles: hasMany(),
    members: hasMany(),
    clone: (0, _emberCustomActions.modelAction)('clone', {
      method: 'POST'
    })
  });

  _exports.default = _default;
});