define("ntfrontend/models/account", ["exports", "ember-data", "ntfrontend/utils/url-maker", "ntfrontend/models/resource", "ember-custom-actions"], function (_exports, _emberData, _urlMaker, _resource, _emberCustomActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;

  var _default = _resource.default.extend({
    name: attr(),
    domain: attr(),
    logoUrl: attr(),
    overdueTasks: attr('number'),
    myOverdueTasks: attr('number'),
    myTasks: attr('number'),
    overdueTasksReportedByMe: attr('number'),
    activeProjectsCount: attr('number'),
    myProjectsCount: attr('number'),
    collaboratorsCount: attr('number'),
    adminsCount: attr('number'),
    managersCount: attr('number'),
    trialStartedAt: attr('date'),
    transportSetting: belongsTo('transport-setting'),
    projects: hasMany(),
    projectTypes: hasMany(),
    documentTemplates: hasMany(),
    customFields: hasMany('customField', {
      async: true
    }),
    subscriptions: hasMany(),
    current: (0, _emberCustomActions.resourceAction)('current', {
      method: 'GET'
    }),
    import: (0, _emberCustomActions.modelAction)('import', {
      method: 'PATCH'
    }),
    activeSubscription: Ember.computed('subscriptions.[]', function () {
      return this.subscriptions.filter(function (subscription) {
        return subscription.get('status') === 'active';
      }).get('firstObject');
    }),
    url: Ember.computed('domain', function () {
      return _urlMaker.default.subdomainUrl(this.domain);
    }),
    expiredAt: Ember.computed('trialStartedAt', function () {
      return moment(this.trialStartedAt).add(30, 'days').format();
    }),
    daysLeft: Ember.computed('expiredAt', 'activeSubscription', function () {
      var planDaysLeft = this.get('activeSubscription.expiredAt') || this.expiredAt;
      var today = moment();
      var expireDate = moment(planDaysLeft);
      return expireDate.diff(today, 'days');
    }),
    defaultRole: 'collaborator'
  });

  _exports.default = _default;
});