define("ntfrontend/components/shared/tabs-menu/link/component", ["exports", "ntfrontend/mixins/components/tooltip"], function (_exports, _tooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.LinkComponent.extend(_tooltip.default, {
    intl: Ember.inject.service(),
    tagName: 'li',
    tooltipEnabled: Ember.computed.not('active'),
    linkTitle: Ember.computed('titleKey', function () {
      return this.intl.t(this.titleKey);
    })
  });

  _exports.default = _default;
});