define("ntfrontend/routes/projects/project/issues/index", ["exports", "ntfrontend/utils/ajaxer", "ntfrontend/routes/org-auth", "ntfrontend/models/issue"], function (_exports, _ajaxer, _orgAuth, _issue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend({
    model: function model(params) {
      var data = {
        include: 'owner,requester,work-package-list',
        filter: this._filters(params),
        page: {
          size: params.perPage,
          number: params.page
        },
        sort: params.sort
      };
      return Ember.RSVP.hash({
        issues: this.store.query('issue', data),
        project: this.modelFor('projects.project'),
        lists: {
          statuses: _issue.statuses
        }
      });
    },
    _filters: function _filters(params) {
      var filters = {
        projectId: this.modelFor('projects.project').id,
        workPackageId: params.workPackageId,
        milestoneId: params.milestoneId,
        customFields: params.customFields,
        requesterId: params.requesterId,
        issueType: params.issueType,
        archived: params.archived,
        ownerId: params.ownerId,
        status: params.status,
        due: params.due,
        q: params.q,
        typeName: params.typeName
      };
      return _ajaxer.default.reduceObject(filters);
    }
  });

  _exports.default = _default;
});