define("ntfrontend/components/works/create-tab/details/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SpsZKTxr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"progress -bg-white -primary static-value\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"progress__bar\"],[12,\"style\",[23,\"progressBarStyle\"]],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"progress__number\"],[9],[1,[23,\"progress\"],false],[7,\"small\"],[9],[0,\"%\"],[10],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/works/create-tab/details/progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});