define("ntfrontend/components/projects/project-card/-header/-progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qNaejCDQ",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"project__progress-wrapper\"],[9],[0,\"\\n  \"],[7,\"a\"],[12,\"class\",[29,\"if\",[[25,[\"editable\"]],\"inplace-editing\"],null]],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[30,[\"progress \",[29,\"if\",[[25,[\"editable\"]],\"static-value\"],null]]]],[9],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"progress__bar\"],[12,\"style\",[23,\"progressBarStyle\"]],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"progress__number\"],[9],[1,[25,[\"model\",\"progress\"]],false],[7,\"small\"],[9],[0,\"%\"],[10],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"redirectToWorkView\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/project-card/-header/-progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});