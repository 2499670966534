define("ntfrontend/components/accounts/account-details/document-templates/import-template/account-select/component", ["exports", "ntfrontend/mixins/components/model-saving", "jquery"], function (_exports, _modelSaving, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    store: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _jquery.default)('.dropdown', this.element).on('show.bs.dropdown', function () {
        if (!_this.shownDropdown) {
          _this.set('shownDropdown', true);
        }
      });
    },
    accounts: Ember.computed(function () {
      return this.store.query('account', {});
    }),
    actions: {
      changeAccount: function changeAccount(account) {
        this.set('model', account);
      }
    }
  });

  _exports.default = _default;
});