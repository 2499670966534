define("ntfrontend/controllers/requests/request", ["exports", "ntfrontend/controllers/documents/document"], function (_exports, _document) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _document.default.extend();

  _exports.default = _default;
});