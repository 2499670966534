define("ntfrontend/components/auth/password-form/component", ["exports", "ntfrontend/utils/ajaxer"], function (_exports, _ajaxer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('data', Ember.Object.create());
    },
    actions: {
      reset: function reset() {
        var _this = this;

        var token = this.token,
            currentSession = this.currentSession,
            data = this.data;
        this.set('data.reset_password_token', token);
        currentSession.newPassword(data).then(function () {
          _this._redirectToIndex();
        }, function (error) {
          Ember.run(function () {
            _this.set('data.errors', (0, _ajaxer.errorize)(error.errors));
          });
        });
      }
    },
    _redirectToIndex: function _redirectToIndex() {
      this.router.transitionTo('index');
    }
  });

  _exports.default = _default;
});