define("ntfrontend/components/accounts/call-to-create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8S9XeCjG",
    "block": "{\"symbols\":[\"openModal\"],\"statements\":[[4,\"if\",[[29,\"can\",[\"create accounts in user\",[25,[\"currentSession\",\"user\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"welcome-content welcome-content--left\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"lead\"],[9],[0,\"\\n      \"],[7,\"h2\"],[9],[1,[29,\"t\",[\"organization.labels.callToAction.createOrganization\"],null],false],[10],[0,\"\\n\\n      \"],[7,\"ul\"],[9],[0,\"\\n        \"],[7,\"li\"],[11,\"class\",\"icon-projects\"],[9],[1,[29,\"t\",[\"organization.labels.callToAction.list.manageProjects\"],null],false],[10],[0,\"\\n        \"],[7,\"li\"],[11,\"class\",\"icon-tasks\"],[9],[1,[29,\"t\",[\"organization.labels.callToAction.list.organizeTasks\"],null],false],[10],[0,\"\\n        \"],[7,\"li\"],[11,\"class\",\"icon-users\"],[9],[0,\"\\n          \"],[1,[29,\"t\",[\"organization.labels.callToAction.list.collaborate\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[5,\"accounts/create-modal\",[],[[\"@class\"],[\"text-center\"]],{\"statements\":[[0,\"\\n      \"],[7,\"a\"],[11,\"class\",\"btn-success icon-plus-circle btn-large\"],[9],[1,[29,\"t\",[\"organization.create\"],null],false],[3,\"action\",[[24,0,[]],[24,1,[]]]],[10],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"welcome-content welcome-content--right\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"switch-organization\"],[9],[0,\"\\n    \"],[7,\"h2\"],[9],[1,[29,\"t\",[\"organization.switch\"],null],false],[10],[0,\"\\n    \"],[7,\"hr\"],[9],[10],[0,\"\\n    \"],[5,\"accounts/accounts-list\",[],[[],[]]],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/accounts/call-to-create/template.hbs"
    }
  });

  _exports.default = _default;
});