define("ntfrontend/abilities/project", ["exports", "ntfrontend/abilities/resourcify"], function (_exports, _resourcify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resourcify.default.extend({
    canArchive: Ember.computed.readOnly('model.ability.actions.archive'),
    canCreatePdf: Ember.computed.readOnly('model.ability.actions.createPdf'),
    canCreateExcel: Ember.computed.readOnly('model.ability.actions.createExcel'),
    canUnarchive: Ember.computed.readOnly('model.ability.actions.unarchive'),
    canCreateMemos: Ember.computed.readOnly('model.ability.actions.createMemos'),
    canCreateIssues: Ember.computed.readOnly('model.ability.actions.createIssues'),
    canCreateRequests: Ember.computed.readOnly('model.ability.actions.createRequests'),
    canCreateScenarios: Ember.computed.readOnly('model.ability.actions.createScenarios'),
    canCreateMilestones: Ember.computed.readOnly('model.ability.actions.createMilestones'),
    canDisplayDashboard: Ember.computed.readOnly('model.ability.actions.displayDashboard'),
    canCreateWorkPackages: Ember.computed.readOnly('model.ability.actions.createWorkPackages'),
    canManageWork: Ember.computed.readOnly('model.ability.actions.manageWork'),
    canManageMembers: Ember.computed.readOnly('model.ability.actions.manageMembers'),
    canCreateDocuments: Ember.computed.or('canCreateMemos', 'canCreateRequests', 'canCreateIssues')
  });

  _exports.default = _default;
});