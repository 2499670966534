define("ntfrontend/components/projects/dashboard/task-assignments/assignment-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IMx3bzyX",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[5,\"link-to\",[],[[\"@route\",\"@query\"],[\"projects.project.tasks\",[29,\"hash\",null,[[\"completed\",\"due\",\"ownerId\"],[false,\"inFuture\",[25,[\"model\",\"userId\"]]]]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"tasks-graph__bar ok\"],[12,\"style\",[23,\"inProgressWidth\"]],[9],[1,[25,[\"model\",\"inProgressTasksCount\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"link-to\",[],[[\"@route\",\"@query\"],[\"projects.project.tasks\",[29,\"hash\",null,[[\"completed\",\"due\",\"ownerId\"],[false,\"overdue\",[25,[\"model\",\"userId\"]]]]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"tasks-graph__bar bad\"],[12,\"style\",[23,\"overdueWidth\"]],[9],[1,[25,[\"model\",\"overdueTasksCount\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/dashboard/task-assignments/assignment-item/template.hbs"
    }
  });

  _exports.default = _default;
});