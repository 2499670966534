define("ntfrontend/components/shared/material-labels/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z7qVAWta",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,\"shared/material-labels/label\",[],[[\"@labelFor\",\"@placeholder\",\"@hasValue\"],[[23,\"labelFor\"],[23,\"placeholder\"],[23,\"hasValue\"]]],{\"statements\":[[0,\"\\n  \"],[15,1,[[29,\"hash\",[[25,[\"hasValue\"]]],[[\"changeValue\"],[[29,\"action\",[[24,0,[]],\"changeValue\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/material-labels/template.hbs"
    }
  });

  _exports.default = _default;
});