define("ntfrontend/components/documents/details/project-select/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    store: Ember.inject.service(),
    actions: {
      changeProject: function changeProject(targetProject) {
        var _this = this;

        this.store.findRecord('project', targetProject.get('id')).then(function (project) {
          _this.set('model.project', project);

          _this.send('forceSaveModel');
        });
      }
    }
  });

  _exports.default = _default;
});