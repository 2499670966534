define("ntfrontend/mixins/routes/scroll-to-anchor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    states: Ember.inject.service(),
    scrollToAnchor: function scrollToAnchor(anchor) {
      if (anchor) {
        this.states.set('scrollTo', anchor);
      }
    },
    afterModel: function afterModel(model, transition) {
      this._super.apply(this, arguments);

      this.scrollToAnchor(transition.to.queryParams.anchor);
    }
  });

  _exports.default = _default;
});