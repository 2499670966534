define("ntfrontend/components/shared/filters/custom-fields/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zvhdjyXo",
    "block": "{\"symbols\":[\"customFilter\"],\"statements\":[[4,\"each\",[[25,[\"customFilters\"]]],null,{\"statements\":[[0,\"  \"],[5,\"shared/filters/custom-fields/list\",[],[[\"@customField\",\"@value\",\"@updateFilter\",\"@removeFilter\"],[[24,1,[]],[24,1,[\"value\"]],[29,\"action\",[[24,0,[]],\"updateFilter\"],null],[29,\"action\",[[24,0,[]],\"removeFilter\"],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/filters/custom-fields/template.hbs"
    }
  });

  _exports.default = _default;
});