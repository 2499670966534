define("ntfrontend/initializers/routes-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    Ember.Route.reopen({
      deserializeQueryParam: function deserializeQueryParam(value, key, defaultValueType) {
        var returned = this._super.apply(this, arguments);

        if (defaultValueType === 'string') {
          return returned === 'null' ? null : returned;
        }

        return returned;
      },
      mapParam: function mapParam(value, actual, expected) {
        return value === actual ? expected : value;
      }
    });
  }

  var _default = {
    name: 'routes-params',
    initialize: initialize
  };
  _exports.default = _default;
});