define("ntfrontend/components/projects/dashboard/progress-bar/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    router: Ember.inject.service(),
    can: Ember.inject.service(),
    tagName: '',
    rollbackAttributes: false,
    disabled: Ember.computed.not('model.hasDirtyAttributes'),
    progressBarStyle: Ember.computed('model.progress', function () {
      return Ember.String.htmlSafe("width: ".concat(this.get('model.progress'), "%"));
    }),
    actions: {
      redirectToWorkView: function redirectToWorkView() {
        var projectSlug = this.get('model.project.slug');
        var urlToRedirect = "/projects/".concat(projectSlug, "/work/plan");

        if (this.can.can('manage work in project'), this.get('model.project')) {
          this.router.transitionTo(urlToRedirect);
        }
      },
      onClose: function onClose() {
        this.model.rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});