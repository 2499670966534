define("ntfrontend/components/shared/filters/type-name/component", ["exports", "ntfrontend/mixins/components/open-dropdown"], function (_exports, _openDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_openDropdown.default, {
    intl: Ember.inject.service(),
    classNames: ['dropdown'],
    allTypes: Ember.computed.alias('scope.projectType.documentTypes'),
    nameTypes: Ember.computed(function () {
      return this.allTypes.filterBy('resourceType', this.resourceName);
    }),
    currentName: Ember.computed('typeName', function () {
      if (this.typeName) {
        return this.nameTypes.findBy('id', this.typeName).get('name');
      } else {
        return this.intl.t('shared.filters.all');
      }
    }),
    actions: {
      changeFilter: function changeFilter(id) {
        this.set('typeName', id);
      }
    }
  });

  _exports.default = _default;
});