define("ntfrontend/components/works/plan-tab/work-item-modal/component", ["exports", "ember-changeset", "ntfrontend/mixins/components/model-saving", "ntfrontend/helpers/time-scope", "ntfrontend/utils/comma-to-dot"], function (_exports, _emberChangeset, _modelSaving, _timeScope, _commaToDot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  var _default = Ember.Component.extend(_modelSaving.default, {
    store: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    tagName: '',
    users: Ember.computed.mapBy('project.members', 'user'),
    actionType: Ember.computed('typeModal', function () {
      var typeModal = this.typeModal;
      return typeModal === 'create' || typeModal === 'createByWorkPackage' ? 'createModel' : 'updateModel';
    }),
    title: Ember.computed('typeModal', function () {
      var typeModal = this.typeModal;
      return typeModal === 'update' ? 'work.labels.editWorkItem' : 'work.labels.createWorkItem';
    }),
    successCallback: function successCallback() {
      var refreshWorkItems = this.refreshWorkItems;
      this.notify.success(this.notifyLabel);

      this._clearFields();

      if (refreshWorkItems) {
        refreshWorkItems();
      }
    },
    actions: {
      createModel: function createModel() {
        var project = this.project,
            filterTimeScope = this.filterTimeScope,
            changeset = this.changeset;
        changeset.set('hoursPlanned', (0, _timeScope.timeScope)([changeset.get('hoursPlanned'), filterTimeScope, 'setValue']));
        var data = {
          project: project,
          name: changeset.get('name'),
          description: changeset.get('description'),
          milestone: changeset.get('milestone'),
          workPackage: changeset.get('workPackage'),
          hoursPlanned: changeset.get('hoursPlanned'),
          owner: changeset.get('owner')
        };
        this.set('model', this.store.createRecord('workItem', data));
        return this.saveModelTask.perform();
      },
      updateModel: function updateModel() {
        var _this = this;

        var filterTimeScope = this.filterTimeScope,
            changeset = this.changeset;
        changeset.set('hoursPlanned', (0, _timeScope.timeScope)([changeset.get('hoursPlanned'), filterTimeScope, 'setValue']));
        changeset.set('hoursEstimated', (0, _timeScope.timeScope)([changeset.get('hoursEstimated'), filterTimeScope, 'setValue']));
        changeset.save().then(function () {
          return _this.successCallback();
        });
        return Ember.RSVP.resolve();
      },
      openModal: function openModal() {
        this.set('showModal', true);

        this._setNotifyLabel();

        if (this.typeModal === 'update') {
          this.set('changeset', new _emberChangeset.default(this.model));

          this._setHours('hoursPlanned');

          this._setHours('hoursEstimated');
        } else {
          var newWorkItem = this.store.createRecord('work-item');
          this.set('changeset', new _emberChangeset.default(newWorkItem));

          this._setFields();
        }
      },
      setCreateAnother: function setCreateAnother(_ref) {
        var checked = _ref.target.checked;
        this.set('closeAfterSuccess', !checked);
      },
      onClose: function onClose() {
        this._clearFields();
      }
    },
    _setHours: function _setHours(hours) {
      var computedHours = (0, _timeScope.timeScope)([this.changeset.get(hours), this.filterTimeScope]);
      this.changeset.set(hours, (0, _commaToDot.commaToDot)(computedHours));
    },
    _setFields: function _setFields() {
      var changeset = this.changeset,
          milestone = this.milestone,
          workPackage = this.workPackage,
          currentSession = this.currentSession;
      var fields = changeset.getProperties('milestone', 'workPackage');
      Object.keys(fields).forEach(function (property) {
        changeset.set(property, null);
      });

      if (milestone && workPackage) {
        changeset.set('milestone', milestone);
        changeset.set('workPackage', workPackage);
      }

      changeset.set('owner', currentSession.get('user'));
    },
    _setNotifyLabel: function _setNotifyLabel() {
      var notifyLabel = this.typeModal === 'update' ? 'notify.workItem.updated' : 'notify.workItem.created';
      this.set('notifyLabel', notifyLabel);
    },
    _clearFields: function _clearFields() {
      var _this2 = this;

      if (this.isDestroyed) {
        return;
      } else {
        var fields = this.getProperties('name', 'description', 'hoursPlanned');

        if (this.closeAfterSuccess) {
          var restFields = this.changeset.getProperties('owner', 'milestone', 'workPackage');
          fields = _extends(fields, restFields);
        }

        var placeholders = this.getProperties('resetName', 'resetDescription', 'resetHours');
        Object.keys(fields).forEach(function (property) {
          _this2.changeset.set(property, null);
        });
        Object.keys(placeholders).forEach(function (property) {
          _this2.set(property, null);
        });
      }
    }
  });

  _exports.default = _default;
});