define("ntfrontend/components/accounts/account-details/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentSession: Ember.inject.service(),
    account: Ember.computed.alias('currentSession.account')
  });

  _exports.default = _default;
});