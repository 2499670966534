define("ntfrontend/components/shared/filters/custom-fields/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XRHbDVpS",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"label\"],[11,\"class\",\"text-muted filter-name\"],[9],[0,\"\\n  \"],[1,[25,[\"customField\",\"name\"]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"a\"],[11,\"class\",\"dropdown-toggle\"],[11,\"data-toggle\",\"dropdown\"],[11,\"aria-haspopup\",\"true\"],[11,\"aria-expanded\",\"true\"],[11,\"type\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"selected\"]]],null,{\"statements\":[[0,\"    \"],[1,[23,\"selected\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[29,\"t\",[\"shared.labels.notSpecified\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"selected\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[11,\"class\",\"remove-filter-button icon-clear icon--small\"],[11,\"type\",\"button\"],[9],[3,\"action\",[[24,0,[]],[25,[\"removeFilter\"]],[25,[\"customField\",\"id\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"ul\"],[11,\"class\",\"dropdown-menu\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"customField\",\"options\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[0,\"\\n      \"],[7,\"a\"],[9],[1,[24,1,[]],false],[3,\"action\",[[24,0,[]],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"selected\"]]],null]],null],[24,1,[]]]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/filters/custom-fields/list/template.hbs"
    }
  });

  _exports.default = _default;
});