define("ntfrontend/components/projects/dashboard/work-packages/list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    unassigned: Ember.computed.readOnly('workPackages.content.meta.unassigned'),
    workPackages: Ember.computed('sort', 'project', function () {
      return Ember.get(this, 'store').query('workPackage', {
        include: 'owner',
        filter: {
          projectId: Ember.get(this, 'project.id')
        },
        sort: Ember.get(this, 'sort')
      });
    }),
    actions: {
      refreshWorkPackages: function refreshWorkPackages() {
        this.notifyPropertyChange('workPackages');
      }
    }
  });

  _exports.default = _default;
});