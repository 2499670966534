define("ntfrontend/models/project", ["exports", "ember-data", "ntfrontend/models/resource", "ember-custom-actions"], function (_exports, _emberData, _resource, _emberCustomActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.statuses = void 0;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  var statuses = {
    1: 'concept',
    2: 'request',
    3: 'approved',
    4: 'inProgress',
    5: 'onHold',
    6: 'completed',
    7: 'cancelled'
  };
  _exports.statuses = statuses;

  var _default = _resource.default.extend({
    slug: attr('number'),
    name: attr(),
    description: attr(),
    code: attr(),
    archived: attr(),
    statusDescription: attr(),
    status: attr('number', {
      defaultValue: 4
    }),
    startAt: attr('date'),
    endAt: attr('date'),
    overdueTasks: attr('number'),
    myOverdueTasks: attr('number'),
    memoCount: attr('number'),
    requestCount: attr('number'),
    issueCount: attr('number'),
    openTasksCount: attr('number'),
    memos: hasMany(),
    milestones: hasMany(),
    workPackages: hasMany(),
    workItems: hasMany('work-item'),
    workLogs: hasMany('work-log'),
    account: belongsTo(),
    dashboard: belongsTo(),
    projectMember: belongsTo(),
    projectSetting: belongsTo(),
    taskAssignment: belongsTo(),
    ability: belongsTo(),
    owner: belongsTo('user'),
    creator: belongsTo('user'),
    requester: belongsTo('user'),
    projectType: belongsTo(),
    archive: (0, _emberCustomActions.modelAction)('archive', {
      method: 'POST'
    }),
    unarchive: (0, _emberCustomActions.modelAction)('unarchive', {
      method: 'POST'
    }),
    statuses: Ember.computed(function () {
      return statuses;
    }),
    statusKey: Ember.computed('statuses', function () {
      return this.statuses[this.status];
    })
  });

  _exports.default = _default;
});