define("ntfrontend/mixins/components/refresh-relation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    loaded: Ember.computed.or('relationModel.isLoaded', 'relationModel.isFulfilled'),
    relationModel: Ember.computed('property', function () {
      throw new Error("'relationModel' property is required by 'RefreshRelation' mixin and have to be set in component!");
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._shouldReloadRelation();
    },
    _shouldReloadRelation: function _shouldReloadRelation() {
      if (this.loaded) {
        return this.relationModel.then(function (model) {
          return model.reload();
        });
      }
    }
  });

  _exports.default = _default;
});