define("ntfrontend/components/create-page/tabs-content/template-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2pHwoD4g",
    "block": "{\"symbols\":[\"templates\",\"accountName\",\"template\"],\"statements\":[[4,\"each\",[[29,\"-each-in\",[[25,[\"groupedTemplates\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"create-item__org\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"create-item__org-name label label-info\"],[9],[1,[24,2,[]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\"],[4,\"each\",[[24,1,[]]],null,{\"statements\":[[0,\"      \"],[5,\"create-page/tabs-content/template-card\",[],[[\"@model\",\"@disabled\",\"@id\",\"@onClick\",\"@heading\",\"@description\",\"@thumbnail\",\"@infoLabel\",\"@withFooter\",\"@withThumbnail\",\"@objectType\",\"@previewRoute\",\"@buttonLabel\",\"@resourceType\"],[[24,3,[]],[23,\"disabled\"],[24,3,[\"id\"]],[23,\"selectTemplate\"],[24,3,[\"name\"]],[24,3,[\"description\"]],[24,3,[\"thumbnail\"]],[24,3,[\"accountName\"]],true,true,[23,\"objectType\"],\"account.templates.projects.edit\",[29,\"t\",[\"shared.labels.create\"],null],\"project\"]]],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/create-page/tabs-content/template-list/template.hbs"
    }
  });

  _exports.default = _default;
});