define("ntfrontend/components/members/member-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showCollaborators: true,
    showRoles: true,
    showEmails: true,
    showEdit: true,
    resourceMembers: Ember.computed('members.@each.role', 'showCollaborators', function () {
      if (this.showCollaborators) {
        return this.members;
      } else {
        return this.members.filter(function (member) {
          return member.get('role') !== 'collaborator';
        });
      }
    })
  });

  _exports.default = _default;
});