define("ntfrontend/components/accounts/document-types/sets/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hEPP486y",
    "block": "{\"symbols\":[\"typeSet\"],\"statements\":[[5,\"shared/loading-state/block\",[],[[\"@model\"],[[23,\"model\"]]],{\"statements\":[[0,\"\\n  \"],[7,\"ol\"],[11,\"class\",\"topic__list\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[5,\"accounts/document-types/sets/set\",[],[[\"@model\"],[[24,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/accounts/document-types/sets/template.hbs"
    }
  });

  _exports.default = _default;
});