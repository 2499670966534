define("ntfrontend/components/works/create-tab/details/progress-bar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    progressBarStyle: Ember.computed('progress', function () {
      return Ember.String.htmlSafe("width: ".concat(this.get('progress'), "%"));
    })
  });

  _exports.default = _default;
});