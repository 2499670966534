define("ntfrontend/components/templates/new-template/modal-body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "STlOqNBs",
    "block": "{\"symbols\":[],\"statements\":[[5,\"templates/new-template/modal-body/input\",[],[[\"@name\",\"@onChange\"],[[25,[\"model\",\"name\"]],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"model\",\"name\"]]],null]],null]]]],[0,\"\\n\\n\"],[5,\"templates/new-template/modal-body/dropdown\",[],[[\"@type\",\"@documentsNames\",\"@onChange\"],[[25,[\"model\",\"documentType\"]],[29,\"readonly\",[[25,[\"documentsNames\"]]],null],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"model\",\"documentType\"]]],null]],null]]]],[0,\"\\n\\n\"],[5,\"templates/new-template/modal-body/project-types\",[],[[\"@class\",\"@model\",\"@projectTypes\",\"@onChange\"],[\"form-group\",[23,\"model\"],[23,\"projectTypes\"],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"model\",\"projectTypes\"]]],null]],null]]]],[0,\"\\n\\n\"],[5,\"shared/form-confirm-button\",[],[[\"@disabled\",\"@label\"],[[29,\"readonly\",[[25,[\"disabled\"]]],null],[29,\"t\",[\"shared.actions.create\"],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/templates/new-template/modal-body/template.hbs"
    }
  });

  _exports.default = _default;
});