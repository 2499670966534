define("ntfrontend/routes/projects/project/work/logs", ["exports", "ntfrontend/mixins/routes/ember-can", "ntfrontend/routes/org-auth"], function (_exports, _emberCan, _orgAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend(_emberCan.default, {
    requireAbilities: Ember.computed('abilities', function () {
      this.set('abilities', {
        'manage work in project': this.modelFor('projects.project')
      });
    }),
    model: function model() {
      var project = this.modelFor('projects.project');
      return Ember.RSVP.hash({
        project: project
      });
    }
  });

  _exports.default = _default;
});