define("ntfrontend/services/sweet-alert", ["exports", "sweetalert"], function (_exports, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    confirm: function confirm(message, title, onConfirm, onCancel) {
      var _this = this;

      var messageKey = message ? message : 'notify.default.confirm.message';
      var titleKey = title ? title : 'notify.default.confirm.title';
      (0, _sweetalert.default)({
        type: 'warning',
        showCancelButton: true,
        title: this.intl.t(titleKey),
        text: this.intl.t(messageKey)
      }, function (isConfirm) {
        if (isConfirm) {
          _this._functionOrBoolean(onConfirm, true);
        } else {
          _this._functionOrBoolean(onCancel, false);
        }
      });
    },
    success: function success(message, title) {
      var messageKey = message ? message : 'notify.default.success.message';
      var titleKey = title ? title : 'notify.default.success.title';
      this.alert(messageKey, titleKey, 'success');
    },
    warning: function warning(message, title) {
      var messageKey = message ? message : 'notify.default.warning.message';
      var titleKey = title ? title : 'notify.default.warning.title';
      this.alert(messageKey, titleKey, 'warning');
    },
    error: function error(message, title) {
      var messageKey = message ? message : 'notify.default.error.message';
      var titleKey = title ? title : 'notify.default.error.title';
      this.alert(messageKey, titleKey, 'error');
    },
    info: function info(message, title) {
      var messageKey = message ? message : 'notify.default.info.message';
      var titleKey = title ? title : 'notify.default.info.title';
      this.alert(messageKey, titleKey, 'info');
    },
    prompt: function prompt(message, title) {
      var _this2 = this;

      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var titleKey = title ? title : 'notify.default.prompt.title';
      var messageKey = message ? message : 'notify.default.prompt.message';
      return new Ember.RSVP.Promise(function (resolve, reject) {
        (0, _sweetalert.default)({
          title: _this2.intl.t(titleKey),
          text: _this2.intl.t(messageKey),
          type: 'input',
          showCancelButton: true,
          closeOnConfirm: false,
          allowOutsideClick: true,
          inputPlaceholder: options.placeholder ? _this2.intl.t(options.placeholder) : '',
          inputType: options.type
        }, function (inputValue) {
          if (inputValue === false) {
            reject();
          } else if (inputValue === '' && options.required) {
            _sweetalert.default.showInputError('This field is required!');

            return false;
          }

          resolve(inputValue);

          _sweetalert.default.close();
        });
      });
    },
    alert: function alert(message, title, type) {
      (0, _sweetalert.default)({
        type: type,
        title: this.intl.t(title),
        text: this.intl.t(message),
        allowOutsideClick: true
      });
    },
    _functionOrBoolean: function _functionOrBoolean(value, bool) {
      if (Ember.typeOf(value) === 'function') {
        return value();
      } else {
        return bool;
      }
    }
  });

  _exports.default = _default;
});