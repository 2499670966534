define("ntfrontend/abilities/work-item", ["exports", "ntfrontend/abilities/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    canComplete: Ember.computed.readOnly('model.ability.actions.complete')
  });

  _exports.default = _default;
});