define("ntfrontend/components/shared/kanban-view/tiles/request/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iwPyBFvp",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"kanban-grid__tile-header\"],[9],[0,\"\\n  \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"requests.request\",[25,[\"model\",\"slug\"]]]],{\"statements\":[[1,[25,[\"model\",\"name\"]],false]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"users/profile-box/picture\",[],[[\"@user\",\"@class\",\"@width\",\"@height\"],[[25,[\"model\",\"owner\"]],\"pull-right\",\"30\",\"30\"]]],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[5,\"shared/kanban-view/tiles/inline-date\",[],[[\"@class\",\"@value\"],[\"kanban-grid__date\",[25,[\"model\",\"dueDate\"]]]]],[0,\"\\n\"],[7,\"div\"],[11,\"style\",\"clear: both\"],[9],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"pull-right table__actions\"],[9],[0,\"\\n  \"],[5,\"link-to\",[],[[\"@route\",\"@query\"],[\"tasks\",[29,\"hash\",null,[[\"documentId\",\"due\",\"ownerId\"],[[29,\"document-id\",[[25,[\"model\"]]],null],\"overdue\",[25,[\"currentSession\",\"user\",\"id\"]]]]]]],{\"statements\":[[0,\"\\n    \"],[5,\"tasks/overdue-indicator\",[],[[\"@value\",\"@userScoped\"],[[25,[\"model\",\"myOverdueTasks\"]],true]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"link-to\",[],[[\"@route\",\"@query\"],[\"tasks\",[29,\"hash\",null,[[\"documentId\",\"due\",\"ownerId\"],[[29,\"document-id\",[[25,[\"model\"]]],null],\"overdue\",null]]]]],{\"statements\":[[0,\"\\n    \"],[5,\"tasks/overdue-indicator\",[],[[\"@value\"],[[25,[\"model\",\"myOverdueTasks\"]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"style\",\"clear: both\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/kanban-view/tiles/request/template.hbs"
    }
  });

  _exports.default = _default;
});