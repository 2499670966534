define("ntfrontend/components/shared/sneak-peek-selector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var componentsPaths = {
    task: 'tasks/task-sneak-peek',
    project: 'projects/project-card/project-sneak-peek',
    documentTemplate: 'templates/document-templates/document-sneak-peek',
    workLog: 'works/sneak-peek/work-log-sneak-peek',
    createWorkLog: 'works/sneak-peek/create-work-log-sneak-peek',
    updateWorkLog: 'works/sneak-peek/edit-work-log-sneak-peek',
    transport: 'transports/transport-sneak-peek'
  };

  var _default = Ember.Component.extend({
    paneManager: Ember.inject.service(),
    selectedSneak: Ember.computed.alias('paneManager.selectedSneak'),
    paneComponent: Ember.computed('selectedSneak', function () {
      return componentsPaths[this.selectedSneak];
    })
  });

  _exports.default = _default;
});