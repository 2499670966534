define("ntfrontend/components/shared/select-resource/component", ["exports", "ntfrontend/mixins/components/power-select/input", "ntfrontend/helpers/model-type-name"], function (_exports, _input, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_input.default, {
    store: Ember.inject.service(),
    endpoint: Ember.inject.service(),
    allowClear: false,
    resourceType: Ember.computed('type', function () {
      return this.type.dasherize();
    }),
    actions: {
      search: function search(q) {
        var queryParams = {
          q: q
        };
        var resourceType = (0, _modelTypeName.modelTypeName)([this.scope]);
        queryParams["".concat(resourceType, "_id")] = this.get('scope.id');
        return this.store.query('project', {
          filter: queryParams
        });
      },
      onChange: function onChange(resource) {
        this.onSelectionChanged(resource);
      },
      onClose: function onClose() {
        this.escapeSelect();
      }
    }
  });

  _exports.default = _default;
});