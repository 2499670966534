define("ntfrontend/components/documents/distribute-document/component", ["exports", "ntfrontend/helpers/model-type-name"], function (_exports, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    modelName: Ember.computed('document', function () {
      return (0, _modelTypeName.modelTypeName)([this.document]).toLowerCase();
    }),
    member: Ember.computed('memberId', function () {
      return this.store.findRecord('member', this.memberId);
    }),
    distributeHeader: Ember.computed('modelName', function () {
      return "".concat(this.modelName, ".share.shareHeader");
    }),
    distributeMessage: Ember.computed('modelName', 'memberId', function () {
      if (this.memberId) {
        return "".concat(this.modelName, ".share.shareMessageSingle");
      } else {
        return "".concat(this.modelName, ".share.shareMessage");
      }
    }),
    _prepareDataDistribution: function _prepareDataDistribution() {
      return {
        memberId: this.memberId,
        customMessage: this.distributionMessage
      };
    },
    actions: {
      distribute: function distribute() {
        var _this = this;

        var data = this._prepareDataDistribution();

        this.set('disabled', true);
        return this.document.distribute({
          data: data
        }).then(function () {
          var objects = data.memberId ? _this.member : _this.get('document.members').toArray();

          _this.notify.successWithUsers("notify.".concat(_this.modelName, ".distributed"), null, objects);
        }).finally(function () {
          _this.set('disabled', false);

          _this.set('showModal', false);
        });
      }
    }
  });

  _exports.default = _default;
});