define("ntfrontend/components/projects/dashboard/task-assignments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U8YJPZgn",
    "block": "{\"symbols\":[\"user\",\"user\"],\"statements\":[[7,\"div\"],[11,\"class\",\"page-header\"],[9],[0,\"\\n  \"],[7,\"h2\"],[11,\"class\",\"h2\"],[9],[1,[29,\"t\",[\"project.dashboard.tasksPerOwner\"],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"page-header__menu\"],[9],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@query\"],[\"projects.project.tasks\",[29,\"hash\",null,[[\"completed\",\"ownerId\",\"due\",\"requesterId\"],[\"false\",null,null,null]]]]],{\"statements\":[[0,\"\\n      \"],[7,\"a\"],[11,\"class\",\"btn-primary icon-search\"],[9],[1,[29,\"t\",[\"project.dashboard.showOpenTasks\"],null],false],[10],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[5,\"shared/loading-state/block\",[],[[\"@model\"],[[23,\"relationModel\"]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,[\"sortedUsers\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"tasks-graph\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"tasks-graph__labels\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"sortedUsers\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"class\",\"tasks-graph__label\"],[9],[1,[24,2,[\"userName\"]],false],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"tasks-graph__bars\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"sortedUsers\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"class\",\"tasks-graph__progress\"],[9],[0,\"\\n            \"],[5,\"projects/dashboard/task-assignments/assignment-item\",[],[[\"@model\",\"@max\"],[[24,1,[]],[23,\"maxTasksCount\"]]]],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/dashboard/task-assignments/template.hbs"
    }
  });

  _exports.default = _default;
});