define("ntfrontend/controllers/tasks/index", ["exports", "ember-parachute", "ntfrontend/mixins/controllers/refresh-model", "ntfrontend/mixins/controllers/pagination-utils", "ntfrontend/query-params/shared/pagination", "ntfrontend/query-params/shared/custom-fields"], function (_exports, _emberParachute, _refreshModel, _paginationUtils, _pagination, _customFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.queryParams = void 0;
  var localQueryParams = {
    sort: {
      defaultValue: 'deadlineAt',
      refresh: true
    },
    q: {
      defaultValue: null,
      refresh: true
    },
    documentId: {
      defaultValue: null,
      refresh: true
    },
    ownerId: {
      defaultValue: null,
      refresh: true
    },
    requesterId: {
      defaultValue: null,
      refresh: true
    },
    projectId: {
      defaultValue: null,
      refresh: true
    },
    reportedBy: {
      defaultValue: null,
      refresh: true
    },
    due: {
      defaultValue: null,
      refresh: true
    },
    completed: {
      defaultValue: 'false',
      refresh: true
    }
  };
  var queryParams = new _emberParachute.default(localQueryParams, _pagination.default, _customFields.default);
  _exports.queryParams = queryParams;

  var _default = Ember.Controller.extend(_paginationUtils.default, queryParams.Mixin, _refreshModel.default, {
    queryParamsDidChange: function queryParamsDidChange(parachuteEvent) {
      this.setFirstPage(parachuteEvent);

      this._refreshModel();
    }
  });

  _exports.default = _default;
});