define("ntfrontend/controllers/create", ["exports", "ember-parachute"], function (_exports, _emberParachute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.queryParams = void 0;
  var localQueryParams = {
    tab: {
      defaultValue: 'project'
    },
    projectSlug: {
      defaultValue: null
    }
  };
  var queryParams = new _emberParachute.default(localQueryParams);
  _exports.queryParams = queryParams;

  var _default = Ember.Controller.extend(queryParams.Mixin);

  _exports.default = _default;
});