define("ntfrontend/routes/auth/confirmation", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    currentSession: Ember.inject.service(),
    beforeModel: function beforeModel(param) {
      var _this = this;

      this._super.apply(this, arguments);

      if (!param.to.queryParams.token) {
        this.transitionTo('index');
      }

      this.currentSession.confirm(param.to.queryParams.token).then(function (authToken) {
        _this.set('currentSession.newUser', true);

        _this.transitionTo('auth.login', {
          queryParams: {
            authToken: authToken,
            confirmIdentity: false
          }
        });
      }, function () {
        _this.transitionTo('index');
      });
    }
  });

  _exports.default = _default;
});