define("ntfrontend/components/custom-fields/document-list/item-row/item/richtext-field/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    preview: Ember.computed(function () {
      return "".concat(this._truncateValue(), "...");
    }),
    _truncateValue: function _truncateValue() {
      return (0, _jquery.default)("<div>".concat(this.get('customValue.value'), "</div>"), this.element).text().substr(0, 5);
    }
  });

  _exports.default = _default;
});