define("ntfrontend/helpers/new-line-to-br", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newLineToBr = newLineToBr;
  _exports.default = void 0;
  var Handlebars = Ember.Handlebars;

  function newLineToBr() {
    var input = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var string = Handlebars.Utils.escapeExpression(input);
    string = string.replace(/(\r\n|\n|\r)/gm, '<br>');
    return Ember.String.htmlSafe(string);
  }

  var _default = Ember.Helper.helper(newLineToBr);

  _exports.default = _default;
});