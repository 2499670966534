define("ntfrontend/authenticators/token", ["exports", "ember-simple-auth/authenticators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    endpoint: Ember.inject.service(),
    router: Ember.inject.service(),
    fetcher: Ember.inject.service(),
    resourceName: 'user',
    tokenAttributeName: 'token',
    identificationAttributeName: 'email',
    restore: function restore(data) {
      var tokenAttributeName = this.tokenAttributeName,
          identificationAttributeName = this.identificationAttributeName;
      var tokenAttribute = Ember.get(data, tokenAttributeName);
      var identificationAttribute = Ember.get(data, identificationAttributeName);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(tokenAttribute) && !Ember.isEmpty(identificationAttribute)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },
    authenticate: function authenticate(token) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var resourceName = _this.resourceName;
        var data = {};
        data[resourceName] = {
          token: token
        };

        _this.makeRequest(data).then(function (response) {
          Ember.run(null, resolve, response);
        }, function (xhr) {
          Ember.run(null, reject, xhr.responseJSON || xhr.responseText);
        });
      });
    },
    invalidate: function invalidate(user) {
      return this.fetcher.delete(this.endpoint.logout, {
        data: {
          user: user
        }
      });
    },
    makeRequest: function makeRequest(data) {
      return this.fetcher.post(this.endpoint.login, {
        data: data
      });
    }
  });

  _exports.default = _default;
});