define("ntfrontend/components/documents/participation-members/delivery-status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    definedLabels: {
      'draft': 'brand--primary',
      'failed': 'brand--danger',
      'not_sent': '',
      'outdated': 'brand--warning',
      'sent': 'brand--success'
    },
    deliveryStatus: Ember.computed.alias('member.deliveryStatus'),
    statusClass: Ember.computed('deliveryStatus', function () {
      var definedLabels = this.definedLabels,
          deliveryStatus = this.deliveryStatus;
      return definedLabels[deliveryStatus] || '';
    }),
    statusTranslation: Ember.computed('deliveryStatus', function () {
      var deliveryStatus = this.deliveryStatus;
      return deliveryStatus ? "member.deliveries.".concat(deliveryStatus.camelize()) : '';
    }),
    click: function click() {
      this.set('showModal', true);
    }
  });

  _exports.default = _default;
});