define("ntfrontend/components/shared/tooltip-block/component", ["exports", "ntfrontend/mixins/components/tooltip"], function (_exports, _tooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tooltip.default, {
    intl: Ember.inject.service(),
    tagName: 'span',
    tooltipTitle: Ember.computed('type', function () {
      return this.intl.t(this.tooltipKey);
    })
  });

  _exports.default = _default;
});