define("ntfrontend/components/auth/register-form/country-select/component", ["exports", "ntfrontend/utils/countries"], function (_exports, _countries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['country-select'],
    'data-test-country-select': '',
    countries: _countries.default,
    selectedComponent: 'auth/register-form/country-select/selected'
  });

  _exports.default = _default;
});