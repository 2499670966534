define("ntfrontend/templates/user/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ix2Lbizh",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"page-title\",[[29,\"t\",[\"user.settings\"],null]],null],false],[0,\"\\n\\n\"],[7,\"h2\"],[11,\"class\",\"text-center page-header\"],[9],[1,[29,\"t\",[\"user.settings\"],null],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"col-sm-8 col-sm-offset-2\"],[9],[0,\"\\n  \"],[5,\"users/edit-form\",[],[[],[]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/user/edit.hbs"
    }
  });

  _exports.default = _default;
});