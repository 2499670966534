define("ntfrontend/components/projects/project-settings/type-set/component", ["exports", "ntfrontend/mixins/components/model-saving", "jquery"], function (_exports, _modelSaving, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    sweetAlert: Ember.inject.service(),
    projectTypes: Ember.computed.alias('model.account.projectTypes'),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _jquery.default)('.dropdown', this.element).on('show.bs.dropdown', function () {
        if (!_this.shownDropdown) {
          _this.set('shownDropdown', true);
        }
      });
    },
    actions: {
      changeType: function changeType(type) {
        var _this2 = this;

        this.sweetAlert.confirm('notify.projectTemplate.changing', null, function () {
          _this2.set('model.projectType', type);

          _this2.saveModelTask.perform();
        });
      }
    }
  });

  _exports.default = _default;
});