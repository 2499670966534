define("ntfrontend/components/members/add-member-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    disabled: Ember.computed('model.daysLeft', function () {
      return this.get('model.daysLeft') <= 0;
    }),
    actions: {
      redirectToNewMemberRoute: function redirectToNewMemberRoute() {
        this.router.transitionTo('account.users.new');
      }
    }
  });

  _exports.default = _default;
});