define("ntfrontend/components/auth/register-form/phone-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gJ53iNhl",
    "block": "{\"symbols\":[\"mat-label\"],\"statements\":[[5,\"shared/form-input\",[],[[\"@errors\"],[[23,\"errors\"]]],{\"statements\":[[0,\"\\n  \"],[5,\"shared/material-labels\",[],[[\"@key\",\"@labelFor\",\"@placeholder\"],[\"hasPhone\",\"inputPhone\",[29,\"t\",[\"auth.phoneNumber.placeholder\"],null]]],{\"statements\":[[0,\"\\n    \"],[5,\"input\",[],[[\"@class\",\"@id\",\"@maxlength\",\"@minlength\",\"@type\",\"@required\",\"@change\",\"@disabled\",\"@keyPress\",\"@value\",\"@key-up\"],[\"form-control input-label\",\"inputPhone\",\"20\",\"6\",\"tel\",\"true\",[23,\"onchange\"],[23,\"disabledPhoneInput\"],[23,\"validatePhoneInput\"],[23,\"phoneNumber\"],[24,1,[\"changeValue\"]]]]],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/auth/register-form/phone-input/template.hbs"
    }
  });

  _exports.default = _default;
});