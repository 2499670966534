define("ntfrontend/components/users/profile-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['profile-box'],
    editable: true,
    store: Ember.inject.service('store'),
    includeCollaborators: true,
    placeholderKey: 'user.actions.select',
    scope: Ember.computed.reads('currentSession.account'),
    userId: Ember.computed('user', function () {
      return this.get('user.id');
    }),
    actions: {
      userChange: function userChange(user) {
        var onUserChange = this.onUserChange;

        if (user) {
          this.set('user', user);
          onUserChange(user);
        } else {
          onUserChange(null);
        }
      }
    }
  });

  _exports.default = _default;
});