define("ntfrontend/components/transports/transport-list/documents-wrapper/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    documentsToDisplay: Ember.computed.mapBy('model.transportDocuments', 'document'),
    uniqDocumentToDisplay: Ember.computed.uniqBy('documentsToDisplay', 'symbolName'),
    firstTwoDocumentsForTransport: Ember.computed('uniqDocumentToDisplay', function () {
      return this.uniqDocumentToDisplay.slice(0, 2);
    }),
    hiddenDocumentsLength: Ember.computed('uniqDocumentToDisplay', function () {
      return this.uniqDocumentToDisplay.slice(2).length;
    })
  });

  _exports.default = _default;
});