define("ntfrontend/components/templates/templates-list/item/publish/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    tagName: 'a',
    classNames: 'icon-plane icon-hover-animation',
    click: function click() {
      this.model.set('published', true);
      this.saveModelTask.perform();
    },
    successCallback: function successCallback() {
      this.notify.success('notify.template.published');
    }
  });

  _exports.default = _default;
});