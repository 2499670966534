define("ntfrontend/components/shared/intro-js/step/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    attributeBindings: ['data-step', 'data-intro', 'data-position', 'data-highlightClass'],
    'data-step': Ember.computed.readOnly('step'),
    'data-highlightClass': Ember.computed.readOnly('highlightClass'),
    'data-position': Ember.computed('position', function () {
      return this.position || 'bottom';
    }),
    'data-intro': Ember.computed('subject', 'step', function () {
      var translationKey = "intro.".concat(this.subject, ".step").concat(this.step);
      return this.intl.t(translationKey);
    })
  });

  _exports.default = _default;
});