define("ntfrontend/components/accounts/document-types/sets/new-set/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "66IAsEPf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[11,\"class\",\"btn-success icon-plus-circle\"],[9],[1,[29,\"t\",[\"organization.projectTemplate.actions.create\"],null],false],[3,\"action\",[[24,0,[]],\"openModal\"]],[10],[0,\"\\n\\n\"],[5,\"shared/modal-dialog\",[],[[\"@showModal\",\"@onSuccess\",\"@title\"],[[23,\"showModal\"],[29,\"action\",[[24,0,[]],\"createProjectType\"],null],[29,\"t\",[\"organization.projectTemplate.labels.createProjectTemplate\"],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-body\"],[9],[0,\"\\n    \"],[5,\"shared/form-input\",[],[[\"@errors\"],[[25,[\"model\",\"errors\",\"name\"]]]],{\"statements\":[[0,\"\\n      \"],[5,\"input\",[],[[\"@type\",\"@value\",\"@class\",\"@placeholder\",\"@required\"],[\"text\",[25,[\"model\",\"name\"]],\"form-control\",[29,\"t\",[\"organization.projectTemplate.placeholders.name\"],null],\"true\"]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[5,\"shared/form-confirm-button\",[],[[\"@disabled\",\"@label\"],[[23,\"disabled\"],[29,\"t\",[\"shared.actions.create\"],null]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/accounts/document-types/sets/new-set/template.hbs"
    }
  });

  _exports.default = _default;
});