define("ntfrontend/utils/titleize-string", ["exports", "ntfrontend/utils/capitalize-word"], function (_exports, _capitalizeWord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = titleizeString;

  function titleizeString() {
    var string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var title = string.replace(/[ \-_]+/g, ' ');
    var words = title.match(/\w+/g) || [];
    return words.map(function (w) {
      return (0, _capitalizeWord.default)(w);
    }).join(' ');
  }
});