define("ntfrontend/components/members/select-role/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "beAXr1/b",
    "block": "{\"symbols\":[\"role\"],\"statements\":[[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n  \"],[5,\"shared/loading-state/block\",[],[[\"@model\",\"@class\"],[[23,\"roles\"],\"roles\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[25,[\"filteredRoles\"]]],null,{\"statements\":[[0,\"      \"],[5,\"members/select-role/role\",[],[[\"@model\",\"@currentRole\",\"@setRole\",\"@account\"],[[24,1,[]],[23,\"currentRole\"],[23,\"setRole\"],[23,\"account\"]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/members/select-role/template.hbs"
    }
  });

  _exports.default = _default;
});