define("ntfrontend/components/documents/distribute-document/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+De5oK2X",
    "block": "{\"symbols\":[],\"statements\":[[5,\"shared/modal-dialog\",[],[[\"@showModal\",\"@modalClass\",\"@onSuccess\",\"@title\"],[[23,\"showModal\"],\"new-task-modal\",[29,\"action\",[[24,0,[]],\"distribute\"],null],[29,\"t\",[\"shared.actions.share\"],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-body row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-sm-6 col-sm-offset-3\"],[9],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"memberId\"]]],null,{\"statements\":[[0,\"          \"],[1,[29,\"t\",[[25,[\"distributeMessage\"]]],[[\"userName\"],[[25,[\"member\",\"user\",\"displayName\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[29,\"t\",[[25,[\"distributeMessage\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n      \"],[5,\"shared/textarea-autosize\",[],[[\"@value\",\"@class\",\"@placeholder\"],[[23,\"distributionMessage\"],\"form-control\",[29,\"t\",[\"document.share.optionalMessagePlaceholder\"],null]]]],[0,\"\\n      \"],[5,\"shared/form-confirm-button\",[],[[\"@disabled\",\"@label\"],[[23,\"disabled\"],[29,\"t\",[\"document.actions.share\"],null]]]],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/documents/distribute-document/template.hbs"
    }
  });

  _exports.default = _default;
});