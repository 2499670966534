define("ntfrontend/components/tasks/new-task/copy-task/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      focusInput: function focusInput() {
        this.set('modalJustShown', true);
      },
      afterTaskCreation: function afterTaskCreation() {
        this.set('modalJustShown', false);
        this.closeModal();
      }
    }
  });

  _exports.default = _default;
});