define("ntfrontend/components/custom-fields/document-list/item-row/item/inline-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H3F0mcnR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"editable\"]]],null,{\"statements\":[[0,\"  \"],[5,\"shared/inplace-input\",[],[[\"@afterChange\",\"@editable\",\"@type\",\"@value\",\"@class\",\"@placeholderKey\"],[[23,\"afterChange\"],[23,\"editable\"],[23,\"inputType\"],[25,[\"customValue\",\"value\"]],\"table-input\",\"organization.customField.placeholders.setValue\"]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"custom-fields/document-list/item-row/item/value\",[],[[\"@value\"],[[25,[\"customValue\",\"value\"]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/custom-fields/document-list/item-row/item/inline-input/template.hbs"
    }
  });

  _exports.default = _default;
});