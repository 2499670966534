define("ntfrontend/models/memo", ["exports", "ember-data", "ntfrontend/models/document", "ntfrontend/mixins/models/topicable", "ntfrontend/mixins/models/tagable"], function (_exports, _emberData, _document, _topicable, _tagable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = _document.default.extend(_topicable.default, _tagable.default, {
    startAt: attr('date'),
    followingMemo: belongsTo('memo', {
      inverse: null
    }),
    transportDocuments: hasMany()
  });

  _exports.default = _default;
});