define("ntfrontend/components/templates/new-template/component", ["exports", "ntfrontend/models/document", "ntfrontend/mixins/components/model-saving"], function (_exports, _document, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    store: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    modelName: 'documentTemplate',
    projectTypes: Ember.computed.alias('currentSession.account.projectTypes'),
    actions: {
      openModal: function openModal() {
        this._createTemplateObject();

        this.set('showModal', true);
      },
      createDocumentTemplate: function createDocumentTemplate() {
        var _this = this;

        var properties = this._getModelProperties();

        this._setObjectProperties(properties);

        var record = this.store.createRecord(this.modelName, properties);
        this.set('model', record);
        this.set('disabled', true);
        return this.saveModelTask.perform().then(function () {
          _this.set('disabled', false);

          _this.refreshProjectTemplates();
        });
      }
    },
    _createTemplateObject: function _createTemplateObject() {
      this.set('model', Ember.Object.create({
        account: this.get('currentSession.account'),
        documentType: _document.documentsNames[0],
        projectTypes: []
      }));
    },
    _setObjectProperties: function _setObjectProperties(properties) {
      Ember.set(properties, 'documentType', this.model.get('documentType'));
      Ember.set(properties, 'projectTypes', this.model.get('projectTypes'));
    },
    _getModelProperties: function _getModelProperties() {
      return this.model.getProperties(['name', 'account']);
    }
  });

  _exports.default = _default;
});