define("ntfrontend/components/issues/issue-details/component", ["exports", "ntfrontend/mixins/components/model-saving", "ntfrontend/mixins/components/document-show", "ntfrontend/mixins/components/automatic-changes-notifier", "ntfrontend/mixins/components/label-editing"], function (_exports, _modelSaving, _documentShow, _automaticChangesNotifier, _labelEditing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, _documentShow.default, _automaticChangesNotifier.default, _labelEditing.default, {
    can: Ember.inject.service(),
    introIsRunning: false,
    editable: Ember.computed('model', function () {
      return this.can.can('edit issue', this.model);
    }),
    filterUsers: Ember.computed.collect('model.owner', 'model.requester')
  });

  _exports.default = _default;
});