define("ntfrontend/components/accounts/subscriptions/plan-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zj9HErj8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pricing-plans__plan\"],[9],[0,\"\\n  \"],[7,\"h1\"],[11,\"class\",\"pricing-plans__header\"],[9],[1,[23,\"headerText\"],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pricing-description\"],[9],[1,[23,\"managersText\"],false],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"pricing-description\"],[9],[1,[23,\"collaboratorsText\"],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pricing-plans__price\"],[9],[1,[23,\"priceText\"],false],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"pricing-plans__duration text-muted\"],[9],[1,[29,\"t\",[\"subscription.perMonth\"],null],false],[10],[0,\"\\n\\n\"],[4,\"if\",[[29,\"not-eq\",[[25,[\"planName\"]],[25,[\"currentSubscriptionPlan\"]]],null]],null,{\"statements\":[[4,\"if\",[[25,[\"activeSubscription\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\"],[11,\"class\",\"btn-success\"],[12,\"disabled\",[23,\"isDisabled\"]],[11,\"type\",\"button\"],[9],[0,\"\\n        \"],[1,[29,\"t\",[\"subscription.changeButton\"],null],false],[0,\"\\n      \"],[3,\"action\",[[24,0,[]],\"changePlan\",[25,[\"planName\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"a\"],[12,\"class\",[30,[\"btn-success \",[29,\"if\",[[25,[\"isDisabled\"]],\"disabled\"],null]]]],[12,\"href\",[23,\"planUrl\"]],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener noreferrer\"],[9],[0,\"\\n        \"],[1,[29,\"t\",[\"subscription.orderButton\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"changeInProgress\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"pricing-plans__info animated fadeInDown\"],[9],[0,\"\\n    \"],[1,[29,\"t\",[\"subscription.changeInProgress\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/accounts/subscriptions/plan-picker/template.hbs"
    }
  });

  _exports.default = _default;
});