define("ntfrontend/components/auth/welcome-page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HzlsrMJ+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\"],[11,\"class\",\"mt--auto\"],[9],[1,[29,\"t\",[\"auth.welcome.title\"],[[\"name\"],[[25,[\"name\"]]]]],false],[10],[0,\"\\n\\n\"],[7,\"h4\"],[11,\"class\",\"mb\"],[9],[1,[29,\"t\",[\"auth.welcome.message\"],null],false],[10],[0,\"\\n\\n\"],[7,\"div\"],[9],[1,[29,\"t\",[\"auth.welcome.instruction\"],null],false],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/auth/welcome-page/template.hbs"
    }
  });

  _exports.default = _default;
});