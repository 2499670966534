define("ntfrontend/components/templates/project-templates/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v1DdZFzE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[11,\"class\",\"topic__creator\"],[9],[0,\"\\n  \"],[5,\"users/profile-box/picture\",[],[[\"@user\",\"@width\",\"@height\"],[[25,[\"model\",\"creator\"]],\"30\",\"30\"]]],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"td\"],[11,\"class\",\"topic__name\"],[9],[1,[25,[\"model\",\"name\"]],false],[10],[0,\"\\n\\n\"],[7,\"td\"],[9],[1,[29,\"format-date\",[[25,[\"model\",\"createdAt\"]]],[[\"fallback\",\"format\"],[\"-\",\"short\"]]],false],[10],[0,\"\\n\\n\"],[7,\"td\"],[11,\"class\",\"topic__thumbnail\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"model\",\"thumbnail\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\"],[12,\"src\",[25,[\"model\",\"thumbnail\"]]],[12,\"alt\",[23,\"thumbnailAltText\"]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[7,\"td\"],[11,\"class\",\"topic__description\"],[9],[1,[23,\"rawDescription\"],false],[10],[0,\"\\n\\n\"],[7,\"td\"],[11,\"class\",\"table__actions\"],[9],[0,\"\\n\"],[4,\"unless\",[[25,[\"model\",\"published\"]]],null,{\"statements\":[[0,\"    \"],[5,\"templates/templates-list/item/publish\",[],[[\"@model\"],[[23,\"model\"]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[5,\"templates/project-templates/item/dropdown\",[],[[\"@model\"],[[23,\"model\"]]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/templates/project-templates/item/template.hbs"
    }
  });

  _exports.default = _default;
});