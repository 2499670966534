define("ntfrontend/services/intl", ["exports", "ember-intl/services/intl"], function (_exports, _intl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _intl.default.extend({
    errorKeys: function errorKeys(code) {
      var title = '';
      var message = '';

      if (code) {
        var titleKey = "errors.".concat(code, ".title");
        var messageKey = "errors.".concat(code, ".message");
        title = this.t(titleKey) ? titleKey : 'notify.default.error.title';
        message = this.t(messageKey) ? messageKey : 'notify.default.error.message';
      }

      return {
        title: title,
        message: message
      };
    }
  });

  _exports.default = _default;
});