define("ntfrontend/models/project-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    name: attr(),
    description: attr('string'),
    thumbnail: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    published: attr('boolean'),
    account: belongsTo(),
    creator: belongsTo('user'),
    ability: belongsTo(),
    customFields: hasMany(),
    documentTemplates: hasMany(),
    accountName: attr('string')
  });

  _exports.default = _default;
});