define("ntfrontend/utils/ajaxer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.errorize = errorize;
  _exports.reduceObject = reduceObject;
  var errorsTransformer = Ember.Object.extend({
    init: function init() {
      this.set('data', {});
    },
    buildError: function buildError(error) {
      if (error.source) {
        var field = this.getErrorField(error);
        this.addOrCreateErrorForField(field, error);
      }
    },
    getErrorField: function getErrorField(error) {
      return error.source.pointer.split('/').pop().camelize();
    },
    addOrCreateErrorForField: function addOrCreateErrorForField(field, error) {
      if (Ember.isNone(this.data[field])) {
        this.data[field] = [];
      }

      this.data[field].push({
        message: error.detail
      });
      return this.data;
    }
  });

  function errorize(errors) {
    var errorsObject = errorsTransformer.create();

    if (errors && errors.length) {
      errors.forEach(function (error) {
        errorsObject.buildError(error);
      });
    }

    return errorsObject.data;
  }

  function reduceObject(object) {
    for (var obj in object) {
      if (Ember.isBlank(object[obj])) {
        delete object[obj];
      } else if (Ember.isNone(object[obj])) {
        delete object[obj];
      }
    }

    return object;
  }
});