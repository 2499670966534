define("ntfrontend/components/shared/material-labels/label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hwbDO3I6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\"],[7,\"label\"],[12,\"class\",[30,[\"material-label__input\\n    \",[29,\"if\",[[25,[\"hasValue\"]],\"material-label__show\"],null],\"\\n    \",[29,\"if\",[[29,\"eq\",[[25,[\"labelFor\"]],\"inputPhone\"],null],\"-transparent\"],null]]]],[12,\"for\",[23,\"labelFor\"]],[9],[0,\"\\n  \"],[1,[23,\"placeholder\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/material-labels/label/template.hbs"
    }
  });

  _exports.default = _default;
});