define("ntfrontend/components/shared/calendar-date/component", ["exports", "ntfrontend/utils/date-utils"], function (_exports, _dateUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['calendar-date'],
    classNameBindings: ['past:calendar-date--past', 'empty:calendar-date--empty'],
    intl: Ember.inject.service('intl'),
    empty: Ember.computed.empty('date'),
    past: Ember.computed('date', function () {
      return (0, _dateUtils.pastDate)(this.date);
    }),
    formattedYear: Ember.computed('date', 'isCurrentYear', function () {
      if (!Ember.isBlank(this.date) && !this.isCurrentYear) {
        return this.intl.formatTime(this.date, {
          year: 'numeric'
        });
      }
    }),
    formattedMonth: Ember.computed('date', function () {
      if (!Ember.isBlank(this.date)) {
        return this.intl.formatTime(this.date, {
          month: 'short'
        });
      }
    }),
    formattedDayOfMonth: Ember.computed('date', 'editable', function () {
      if (!Ember.isBlank(this.date)) {
        return this.intl.formatTime(this.date, {
          day: '2-digit'
        });
      } else if (this.editable) {
        return this.intl.t('shared.placeholders.setDate');
      } else {
        return this.intl.t('shared.placeholders.noDate');
      }
    }),
    formattedTime: Ember.computed('date', function () {
      if (!Ember.isBlank(this.date) && !(0, _dateUtils.isMidnight)(this.date)) {
        return this.intl.formatTime(this.date, {
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
        });
      }
    }),
    isCurrentYear: Ember.computed('date', function () {
      return (0, _dateUtils.currentYear)(this.date);
    })
  });

  _exports.default = _default;
});