define("ntfrontend/components/shared/attachments/file-list/component", ["exports", "ntfrontend/helpers/model-type-name"], function (_exports, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['uploader-files'],
    attachmentsLoaded: Ember.observer('attachments.isFulfilled', function () {
      if (this.get('attachments.isFulfilled') && this.onLoaded) {
        this.onLoaded();
      }
    }),
    attachments: Ember.computed('model.attachments', 'sort', function () {
      return this._sortTable(this.sort);
    }),
    modelType: Ember.computed('model', function () {
      return (0, _modelTypeName.modelTypeName)([this.model]);
    }),
    _sortTable: function _sortTable(property) {
      var _this = this;

      var sortedAttachments = [];

      if (property) {
        sortedAttachments = this.get('model.attachments').filter(function (x) {
          return x;
        }).sort(function (a, b) {
          if (_this.get('sort')[0] === '-') {
            if (_this.get('sort') === '-creator') {
              return b[property.substring(1)].get('displayName').localeCompare(a[property.substring(1)].get('displayName'));
            }

            if (_this.get('sort') === '-createdAt') {
              return new Date(b[property.substring(1)]) - new Date(a[property.substring(1)]);
            }

            return b[property.substring(1)].localeCompare(a[property.substring(1)]);
          }

          if (_this.get('sort') === 'creator') {
            return a[property].get('displayName').localeCompare(b[property].get('displayName'));
          }

          if (_this.get('sort') === 'createdAt') {
            return new Date(a[property]) - new Date(b[property]);
          }

          return a[property].localeCompare(b[property]);
        });
      }

      return sortedAttachments.length > 0 ? sortedAttachments : this.model.attachments;
    },
    actions: {
      afterRemove: function afterRemove() {
        this.notify.success('notify.attachment.removed');
      },
      openPreview: function openPreview(attachment) {
        this.set('imagePreviewUrl', attachment.get('url'));
        this.set('showModal', true);
      },
      refreshAttachments: function refreshAttachments() {
        this.notifyPropertyChange('attachments');
      },
      updateSort: function updateSort(newSort) {
        this.set('sort', newSort);
      }
    }
  });

  _exports.default = _default;
});