define("ntfrontend/components/documents/meta-data/component", ["exports", "ntfrontend/mixins/components/tooltip", "ntfrontend/utils/url-maker", "ntfrontend/helpers/document-id"], function (_exports, _tooltip, _urlMaker, _documentId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tooltip.default, {
    intl: Ember.inject.service(),
    tooltipElement: '.document-id-wrapper',
    linkUrl: Ember.computed('taskId', 'documentId', function () {
      var subdomain = _urlMaker.default.subdomainUrl(this.get('currentSession.subdomain'));

      return "".concat(subdomain, "/").concat(this.documentId);
    }),
    documentId: Ember.computed('model', function () {
      return (0, _documentId.documentId)([this.model], {
        short: true,
        allowSlug: true
      });
    }),
    tooltipTitle: Ember.computed('taskId', function () {
      return this.intl.t('document.placeholders.shortLink', {
        url: this.linkUrl
      });
    })
  });

  _exports.default = _default;
});