define("ntfrontend/templates/projects/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lBEqvvME",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"page-title\",[[29,\"t\",[\"shared.labels.projects\"],null]],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"page-header\"],[9],[0,\"\\n  \"],[5,\"shared/header-with-total\",[],[[\"@labelKey\",\"@total\"],[\"shared.labels.projects\",[25,[\"model\",\"meta\",\"total\"]]]]],[0,\"\\n  \"],[5,\"shared/search-input\",[],[[\"@value\"],[[23,\"q\"]]]],[0,\"\\n  \"],[5,\"projects/project-filters\",[],[[\"@requesterId\",\"@ownerId\",\"@statusId\",\"@archived\"],[[23,\"requesterId\"],[23,\"ownerId\"],[23,\"statusId\"],[23,\"archived\"]]]],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[5,\"projects/project-list\",[],[[\"@model\"],[[23,\"model\"]]]],[0,\"\\n\"],[5,\"pagination-list\",[],[[\"@model\",\"@currentPage\",\"@perPage\",\"@changePage\"],[[23,\"model\"],[23,\"page\"],[23,\"perPage\"],[29,\"action\",[[24,0,[]],\"changePage\"],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/projects/index.hbs"
    }
  });

  _exports.default = _default;
});