define("ntfrontend/components/shared/header-sort/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'th',
    classNames: 'table__header-sortable',
    click: function click() {
      this.toggleSortDirection();
    },
    isAsc: Ember.computed('name', 'sort', function () {
      return new RegExp("^".concat(this.name)).test(this.sort);
    }),
    isSortable: Ember.computed('name', 'sort', function () {
      return new RegExp("^[-]".concat(this.name, "|^").concat(this.name, "$")).test(this.sort);
    }),
    toggleSortDirection: function toggleSortDirection() {
      var newSort = this.isAsc ? "-".concat(this.name) : this.name;
      this.set('sort', newSort);

      if (this.onChange) {
        this.onChange(newSort);
      }
    },
    iconClass: Ember.computed('isAsc', 'isSortable', function () {
      var klass = this.isAsc ? 'icon-arrow-up' : 'icon-arrow-down';

      if (!this.isSortable) {
        klass = "".concat(klass, " hidden");
      }

      return klass;
    })
  });

  _exports.default = _default;
});