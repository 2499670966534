define("ntfrontend/components/templates/project-templates/edit/project-types/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YPQnWimE",
    "block": "{\"symbols\":[\"projectType\"],\"statements\":[[4,\"each\",[[25,[\"projectTypes\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\"],[11,\"class\",\"project-template__type\"],[9],[0,\"\\n    \"],[1,[24,1,[\"name\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/templates/project-templates/edit/project-types/template.hbs"
    }
  });

  _exports.default = _default;
});