define("ntfrontend/components/subscription-reminder/reminder-subscription/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "97jYSS7Z",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,\"trialInfoLabel\"],false],[0,\"\\n\\n\"],[5,\"link-to\",[],[[\"@route\"],[\"account.subscriptions\"]],{\"statements\":[[0,\"\\n  \"],[1,[29,\"t\",[\"subscription.upgradeSubscription\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/subscription-reminder/reminder-subscription/template.hbs"
    }
  });

  _exports.default = _default;
});