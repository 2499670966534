define("ntfrontend/components/projects/project-details/name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6Nr//hpO",
    "block": "{\"symbols\":[],\"statements\":[[5,\"shared/inplace-input\",[],[[\"@afterChange\",\"@editable\",\"@value\"],[[29,\"action\",[[24,0,[]],\"saveModel\"],null],[29,\"can\",[\"edit project\",[25,[\"model\"]]],null],[23,\"value\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/project-details/name/template.hbs"
    }
  });

  _exports.default = _default;
});