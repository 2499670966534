define("ntfrontend/components/steps/step-item/status/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    statuses: ['open', 'failed', 'done'],
    statusClass: Ember.computed('currentStatus', function () {
      return "step__status__".concat(this.currentStatus);
    }),
    currentStatus: Ember.computed('model.status', function () {
      return this.statuses[this.get('model.status')];
    }),
    disabled: Ember.computed('formObject.{status,owner.id,outcome}', 'currentStatus', function () {
      return this.get('formObject.status') === this.currentStatus || !this.get('formObject.owner.id') || !this.get('formObject.outcome');
    }),
    actions: {
      openModal: function openModal() {
        if (this.editable) {
          this.set('formObject', Ember.Object.create({
            status: this.currentStatus,
            owner: this.get('model.owner'),
            outcome: this.get('model.outcome')
          }));
          this.set('showModal', true);
        }
      },
      saveStatus: function saveStatus() {
        var _this = this;

        var statusIndex = this.statuses.indexOf(this.get('formObject.status'));
        this.model.setProperties({
          status: statusIndex,
          owner: this.get('formObject.owner'),
          outcome: this.get('formObject.outcome')
        });
        this.saveModelTask.perform().then(function () {
          _this.document.reload();
        });
        return Ember.RSVP.resolve();
      }
    }
  });

  _exports.default = _default;
});