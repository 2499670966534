define("ntfrontend/components/templates/document-templates/edit/publish-switcher/component", ["exports", "ntfrontend/mixins/components/model-saving", "ntfrontend/mixins/components/switcher", "jquery"], function (_exports, _modelSaving, _switcher, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, _switcher.default, {
    state: 'published',
    didInsertElement: function didInsertElement() {
      this._setupSwitcher();
    },
    // private
    _setupSwitcher: function _setupSwitcher() {
      var _this = this;

      (0, _jquery.default)('input', this.element).bootstrapSwitch(this._options());
      (0, _jquery.default)('input', this.element).on('switchChange.bootstrapSwitch', function () {
        if (_this.disabled) {
          (0, _jquery.default)('input', _this.element).bootstrapSwitch('toggleState', true);
        } else {
          _this.toggleState();
        }
      });
    },
    _options: function _options() {
      return {
        onColor: 'success',
        offColor: 'warning',
        disabled: this.disabledInput,
        onText: this.intl.t('shared.switcher.active'),
        offText: this.intl.t('shared.switcher.draft')
      };
    },
    _afterSaveError: function _afterSaveError() {
      this._super.apply(this, arguments);

      (0, _jquery.default)('input', this.element).bootstrapSwitch('toggleState', true);
    },
    _afterSaveSuccess: function _afterSaveSuccess() {
      this.notify.success('notify.template.updated');
    }
  });

  _exports.default = _default;
});