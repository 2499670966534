define("ntfrontend/components/auth/register-form/country-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lo/vJx86",
    "block": "{\"symbols\":[\"country\"],\"statements\":[[5,\"power-select\",[],[[\"@options\",\"@placeholder\",\"@selected\",\"@selectedItemComponent\",\"@searchField\",\"@onchange\"],[[23,\"countries\"],\"Country\",[23,\"country\"],[23,\"selectedComponent\"],\"name\",[23,\"onchange\"]]],{\"statements\":[[0,\"\\n  \"],[1,[24,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/auth/register-form/country-select/template.hbs"
    }
  });

  _exports.default = _default;
});