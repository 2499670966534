define("ntfrontend/components/shared/input-datefield/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['picker-container', 'input-group', 'date'],
    defaultFormat: 'DD/MM/YYYY hh:mm',
    didInsertElement: function didInsertElement() {
      this.setupPicker();
      this.setDate(this.value || moment().format('DD/MM/YYYY'));
      this.handleHideEvent();
      this.showPicker();
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(this.element).data('DateTimePicker').destroy();

      this._super.apply(this, arguments);
    },
    date: Ember.computed.reads('value'),
    setupPicker: function setupPicker() {
      (0, _jquery.default)(this.element).datetimepicker({
        format: this.format || this.defaultFormat,
        icons: {
          time: 'icon-time',
          date: 'icon-calendar',
          up: 'icon-arrow-up',
          down: 'icon-arrow-down',
          previous: 'icon-arrow-left',
          next: 'icon-arrow-right'
        }
      });
    },
    onDateChanged: function onDateChanged(event) {
      var date = event.date._d;
      this.changeDate(date);
      (0, _jquery.default)(this.element).data('DateTimePicker').hide();
    },
    handleHideEvent: function handleHideEvent() {
      var _this = this;

      (0, _jquery.default)(this.element).on('dp.hide', function (event) {
        Ember.run.scheduleOnce('afterRender', _this, _this.onDateChanged, event);
      });
    },
    setDate: function setDate(date) {
      (0, _jquery.default)(this.element).data('DateTimePicker').date(date);
      (0, _jquery.default)(this.element).data('DateTimePicker').viewDate(date);
    },
    showPicker: function showPicker() {
      moment.updateLocale('en', {
        week: {
          dow: 1
        }
      });
      (0, _jquery.default)(this.element).data('DateTimePicker').show();
    }
  });

  _exports.default = _default;
});