define("ntfrontend/components/templates/project-templates/new/component", ["exports", "ntfrontend/components/templates/new-template/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    modelName: 'projectType',
    _createTemplateObject: function _createTemplateObject() {
      this.set('model', Ember.Object.create({
        account: this.get('currentSession.account'),
        creator: this.get('currentSession.user'),
        projectTypes: []
      }));
    },
    _setObjectProperties: function _setObjectProperties(properties) {
      var model = this.model;
      Ember.set(properties, 'projectTypes', model.get('projectTypes'));
      Ember.set(properties, 'creator', model.get('creator'));
    },
    _getModelProperties: function _getModelProperties() {
      return this.model.getProperties(['name', 'account', 'description', 'thumbnail']);
    }
  });

  _exports.default = _default;
});