define("ntfrontend/components/projects/dashboard/state-status/state/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var stateClasses = {
    'unknown': 'unknown',
    'onTrack': 'good',
    'attention': 'ok',
    'critical': 'bad',
    'notRelevant': 'not-relevant'
  };

  var _default = Ember.Component.extend({
    scope: 'project.dashboard',
    labelValue: Ember.computed('state', function () {
      return "".concat(this.scope, ".states.").concat(this.state, ".label");
    }),
    cssState: Ember.computed('state', function () {
      return stateClasses[this.state];
    })
  });

  _exports.default = _default;
});