define("ntfrontend/components/templates/templates-list/item/dropdown/clone/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    notify: Ember.inject.service('notify-alert'),
    actions: {
      openModal: function openModal() {
        this.set('showModal', true);
      },
      clone: function clone() {
        var _this = this;

        var data = {
          name: this.name
        };
        return this.model.clone({
          data: data
        }).then(function () {
          _this.refreshProjectTemplates();

          _this.notify.success('notify.template.cloned');
        });
      }
    }
  });

  _exports.default = _default;
});