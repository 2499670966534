define("ntfrontend/templates/projects/project/transport", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xKRxVX+d",
    "block": "{\"symbols\":[],\"statements\":[[5,\"transports\",[],[[\"@sort\",\"@q\",\"@projectId\",\"@documentId\",\"@creatorId\",\"@startDate\",\"@endDate\",\"@systems\",\"@page\",\"@perPage\",\"@changePage\"],[[24,0,[\"sort\"]],[24,0,[\"q\"]],[24,0,[\"model\",\"project\",\"id\"]],[24,0,[\"documentId\"]],[24,0,[\"creatorId\"]],[24,0,[\"startDate\"]],[24,0,[\"endDate\"]],[24,0,[\"systems\"]],[24,0,[\"page\"]],[24,0,[\"perPage\"]],[29,\"action\",[[24,0,[]],\"changePage\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/projects/project/transport.hbs"
    }
  });

  _exports.default = _default;
});