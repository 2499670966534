define("ntfrontend/controllers/projects/project/members", ["exports", "ember-parachute", "ntfrontend/mixins/controllers/pagination-utils", "ntfrontend/query-params/shared/pagination"], function (_exports, _emberParachute, _paginationUtils, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.queryParams = void 0;
  var queryParams = new _emberParachute.default(_pagination.default);
  _exports.queryParams = queryParams;

  var _default = Ember.Controller.extend(_paginationUtils.default, queryParams.Mixin);

  _exports.default = _default;
});