define("ntfrontend/components/create-page/tabs-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['create__item'],
    classNameBindings: ['isActive:-active', 'itemClassName'],
    click: function click() {
      this.tabChange(this.item);
    },
    itemClassName: Ember.computed('activeItem', function () {
      return "-".concat(this.item);
    }),
    isActive: Ember.computed('activeItem', function () {
      return this.activeItem === this.item;
    }),
    iconClassName: Ember.computed('item', function () {
      return "create__icon icon-".concat(this.item, "s");
    }),
    resourceName: Ember.computed('item', function () {
      return "shared.labels.".concat(this.item);
    }),
    resourceDescription: Ember.computed('item', function () {
      return "createPage.descriptions.".concat(this.item);
    })
  });

  _exports.default = _default;
});