define("ntfrontend/helpers/image-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (type) {
    return new RegExp('^image/.+').test(type);
  });

  _exports.default = _default;
});