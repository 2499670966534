define("ntfrontend/components/documents/document-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b/Gmvo9v",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"padding-0\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"work-package-list-item\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"model\",\"workPackageList\",\"name\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[12,\"style\",[30,[\"background-color: #\",[29,\"generate-color\",[[25,[\"model\",\"workPackageList\",\"name\"]]],null]]]],[12,\"class\",[30,[\"work-package-indicator-without-hover \",[29,\"if\",[[25,[\"model\",\"workPackageList\",\"name\"]],\"work-package-indicator\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"model\",\"workPackageList\",\"name\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"class\",\"work-package-description-container\"],[9],[0,\"\\n            \"],[1,[25,[\"model\",\"workPackageList\",\"name\"]],false],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"work-package-indicator-without-hover\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[7,\"div\"],[9],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[[23,\"redirectLink\"],[25,[\"model\",\"slug\"]]]],{\"statements\":[[0,\"\\n        \"],[1,[25,[\"model\",\"name\"]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/documents/document-name/template.hbs"
    }
  });

  _exports.default = _default;
});