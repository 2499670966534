define("ntfrontend/components/accounts/subscriptions/plan-picker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: 'pricing-plans__container',
    classNameBindings: ['isActive:pricing-plans__plan--active'],
    planName: null,
    updateSubscription: null,
    plansLimits: {
      free: 1,
      starter: 5,
      regular: 20,
      plus: 50,
      enterprise: 100
    },
    isActive: Ember.computed('currentSubscriptionPlan', 'planName', function () {
      return this.currentSubscriptionPlan === this.planName;
    }),
    isDisabled: Ember.computed('currentSubscription', 'planName', function () {
      return this.plansLimits[this.planName] < this.managersCount || this.get('updateSubscription.isRunning');
    }),
    headerText: Ember.computed('planName', function () {
      return this._translationFor('header');
    }),
    managersText: Ember.computed('planName', function () {
      return this._translationFor('managers');
    }),
    collaboratorsText: Ember.computed('planName', function () {
      return this._translationFor('collaborators');
    }),
    priceText: Ember.computed('planName', function () {
      return this._translationFor('price');
    }),
    planUrl: Ember.computed('planName', 'userParams', function () {
      if (!this.isDisabled) {
        return "https://".concat(EmberENV.recurlySubdomain, ".recurly.com/subscribe/").concat(this.planName, "?").concat(this.userParams);
      }
    }),
    userParams: Ember.computed('user.currentAccount.id', function () {
      // TODO: Edge case when user has one word name
      var _this$get$split = this.get('user.name').split(' '),
          _this$get$split2 = _slicedToArray(_this$get$split, 2),
          firstName = _this$get$split2[0],
          lastName = _this$get$split2[1];

      var firstNameParam = "first_name=".concat(firstName);
      var lastNameParam = "last_name=".concat(lastName);
      var emailParam = "email=".concat(this.get('user.email'));
      var accountCode = "account_code=".concat(this.get('user.currentAccount.id'));
      return [accountCode, firstNameParam, lastNameParam, emailParam].join('&');
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var currentPlan = this.currentPlan,
          currentSubscriptionPlan = this.currentSubscriptionPlan;

      if (!currentSubscriptionPlan) {
        this.set('currentSubscriptionPlan', currentPlan);
      }
    },
    actions: {
      changePlan: function changePlan(planName) {
        var _this = this;

        return this.updateSubscription.perform(planName).then(function () {
          _this.set('changeInProgress', true);
        });
      }
    },
    _translationFor: function _translationFor(key) {
      return this.intl.t("subscription.plans.".concat(this.planName, ".").concat(key));
    }
  });

  _exports.default = _default;
});