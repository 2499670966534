define("ntfrontend/components/accounts/create-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A7/3sPkD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[11,\"class\",\"auth-form\"],[9],[0,\"\\n  \"],[5,\"shared/form-input\",[],[[\"@errors\"],[[25,[\"account\",\"errors\",\"name\"]]]],{\"statements\":[[0,\"\\n    \"],[5,\"input\",[],[[\"@type\",\"@value\",\"@id\",\"@class\",\"@placeholder\",\"@required\",\"@autofocus\"],[\"text\",[25,[\"account\",\"name\"]],\"inputOrganization\",\"form-control\",[29,\"t\",[\"auth.organization.placeholder\"],null],\"true\",\"true\"]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"shared/form-input\",[],[[\"@errors\"],[[25,[\"account\",\"errors\",\"domain\"]]]],{\"statements\":[[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"input-group\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"input-group-addon\"],[9],[1,[29,\"t\",[\"organization.domain.httpPart\"],null],false],[10],[0,\"\\n      \"],[5,\"input\",[],[[\"@type\",\"@value\",\"@id\",\"@class\",\"@placeholder\",\"@required\"],[\"text\",[23,\"selectedDomainName\"],\"inputOrganizationUrl\",\"form-control\",[29,\"t\",[\"organization.domain.placeholder\"],null],\"true\"]]],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"input-group-addon\"],[9],[1,[29,\"t\",[\"organization.domain.9TeamsPart\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"shared/form-confirm-button\",[],[[\"@disabled\",\"@label\"],[[23,\"disabled\"],[29,\"t\",[\"organization.create\"],null]]]],[0,\"\\n\"],[3,\"action\",[[24,0,[]],[29,\"perform\",[[25,[\"createAccount\"]]],null]],[[\"on\"],[\"submit\"]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/accounts/create-form/template.hbs"
    }
  });

  _exports.default = _default;
});