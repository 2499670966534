define("ntfrontend/routes/projects/project/dashboard", ["exports", "ntfrontend/routes/org-auth", "ntfrontend/mixins/routes/ember-can"], function (_exports, _orgAuth, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend(_emberCan.default, {
    requireAbilities: Ember.computed('abilities', function () {
      this.set('abilities', {
        'display dashboard in project': this.modelFor('projects.project')
      });
    }),
    model: function model() {
      var project = this.modelFor('projects.project');
      return Ember.RSVP.hash({
        dashboard: project.get('dashboard'),
        project: project
      });
    }
  });

  _exports.default = _default;
});