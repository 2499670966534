define("ntfrontend/components/works/create-tab/details/date-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'data-test-date-select': '',
    classNames: 'picker--small',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.value) {
        this.set('date', this.value);
      }
    },
    actions: {
      setWorkedHours: function setWorkedHours() {
        this.setWorkDate(this.date);
      }
    }
  });

  _exports.default = _default;
});