define("ntfrontend/templates/account/subscriptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xZ5UdyE5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"page-header\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[1,[29,\"t\",[\"subscription.chooseSubscription\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[5,\"accounts/subscriptions/plan-table\",[],[[],[]]],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"pricing-more-info\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"icon-help\"],[11,\"href\",\"https://www.9teams.com/pricing/\"],[9],[0,\" \"],[1,[29,\"t\",[\"subscription.moreInfo\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/account/subscriptions.hbs"
    }
  });

  _exports.default = _default;
});