define("ntfrontend/components/create-page/tabs-content/work/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AeiIOwKT",
    "block": "{\"symbols\":[\"@projectSlug\",\"@resourceName\",\"@resourceDescription\",\"@transitionPath\"],\"statements\":[[5,\"works/create-tab/new-document\",[],[[\"@objectType\",\"@projectSlug\",\"@resourceName\",\"@resourceDescription\",\"@transitionPath\"],[\"workLog\",[24,1,[]],[24,2,[]],[24,3,[]],[24,4,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/create-page/tabs-content/work/template.hbs"
    }
  });

  _exports.default = _default;
});