define("ntfrontend/components/tasks/task-sneak-peek/deadline-date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6K/JQ7UL",
    "block": "{\"symbols\":[],\"statements\":[[5,\"shared/inplace-datepicker\",[],[[\"@afterChange\",\"@editable\",\"@value\"],[[29,\"action\",[[24,0,[]],\"afterChange\"],null],[29,\"can\",[\"edit task\",[25,[\"model\"]]],null],[25,[\"model\",\"deadlineAt\"]]]],{\"statements\":[[0,\"\\n  \"],[5,\"tasks/inline-deadline-date\",[],[[\"@model\"],[[23,\"model\"]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/tasks/task-sneak-peek/deadline-date/template.hbs"
    }
  });

  _exports.default = _default;
});