define("ntfrontend/components/transports/sneak-peek-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eJzVXDF9",
    "block": "{\"symbols\":[\"@classNames\",\"&default\",\"@model\"],\"statements\":[[7,\"a\"],[12,\"class\",[24,1,[]]],[9],[15,2],[3,\"action\",[[24,0,[]],\"openSneakPeek\",[24,3,[]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/transports/sneak-peek-button/template.hbs"
    }
  });

  _exports.default = _default;
});