define("ntfrontend/components/search-box/previews/default/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E/GHKuGj",
    "block": "{\"symbols\":[],\"statements\":[[5,\"shared/loading-state/block\",[],[[\"@model\",\"@tagName\"],[[23,\"model\"],\"\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"searchbar__details animated fadeIn\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n      \"],[7,\"div\"],[12,\"class\",[30,[\"searchbar__resource \",[29,\"search-group-icon\",[[25,[\"typeName\"]]],null]]]],[9],[10],[0,\"\\n\\n      \"],[7,\"h4\"],[9],[0,\"\\n        \"],[7,\"a\"],[9],[1,[29,\"html-safe\",[[25,[\"model\",\"name\"]]],null],false],[3,\"action\",[[24,0,[]],\"redirect\"]],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"p\"],[11,\"class\",\"text-muted\"],[9],[1,[29,\"t-scope\",[[25,[\"typeName\"]],\"search.types\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"hr\"],[9],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"searchbar__creator\"],[9],[0,\"\\n      \"],[5,\"documents/meta-data\",[],[[\"@model\",\"@class\",\"@labelClass\"],[[23,\"model\"],\"col-sm-9 col-sm-offset-3\",\"text-right\"]]],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/search-box/previews/default/template.hbs"
    }
  });

  _exports.default = _default;
});