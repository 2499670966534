define("ntfrontend/components/steps/step-item/empty-state/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d3Ss5Yaq",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"t\",[\"scenario.placeholders.noTabsContent\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/steps/step-item/empty-state/template.hbs"
    }
  });

  _exports.default = _default;
});