define("ntfrontend/components/documents/participation-members/dropdown/component", ["exports", "ember-inflector", "ntfrontend/mixins/components/model-saving", "ntfrontend/mixins/components/open-dropdown", "ntfrontend/helpers/model-type-name"], function (_exports, _emberInflector, _modelSaving, _openDropdown, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, _openDropdown.default, {
    classNames: ['dropdown'],
    dropdownModel: Ember.computed.alias('member.ability'),
    modelName: Ember.computed('model', function () {
      return (0, _modelTypeName.modelTypeName)([this.model]).toLowerCase();
    }),
    distributeRoute: Ember.computed('modelName', function () {
      return "".concat((0, _emberInflector.pluralize)(this.modelName), ".").concat(this.modelName, ".distribute");
    }),
    actions: {
      afterRemove: function afterRemove() {
        this.notify.success('notify.member.removed');
      },
      openModal: function openModal() {
        this.set('showModal', true);
      }
    }
  });

  _exports.default = _default;
});