define("ntfrontend/mixins/components/force-modal-close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      submit: function submit() {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve) {
          resolve(_this._saveAndCloseModal());
        });
      }
    },
    _saveAndCloseModal: function _saveAndCloseModal() {
      var newContact = this.newContact;
      newContact !== undefined ? this._saveUserModal(newContact) : this._saveMemoModal();
    },
    _saveUserModal: function _saveUserModal(value) {
      this.afterChange(value);
    },
    _saveMemoModal: function _saveMemoModal() {
      this.afterChange();
      this.set('showModal', false);
    }
  });

  _exports.default = _default;
});