define("ntfrontend/components/works/plan-tab/milestone-list/workpackage-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    workItemsSorting: ['position:asc'],
    sortedWorkItems: Ember.computed.sort('workItems', 'workItemsSorting'),
    workItems: Ember.computed('workPackage', 'workPackage.workItems.length', 'arrayFilteredWorkItems.length', 'workPackage.workItems.@each.{hoursLogged,hoursRemaining,progress}', function () {
      var _this = this;

      var workItems = this.workPackage.get('workItems').filter(function (workItem) {
        return workItem.get('milestone.id') === _this.milestone.get('id') && _this.arrayFilteredWorkItems.includes(workItem.get('id'));
      });

      this._setWorkPackageSummary(workItems);

      this.setCountWorkItems.perform(workItems.length);
      return workItems;
    }),
    arrowDirection: Ember.computed('isExpanded', function () {
      return this.isExpanded ? '' : '-expanded';
    }),
    reorderWorkItems: function reorderWorkItems(workItems, draggedModel) {
      var prevPosition = draggedModel.position;
      workItems.forEach(function (model, index) {
        model.set('position', index + 1);
      });

      this._afterReorder(draggedModel, prevPosition);

      this.set('workItems', workItems);
    },
    actions: {
      reorderItems: function reorderItems(itemModels, draggedModel) {
        this.reorderWorkItems(itemModels, draggedModel);
      },
      toggleShowItems: function toggleShowItems() {
        this.toggleProperty('isExpanded');
      }
    },
    _afterReorder: function _afterReorder(draggedModel, prevPosition) {
      var _this2 = this;

      if (draggedModel.position != prevPosition) {
        draggedModel.save().then(function () {
          _this2.notify.success('notify.workItem.reordered');
        });
      }
    },
    _setWorkPackageSummary: function _setWorkPackageSummary(workItems) {
      this.set('workPackageSummary', {
        hoursPlanned: this._sumValue(workItems, 'hoursPlanned'),
        hoursLogged: this._sumValue(workItems, 'hoursLogged'),
        hoursRemaining: this._sumValue(workItems, 'hoursRemaining'),
        hoursEstimated: this._sumValue(workItems, 'hoursEstimated')
      });
    },
    _sumValue: function _sumValue(workItems, property) {
      return workItems.reduce(function (sum, workItem) {
        return sum + workItem.get(property);
      }, 0);
    }
  });

  _exports.default = _default;
});