define("ntfrontend/routes/projects/project/work/index", ["exports", "ntfrontend/routes/org-auth"], function (_exports, _orgAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend({
    beforeModel: function beforeModel() {
      var projectSlug = this.modelFor('projects.project').slug;
      this.transitionTo('projects.project.work.plan', projectSlug);
    }
  });

  _exports.default = _default;
});