define("ntfrontend/utils/capitalize-word", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = capitalizeWord;

  function capitalizeWord(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
});