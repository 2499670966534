define("ntfrontend/components/projects/dashboard/state-status/state/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zJ4rtiAI",
    "block": "{\"symbols\":[],\"statements\":[[5,\"bs-tooltip\",[],[[],[]],{\"statements\":[[1,[29,\"t\",[[25,[\"labelValue\"]]],null],false]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[30,[\"project__timeframe \",[23,\"cssState\"]]]],[9],[0,\"\\n\"],[4,\"if\",[[29,\"eq\",[[25,[\"state\"]],\"unknown\"],null]],null,{\"statements\":[[0,\"      ?\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/dashboard/state-status/state/template.hbs"
    }
  });

  _exports.default = _default;
});