define("ntfrontend/components/shared/kanban-view/component", ["exports", "ember-data", "ntfrontend/helpers/model-type-name", "ember-inflector"], function (_exports, _emberData, _modelTypeName, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PromiseArray = _emberData.default.PromiseArray;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    notify: Ember.inject.service('notify-alert'),
    tagName: '',
    column: null,
    componentTilePath: Ember.computed('model', function () {
      return "shared/kanban-view/tiles/".concat(this.modelName);
    }),
    modelName: Ember.computed('model', function () {
      return (0, _modelTypeName.modelTypeName)([this.get('model.firstObject')]).toLowerCase();
    }),
    items: Ember.computed('column.name', function () {
      var columnName = this.get('column.name');
      return this.get("model.firstObject.".concat((0, _emberInflector.pluralize)(columnName)));
    }),
    boards: Ember.computed('column', 'model', function () {
      var _this = this;

      if (this.column && this.items) {
        return PromiseArray.create({
          promise: Ember.RSVP.resolve(this.items).then(function (items) {
            var columnName = _this.column.name;
            var keys = Object.keys(items);
            return keys.map(function (key) {
              var itemName = items[key];
              return {
                name: _this._nameHumanize(itemName),
                records: _this.model.filter(function (record) {
                  var recordId = record.get(columnName);
                  return parseInt(recordId) === parseInt(key);
                })
              };
            });
          })
        });
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('column', {
        name: 'status',
        label: 'kanban.labels.status'
      });
    },
    actions: {
      moveObject: function moveObject(column, record) {
        var _this2 = this;

        this._getKeyByValue(this.items, column.name).then(function (val) {
          _this2._saveRecord(record, val);
        });
      }
    },
    _nameHumanize: function _nameHumanize(item) {
      return this.intl.t(this._translationKey(item));
    },
    _translationKey: function _translationKey(columnName) {
      return "".concat(this.modelName, ".").concat((0, _emberInflector.pluralize)(this.get('column.name')), ".").concat(columnName);
    },
    _getKeyByValue: function _getKeyByValue(object, value) {
      var _this3 = this;

      return Ember.RSVP.resolve(object).then(function (obj) {
        return Object.keys(obj).find(function (key) {
          return _this3._nameHumanize(obj[key]) === value;
        });
      });
    },
    _fetchEmberData: function _fetchEmberData(data) {
      return this.get((0, _emberInflector.pluralize)(data));
    },
    _formatData: function _formatData(items) {
      return items.reduce(function (acc, item) {
        acc[item.get('id')] = item.get('name');
        return acc;
      }, {});
    },
    _getItemByValueFromEmberArray: function _getItemByValueFromEmberArray(array, key) {
      return array.filter(function (item) {
        return item.get('name') === key;
      }).get('firstObject');
    },
    _saveRecord: function _saveRecord(record, value) {
      var _this4 = this;

      record.set(this.get('column.name'), value);
      record.save().then(function () {
        _this4.notifyPropertyChange('boards');

        _this4.notify.success('notify.default.saved');
      });
    }
  });

  _exports.default = _default;
});