define("ntfrontend/components/templates/new-template/modal-body/component", ["exports", "ntfrontend/models/document"], function (_exports, _document) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['modal-body'],
    documentsNames: _document.documentsNames
  });

  _exports.default = _default;
});