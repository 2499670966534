define("ntfrontend/routes/projects/project", ["exports", "ntfrontend/routes/org-auth", "ntfrontend/mixins/routes/show-project-pane"], function (_exports, _orgAuth, _showProjectPane) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend(_showProjectPane.default, {
    breadCrumb: {},
    indicator: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      var data = {
        include: 'project-setting.ability,project-type,ability',
        filter: {
          slug: "".concat(params.project_slug, ",").concat(this.get('currentSession.account.id'))
        }
      };
      return this.store.query('project', data).then(function (project) {
        return _this.objectOrRedirect(project, 'projects');
      });
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      var projectName = Ember.get(model, 'name');
      var project = {
        title: projectName
      };
      this.indicator.set('indicateCreateButton', !model.get('projectSetting.hasDocuments'));
      Ember.set(this, 'breadCrumb', project);
      return Ember.get(model, 'projectSetting');
    },
    actions: {
      willTransition: function willTransition() {
        this.indicator.set('indicateCreateButton', false);
      }
    }
  });

  _exports.default = _default;
});