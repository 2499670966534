define("ntfrontend/components/documents/actions/duplicate-button/component", ["exports", "ember-inflector", "ntfrontend/helpers/model-type-name"], function (_exports, _emberInflector, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    router: Ember.inject.service(),
    newName: Ember.computed('model.name', function () {
      return "".concat(this.get('model.name'), " (Copy)");
    }),
    path: Ember.computed('model', function () {
      var type = (0, _modelTypeName.modelTypeName)([this.model]).toLowerCase();
      return "".concat((0, _emberInflector.pluralize)(type), ".").concat(type);
    }),
    actions: {
      clone: function clone() {
        var _this = this;

        var path = this.path,
            name = this.newName,
            cloneTasks = this.cloneTasks,
            model = this.model,
            router = this.router;
        var data = {
          name: name,
          cloneTasks: cloneTasks
        };
        this.set('disabled', true);
        return model.clone({
          data: data
        }).then(function (clone) {
          var urlToRedirect = router.urlFor(path, clone.get('slug'));
          router.transitionTo(urlToRedirect);

          _this.set('disabled', false);
        });
      },
      openModal: function openModal() {
        this.set('showModal', true);
      }
    }
  });

  _exports.default = _default;
});