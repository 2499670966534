define("ntfrontend/initializers/ajax-setup", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _jquery.default.ajaxSetup({
      contentType: 'application/json',
      crossDomain: true,
      processData: false,
      dataType: 'json',
      xhrFields: {
        withCredentials: true
      },
      headers: {
        'Content-Type': 'application/vnd.api+json; charset=UTF-8'
      },
      beforeSend: function beforeSend(jqXHR, options) {
        if (options.contentType === 'application/json' && options.type !== 'GET') {
          options.data = JSON.stringify(options.data);
        } else {
          if (options.data) {
            options.data = _jquery.default.param(options.data);
          }
        }
      }
    });
  }

  var _default = {
    name: 'ajax-setup',
    initialize: initialize
  };
  _exports.default = _default;
});