define("ntfrontend/components/works/sneak-peek/work-log-sneak-peek/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    paneManager: Ember.inject.service(),
    workLog: Ember.computed.alias('paneManager.sneakPeekWorkLog'),
    actions: {
      hidePane: function hidePane() {
        this.paneManager.closePane('workLog');
      }
    }
  });

  _exports.default = _default;
});