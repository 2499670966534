define("ntfrontend/components/tasks/filters/status-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8zeF3yLg",
    "block": "{\"symbols\":[\"status\"],\"statements\":[[7,\"label\"],[11,\"class\",\"text-muted filter-name\"],[9],[0,\"\\n  \"],[1,[29,\"t\",[\"shared.labels.status\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"a\"],[11,\"data-toggle\",\"dropdown\"],[11,\"aria-haspopup\",\"true\"],[11,\"aria-expanded\",\"true\"],[11,\"type\",\"button\"],[9],[0,\"\\n  \"],[1,[23,\"currentStatusName\"],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"ul\"],[11,\"class\",\"dropdown-menu\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"statuses\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[7,\"a\"],[9],[1,[24,1,[\"label\"]],false],[3,\"action\",[[24,0,[]],\"changeStatus\",[24,1,[\"name\"]]]],[10],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/tasks/filters/status-filter/template.hbs"
    }
  });

  _exports.default = _default;
});