define("ntfrontend/components/projects/project-card/project-sneak-peek/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jLCREnxy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"sidebar__content\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"sidebar__header\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"page-header -with-icon icon-projects -project\"],[9],[0,\"\\n      \"],[7,\"h3\"],[11,\"class\",\"page-header__name\"],[9],[1,[25,[\"selectedProjectType\",\"name\"]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"icon-clear sidebar__close\"],[9],[3,\"action\",[[24,0,[]],\"hidePane\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"h4\"],[9],[1,[29,\"t\",[\"shared.labels.details\"],null],false],[10],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"text-muted\"],[9],[1,[29,\"t\",[\"shared.labels.description\"],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"sidebar__description-scroller\"],[9],[1,[29,\"html-safe\",[[25,[\"selectedProjectType\",\"description\"]]],null],false],[10],[0,\"\\n  \"],[5,\"projects/project-card/project-sneak-peek/sub-resources\",[],[[\"@selectedProjectType\"],[[23,\"selectedProjectType\"]]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/project-card/project-sneak-peek/template.hbs"
    }
  });

  _exports.default = _default;
});