define("ntfrontend/components/work-logs/kanban-list/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JKSQoDSV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[9],[1,[25,[\"record\",\"workItem\",\"name\"]],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"kanban-grid__work-footer\"],[9],[0,\"\\n  \"],[5,\"users/profile-box/picture\",[],[[\"@user\",\"@width\",\"@height\"],[[25,[\"record\",\"owner\"]],\"30\",\"30\"]]],[0,\"\\n  \"],[1,[29,\"time-scope\",[[25,[\"record\",\"hoursWorked\"]],[25,[\"filterTimeScope\"]]],null],false],[0,\" \"],[1,[29,\"t\",[\"work.labels.timeScope\"],[[\"scope\"],[[25,[\"filterTimeScope\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"style\",\"clear: both\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/work-logs/kanban-list/item/template.hbs"
    }
  });

  _exports.default = _default;
});