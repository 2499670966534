define("ntfrontend/components/works/create-tab/details/variance/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6aqwJXAM",
    "block": "{\"symbols\":[\"@variance\"],\"statements\":[[7,\"div\"],[11,\"class\",\"create-item__overview-data\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"create-item__overview-label text-muted\"],[9],[1,[29,\"t\",[\"work.labels.variance\"],null],false],[10],[0,\"\\n  \"],[1,[29,\"t\",[\"shared.percentValue\"],[[\"value\"],[[24,1,[]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/works/create-tab/details/variance/template.hbs"
    }
  });

  _exports.default = _default;
});