define("ntfrontend/components/custom-fields/custom-values/components/input/component", ["exports", "ntfrontend/components/custom-fields/custom-values/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['row', 'flex--center']
  });

  _exports.default = _default;
});