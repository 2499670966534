define("ntfrontend/components/works/plan-tab/milestone-list/header/date-picker/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    can: Ember.inject.service(),
    successCallback: function successCallback() {
      this.notify.success('notify.default.saved');
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.deadline ? this.set('date', 'deadlineAt') : this.set('date', 'startAt');
      var initialValue = this.get("model.".concat(this.date)) || new Date();
      this.set('initialValue', initialValue);
    },
    actions: {
      afterChange: function afterChange() {
        if (this.initialValue.getTime() !== this.get("model.".concat(this.date)).getTime()) {
          this.set('initialValue', this.get("model.".concat(this.date)));
          this.send('saveModel');
        }
      }
    }
  });

  _exports.default = _default;
});