define("ntfrontend/components/shared/filters/custom-fields/list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['filter', 'dropdown'],
    selected: Ember.computed('value', {
      get: function get() {
        return this.value || null;
      },
      set: function set(_key, value) {
        var id = this.get('customField.id');
        return this.updateFilter(id, value);
      }
    })
  });

  _exports.default = _default;
});