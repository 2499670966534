define("ntfrontend/components/projects/add-new-project/form/import-project-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bWpKnBN4",
    "block": "{\"symbols\":[\"projectType\"],\"statements\":[[7,\"div\"],[12,\"data-select-placeholder\",[29,\"t\",[\"shared.labels.projectTypePlaceholder\"],null]],[12,\"class\",[23,\"classes\"]],[9],[0,\"\\n  \"],[5,\"power-select\",[],[[\"@options\",\"@selected\",\"@onchange\",\"@tagName\",\"@click\",\"@onclose\",\"@searchField\"],[[23,\"groupedProjectTypes\"],[25,[\"project\",\"projectType\"]],[23,\"onChange\"],\"div\",[29,\"action\",[[24,0,[]],\"change\",true],null],[29,\"action\",[[24,0,[]],\"change\",false],null],\"name\"]],{\"statements\":[[0,\"\\n    \"],[1,[24,1,[\"name\"]],false],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"projectTypeRequiresImport\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"animated fadeInDown project-import-info\"],[9],[0,\"\\n      \"],[1,[29,\"t\",[\"project.labels.projectTypeImportInfo\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/add-new-project/form/import-project-type/template.hbs"
    }
  });

  _exports.default = _default;
});