define("ntfrontend/components/members/add-new-member/component", ["exports", "ntfrontend/utils/user-data-extractor"], function (_exports, _userDataExtractor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    router: Ember.inject.service(),
    notify: Ember.inject.service('notify-alert'),
    classNames: 'row',
    defaultRole: Ember.computed('roles', function () {
      return this.roles.filterBy('name', this.get('model.defaultRole'))[0];
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('memberObject', Ember.Object.create());
      this.set('memberObject.role', this.defaultRole);
    },
    selectedUserName: Ember.computed('memberObject.email', {
      get: function get() {
        var email = this.get('memberObject.email');
        var name = Ember.isEmpty(email) ? '' : (0, _userDataExtractor.nameFromEmail)(email);
        this.set('memberObject.name', name);
        return name;
      },
      set: function set(key, value) {
        var name = value;
        this.set('memberObject.name', name);
        return name;
      }
    }),
    inviteUser: function inviteUser(data) {
      var _this = this;

      this.set('disabled', true);
      this.model.invite({
        data: data
      }).then(function () {
        _this.notify.success('notify.user.accountInvite', null, {
          withoutTitle: true
        });

        _this._redirectToUsers();
      }).catch(function (error) {
        return error;
      }).finally(function () {
        _this.set('disabled', false);
      });
    },
    actions: {
      chooseRole: function chooseRole(param) {
        this.set('memberObject.role', param);
        this.set('defaultRole', param);
      },
      create: function create() {
        var data = {
          email: this.get('memberObject.email'),
          name: this.get('memberObject.name'),
          message: this.get('memberObject.message'),
          role: this.get('memberObject.role.name')
        };
        this.inviteUser(data);
      }
    },
    _redirectToUsers: function _redirectToUsers() {
      this.router.transitionTo('account.users.index');
    }
  });

  _exports.default = _default;
});