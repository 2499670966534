define("ntfrontend/mixins/components/templates/import-template-dropdown", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    intl: Ember.inject.service(),
    showLabel: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _jquery.default)('.dropdown', this.element).on('show.bs.dropdown', function () {
        _this.set('showDropdown', true);

        _this.set('showLabel', true);
      });
      (0, _jquery.default)('.dropdown', this.element).on('hide.bs.dropdown', function () {
        _this.set('showLabel', false);
      });
    },
    actions: {
      changeTemplate: function changeTemplate(template) {
        if (this.changeTemplate) {
          this.changeTemplate(template.get('id'));
        }

        this.set('isSelected', true);
        this.set('choosenTemplate', template);
      }
    }
  });

  _exports.default = _default;
});