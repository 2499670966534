define("ntfrontend/components/custom-fields/document-list/item-row/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZHvmLfrJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"component\",[[25,[\"customFieldComponent\"]]],[[\"customValue\",\"tagName\",\"editable\",\"afterChange\"],[[25,[\"customValue\"]],\"td\",[25,[\"editable\"]],[29,\"action\",[[24,0,[]],\"saveCustomValue\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/custom-fields/document-list/item-row/item/template.hbs"
    }
  });

  _exports.default = _default;
});