define("ntfrontend/components/custom-fields/options/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      createNewOption: function createNewOption(option) {
        if (!this.listOptions) {
          this.set('listOptions', Ember.A());
        }

        this.listOptions.pushObject(option);
      }
    }
  });

  _exports.default = _default;
});