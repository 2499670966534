define("ntfrontend/components/works/plan-tab/project-summary/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    store: Ember.inject.service(),
    hoursPlanned: Ember.computed('milestone.[]', 'workItems.@each.hoursPlanned', 'arrayFilteredWorkItems.[]', function () {
      return this._sumValues('hoursPlanned');
    }),
    hoursLogged: Ember.computed('milestone.[]', 'workItems.@each.hoursLogged', 'arrayFilteredWorkItems.[]', function () {
      return this._sumValues('hoursLogged');
    }),
    hoursRemaining: Ember.computed('milestone.[]', 'workItems.@each.hoursRemaining', 'arrayFilteredWorkItems.[]', function () {
      return this._sumValues('hoursRemaining');
    }),
    hoursEstimated: Ember.computed('milestone.[]', 'workItems.@each.hoursEstimated', 'arrayFilteredWorkItems.[]', function () {
      return this._sumValues('hoursEstimated');
    }),
    progress: Ember.computed('workItems.@each.{hoursLogged,hoursEstimated}', 'arrayFilteredWorkItems.[]', function () {
      if (this.arrayFilteredWorkItems.length && this.get('workItems').length) {
        var result = Math.round(this._sumValues('hoursLogged') / this._sumValues('hoursEstimated') * 100);
        return isNaN(result) ? 0 : result;
      } else {
        return 0;
      }
    }),
    variance: Ember.computed('workItems.@each.{hoursEstimated,hoursPlanned}', 'arrayFilteredWorkItems.[]', function () {
      if (this.arrayFilteredWorkItems.length && this.get('workItems').length) {
        var result = Math.round((this._sumValues('hoursEstimated') - this._sumValues('hoursPlanned')) * 100 / this._sumValues('hoursPlanned'));
        return isNaN(result) ? 0 : result;
      } else {
        return 0;
      }
    }),
    _sumValues: function _sumValues(field) {
      var _this = this;

      return this.workItems.reduce(function (total, workItem) {
        return _this.arrayFilteredWorkItems.includes(workItem.get('id')) ? total + workItem[field] : total;
      }, 0);
    }
  });

  _exports.default = _default;
});