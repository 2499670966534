define("ntfrontend/components/shared/intro-js/invisible-intro/component", ["exports", "ntfrontend/mixins/components/intro-component"], function (_exports, _introComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_introComponent.default, {
    currentSession: Ember.inject.service(),
    classNames: ['introjs-startbutton'],
    shouldStart: true,
    introOnSidebar: true,
    changeIntroStyle: function changeIntroStyle(intro) {
      var _this = this;

      var introNumber = document.querySelector('.introjs-helperNumberLayer');
      var introTooltip = document.querySelector('.introjs-tooltipReferenceLayer');

      if (this._inSidebarStep(intro._currentStep)) {
        Ember.run.scheduleOnce('afterRender', function () {
          return _this.addIntroClass(introNumber, introTooltip);
        });
      } else if (introNumber && introTooltip) {
        var containsNumber = introNumber.classList.contains('introjs-project-step-3-number');
        var containsTooltip = introTooltip.classList.contains('introjs-project-step-3-tooltip');

        if (containsNumber || containsTooltip) {
          Ember.run.scheduleOnce('afterRender', function () {
            return _this.removeIntroClass(introNumber, introTooltip);
          });
        }
      }
    },
    bindIntroStyle: function bindIntroStyle(intro) {
      this.introOnSidebar && intro.onchange(Ember.run.bind(this, this.changeIntroStyle, intro));
    },
    addIntroClass: function addIntroClass(number, tooltip) {
      number.classList.add('introjs-project-step-3-number');
      tooltip.classList.add('introjs-project-step-3-tooltip');
    },
    removeIntroClass: function removeIntroClass(number, tooltip) {
      number.classList.remove('introjs-project-step-3-number');
      tooltip.classList.remove('introjs-project-step-3-tooltip');
    },
    _inSidebarStep: function _inSidebarStep(step) {
      return this.sidebarStepsIds.includes(step);
    }
  });

  _exports.default = _default;
});