define("ntfrontend/components/steps/new-step/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4i713uxd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[9],[0,\"\\n  \"],[5,\"shared/form-input\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"input\",[],[[\"@type\",\"@value\",\"@class\",\"@autocomplete\",\"@disabled\",\"@placeholder\",\"@required\"],[\"text\",[23,\"stepName\"],\"form-control\",\"off\",[23,\"disabled\"],[29,\"t\",[\"step.placeholders.name\"],null],true]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"shared/form-confirm-button\",[],[[\"@disabled\",\"@label\"],[[23,\"disabledStep\"],[29,\"t\",[\"shared.actions.add\"],null]]]],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"addNewStep\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/steps/new-step/template.hbs"
    }
  });

  _exports.default = _default;
});