define("ntfrontend/components/shared/kanban-properties-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8j2EhXTz",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[4,\"if\",[[25,[\"modelHasTypes\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[11,\"class\",\"text-muted filter-name\"],[9],[0,\"\\n    \"],[1,[29,\"t\",[\"kanban.labels.property-switch\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"column\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"class\",\"dropdown-toggle\"],[11,\"data-toggle\",\"dropdown\"],[11,\"aria-haspopup\",\"true\"],[11,\"aria-expanded\",\"true\"],[11,\"type\",\"button\"],[9],[0,\"\\n      \"],[1,[29,\"t\",[[25,[\"column\",\"label\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,[\"openDropdown\"]]],null,{\"statements\":[[0,\"    \"],[7,\"ul\"],[11,\"class\",\"dropdown-menu\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"allColumns\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[7,\"a\"],[9],[1,[29,\"t\",[[24,1,[\"label\"]]],null],false],[3,\"action\",[[24,0,[]],\"changeProperty\",[24,1,[]]]],[10],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/kanban-properties-switch/template.hbs"
    }
  });

  _exports.default = _default;
});