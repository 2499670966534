define("ntfrontend/components/create-page/tabs-content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['create-item'],
    classNameBindings: ['itemClassName'],
    isUserTab: Ember.computed.equal('activeItem', 'user'),
    isWorkTab: Ember.computed.equal('activeItem', 'work'),
    isUserOrWorkTab: Ember.computed.or('isUserTab', 'isWorkTab'),
    itemClassName: Ember.computed('activeItem', function () {
      return "-".concat(this.activeItem);
    }),
    bgClassName: Ember.computed('activeItem', function () {
      return "create-item__image icon-".concat(this.activeItem, "s");
    }),
    resourceName: Ember.computed('activeItem', function () {
      return "shared.labels.".concat(this.activeItem);
    }),
    resourceDescription: Ember.computed('activeItem', function () {
      return "createPage.descriptions.".concat(this.activeItem);
    }),
    contentComponent: Ember.computed('activeItem', function () {
      return "create-page/tabs-content/".concat(this.activeItem);
    })
  });

  _exports.default = _default;
});