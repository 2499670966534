define("ntfrontend/models/task", ["exports", "ember-data", "ntfrontend/models/resource", "ntfrontend/mixins/models/tagable", "ntfrontend/mixins/models/logable"], function (_exports, _emberData, _resource, _tagable, _logable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = _resource.default.extend(_logable.default, _tagable.default, {
    slug: attr('number'),
    name: attr(),
    description: attr(),
    documentName: attr(),
    parentClass: attr(),
    parentId: attr(),
    documentSlug: attr(),
    deadlineAt: attr('date'),
    completed: attr('boolean'),
    resourceDocumentType: attr(),
    resource: belongsTo('models/taskable', {
      polymorphic: true
    }),
    owner: belongsTo('user'),
    creator: belongsTo('user'),
    requester: belongsTo('user'),
    document: Ember.computed.alias('resource.document'),
    project: Ember.computed.alias('document.project'),
    didCreate: function didCreate() {
      this._super.apply(this, arguments);

      this.refreshParents();
    },
    refreshParents: function refreshParents() {
      if (this.id) {
        this.store.findRecord('task', this.id, {
          include: 'resource.document.project.account'
        });
      }
    }
  });

  _exports.default = _default;
});