define("ntfrontend/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _emberData, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONAPIAdapter = _emberData.default.JSONAPIAdapter;
  var contentType = 'application/vnd.api+json';

  var _default = JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    endpoint: Ember.inject.service(),
    bullet: Ember.inject.service(),
    session: Ember.inject.service(),
    corsWithCredentials: true,
    coalesceFindRequests: true,
    host: Ember.computed.readOnly('endpoint.host'),
    namespace: Ember.computed.readOnly('endpoint.namespace'),
    ajaxOptions: function ajaxOptions(url, type) {
      var hash = this._super.apply(this, arguments);

      if (hash.contentType) {
        hash.contentType = contentType;
      }

      if (type === 'GET') {
        hash.processData = true;
      }

      var beforeSend = hash.beforeSend;

      hash.beforeSend = function (xhr, settings) {
        this.bullet.addRequest(settings.url);
        xhr.setRequestHeader('Accept', contentType);

        if (beforeSend) {
          beforeSend(xhr);
        }
      };

      return hash;
    },
    authorize: function authorize(xhr) {
      var _this$get = this.get('session.data.authenticated'),
          email = _this$get.email,
          token = _this$get.token;

      if (email && token) {
        var authData = "Token token=\"".concat(token, "\", email=\"").concat(email, "\"");
        xhr.setRequestHeader('Authorization', authData);
      }
    }
  });

  _exports.default = _default;
});