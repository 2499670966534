define("ntfrontend/components/works/create-tab/details/hours-remaining-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vgz0VlBu",
    "block": "{\"symbols\":[\"mat-label\",\"@inputId\",\"@hoursRemaining\",\"@setWorkItemHoursRemaining\",\"@disabled\"],\"statements\":[[5,\"shared/material-labels\",[],[[\"@labelFor\",\"@placeholder\"],[[24,2,[]],[29,\"t\",[\"work.placeholders.hoursRemaining\"],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"input\",[],[[\"@min\",\"@class\",\"@id\",\"@type\",\"@step\",\"@value\",\"@key-up\",\"@change\",\"@disabled\"],[\"0\",\"form-control input-label\",[24,2,[]],\"number\",\"0.01\",[24,3,[]],[24,1,[\"changeValue\"]],[24,4,[]],[24,5,[]]]]],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/works/create-tab/details/hours-remaining-select/template.hbs"
    }
  });

  _exports.default = _default;
});