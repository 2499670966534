define("ntfrontend/routes/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authenticationRoute: 'auth.login',
    redirect: function redirect() {
      this._super.apply(this, arguments);

      var session = this.session;

      if (!this.get('urlChecker.isApp')) {
        if (session.get('redirect')) {
          this.transitionTo(session.get('redirect'));
          session.set('redirect', null);
        } else {
          var currentUser = this.currentSession.user;

          var redirectPath = this._getRedirectPath(currentUser.get('roleInOrganization'));

          this.transitionTo(redirectPath);
        }
      }
    },
    _getRedirectPath: function _getRedirectPath(role) {
      var dashboardAllowedRoles = ['owner', 'admin'];
      return dashboardAllowedRoles.includes(role) ? 'dashboard' : 'projects';
    }
  });

  _exports.default = _default;
});