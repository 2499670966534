define("ntfrontend/components/work-logs/list-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eUGAnRyv",
    "block": "{\"symbols\":[\"@model\",\"@project\"],\"statements\":[[7,\"tr\"],[12,\"class\",[29,\"if\",[[25,[\"workLogSelected\"]],\"-highlighted\"],null]],[9],[0,\"\\n  \"],[7,\"td\"],[9],[0,\"\\n    \"],[5,\"works/sneak-peek/sneak-peek-button\",[],[[\"@model\"],[[23,\"model\"]]]],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"table__description\"],[9],[1,[25,[\"model\",\"description\"]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"td\"],[9],[5,\"users/profile-box/picture\",[],[[\"@user\",\"@width\",\"@height\"],[[25,[\"model\",\"owner\"]],\"30\",\"30\"]]],[10],[0,\"\\n  \"],[7,\"td\"],[9],[1,[29,\"time-scope\",[[25,[\"model\",\"hoursWorked\"]],[25,[\"filterTimeScope\"]]],null],false],[0,\" \"],[1,[29,\"t\",[\"work.labels.timeScope\"],[[\"scope\"],[[25,[\"filterTimeScope\"]]]]],false],[10],[0,\"\\n  \"],[7,\"td\"],[9],[1,[25,[\"model\",\"workItem\",\"milestone\",\"name\"]],false],[10],[0,\"\\n  \"],[7,\"td\"],[9],[1,[25,[\"model\",\"workItem\",\"workPackage\",\"name\"]],false],[10],[0,\"\\n  \"],[7,\"td\"],[9],[5,\"users/profile-box/picture\",[],[[\"@user\",\"@width\",\"@height\"],[[25,[\"model\",\"workItem\",\"owner\"]],\"30\",\"30\"]]],[10],[0,\"\\n  \"],[7,\"td\"],[9],[1,[29,\"format-date\",[[25,[\"model\",\"workDate\"]]],[[\"format\"],[\"long\"]]],false],[10],[0,\"\\n  \"],[7,\"td\"],[9],[0,\"\\n    \"],[5,\"work-logs/list-item/dropdown\",[],[[\"@model\",\"@project\"],[[24,1,[]],[24,2,[]]]]],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/work-logs/list-item/template.hbs"
    }
  });

  _exports.default = _default;
});