define("ntfrontend/components/custom-fields/document-list/item-row/item/value/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tA36ULuQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[1,[23,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"placeholder\"],[9],[1,[29,\"t\",[\"shared.labels.notDefined\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/custom-fields/document-list/item-row/item/value/template.hbs"
    }
  });

  _exports.default = _default;
});