define("ntfrontend/components/shared/textarea-autosize/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextArea.extend({
    didInsertElement: function didInsertElement() {
      autosize(this._getComponentForm());
    },
    willDestroyElement: function willDestroyElement() {
      autosize.destroy(this._getComponentForm());

      this._super.apply(this, arguments);
    },
    _getComponentForm: function _getComponentForm() {
      return this.element.querySelector('.form-control');
    }
  });

  _exports.default = _default;
});