define("ntfrontend/components/works/plan-tab/indicator-status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    arrowColor: Ember.computed('variance', function () {
      var defaultArrowColor = '-success';

      var valueForVariance = this._valueForReceivedVariance('', '-danger');

      return valueForVariance ? valueForVariance : defaultArrowColor;
    }),
    arrowLabel: Ember.computed('variance', function () {
      var translationPath = 'work.labels';
      return this.variance >= 0 ? "".concat(translationPath, ".more") : "".concat(translationPath, ".less");
    }),
    arrowDirection: Ember.computed('variance', function () {
      var defaultArrowDirection = 'down';

      var valueForVariance = this._valueForReceivedVariance('right', 'up');

      return valueForVariance ? valueForVariance : defaultArrowDirection;
    }),
    _valueForReceivedVariance: function _valueForReceivedVariance(valueForZeroVariance, valueForVarianceGreaterThanZero) {
      if (this.variance == 0) {
        return valueForZeroVariance;
      } else if (this.variance > 0) {
        return valueForVarianceGreaterThanZero;
      }
    }
  });

  _exports.default = _default;
});