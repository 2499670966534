define("ntfrontend/mixins/models/taskable", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Ember.Mixin.create({
    tasks: hasMany(),
    ability: belongsTo()
  });

  _exports.default = _default;
});