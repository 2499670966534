define("ntfrontend/components/shared/drop-zone/-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RS7KiBGS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"  \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"drop-zone-content\"],[9],[0,\"\\n    \"],[7,\"h2\"],[11,\"class\",\"drop-zone-overlay__header\"],[9],[0,\"\\n      \"],[1,[29,\"t\",[\"shared.placeholders.clickToUploadFiles\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/drop-zone/-content/template.hbs"
    }
  });

  _exports.default = _default;
});