define("ntfrontend/serializers/account", ["exports", "ntfrontend/serializers/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    attrs: {
      overdueTasks: {
        serialize: false
      },
      myOverdueTasks: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});