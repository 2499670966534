define("ntfrontend/utils/user-data-extractor", ["exports", "ntfrontend/utils/titleize-string"], function (_exports, _titleizeString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nameFromEmail = nameFromEmail;
  _exports.extractEmail = extractEmail;
  _exports.extractName = extractName;
  _exports.extractData = extractData;
  var emailRegexp = /[^@\s<]+@([^@\s]+\.)+[^@\s>]+/;

  function nameFromEmail(email) {
    var name = '';
    var matchedEmail = email.match(emailRegexp);

    if (!Ember.isEmpty(matchedEmail)) {
      email = matchedEmail[0];
      name = email.slice(0, email.indexOf('@'));
      name = name.replace(/[._]+/g, ' ');
    }

    return (0, _titleizeString.default)(name);
  }

  function extractEmail() {
    var input = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var emailResults = input.match(emailRegexp);
    return Ember.isEmpty(emailResults) ? false : emailResults[0];
  }

  function extractName(input) {
    var email = extractEmail(input);
    var parts = input.split(/\s+/);
    var emailId = parts.indexOf(email);

    if (emailId >= 0) {
      parts.splice(emailId, 1);
    }

    if (parts.length === 0) {
      return nameFromEmail(email);
    } else if (parts.length > 0) {
      return (0, _titleizeString.default)(parts.join(' ').trim());
    } else {
      return false;
    }
  }

  function extractData(input) {
    var email = extractEmail(input);
    var name = extractName(input);
    return email && name ? {
      email: email,
      name: name
    } : false;
  }
});