define("ntfrontend/components/members/add-member/invite-input/component", ["exports", "ntfrontend/utils/user-data-extractor", "ntfrontend/mixins/components/power-select/input"], function (_exports, _userDataExtractor, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_input.default, {
    allowClear: false,
    placeholderDestination: 'member.placeholders.powerSelectInvite',
    suggestionDestination: 'shared.labels.addAsCollaborator',
    includeCollaborators: true,
    includeOrigin: true,
    actions: {
      search: function search(q) {
        return this.processSearch.perform(q);
      },
      onChange: function onChange(user) {
        if (user) {
          this.onSelectionChanged(user.get('id'));
        } else {
          this.onSelectionChanged(user);
        }
      },
      inviteNewUser: function inviteNewUser(data) {
        if (!data.includes('@')) {
          return this.notify.error('validation.errors.email');
        }

        var id = data;
        var user = Number(id) ? id : (0, _userDataExtractor.extractData)(id);
        this.onSelectionChanged(user);
      },
      customSuggestion: function customSuggestion() {
        return this.onCreateLabel;
      }
    }
  });

  _exports.default = _default;
});