define("ntfrontend/components/projects/dashboard/milestones/milestones-list/dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RZ6ZaBvO",
    "block": "{\"symbols\":[],\"statements\":[[5,\"shared/actions/more-actions-button\",[],[[],[]]],[0,\"\\n\\n\"],[7,\"ul\"],[11,\"class\",\"dropdown-menu dropdown-menu-right\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"can\",[\"edit milestone\",[25,[\"model\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[7,\"a\"],[9],[1,[29,\"t\",[\"dashboard.actions.edit\"],null],false],[3,\"action\",[[24,0,[]],\"openModal\"]],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[29,\"can\",[\"remove milestone\",[25,[\"model\"]]],null]],null,{\"statements\":[[0,\"    \"],[5,\"shared/actions/remove-button\",[],[[\"@model\",\"@afterRemove\",\"@tagName\"],[[23,\"model\"],[29,\"action\",[[24,0,[]],\"removeNotify\"],null],\"li\"]],{\"statements\":[[0,\"\\n      \"],[7,\"a\"],[9],[1,[29,\"t\",[\"shared.actions.remove\"],null],false],[10],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[4,\"if\",[[29,\"can\",[\"edit milestone\",[25,[\"model\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"projects/dashboard/milestones/edit-milestone\",[],[[\"@model\",\"@showModal\"],[[23,\"model\"],[23,\"showModal\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/dashboard/milestones/milestones-list/dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});