define("ntfrontend/components/templates/project-templates/item/component", ["exports", "ntfrontend/components/templates/templates-list/item/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    thumbnailAltText: Ember.computed('model.{thumbnail,name}', function () {
      return "".concat(this.model.name, " thumbnail");
    }),
    rawDescription: Ember.computed('model.description', function () {
      var description = this.model.description;

      if (!description) {
        return '';
      }

      var div = document.createElement('div');
      div.innerHTML = "<div>".concat(description, "</div>");
      return div.textContent.trim();
    })
  });

  _exports.default = _default;
});