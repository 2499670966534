define("ntfrontend/components/projects/project-card/project-sneak-peek/sub-resources/document-templates/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F1X9iFbk",
    "block": "{\"symbols\":[\"documentTemplate\"],\"statements\":[[4,\"if\",[[25,[\"sortedDocumentTemplates\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"sidebar__table-scroller\"],[9],[0,\"\\n    \"],[7,\"table\"],[11,\"class\",\"table\"],[9],[0,\"\\n      \"],[7,\"thead\"],[9],[0,\"\\n        \"],[7,\"tr\"],[9],[0,\"\\n          \"],[7,\"th\"],[9],[1,[29,\"t\",[\"shared.labels.documentType\"],null],false],[10],[0,\"\\n          \"],[7,\"th\"],[9],[1,[29,\"t\",[\"shared.labels.name\"],null],false],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"sortedDocumentTemplates\"]]],null,{\"statements\":[[0,\"          \"],[5,\"projects/project-card/project-sneak-peek/sub-resources/document-templates/item\",[],[[\"@documentTemplate\"],[[24,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[29,\"t\",[\"document.placeholders.noTemplatesDefined\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/project-card/project-sneak-peek/sub-resources/document-templates/template.hbs"
    }
  });

  _exports.default = _default;
});