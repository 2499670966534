define("ntfrontend/components/projects/project-card/project-sneak-peek/sub-resources/document-templates/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    documentTemplates: Ember.computed.alias('projectTypePreview.documentTemplates'),
    sortedDocumentTemplates: Ember.computed('documentTemplates', function () {
      if (this.documentTemplates) {
        // not sure about this if statements. Should we use it or it's wrong way?
        return this.documentTemplates.sortBy('document_type');
      }
    })
  });

  _exports.default = _default;
});