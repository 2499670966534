define("ntfrontend/mixins/components/automatic-changes-notifier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    notify: Ember.inject.service('notify-alert'),
    sendEmailNotification: true,
    infoNotifyOptions: {
      icon: 'icon-info',
      type: 'info'
    },
    successCallback: function successCallback(model) {
      var members = model.get('members');

      var membersWithDistribution = this._membersWithDistribution(members);

      if (membersWithDistribution.length > 0 && this.sendEmailNotification) {
        var title = 'notify.default.info.title';
        var message = 'notify.changesNotification.scheduled';
        var options = this.infoNotifyOptions;
        this.notify.successWithUsers(message, title, membersWithDistribution, options);
      } else {
        this.notify.success('notify.default.saved');
      }
    },
    _membersWithDistribution: function _membersWithDistribution() {
      var members = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return members.filter(function (member) {
        var deliveryStatus = member.get('deliveryStatus');
        return deliveryStatus == 'sent' || deliveryStatus == 'draft';
      });
    }
  });

  _exports.default = _default;
});