define("ntfrontend/components/templates/templates-list/item/auto-clone/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    classNames: ['switcher-small', 'icon-checkmark'],
    classNameBindings: ['checked:switcher-small--done'],
    checked: Ember.computed.alias('model.autoClone'),
    click: function click() {
      this.model.toggleProperty('autoClone');
      this.saveModelTask.perform();
    }
  });

  _exports.default = _default;
});