define("ntfrontend/components/templates/document-templates/edit/meta-data/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LTidbzM+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row flex--center\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-4\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"text-muted\"],[9],[1,[29,\"t\",[\"shared.labels.creator\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-8\"],[9],[0,\"\\n    \"],[1,[25,[\"model\",\"creator\",\"displayName\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row flex--center\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-4\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"text-muted\"],[9],[1,[29,\"t\",[\"shared.labels.createdAt\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-8\"],[9],[0,\"\\n    \"],[1,[29,\"format-date\",[[25,[\"model\",\"createdAt\"]]],[[\"format\"],[\"long\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row flex--center\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-4\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"text-muted\"],[9],[1,[29,\"t\",[\"shared.labels.updatedAt\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-8\"],[9],[0,\"\\n    \"],[1,[29,\"format-date\",[[25,[\"model\",\"updatedAt\"]]],[[\"format\"],[\"long\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/templates/document-templates/edit/meta-data/template.hbs"
    }
  });

  _exports.default = _default;
});