define("ntfrontend/components/documents/details/work-package/dropdown/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    store: Ember.inject.service(),
    workPackages: Ember.computed('model.project', function () {
      var params = {
        filter: {
          projectId: this.get('model.project.id')
        }
      };
      return this.store.query('workPackage', params);
    }),
    actions: {
      changeWorkPackage: function changeWorkPackage(workPackage) {
        this.set('model.workPackage', workPackage);
        this.saveModelTask.perform();
      }
    }
  });

  _exports.default = _default;
});