define("ntfrontend/components/accounts/document-types/sets/set/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q1M7V/K5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"topic__item__top\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"topic__name\"],[9],[1,[25,[\"model\",\"name\"]],false],[3,\"action\",[[24,0,[]],\"toggleTypes\"]],[10],[0,\"\\n  \"],[5,\"shared/actions/remove-button\",[],[[\"@model\",\"@afterRemove\",\"@messageKey\"],[[23,\"model\"],[29,\"action\",[[24,0,[]],\"afterRemove\"],null],\"notify.projectTemplate.removing\"]]],[0,\"\\n  \"],[5,\"accounts/document-types/sets/edit-set\",[],[[\"@model\"],[[23,\"model\"]]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/accounts/document-types/sets/set/template.hbs"
    }
  });

  _exports.default = _default;
});