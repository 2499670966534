define("ntfrontend/components/create-page/tabs-content/template-card/sneak-peek-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3+V8tf1E",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[12,\"class\",[23,\"classNames\"]],[9],[0,\"\\n  \"],[1,[29,\"t\",[\"shared.labels.preview\"],null],false],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"openSneakPeek\",[25,[\"model\"]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/create-page/tabs-content/template-card/sneak-peek-button/template.hbs"
    }
  });

  _exports.default = _default;
});