define("ntfrontend/components/work-logs/list-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    paneManager: Ember.inject.service(),
    tagName: 'tbody',
    workLogSelected: Ember.computed('paneManager.sneakPeekWorkLog', function () {
      var workLogId = this.get('paneManager.sneakPeekWorkLog.id');
      return workLogId === this.model.id;
    })
  });

  _exports.default = _default;
});