define("ntfrontend/components/members/managers-count/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: 'h4',
    classNames: ['managers-count'],
    actualSubscriptionPlan: Ember.computed.alias('model.activeSubscription.plan'),
    managersCount: Ember.computed('model.{activeSubscription,managersCount}', function () {
      return this.actualSubscriptionPlan ? this._managersCountLabel() : this._trialStatus();
    }),
    _managersCountLabel: function _managersCountLabel() {
      var currentManagersCount = this.get('model.managersCount');

      var maxManagerLimitForPlan = this._keyTranslations("plans.".concat(this.actualSubscriptionPlan, ".managers"));

      var managersCountInformation = this._keyTranslations('managersSlotsLeft', {
        currentManagersCount: currentManagersCount,
        maxManagerLimitForPlan: maxManagerLimitForPlan
      });

      return managersCountInformation;
    },
    _trialStatus: function _trialStatus() {
      if (this.get('model.trialStartedAt')) {
        return this._keyTranslations(this.get('model.daysLeft') > 0 ? 'trial' : 'readOnly');
      } else {
        return this._keyTranslations('freeAccount');
      }
    },
    _keyTranslations: function _keyTranslations(key) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.intl.t("subscription.".concat(key), options);
    }
  });

  _exports.default = _default;
});