define("ntfrontend/components/dashboard/tasks-preview/empty-state/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var translations = {
    overdue: 'noOverdueTasks',
    my: 'noTasks',
    reportedByMe: 'noTasksReportedByMe'
  };

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    noTasksLabel: Ember.computed('activeTab', function () {
      var translationKey = this._getTranslationKeyForTab(this.activeTab);

      return this.intl.t("shared.labels.".concat(translationKey));
    }),
    _getTranslationKeyForTab: function _getTranslationKeyForTab(tab) {
      return translations[tab];
    }
  });

  _exports.default = _default;
});