define("ntfrontend/components/custom-fields/type-selector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'custom-fields',
    fieldTypes: ['input', 'number', 'user', 'list', 'richtext'],
    type: 'input',
    fieldType: 'fieldType',
    actions: {
      onChange: function onChange(type) {
        this.set('fieldType', type);
      }
    }
  });

  _exports.default = _default;
});