define("ntfrontend/models/subscription", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    plan: attr(),
    status: attr(),
    expiredAt: attr('date'),
    maxManagersCount: attr(),
    account: belongsTo()
  });

  _exports.default = _default;
});