define("ntfrontend/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    name: attr(),
    email: attr(),
    password: attr(),
    roleInOrganization: attr(),
    phoneNumber: attr(),
    requirePasswordChange: attr('boolean'),
    currentPassword: attr('string', {
      serialize: false
    }),
    currentAccount: belongsTo('account'),
    intro: belongsTo(),
    members: hasMany(),
    workItems: hasMany('work-item'),
    displayName: Ember.computed('name', 'email', function () {
      return this.name || this.email;
    })
  });

  _exports.default = _default;
});