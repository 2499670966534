define("ntfrontend/routes/projects/project/scenarios/index", ["exports", "ntfrontend/utils/ajaxer", "ntfrontend/routes/org-auth", "ntfrontend/models/scenario"], function (_exports, _ajaxer, _orgAuth, _scenario) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend({
    model: function model(params) {
      var data = {
        include: 'owner,last-open-step.owner,last-step,work-package-list',
        filter: this._filters(params),
        page: {
          size: params.perPage,
          number: params.page
        },
        sort: params.sort
      };
      return Ember.RSVP.hash({
        scenarios: this.store.query('scenario', data),
        project: this.modelFor('projects.project'),
        lists: {
          statuses: _scenario.statuses
        }
      });
    },
    _filters: function _filters(params) {
      var userId = this.get('currentSession.user.id');
      var filters = {
        ownerId: this.mapParam(params.ownerId, 'me', userId),
        projectId: this.modelFor('projects.project').id,
        archived: params.archived,
        workPackageId: params.workPackageId,
        milestoneId: params.milestoneId,
        due: params.due,
        q: params.q,
        status: params.status,
        typeName: params.typeName,
        customFields: params.customFields
      };
      return _ajaxer.default.reduceObject(filters);
    }
  });

  _exports.default = _default;
});