define("ntfrontend/helpers/type-anchor", ["exports", "ntfrontend/helpers/model-type-name"], function (_exports, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.typeAnchor = typeAnchor;
  _exports.default = void 0;

  function typeAnchor(model) {
    return "".concat((0, _modelTypeName.modelTypeName)(model).dasherize(), "-").concat(model[0].get('id'));
  }

  var _default = Ember.Helper.helper(typeAnchor);

  _exports.default = _default;
});