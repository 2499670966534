define("ntfrontend/models/custom-field", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    name: attr(),
    fieldType: attr(),
    options: attr(),
    preview: attr(),
    visibleOnList: attr(),
    multipleValue: attr(),
    account: belongsTo(),
    customFieldAssignments: hasMany()
  });

  _exports.default = _default;
});