define("ntfrontend/components/shared/inplace-textarea/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nawrmiYk",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"showField\"]]],null,{\"statements\":[[4,\"if\",[[25,[\"isEditing\"]]],null,{\"statements\":[[0,\"    \"],[5,\"shared/form-input\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"textarea\",[],[[\"@value\",\"@class\",\"@placeholder\",\"@rows\"],[[23,\"value\"],\"form-control\",[23,\"placeholder\"],\"1\"]]],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"static-value\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"value\"]]],null,{\"statements\":[[0,\"        \"],[1,[29,\"new-line-to-br\",[[25,[\"value\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"editable\"]]],null,{\"statements\":[[0,\"          \"],[7,\"span\"],[11,\"class\",\"placeholder\"],[9],[1,[23,\"placeholder\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"    \"],[3,\"action\",[[24,0,[]],\"toggleEditing\"]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/inplace-textarea/template.hbs"
    }
  });

  _exports.default = _default;
});