define("ntfrontend/templates/account/templates/projects/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vpEgsrAg",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"page-title\",[[29,\"t\",[\"shared.labels.projectTemplates\"],null]],null],false],[0,\"\\n\"],[5,\"templates/project-templates\",[],[[\"@account\",\"@projectTypeId\"],[[23,\"model\"],[23,\"projectTypeId\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/account/templates/projects/index.hbs"
    }
  });

  _exports.default = _default;
});