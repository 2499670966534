define("ntfrontend/components/templates/templates-list/item/dropdown/clone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6Kv7LfMN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[9],[1,[29,\"t\",[\"shared.actions.clone\"],null],false],[3,\"action\",[[24,0,[]],\"openModal\"]],[10],[0,\"\\n\"],[5,\"shared/modal-dialog\",[],[[\"@showModal\",\"@onSuccess\",\"@title\"],[[23,\"showModal\"],[29,\"action\",[[24,0,[]],\"clone\"],null],[29,\"t\",[\"organization.documentTemplate.labels.cloneDocumentTemplate\"],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-body\"],[9],[0,\"\\n    \"],[5,\"shared/form-input\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"input\",[],[[\"@type\",\"@value\",\"@class\",\"@placeholder\",\"@required\",\"@autofocus\"],[\"text\",[23,\"name\"],\"form-control\",[29,\"t\",[\"project.name.placeholder\"],null],\"true\",\"true\"]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[5,\"shared/form-confirm-button\",[],[[\"@label\"],[[29,\"t\",[\"shared.actions.clone\"],null]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/templates/templates-list/item/dropdown/clone/template.hbs"
    }
  });

  _exports.default = _default;
});