define("ntfrontend/mixins/components/open-dropdown", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    dropdownModel: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _jquery.default)(this.element).on('show.bs.dropdown', function () {
        _this._reloadModel();

        _this.set('openDropdown', true);
      });
    },
    _reloadModel: function _reloadModel() {
      if (this.dropdownModel && this.get('dropdownModel.isLoaded')) {
        this.dropdownModel.then(function (model) {
          model.reload();
        });
      }
    }
  });

  _exports.default = _default;
});