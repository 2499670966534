define("ntfrontend/components/members/add-member/invite-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+snGTlHG",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[5,\"power-select-with-create\",[],[[\"@placeholder\",\"@search\",\"@oncreate\",\"@onchange\",\"@buildSuggestion\",\"@suggestedOptionComponent\",\"@showCreateWhen\",\"@searchMessage\",\"@options\"],[[23,\"placeholder\"],[29,\"action\",[[24,0,[]],\"search\"],null],[29,\"action\",[[24,0,[]],\"inviteNewUser\"],null],[29,\"action\",[[24,0,[]],\"onChange\"],null],[29,\"action\",[[24,0,[]],\"customSuggestion\"],null],\"shared/power-select/suggested-option\",[29,\"action\",[[24,0,[]],\"shouldShowCreateOption\"],null],[23,\"searchMessage\"],[23,\"options\"]]],{\"statements\":[[0,\"\\n  \"],[1,[24,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/members/add-member/invite-input/template.hbs"
    }
  });

  _exports.default = _default;
});