define("ntfrontend/components/create-page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yykZdibt",
    "block": "{\"symbols\":[\"item\",\"@projectSlug\",\"@transitionPath\"],\"statements\":[[7,\"div\"],[11,\"class\",\"create-page__sidebar\"],[9],[0,\"\\n\"],[4,\"each\",[[24,0,[\"tabs\"]]],null,{\"statements\":[[0,\"    \"],[5,\"create-page/tabs-item\",[],[[\"@item\",\"@activeItem\",\"@tabChange\"],[[24,1,[]],[24,0,[\"activeTab\"]],[29,\"action\",[[24,0,[]],\"tabChange\"],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"create-page__content-wrapper\"],[9],[0,\"\\n  \"],[5,\"create-page/tabs-content\",[],[[\"@activeItem\",\"@projectSlug\",\"@tabChange\",\"@transitionPath\"],[[24,0,[\"activeTab\"]],[24,2,[]],[29,\"action\",[[24,0,[]],\"tabChange\"],null],[24,3,[]]]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/create-page/template.hbs"
    }
  });

  _exports.default = _default;
});