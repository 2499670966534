define("ntfrontend/routes/account/users/index", ["exports", "ntfrontend/routes/org-auth", "ntfrontend/mixins/routes/members-listing"], function (_exports, _orgAuth, _membersListing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend(_membersListing.default, {
    currentSession: Ember.inject.service(),
    resource: Ember.computed(function () {
      return this.get('currentSession.account');
    })
  });

  _exports.default = _default;
});