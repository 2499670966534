define("ntfrontend/components/auth/register-form/phone-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['phone-number-input'],
    hasValue: false,
    actions: {
      changeValue: function changeValue(value) {
        this.set('hasValue', !!value);
      }
    }
  });

  _exports.default = _default;
});