define("ntfrontend/components/custom-fields/type-selector-muted/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7BTUbooj",
    "block": "{\"symbols\":[\"type\"],\"statements\":[[4,\"each\",[[25,[\"fieldTypes\"]]],null,{\"statements\":[[0,\"  \"],[7,\"input\"],[12,\"id\",[24,1,[]]],[11,\"name\",\"field_type\"],[12,\"class\",[30,[\"hidden \",[29,\"if\",[[29,\"eq\",[[25,[\"fieldType\"]],[24,1,[]]],null],\"checked\"],null]]]],[11,\"type\",\"radio\"],[9],[10],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"custom-fields__element custom-fields__element--disabled\"],[12,\"for\",[24,1,[]]],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[30,[\"icon-type-\",[24,1,[]],\" custom-fields__icon\"]]],[9],[10],[0,\"\\n    \"],[7,\"div\"],[9],[1,[29,\"t-scope\",[[24,1,[]],\"organization.customField.placeholders\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/custom-fields/type-selector-muted/template.hbs"
    }
  });

  _exports.default = _default;
});