define("ntfrontend/components/projects/dashboard/work-packages/edit/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    tagName: '',
    actions: {
      onClose: function onClose() {
        this.model.rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});