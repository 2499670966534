define("ntfrontend/components/templates/project-templates/component", ["exports", "ntfrontend/components/templates/templates-list/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  var _default = _component.default.extend({
    phoenixChannel: Ember.inject.service(),
    templates: Ember.computed('data', function () {
      return this.store.query('project-type', this.data);
    }),
    data: Ember.computed('perPage', 'page', 'sort', 'account.id', 'published', function () {
      return _extends(this.baseFilters(), {
        filter: this.filters()
      });
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.phoenixChannel.joinMessagesChannel(this.currentSession.user.id, this._handleSocketResponse.bind(this));
    },
    customFilters: function customFilters() {
      return {
        projectTypeId: this.projectTypeId
      };
    },
    _handleSocketResponse: function _handleSocketResponse(response) {
      this._showNotification(response);
    },
    _showNotification: function _showNotification(res) {
      this.notify.socketNotify(res);
    },
    actions: {
      refreshProjectTemplates: function refreshProjectTemplates() {
        this.notifyPropertyChange('templates');
      }
    }
  });

  _exports.default = _default;
});