define("ntfrontend/components/create-page/icons/resource-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Byh+kvvh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[23,\"icon\"]],[9],[0,\"\\n  \"],[7,\"svg\"],[11,\"viewBox\",\"0 0 250 250\"],[11,\"class\",\"create-item__image-bg\"],[9],[0,\"\\n    \"],[7,\"path\"],[11,\"d\",\"M4.8 113.34l.02.02c-6.4 6.41-6.4 16.9 0 23.3L35.5 167.4c15.72 12.23 15.34-10.96 27.83-24.89l.2-.22.98-1.04a31.2 31.2 0 0 1 44.16 0 31.27 31.27 0 0 1-1.04 45.18l-.23.2c-13.92 12.5-37.09 12.12-24.86 27.85l30.7 30.72a16.5 16.5 0 0 0 23.28 0l26.43-26.45c20.39-20.4 18.75 5.7 32.33 20.84l.2.23.98 1.04a31.2 31.2 0 0 0 44.16 0 31.27 31.27 0 0 0-1.04-45.18l-.22-.2c-15.14-13.6-41.22-11.96-20.82-32.37l26.41-26.44c6.4-6.4 6.4-16.9 0-23.3l-26.41-26.44h-.01c-20.4-20.42 5.68-15.46 20.82-29.04l.22-.2a31.27 31.27 0 0 0 1.04-45.18 31.2 31.2 0 0 0-45.14 1.04l-.2.22c-13.58 15.15-11.94 37.92-32.34 17.5L136.51 4.82a16.5 16.5 0 0 0-23.28 0L86.82 31.26c-20.4 20.4-18.76-5.69-32.33-20.84l-.2-.22A31.2 31.2 0 0 0 9.14 9.16a31.27 31.27 0 0 0 1.03 45.17l.23.2C25.54 68.13 51.6 66.5 31.24 86.89L4.8 113.34z\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/create-page/icons/resource-icon/template.hbs"
    }
  });

  _exports.default = _default;
});