define("ntfrontend/components/templates/project-templates/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1W9fOwxi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[11,\"class\",\"btn-success icon-plus-circle\"],[9],[0,\"\\n  \"],[1,[29,\"t\",[\"organization.documentTemplate.actions.create\"],null],false],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"openModal\"]],[10],[0,\"\\n\\n\"],[5,\"shared/modal-dialog\",[],[[\"@showModal\",\"@onSuccess\",\"@title\"],[[23,\"showModal\"],[29,\"action\",[[24,0,[]],\"createDocumentTemplate\"],null],[29,\"t\",[\"organization.documentTemplate.labels.createDocumentTemplate\"],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"templates/project-templates/new/modal-body\",[],[[\"@model\",\"@disabled\",\"@projectTypes\"],[[23,\"model\"],[29,\"readonly\",[[25,[\"disabled\"]]],null],[23,\"projectTypes\"]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/templates/project-templates/new/template.hbs"
    }
  });

  _exports.default = _default;
});