define("ntfrontend/mixins/components/label-editing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      changeLabels: function changeLabels(labels) {
        var _this = this;

        var modelLabels = this.get('model.labels').toArray();
        modelLabels.forEach(function (label) {
          return _this.get('model.labels').removeObject(label);
        });
        labels.forEach(function (label) {
          return _this.get('model.labels').pushObject(label);
        });
      }
    }
  });

  _exports.default = _default;
});