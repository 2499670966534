define("ntfrontend/mixins/routes/document-detailed-breadcrumb", ["exports", "ember-inflector", "ntfrontend/helpers/model-type-name"], function (_exports, _emberInflector, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    states: Ember.inject.service(),
    breadCrumb: Ember.computed(function () {
      var crumb = {
        title: this.get('crumbModels.document.name'),
        injection: [this.documentTypeCrumb, {
          model: this.get('crumbModels.project'),
          offset: -1,
          linkable: true
        }, {
          title: 'Projects',
          path: 'projects',
          offset: -2,
          linkable: true
        }]
      };
      return crumb;
    }),
    documentTypeCrumb: Ember.computed('crumbModels.document', function () {
      var doc = this.get('crumbModels.document');
      var docType = (0, _emberInflector.pluralize)((0, _modelTypeName.modelTypeName)([doc])).toLowerCase();
      var title = this.intl.t("shared.labels.".concat(docType));
      var path = "projects.project.".concat(docType);
      return {
        path: path,
        title: title,
        offset: -3,
        model: this.get('crumbModels.project'),
        linkable: true
      };
    }),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      this.set('states.breadCrumbReady', false);
    },
    afterModel: function afterModel(model) {
      var _this = this;

      this._super.apply(this, arguments);

      var data = {
        document: model,
        project: model.get('project')
      };
      Ember.RSVP.hash(data).then(function (loadedData) {
        _this.set('crumbModels', loadedData);

        _this.notifyPropertyChange('breadCrumb');

        _this.set('states.breadCrumbReady', true);
      });
    }
  });

  _exports.default = _default;
});