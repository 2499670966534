define("ntfrontend/components/custom-fields/assignments/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['custom-field-assignments'],
    currentSession: Ember.inject.service(),
    projectTypes: Ember.computed.alias('currentSession.account.projectTypes'),
    resorceTypes: ['Memo', 'Issue', 'Request', 'Scenario'],
    resorceClassTypes: ['memos', 'warning', 'requests', 'scenarios']
  });

  _exports.default = _default;
});