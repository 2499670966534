define("ntfrontend/models/dashboard", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.states = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  var states = {
    0: 'unknown',
    1: 'onTrack',
    2: 'attention',
    3: 'critical',
    4: 'notRelevant'
  };
  _exports.states = states;

  var _default = Model.extend({
    project: belongsTo(),
    progress: attr('number', {
      defaultValue: 0
    }),
    time: attr('number'),
    budget: attr('number'),
    scope: attr('number'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    timeDescription: attr(),
    budgetDescription: attr(),
    scopeDescription: attr(),
    taskAssignments: attr(),
    members: hasMany(),
    thread: hasMany(),
    roles: hasMany(),
    states: Ember.computed(function () {
      return states;
    })
  });

  _exports.default = _default;
});