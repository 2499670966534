define("ntfrontend/components/topics/template-topic-item/component", ["exports", "ntfrontend/mixins/components/model-saving", "ntfrontend/helpers/type-anchor"], function (_exports, _modelSaving, _typeAnchor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    fileUpload: Ember.inject.service(),
    taskDistributionNotifier: Ember.inject.service(),
    classNames: 'topic__item',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('elementId', (0, _typeAnchor.typeAnchor)([this.model]));
    },
    actions: {
      afterRemove: function afterRemove() {
        this.notify.success('notify.topic.removed');
      },
      addAttachments: function addAttachments(files) {
        var _this = this;

        this.fileUpload.uploadAttachmentsFor(files, this.model).then(function () {
          _this.taskDistributionNotifier.notifyWithReloadMembers(_this.get('model.members'));
        });
      }
    }
  });

  _exports.default = _default;
});