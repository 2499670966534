define("ntfrontend/components/shared/tabs-menu/link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lA9tQVt5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"a\"],[11,\"class\",\"sub-nav__link\"],[9],[0,\"\\n  \"],[15,1],[0,\"\\n  \"],[1,[23,\"linkTitle\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/tabs-menu/link/template.hbs"
    }
  });

  _exports.default = _default;
});