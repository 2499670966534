define("ntfrontend/initializers/error-handler", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  (0, _jquery.default)(document).keydown(function (e) {
    if (e.keyCode === 27) {
      (0, _jquery.default)('.modal').modal('hide');
    }
  });
  var errorHandler = Ember.Object.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    notify: Ember.inject.service('notify-alert'),
    init: function init() {
      this.handleAjaxErrors();
    },
    handleAjaxErrors: function handleAjaxErrors() {
      var _this = this;

      (0, _jquery.default)(document).ajaxError(function (evt, xhr) {
        if (xhr.statusText !== 'abort') {
          switch (xhr.status) {
            case 401:
              {
                _this.router.transitionTo('index');

                _this.notify.error('notify.auth.noAccess.message');

                break;
              }

            case 404:
              {
                _this.notify.error('notify.default.notFound.message');

                break;
              }

            default:
              {
                var errors = xhr.responseJSON && xhr.responseJSON.errors ? xhr.responseJSON.errors : null;

                _this.displayErrors(errors);
              }
          }
        }
      });
    },
    displayErrors: function displayErrors(errors) {
      var _this2 = this;

      if (errors) {
        var codes = [];
        errors.forEach(function (error) {
          codes.push(error.code);
        });

        _jquery.default.unique(codes).forEach(function (code) {
          var translate = _this2.intl.errorKeys(code);

          _this2.notify.error(translate.message, translate.title);
        });
      } else {
        this.notify.error();
      }
    }
  });
  var _default = {
    name: 'error-handler',
    initialize: function initialize(App) {
      App.register('error-handler:main', errorHandler);
      App.inject('route', 'errorHandler', 'error-handler:main');
    },
    errorHandler: errorHandler
  };
  _exports.default = _default;
});