define("ntfrontend/templates/account/users/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1aFToKR6",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"page-title\",[[29,\"t\",[\"shared.labels.newMember\"],null]],null],false],[0,\"\\n\\n\"],[5,\"members/add-new-member\",[],[[\"@roles\",\"@model\"],[[25,[\"model\",\"roles\"]],[25,[\"model\",\"resource\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/account/users/new.hbs"
    }
  });

  _exports.default = _default;
});