define("ntfrontend/components/works/plan-tab/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B2TODFvV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"progress__bar\"],[12,\"style\",[23,\"progressBarStyle\"]],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"progress__number\"],[9],[1,[23,\"progress\"],false],[7,\"small\"],[9],[0,\"%\"],[10],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/works/plan-tab/progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});