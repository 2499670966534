define("ntfrontend/components/documents/new-document/component", ["exports", "ember-inflector", "ntfrontend/helpers/model-type-name"], function (_exports, _emberInflector, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    objectType: '',
    headerTranslation: Ember.computed('objectType', function () {
      return "".concat(this.objectType, ".new");
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('tempObject', Ember.Object.create());
    },
    afterCreate: function afterCreate(model) {
      var modelType = (0, _modelTypeName.modelTypeName)([model]).toLowerCase();
      var modelRoute = "".concat((0, _emberInflector.pluralize)(modelType), ".").concat(modelType);
      var urlToRedirect = this.router.urlFor(modelRoute, model.get('slug'));
      this.router.transitionTo(urlToRedirect);
    },
    actions: {
      create: function create() {
        var _this = this;

        var newObject = this.store.createRecord(this.objectType, {
          project: this.project,
          name: this.get('tempObject.name')
        });
        this.set('disabled', true);
        newObject.save().then(function () {
          _this._afterNewObjectSave(newObject);
        }, function () {
          _this.set('newObject', newObject);

          newObject.rollbackAttributes();
        }).finally(function () {
          _this.set('disabled', false);
        });
      },
      changeTemplate: function changeTemplate(templateId) {
        this.tempObject.set('templateId', templateId);
      }
    },
    _afterNewObjectSave: function _afterNewObjectSave(newObject) {
      var _this2 = this;

      var templateId = this.get('tempObject.templateId');

      if (templateId) {
        newObject.importTemplate({
          data: {
            templateId: templateId
          }
        }).then(function () {
          _this2._success(newObject);
        });
      } else {
        this._success(newObject);
      }
    },
    _success: function _success(newObject) {
      this.notify.success("notify.".concat(this.objectType, ".created"));
      this.afterCreate(newObject);
    }
  });

  _exports.default = _default;
});