define("ntfrontend/components/documents/templates/import-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PwQLjA9e",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[29,\"can\",[\"importTemplate to document\",[25,[\"model\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"shared/modal-dialog\",[],[[\"@showModal\",\"@onClose\",\"@onSuccess\",\"@title\"],[[23,\"showModal\"],[29,\"action\",[[24,0,[]],\"onModalClose\"],null],[29,\"action\",[[24,0,[]],\"importTemplate\"],null],[29,\"t\",[\"document.labels.importTemplate\"],null]]],{\"statements\":[[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"modal-body\"],[9],[0,\"\\n      \"],[5,\"documents/templates/import-button/dropdown\",[],[[\"@model\",\"@projectTypeId\",\"@choosenTemplate\"],[[23,\"model\"],[25,[\"projectTemplate\",\"id\"]],[23,\"choosenTemplate\"]]]],[0,\"\\n      \"],[5,\"shared/form-confirm-button\",[],[[\"@disabled\",\"@label\"],[[23,\"disabled\"],[29,\"t\",[\"shared.actions.import\"],null]]]],[0,\"\\n    \"],[10],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/documents/templates/import-button/template.hbs"
    }
  });

  _exports.default = _default;
});