define("ntfrontend/transforms/custom-value", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Transform = _emberData.default.Transform;

  var _default = Transform.extend({
    deserialize: function deserialize(payload) {
      var parsedPayload = JSON.parse(payload);
      var serialized = parsedPayload.map(function (customValue) {
        return Ember.Object.create(customValue);
      });
      return serialized;
    },
    serialize: function serialize(payload) {
      var deserialized;

      if (payload) {
        var pojoPayload = payload.map(function (object) {
          var pojo = {};
          Object.keys(object).forEach(function (key) {
            pojo[key] = object.get(key);
          });
          return pojo;
        });
        deserialized = JSON.stringify(pojoPayload);
      } else {
        deserialized = JSON.stringify([]);
      }

      return deserialized;
    }
  });

  _exports.default = _default;
});