define("ntfrontend/services/phoenix-channel", ["exports", "ntfrontend/config/environment", "ember-phoenix/services/phoenix-socket"], function (_exports, _environment, _phoenixSocket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _phoenixSocket.default.extend({
    notify: Ember.inject.service('notify-alert'),
    currentSession: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var connection = {
        endpoint: null,
        channels: []
      };
      this.set('connection', connection);
      this.on('open', function (socket) {
        _this.set('connection.endpoint', socket);
      });
    },
    connect: function connect(email, token) {
      return this._super(_environment.default.socketEndpoint, {
        params: {
          email: email,
          token: token
        }
      });
    },
    joinNotificationsChannel: function joinNotificationsChannel(id) {
      var _this2 = this;

      var channelName = "notifications:".concat(id);
      var channel = this.joinChannel(channelName);
      channel.on('notification', function (res) {
        _this2.notify.socketNotify(res);
      });
    },
    joinMessagesChannel: function joinMessagesChannel(userId, callback) {
      var channelName = "messages:".concat(userId);
      var channel = this.joinChannel(channelName);
      channel.on('message', function (res) {
        callback(res);
      });
    }
  });

  _exports.default = _default;
});