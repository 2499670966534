define("ntfrontend/templates/projects/project/work/logs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NsWBp6np",
    "block": "{\"symbols\":[],\"statements\":[[5,\"work-logs\",[],[[\"@project\",\"@userId\",\"@ownerId\",\"@workPackageId\",\"@milestoneId\",\"@filterTimeScope\",\"@q\",\"@workItemId\",\"@startDate\",\"@endDate\",\"@sort\",\"@changePage\",\"@page\",\"@perPage\"],[[24,0,[\"model\",\"project\"]],[24,0,[\"userId\"]],[24,0,[\"ownerId\"]],[24,0,[\"workPackageId\"]],[24,0,[\"milestoneId\"]],[24,0,[\"filterTimeScope\"]],[24,0,[\"q\"]],[24,0,[\"workItemId\"]],[24,0,[\"startDate\"]],[24,0,[\"endDate\"]],[24,0,[\"sort\"]],[29,\"action\",[[24,0,[]],\"changePage\"],null],[24,0,[\"page\"]],[24,0,[\"perPage\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/projects/project/work/logs.hbs"
    }
  });

  _exports.default = _default;
});