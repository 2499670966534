define("ntfrontend/components/documents/templates/import-button/dropdown/component", ["exports", "ntfrontend/helpers/model-type-name", "ntfrontend/mixins/components/templates/import-template-dropdown"], function (_exports, _modelTypeName, _importTemplateDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_importTemplateDropdown.default, {
    didProjectChange: Ember.observer('projectTypeId', function () {
      this.set('choosenTemplate', null);
      this.changeTemplate(null);
    }),
    modelTypeName: Ember.computed('model', function () {
      return (0, _modelTypeName.modelTypeName)([this.model]);
    }),
    noTemplatesDefinedFor: Ember.computed('modelTypeName', function () {
      var pluralizedDocument = this.modelTypeName.pluralize();
      return this.intl.t('document.placeholders.noTemplatesDefinedFor', {
        documentType: pluralizedDocument
      });
    }),
    documentTemplates: Ember.computed('modelTypeName', 'projectTypeId', function () {
      return this.store.query('documentTemplate', {
        filter: {
          accountId: this.get('currentSession.account.id'),
          documentType: this.modelTypeName.capitalize(),
          projectTypeId: this.projectTypeId,
          published: true
        }
      });
    })
  });

  _exports.default = _default;
});