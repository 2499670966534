define("ntfrontend/components/projects/dashboard/milestones/edit-milestone/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    disabled: Ember.computed('saveModelTask.isRunning', 'model.hasDirtyAttributes', function () {
      return !this.get('model.hasDirtyAttributes') || this.get('saveModelTask.isRunning');
    }),
    actions: {
      onClose: function onClose() {
        this.model.rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});