define("ntfrontend/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "npq5j5kc",
    "block": "{\"symbols\":[],\"statements\":[[5,\"head-layout\",[],[[],[]]],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"displaySearch\"]]],null,{\"statements\":[[0,\"  \"],[5,\"search-box\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[5,\"new-version-notifier\",[],[[\"@updateInterval\",\"@updateMessage\",\"@reloadButtonText\"],[[23,\"checkVersionInterval\"],[29,\"t\",[\"shared.labels.updatedAppVersion\"],null],[29,\"t\",[\"shared.labels.reloadButton\"],null]]]],[0,\"\\n\\n\"],[5,\"navbar-menu\",[],[[],[]]],[0,\"\\n\"],[5,\"sidebar-menu\",[],[[],[]]],[0,\"\\n\"],[5,\"auth/password-modal\",[],[[],[]]],[0,\"\\n\"],[5,\"shared/ensure-identity\",[],[[],[]]],[0,\"\\n\"],[5,\"shared/modal-dialog/wrapper\",[],[[],[]]],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"page-content\"],[9],[0,\"\\n  \"],[1,[23,\"outlet\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[5,\"shared/sneak-peek-pane\",[],[[\"@pane\"],[\"task-view\"]],{\"statements\":[[0,\"\\n  \"],[5,\"shared/sneak-peek-selector\",[],[[\"@class\"],[\"h-100\"]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"shared/sneak-peek-pane/pane\",[],[[\"@name\",\"@class\"],[\"task-view\",\"sidebar\"]]],[0,\"\\n\"],[1,[23,\"footer-menu\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/application.hbs"
    }
  });

  _exports.default = _default;
});