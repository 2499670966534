define("ntfrontend/components/steps/add-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gNGNHSB7",
    "block": "{\"symbols\":[\"@step\"],\"statements\":[[7,\"a\"],[12,\"class\",[30,[\"icon-plus-circle icon-color-success dropdown-toggle \",[29,\"if\",[[24,0,[\"stepIsEmpty\"]],\"bouncing-betty\"],null]]]],[11,\"data-toggle\",\"dropdown\"],[11,\"aria-haspopup\",\"true\"],[11,\"aria-expanded\",\"true\"],[11,\"type\",\"button\"],[9],[10],[0,\"\\n\"],[7,\"ul\"],[11,\"class\",\"dropdown-menu\"],[9],[0,\"\\n  \"],[7,\"li\"],[9],[0,\"\\n    \"],[5,\"tasks/new-task\",[],[[\"@resource\",\"@label\"],[[24,1,[]],[29,\"t\",[\"task.actions.new\"],null]]]],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"li\"],[9],[0,\"\\n    \"],[5,\"tasks/new-task/step\",[],[[\"@resource\",\"@label\",\"@forStep\"],[[24,1,[]],[29,\"t\",[\"task.actions.newForStep\"],null],true]]],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"li\"],[9],[0,\"\\n    \"],[5,\"shared/attachments/file-uploader\",[],[[\"@model\",\"@tagName\"],[[23,\"step\"],\"a\"]],{\"statements\":[[0,\"\\n      \"],[1,[29,\"t\",[\"shared.placeholders.addFiles\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"can\",[\"create transports in account\",[24,0,[\"account\"]]],null]],null,{\"statements\":[[0,\"      \"],[5,\"transports/transport-modal\",[],[[\"@documentItem\",\"@label\",\"@assignView\"],[[24,1,[]],[29,\"t\",[\"shared.actions.addTransport\"],null],true]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/steps/add-button/template.hbs"
    }
  });

  _exports.default = _default;
});