define("ntfrontend/components/tasks/task-list/empty-state/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hMaYD9Zt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"empty-state\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"src\",\"/assets/images/notasks.svg\"],[11,\"class\",\"empty-state__icon\"],[12,\"alt\",[29,\"t\",[\"document.alts.noTasksIcon\"],null]],[9],[10],[0,\"\\n  \"],[7,\"h4\"],[11,\"class\",\"empty-state__header\"],[9],[0,\"\\n    \"],[1,[29,\"t\",[\"document.placeholders.noTasksHeader\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"empty-state__text\"],[9],[0,\"\\n    \"],[1,[29,\"t\",[\"document.placeholders.noTasksContent\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/tasks/task-list/empty-state/template.hbs"
    }
  });

  _exports.default = _default;
});