define("ntfrontend/helpers/search-group-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.searchGroupIcon = searchGroupIcon;
  _exports.default = void 0;
  var icons = {
    'Memo': 'memos',
    'Issue': 'warning',
    'Scenario': 'scenarios',
    'Request': 'requests',
    'Task': 'tasks',
    'Project': 'projects'
  };

  function searchGroupIcon(groupName) {
    return icons[groupName] ? "icon-".concat(icons[groupName]) : 'icon-info';
  }

  var _default = Ember.Helper.helper(searchGroupIcon);

  _exports.default = _default;
});