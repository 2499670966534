define("ntfrontend/components/users/profile-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1px7hoUt",
    "block": "{\"symbols\":[\"escapeSelect\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"profile-box__img\"],[9],[0,\"\\n  \"],[5,\"users/profile-box/picture\",[],[[\"@user\"],[[23,\"user\"]]]],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"profile-box__contents\"],[9],[0,\"\\n  \"],[5,\"shared/inplace-user\",[],[[\"@value\",\"@editable\",\"@placeholder\",\"@tooltipTitleKey\"],[[23,\"user\"],[23,\"editable\"],[23,\"placeholderKey\"],[23,\"tooltipTitleKey\"]]],{\"statements\":[[0,\"\\n    \"],[5,\"users/select-user\",[],[[\"@scope\",\"@value\",\"@onSelectionChanged\",\"@includeCollaborators\",\"@escapeSelect\",\"@refreshMembers\"],[[23,\"scope\"],[23,\"user\"],[29,\"action\",[[24,0,[]],\"userChange\"],null],[23,\"includeCollaborators\"],[24,1,[]],[23,\"refreshMembers\"]]]],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[15,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/users/profile-box/template.hbs"
    }
  });

  _exports.default = _default;
});