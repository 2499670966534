define("ntfrontend/components/shared/header-with-total/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var defaultPageSize = 25;

  var _default = Ember.Component.extend({
    tagName: 'h2',
    classNames: 'h2',
    totalCount: Ember.computed.alias('resource.meta.total'),
    currentPageCount: Ember.computed.alias('resource.length'),
    count: Ember.computed('totalCount', 'currentPageCount', function () {
      var totalCount = this.totalCount,
          currentPageCount = this.currentPageCount;
      var result = totalCount > defaultPageSize ? totalCount : currentPageCount;
      return result;
    }),
    countLabel: Ember.computed('count', function () {
      return this.count.toString();
    })
  });

  _exports.default = _default;
});