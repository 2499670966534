define("ntfrontend/components/tasks/task-sneak-peek/component", ["exports", "ntfrontend/mixins/components/label-editing", "ntfrontend/mixins/components/model-saving"], function (_exports, _labelEditing, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, _labelEditing.default, {
    notify: Ember.inject.service('notify-alert'),
    store: Ember.inject.service(),
    paneManager: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    taskDistributionNotifier: Ember.inject.service(),
    classNames: 'sidebar-task',
    task: Ember.computed.alias('paneManager.sneakPeekTask'),
    init: function init() {
      this._super.apply(this, arguments);

      this.taskObserver();
    },
    taskObserver: Ember.observer('task.slug', function () {
      var _this = this;

      if (this.task) {
        var data = {
          filter: {
            slug: "".concat(this.task.slug, ",").concat(this.get('currentSession.account.id'))
          },
          include: 'thread.ability,requester,owner,ability,resource'
        };
        this.set('model', null);
        this.store.query('task', data).then(function (tasks) {
          _this.set('model', tasks.get('firstObject'));
        });
      }
    }),
    successCallback: function successCallback() {
      this.taskDistributionNotifier.notify(this.get('model.members'));
    },
    actions: {
      refreshMembers: function refreshMembers() {
        this.taskDistributionNotifier.notifyWithReloadMembers(this.get('model.members'));
      },
      afterInvite: function afterInvite() {
        this.notify.success('notify.watcher.added', null, {
          withoutTitle: true
        });
      },
      afterRemove: function afterRemove() {
        this.notify.success('notify.task.removed');
        this.paneManager.closePane('task');
      },
      hidePane: function hidePane() {
        this.paneManager.closePane('task');
      }
    }
  });

  _exports.default = _default;
});