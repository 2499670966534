define("ntfrontend/components/shared/filters/date-range-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'data-test-date-select': '',
    classNames: 'filter',
    init: function init() {
      this._super.apply(this, arguments);

      if (this.dateIsExist) {
        this.set('fromDate', new Date(this.startDate));
        this.set('toDate', new Date(this.endDate));
        this.set('center', this.fromDate);
        this.set('range', {
          start: this.fromDate,
          end: this.toDate
        });
      } else {
        this.set('range', {
          start: null,
          end: null
        });
      }
    },
    dateIsExist: Ember.computed('range.[]', 'startDate', 'endDate', function () {
      return this.startDate && this.endDate;
    }),
    dateRange: Ember.computed('range.[]', 'startDate', 'endDate', function () {
      if (this.dateIsExist || this.range.start) {
        var from = moment(this.range.start).format('DD MMM YYYY');
        var to = moment(this.range.end).format('DD MMM YYYY');
        to = this.range.end == null ? '' : to;
        return "".concat(from, " - ").concat(to);
      } else {
        return this.intl.t('project.filter.all');
      }
    }),
    actions: {
      close: function close() {
        if (this.range.start) {
          if (this.range.end == null) {
            this.set('range.end', this.range.start);
            this.notifyPropertyChange('dateRange');
          }

          this._setDate();
        }
      },
      resetRange: function resetRange(closeCalendar) {
        var _this = this;

        var dateProperties = this.getProperties('startDate', 'endDate', 'range.start', 'range.end');
        Object.keys(dateProperties).forEach(function (property) {
          _this.set(property, null);
        });
        this.notifyPropertyChange('dateRange');
        closeCalendar();
      }
    },
    _setDate: function _setDate() {
      this.set('startDate', moment(this.range.start).format('YYYY-MM-DD'));
      this.set('endDate', moment(this.range.end).format('YYYY-MM-DD'));
    }
  });

  _exports.default = _default;
});