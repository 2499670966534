define("ntfrontend/components/templates/templates-list/component", ["exports", "ntfrontend/utils/ajaxer"], function (_exports, _ajaxer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    page: 1,
    data: Ember.computed('perPage', 'page', 'sort', 'account.id', 'published', function () {
      return _extends(this.baseFilters(), {
        filter: this.filters()
      });
    }),
    documentTemplates: Ember.computed('data', function () {
      return this.store.query('document-template', this.data);
    }),
    filters: function filters() {
      var filters = {
        accountId: this.get('account.id'),
        published: this.published
      };
      var customFilters = this.customFilters();
      return _ajaxer.default.reduceObject(_extends(filters, customFilters));
    },
    customFilters: function customFilters() {
      return {};
    },
    baseFilters: function baseFilters() {
      return {
        include: 'creator',
        page: {
          size: this.perPage,
          number: this.page
        },
        sort: this.sort
      };
    },
    actions: {
      refreshProjectTemplates: function refreshProjectTemplates() {
        this.notifyPropertyChange('data');
      }
    }
  });

  _exports.default = _default;
});