define("ntfrontend/components/shared/inplace-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RKnrNRfR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"showField\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[29,\"if\",[[25,[\"isInplaceEditing\"]],\"inplace-editing\"],null]],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[30,[[29,\"unless\",[[25,[\"isEditing\"]],\"static-value\"],null],\" \",[29,\"if\",[[25,[\"isFormControl\"]],\"form-control\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"showPlaceholder\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"placeholder\"],[9],[0,\"\\n          \"],[1,[23,\"placeholder\"],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,[\"isEditing\"]]],null,{\"statements\":[[0,\"        \"],[5,\"shared/wysiwyg-editor\",[],[[\"@disabled\",\"@placeholderKey\",\"@value\",\"@cancelButton\",\"@onBlur\",\"@saveButton\"],[[23,\"disabled\"],[23,\"placeholderKey\"],[23,\"editorValue\"],[29,\"action\",[[24,0,[]],\"cancel\"],null],[29,\"action\",[[24,0,[]],\"save\"],null],[29,\"action\",[[24,0,[]],\"save\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[29,\"html-safe\",[[25,[\"showingValue\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/inplace-editor/template.hbs"
    }
  });

  _exports.default = _default;
});