define("ntfrontend/components/shared/inplace-datepicker/component", ["exports", "ntfrontend/mixins/components/inplace/editable", "ntfrontend/mixins/components/inplace/placeholder"], function (_exports, _editable, _placeholder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_editable.default, _placeholder.default, {
    placeholderKey: 'project.placeholders.date',
    visibleDashedStyle: false,
    defaultClass: 'static-value',
    datePickerClass: Ember.computed('visibleDashedStyle', function () {
      return this.visibleDashedStyle ? "".concat(this.defaultClass, " static-value--dashed") : this.defaultClass;
    }),
    clearable: Ember.computed('clear', 'editable', 'value', function () {
      return this.value && this.editable && this.clear;
    }),
    actions: {
      doneEditing: function doneEditing(date) {
        if (date) {
          this.send('toggleEditing');
          this.set('value', date);

          this._processChange();
        }
      },
      clearDate: function clearDate() {
        this.set('value', null);
      }
    },
    _processChange: function _processChange() {
      var afterChange = this.afterChange;

      if (afterChange) {
        afterChange();
      }
    }
  });

  _exports.default = _default;
});