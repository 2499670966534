define("ntfrontend/routes/account/templates/projects/index", ["exports", "ntfrontend/routes/org-auth"], function (_exports, _orgAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend({
    features: Ember.inject.service(),
    model: function model() {
      return this.currentSession.account;
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);

      if (!this.features.isEnabled('project-templates')) {
        this.transitionTo('index');
      }
    }
  });

  _exports.default = _default;
});