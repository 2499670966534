define("ntfrontend/abilities/request", ["exports", "ntfrontend/abilities/document"], function (_exports, _document) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _document.default.extend({
    canUpdateWorkPackage: Ember.computed.readOnly('model.ability.actions.updateWorkPackage')
  });

  _exports.default = _default;
});