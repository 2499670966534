define("ntfrontend/components/comments/comment-item/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    intl: Ember.inject.service(),
    editable: false,
    showUpdatedAt: Ember.computed('model.updatedAt', 'model.createdAt', function () {
      return Date.parse(this.get('model.createdAt')) !== Date.parse(this.get('model.updatedAt'));
    }),
    focusField: function focusField() {
      this._super.apply(this, arguments);

      autosize(document.querySelector('.form-control'));
    },
    willDestroyElement: function willDestroyElement() {
      autosize.destroy(document.querySelector('.form-control'));

      this._super.apply(this, arguments);
    },
    isEditingDidChange: Ember.observer('isEditing', function () {
      if (this.isEditing) {
        Ember.run.scheduleOnce('afterRender', this, this.focusField);
      }
    }),
    actions: {
      afterRemove: function afterRemove() {
        this.notify.success('notify.comment.removed');
      },
      toggleEditing: function toggleEditing() {
        this.toggleProperty('isEditing');
      },
      cancel: function cancel() {
        this.model.rollbackAttributes();
        this.toggleProperty('isEditing');
      },
      saveModel: function saveModel() {
        var _this = this;

        if (this.get('model.hasDirtyAttributes')) {
          this.set('disabled', true);
          this.saveModelTask.perform().then(function () {
            _this.set('isEditing', false);
          }).finally(function () {
            _this.set('disabled', false);
          });
        } else {
          this.set('isEditing', false);
        }
      }
    }
  });

  _exports.default = _default;
});