define("ntfrontend/components/custom-fields/custom-values/components/list/single-value/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CAMAiA28",
    "block": "{\"symbols\":[\"dd\",\"menu\",\"option\"],\"statements\":[[7,\"div\"],[11,\"class\",\"inplace-editing\"],[9],[0,\"\\n  \"],[5,\"bs-dropdown\",[],[[\"@class\"],[\"dropdown form-control select\"]],{\"statements\":[[0,\"\\n    \"],[6,[24,1,[\"toggle\"]],[],[[\"@tagName\"],[\"div\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,[\"customValue\",\"value\"]]],null,{\"statements\":[[0,\"        \"],[1,[25,[\"customValue\",\"value\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[29,\"t\",[\"organization.customField.labels.selectOption\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[6,[24,1,[\"menu\"]],[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[25,[\"customValue\",\"options\"]]],null,{\"statements\":[[0,\"        \"],[6,[24,2,[\"item\"]],[],[[],[]],{\"statements\":[[0,\"\\n          \"],[7,\"a\"],[9],[1,[24,3,[]],false],[3,\"action\",[[24,0,[]],\"updateValue\",[24,3,[]]]],[10],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"    \"]],\"parameters\":[2]}],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/custom-fields/custom-values/components/list/single-value/template.hbs"
    }
  });

  _exports.default = _default;
});