define("ntfrontend/services/resource-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    codePatern: /(^[m|p|t|r|i|s])(\d+$)/,
    routePathMapping: {
      m: 'memos.memo',
      r: 'requests.request',
      s: 'scenarios.scenario',
      i: 'issues.issue',
      p: 'projects.project',
      t: 'tasks.task'
    },
    decode: function decode(code) {
      var result = code.dasherize().match(this.codePatern);
      return result ? this._codeObject(result) : false;
    },
    _codeObject: function _codeObject(array) {
      var routePath = this.routePathMapping[array[1]];
      var resourceId = parseInt(array[2], 10);
      return {
        routePath: routePath,
        resourceId: resourceId
      };
    }
  });

  _exports.default = _default;
});