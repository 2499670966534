define("ntfrontend/components/documents/participation-members/delivery-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r9t4YY/7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"delivery \",[23,\"statusClass\"]]]],[9],[0,\"\\n  \"],[1,[29,\"t\",[[25,[\"statusTranslation\"]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[5,\"documents/distribute-document\",[],[[\"@document\",\"@memberId\",\"@showModal\"],[[25,[\"member\",\"resource\",\"content\"]],[25,[\"member\",\"id\"]],[23,\"showModal\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/documents/participation-members/delivery-status/template.hbs"
    }
  });

  _exports.default = _default;
});