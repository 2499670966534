define("ntfrontend/components/shared/kanban-view/tiles/inline-date/component", ["exports", "ntfrontend/utils/date-utils"], function (_exports, _dateUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['inline-deadline-date'],
    overdueDate: Ember.computed('value', function () {
      return (0, _dateUtils.pastDate)(this.value);
    }),
    dateStatusClass: Ember.computed('overdueDate', function () {
      var dateStatus = this.overdueDate ? 'overdue' : 'pending';
      return "calendar-deadline-date--task-".concat(dateStatus);
    })
  });

  _exports.default = _default;
});