define("ntfrontend/components/custom-fields/list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      var options = {
        actions: {
          afterRemove: this.createAfterRemove()
        }
      };
      this.set('options', options);
    },
    createAfterRemove: function createAfterRemove() {
      var _this = this;

      return function () {
        _this.notifyPropertyChange('customFields');

        _this.notify.success('notify.customField.removed');
      };
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._initialFetch().then(function () {
        _this2.notifyPropertyChange('customFields');
      });
    },
    _initialFetch: function _initialFetch() {
      var params = {
        filter: {
          accountId: this.get('account.id')
        },
        sort: this.sort
      };
      return this.store.query('customField', params);
    },
    customFields: Ember.computed('account.customFields.[]', function () {
      return this.store.peekAll('customField');
    }),
    filteredCustomFields: Ember.computed('customFields.@each.isNew', function () {
      return this.customFields.reject(function (customField) {
        return customField.get('isNew');
      });
    })
  });

  _exports.default = _default;
});