define("ntfrontend/serializers/application", ["exports", "ember-data", "ember-custom-actions/utils/normalize-payload"], function (_exports, _emberData, _normalizePayload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONAPISerializer = _emberData.default.JSONAPISerializer;

  var _default = JSONAPISerializer.extend({
    metaNormalizerOperation: 'camelize',
    attrs: {
      ability: {
        serialize: false
      }
    },
    normalizeArrayResponse: function normalizeArrayResponse() {
      var normalizedDocument = this._super.apply(this, arguments); // Normalize document meta


      if (normalizedDocument.meta) {
        normalizedDocument.meta = this._normalizeMeta(normalizedDocument.meta);
      }

      return normalizedDocument;
    },
    extractRelationship: function extractRelationship() {
      var normalizedRelationship = this._super.apply(this, arguments); // Normalize relationship meta


      if (normalizedRelationship.meta) {
        normalizedRelationship.meta = this._normalizeMeta(normalizedRelationship.meta);
      }

      return normalizedRelationship;
    },
    // Private
    _normalizeMeta: function _normalizeMeta(meta) {
      return (0, _normalizePayload.default)(meta, this.metaNormalizerOperation);
    }
  });

  _exports.default = _default;
});