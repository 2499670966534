define("ntfrontend/components/shared/document-task-redirect/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pg5eDdVs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,\"link-to\",[],[[\"@route\",\"@model\",\"@query\",\"@class\"],[[23,\"documentRoute\"],[23,\"documentSlug\"],[29,\"hash\",null,[[\"anchor\",\"task\"],[[25,[\"anchorLink\"]],[25,[\"model\",\"slug\"]]]]],[23,\"class\"]]],{\"statements\":[[0,\"\\n  \"],[15,1],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/document-task-redirect/template.hbs"
    }
  });

  _exports.default = _default;
});