define("ntfrontend/components/auth/password-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nj+4eiSP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[9],[0,\"\\n  \"],[5,\"shared/form-input\",[],[[\"@errors\"],[[25,[\"data\",\"errors\",\"password\"]]]],{\"statements\":[[0,\"\\n    \"],[5,\"input\",[],[[\"@value\",\"@id\",\"@required\",\"@class\",\"@type\",\"@placeholder\"],[[25,[\"data\",\"password\"]],\"inputPassword\",\"true\",\"form-control\",\"password\",[29,\"t\",[\"auth.password.placeholder\"],null]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"shared/form-input\",[],[[\"@errors\"],[[25,[\"data\",\"errors\",\"password_confirmation\"]]]],{\"statements\":[[0,\"\\n    \"],[5,\"input\",[],[[\"@value\",\"@id\",\"@required\",\"@class\",\"@type\",\"@placeholder\"],[[25,[\"data\",\"password_confirmation\"]],\"inputPasswordConfirm\",\"true\",\"form-control\",\"password\",[29,\"t\",[\"auth.rePassword.placeholder\"],null]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-block btn-large btn-primary\"],[11,\"type\",\"submit\"],[9],[1,[29,\"t\",[\"auth.passwordReset\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"reset\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/auth/password-form/template.hbs"
    }
  });

  _exports.default = _default;
});