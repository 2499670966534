define("ntfrontend/components/subscription-reminder/reminder-subscription/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    expiredAt: Ember.computed('account.{expiredAt,activeSubscription}', function () {
      var accountPlan = this.get('account.activeSubscription.expiredAt') || this.get('account.expiredAt');
      return moment(accountPlan).format('Do MMMM YYYY');
    }),
    trialInfoLabel: Ember.computed('account.daysLeft', 'expiredAt', function () {
      var daysLeft = this.get('account.daysLeft');

      if (daysLeft > 0) {
        return this.intl.t('subscription.dateWithDaysLeft', {
          daysLeft: this.get('account.daysLeft'),
          date: this.expiredAt
        });
      } else {
        return this.intl.t('subscription.expiredAt', {
          daysLeft: Math.abs(this.get('account.daysLeft'))
        });
      }
    })
  });

  _exports.default = _default;
});