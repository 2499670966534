define("ntfrontend/components/custom-fields/custom-values/components/list/multi-value/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V/o/ArkY",
    "block": "{\"symbols\":[\"value\"],\"statements\":[[4,\"if\",[[25,[\"isEditing\"]]],null,{\"statements\":[[0,\"  \"],[5,\"shared/multi-select\",[],[[\"@options\",\"@selected\",\"@placeholder\",\"@onChange\"],[[25,[\"customValue\",\"options\"]],[23,\"originalCustomValues\"],[29,\"t\",[\"shared.labels.projectTemplatesPlaceholder\"],null],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"originalCustomValues\"]]],null]],null]]]],[0,\"\\n\\n  \"],[5,\"bs-button\",[],[[\"@type\",\"@class\",\"@onClick\"],[\"success\",\"btn-sm\",[29,\"action\",[[24,0,[]],\"finishEditing\"],null]]],{\"statements\":[[0,\"\\n    \"],[1,[29,\"t\",[\"shared.actions.save\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"bs-button\",[],[[\"@type\",\"@class\",\"@onClick\"],[\"warning\",\"btn-sm\",[29,\"action\",[[24,0,[]],\"cancelEditing\"],null]]],{\"statements\":[[0,\"\\n    \"],[1,[29,\"t\",[\"shared.actions.close\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"emptyValues\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"inplace-editing\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"placeholder custom-hover\"],[9],[0,\"\\n        \"],[1,[29,\"t\",[\"organization.customField.labels.selectOption\"],null],false],[0,\"\\n      \"],[3,\"action\",[[24,0,[]],\"enableEditing\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[29,\"if\",[[25,[\"customValue\",\"value\"]],\"labels__show\",\"\"],null]],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"customValue\",\"value\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"inline-block label label-info\"],[9],[1,[24,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[3,\"action\",[[24,0,[]],\"enableEditing\"]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/custom-fields/custom-values/components/list/multi-value/template.hbs"
    }
  });

  _exports.default = _default;
});