define("ntfrontend/components/templates/new-template/modal-body/input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5DeDsaAH",
    "block": "{\"symbols\":[],\"statements\":[[5,\"shared/form-input\",[],[[\"@errors\"],[[25,[\"model\",\"errors\",\"name\"]]]],{\"statements\":[[0,\"\\n  \"],[5,\"input\",[],[[\"@autofocus\",\"@value\",\"@type\",\"@required\",\"@class\",\"@placeholder\",\"@change\"],[[23,\"autofocus\"],[23,\"name\"],\"text\",\"true\",\"form-control\",[29,\"t\",[[25,[\"placeholder\"]]],null],[29,\"action\",[[24,0,[]],[25,[\"onChange\"]]],[[\"value\"],[\"target.value\"]]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/templates/new-template/modal-body/input/template.hbs"
    }
  });

  _exports.default = _default;
});