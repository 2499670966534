define("ntfrontend/mixins/components/custom-fields-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    availableTypes: function availableTypes() {
      return {
        user: 'user-field',
        richtext: 'richtext-field',
        input: 'inline-input',
        number: 'inline-input',
        list: 'list-field'
      };
    }
  });

  _exports.default = _default;
});