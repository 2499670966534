define("ntfrontend/components/work-logs/kanban-list/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    paneManager: Ember.inject.service(),
    classNames: ['board-tile', 'kanban-grid__tile', 'work-log-tile'],
    classNameBindings: ['isSelected'],
    isSelected: Ember.computed('paneManager.sneakPeekWorkLog', function () {
      if (this.paneManager.sneakPeekWorkLog) {
        return this.record == this.paneManager.sneakPeekWorkLog;
      }
    }),
    click: function click() {
      this._openSneakPeek(this.record);
    },
    _openSneakPeek: function _openSneakPeek(workLog) {
      this.set('paneManager.showPane', true);
      this.set('paneManager.sneakPeekWorkLog', workLog);
      this.set('paneManager.selectedSneak', 'workLog');
    }
  });

  _exports.default = _default;
});