define("ntfrontend/components/users/select-user/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PTJZ7VQj",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[5,\"power-select\",[],[[\"@search\",\"@selected\",\"@onchange\",\"@onclose\",\"@searchMessage\",\"@initiallyOpened\",\"@options\",\"@allowClear\",\"@triggerClass\",\"@renderInPlace\"],[[29,\"action\",[[24,0,[]],\"search\"],null],[23,\"value\"],[29,\"action\",[[24,0,[]],\"onChange\"],null],[29,\"action\",[[24,0,[]],\"onClose\"],null],[23,\"searchMessage\"],true,[23,\"options\"],[23,\"allowClear\"],[23,\"allowClearClass\"],true]],{\"statements\":[[0,\"\\n  \"],[1,[24,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/users/select-user/template.hbs"
    }
  });

  _exports.default = _default;
});