define("ntfrontend/components/custom-fields/assignments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QN0Bm8zk",
    "block": "{\"symbols\":[\"projectType\",\"resourceType\",\"type\"],\"statements\":[[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-xs-8\"],[9],[10],[0,\"\\n\"],[4,\"each\",[[25,[\"resorceClassTypes\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"col-xs-1\"],[9],[0,\"\\n        \"],[7,\"i\"],[12,\"class\",[30,[\"icon-\",[24,3,[]]]]],[9],[5,\"bs-tooltip\",[],[[\"@title\"],[[29,\"t-scope\",[[24,3,[]],\"shared.labels\"],null]]]],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"hr\"],[9],[10],[0,\"\\n\\n\"],[4,\"each\",[[25,[\"projectTypes\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-xs-8\"],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n\"],[4,\"each\",[[25,[\"resorceTypes\"]]],null,{\"statements\":[[0,\"        \"],[5,\"custom-fields/assignments/assignment\",[],[[\"@customField\",\"@projectType\",\"@resourceType\"],[[23,\"customField\"],[24,1,[]],[24,2,[]]]]],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[10],[0,\"\\n    \"],[7,\"hr\"],[9],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/custom-fields/assignments/template.hbs"
    }
  });

  _exports.default = _default;
});