define("ntfrontend/components/shared/modal-dialog/modal/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'modal fade',
    attributeBindings: ['role'],
    role: 'dialog',
    title: '9teams',
    showModal: false,
    options: Ember.computed(function () {
      return {
        show: true,
        keyboard: true
      };
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _jquery.default)(this.element).on('hidden.bs.modal', function () {
        _this.send('onClose');

        if (_this.showModal) {
          _this.set('showModal', false);
        }
      });
      (0, _jquery.default)(this.element).on('shown.bs.modal', function () {
        if (_this.onOpen) {
          _this.onOpen();
        }
      });

      this._initModal();
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(this.element).off('hidden.bs.modal');
    },
    showModalChanged: Ember.observer('showModal', function () {
      this._initModal();

      if (!this.showModal) {
        (0, _jquery.default)(this.element).modal('hide');
      }
    }),
    actions: {
      onClose: function onClose() {
        if (this.onClose) {
          this.onClose();
        }
      },
      onSuccess: function onSuccess() {
        var _this2 = this;

        if (this.onSuccess) {
          this.onSuccess().then(function () {
            if (_this2.closeAfterSuccess) {
              (0, _jquery.default)(_this2.element) ? (0, _jquery.default)(_this2.element).modal('hide') : _this2._forceCloseModal();
            }
          });
        }
      }
    },
    _initModal: function _initModal() {
      if (this.showModal) {
        (0, _jquery.default)(this.element).modal(this.options);
        this.set('closeAfterSuccess', true);
      }
    },
    _forceCloseModal: function _forceCloseModal() {
      (0, _jquery.default)('body').removeClass('modal-open');
      (0, _jquery.default)('.modal-backdrop').remove();
    }
  });

  _exports.default = _default;
});