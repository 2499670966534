define("ntfrontend/initializers/zendesk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  var Zendesk = Ember.Object.extend({
    initialize: function initialize() {
      window.zEmbed || function () {
        var queue = [];

        window.zEmbed = function () {
          queue.push(arguments);
        };

        window.zE = window.zE || window.zEmbed;
        document.zendeskHost = '9teams.zendesk.com';
        document.zEQueue = queue;
      }();
    },
    configure: function configure() {
      var zenDeskSource = '//assets.zendesk.com/embeddable_framework/main.js';

      this._loadScript(zenDeskSource, function () {
        zE(function () {
          zE.setLocale('en');
          zE.hide();
        });
      });

      this._webWidget();
    },
    _webWidget: function _webWidget() {
      window.zESettings = {
        webWidget: {
          color: {
            theme: '#2caace'
          },
          zIndex: 4010,
          offset: {
            horizontal: '70px',
            vertical: '20px'
          },
          position: {
            horizontal: 'left',
            vertical: 'bottom'
          },
          contactForm: {
            subject: true
          }
        }
      };
    },
    _loadScript: function _loadScript(url, callback) {
      var script = document.createElement('script');
      script.type = 'text/javascript';

      if (script.readyState) {
        script.onreadystatechange = function () {
          if (script.readyState == 'loaded' || script.readyState == 'complete') {
            script.onreadystatechange = null;
            callback();
          }
        };
      } else {
        script.onload = function () {
          callback();
        };
      }

      script.src = url;
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  });

  function initialize() {
    var zendesk = Zendesk.create();
    zendesk.initialize();
    zendesk.configure();
  }

  var _default = {
    name: 'zendesk',
    initialize: initialize
  };
  _exports.default = _default;
});