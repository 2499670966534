define("ntfrontend/components/shared/multi-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EhwhGjSr",
    "block": "{\"symbols\":[\"resource\"],\"statements\":[[5,\"power-select-multiple\",[],[[\"@options\",\"@selected\",\"@searchField\",\"@placeholder\",\"@onchange\",\"@triggerClass\",\"@class\"],[[23,\"options\"],[23,\"selected\"],[23,\"searchField\"],[23,\"placeholder\"],[23,\"onChange\"],[23,\"triggerClass\"],[23,\"class\"]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,[\"resourceAsObject\"]]],null,{\"statements\":[[0,\"    \"],[1,[24,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[24,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/multi-select/template.hbs"
    }
  });

  _exports.default = _default;
});