define("ntfrontend/components/shared/filters/project-type/component", ["exports", "ntfrontend/mixins/components/open-dropdown"], function (_exports, _openDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_openDropdown.default, {
    intl: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    classNames: ['dropdown'],
    projectTypes: Ember.computed.alias('currentSession.account.projectTypes'),
    currentName: Ember.computed('projectTypeId', function () {
      if (this.projectTypeId) {
        return this.projectTypes.findBy('id', this.projectTypeId).get('name');
      } else {
        return this.intl.t('shared.filters.all');
      }
    }),
    actions: {
      changeFilter: function changeFilter(id) {
        this.set('projectTypeId', id);
      }
    }
  });

  _exports.default = _default;
});