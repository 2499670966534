define("ntfrontend/components/templates/project-templates/import-templates/templates-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OptionObject = Ember.Object.extend({
    collection: [],
    selected: Ember.computed('collection', 'object', {
      get: function get() {
        return this.collection.includes(this.object);
      },
      set: function set(key, value) {
        if (value) {
          this.collection.addObject(this.object);
        } else {
          this.collection.removeObject(this.object);
        }

        return value;
      }
    })
  });

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    options: Ember.computed.map('projectTypes', function (template) {
      return OptionObject.create({
        object: template,
        collection: this.selectedTemplates
      });
    }),
    actions: {
      select: function select(option) {
        option.toggleProperty('selected');
      },
      selectAll: function selectAll() {
        this.options.forEach(function (option) {
          return option.set('selected', true);
        });
      },
      deselectAll: function deselectAll() {
        this.options.forEach(function (option) {
          return option.set('selected', false);
        });
      }
    }
  });

  _exports.default = _default;
});