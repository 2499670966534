define("ntfrontend/components/topics/task-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    paneManager: Ember.inject.service(),
    classNames: ['topic__task-list'],
    taskSorting: ['deadlineAt'],
    sortedTasks: Ember.computed.sort('tasks', 'taskSorting'),
    actions: {
      openSneakPeek: function openSneakPeek(task) {
        this.set('paneManager.showPane', true);
        this.set('paneManager.sneakPeekTask', task);
      }
    }
  });

  _exports.default = _default;
});