define("ntfrontend/components/accounts/document-types/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KaWZLCix",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"page-header\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"h2\"],[9],[1,[29,\"t\",[\"organization.labels.templatesSets\"],null],false],[10],[0,\"\\n  \"],[5,\"accounts/document-types/sets/new-set\",[],[[\"@account\",\"@class\"],[[23,\"account\"],\"page-header__menu\"]]],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-sm-12\"],[9],[0,\"\\n    \"],[5,\"accounts/document-types/sets\",[],[[\"@model\"],[[23,\"templates\"]]]],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/accounts/document-types/template.hbs"
    }
  });

  _exports.default = _default;
});