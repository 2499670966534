define("ntfrontend/routes/create", ["exports", "ntfrontend/routes/org-auth"], function (_exports, _orgAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend({
    beforeModel: function beforeModel(transition) {
      var defaultName = 'projects.project.work.logs';
      var redirectRoutes = ['projects.project.work.plan', 'memos.memo.index', 'requests.request', 'issues.issue', 'scenarios.scenario'];

      var path = this._setPath(redirectRoutes, defaultName, transition);

      this.set('transitionPath', path);
    },
    setupController: function setupController() {
      this.controller.set('transitionPath', this.transitionPath);
    },
    _setPath: function _setPath(redirectRoutes, defaultName, transition) {
      var from = transition.from;
      var path = {
        name: defaultName,
        slug: null
      };

      if (from && from.attributes) {
        var routeName = from.name;
        path.name = this._setName(redirectRoutes, routeName, defaultName);
        path.slug = this._setSlug(redirectRoutes, routeName, transition);
      }

      return path;
    },
    _setName: function _setName(redirectRoutes, routeName, defaultName) {
      return redirectRoutes.includes(routeName) ? routeName : defaultName;
    },
    _setSlug: function _setSlug(redirectRoutes, routeName, transition) {
      return redirectRoutes.slice(1).includes(routeName) ? transition.from.attributes.get('project.slug') : null;
    }
  });

  _exports.default = _default;
});