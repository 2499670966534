define("ntfrontend/components/works/sneak-peek/create-work-log-sneak-peek/component", ["exports", "ntfrontend/mixins/components/model-saving"], function (_exports, _modelSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modelSaving.default, {
    paneManager: Ember.inject.service(),
    router: Ember.inject.service(),
    selectedProject: Ember.computed.alias('paneManager.sneakPeekProject'),
    workDate: Ember.computed.alias('paneManager.sneakPeekWorkLogDate'),
    workItem: Ember.computed.alias('paneManager.sneakWorkItem'),
    actions: {
      hidePane: function hidePane() {
        this.set('paneManager.createWorkLog', null);
        this.set('paneManager.selectedSneak', null);
        this.set('paneManager.sneakWorkItem', null);
        this.set('paneManager.showPane', false);
      },
      refreshWorkItems: function refreshWorkItems() {
        var route = Ember.getOwner(this).lookup("route:".concat(this.router.currentRouteName));
        return route.refresh();
      }
    }
  });

  _exports.default = _default;
});