define("ntfrontend/components/custom-fields/document-list/item-row/item/user-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FTVKExuv",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"customValue\",\"value\"]]],null,{\"statements\":[[0,\"  \"],[5,\"custom-fields/document-list/item-row/item/user-field/modal\",[],[[\"@user\",\"@editable\",\"@afterChange\"],[[23,\"user\"],[23,\"editable\"],[29,\"action\",[[24,0,[]],\"updateUserModel\"],null]]],{\"statements\":[[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[29,\"if\",[[25,[\"editable\"]],\"cursor-on-hover\"],null]],[9],[0,\"\\n      \"],[5,\"users/profile-box/picture\",[],[[\"@user\",\"@width\",\"@height\"],[[23,\"user\"],\"30\",\"30\"]]],[0,\"\\n    \"],[10],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"custom-fields/document-list/item-row/item/user-field/modal\",[],[[\"@editable\",\"@afterChange\"],[[23,\"editable\"],[29,\"action\",[[24,0,[]],\"updateUserModel\"],null]]],{\"statements\":[[0,\"\\n    \"],[7,\"span\"],[12,\"class\",[30,[\"placeholder \",[29,\"if\",[[25,[\"editable\"]],\"cursor-on-hover\"],null]]]],[9],[0,\"\\n      \"],[1,[29,\"t\",[\"shared.labels.notDefined\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/custom-fields/document-list/item-row/item/user-field/template.hbs"
    }
  });

  _exports.default = _default;
});