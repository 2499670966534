define("ntfrontend/components/sidebar-menu/account-switcher/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentSession: Ember.inject.service(),
    classNames: 'accounts-modal',
    currentTab: 'list',
    actions: {
      openModal: function openModal() {
        this.set('currentTab', 'list');
        this.set('showModal', true);
      },
      selectTab: function selectTab(tab) {
        this.set('currentTab', tab);
      }
    }
  });

  _exports.default = _default;
});