define("ntfrontend/abilities/topic", ["exports", "ntfrontend/abilities/resourcify"], function (_exports, _resourcify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resourcify.default.extend();

  _exports.default = _default;
});