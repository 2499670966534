define("ntfrontend/abilities/account", ["exports", "ntfrontend/abilities/resourcify"], function (_exports, _resourcify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resourcify.default.extend({
    canCreateCustomFields: Ember.computed.readOnly('model.ability.actions.createCustomFields'),
    canCreateProjects: Ember.computed.readOnly('model.ability.actions.createProjects'),
    canVisit: Ember.computed.readOnly('model.ability.actions.visit'),
    canDisplayAccountDetails: Ember.computed.readOnly('model.ability.actions.displayAccountDetails'),
    canCreateDocumentTemplates: Ember.computed.readOnly('model.ability.actions.createDocumentTemplates'),
    canCreateExcel: Ember.computed.readOnly('model.ability.actions.createExcel'),
    canCreateTransports: Ember.computed.readOnly('model.ability.actions.createTransports')
  });

  _exports.default = _default;
});