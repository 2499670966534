define("ntfrontend/components/projects/dashboard/state-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HA+/mc4G",
    "block": "{\"symbols\":[\"key\",\"value\"],\"statements\":[[4,\"unless\",[[25,[\"valueOnly\"]]],null,{\"statements\":[[0,\"  \"],[5,\"projects/dashboard/state-status/label\",[],[[\"@model\",\"@property\"],[[23,\"model\"],[23,\"property\"]]]],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\"],[12,\"class\",[30,[\"dropdown \",[29,\"if\",[[25,[\"editable\"]],\"inplace-editing\"],null]]]],[9],[0,\"\\n  \"],[7,\"a\"],[12,\"data-toggle\",[29,\"if\",[[25,[\"editable\"]],\"dropdown\"],null]],[9],[0,\"\\n    \"],[5,\"projects/dashboard/state-status/state\",[],[[\"@state\"],[[23,\"stateKey\"]]]],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"ul\"],[11,\"class\",\"dropdown-menu\"],[9],[0,\"\\n\"],[4,\"each\",[[29,\"-each-in\",[[25,[\"model\",\"states\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[0,\"\\n        \"],[7,\"a\"],[9],[0,\"\\n          \"],[5,\"shared/property-label\",[],[[\"@list\",\"@property\",\"@translationKey\"],[[25,[\"model\",\"states\"]],[24,2,[]],[29,\"concat\",[\"project.dashboard.states.\",[24,1,[]],\".label\"],null]]]],[0,\"\\n        \"],[3,\"action\",[[24,0,[]],\"changeState\",[24,2,[]]]],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[5,\"projects/dashboard/state-status/modal\",[],[[\"@model\",\"@property\",\"@showModal\"],[[23,\"model\"],[23,\"property\"],[23,\"showModal\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/projects/dashboard/state-status/template.hbs"
    }
  });

  _exports.default = _default;
});