define("ntfrontend/models/scenario", ["exports", "ember-data", "ntfrontend/models/document", "ntfrontend/mixins/models/tagable", "ntfrontend/mixins/models/stepable", "ntfrontend/utils/group-by"], function (_exports, _emberData, _document, _tagable, _stepable, _groupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.statuses = void 0;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var statuses = {
    1: 'open',
    2: 'draft',
    3: 'forApproval',
    4: 'active',
    5: 'onHold',
    6: 'blocked',
    7: 'closed'
  };
  _exports.statuses = statuses;

  var _default = _document.default.extend(_stepable.default, _tagable.default, {
    dueDate: attr('date'),
    openStepsCount: attr('number'),
    failedStepsCount: attr('number'),
    doneStepsCount: attr('number'),
    statusDescription: attr(),
    status: attr('number', {
      defaultValue: 1
    }),
    lastStep: belongsTo('step', {
      inverse: null
    }),
    lastOpenStep: belongsTo('step', {
      inverse: null
    }),
    statuses: Ember.computed(function () {
      return statuses;
    }),
    progressObserver: Ember.observer('steps.@each.status', function () {
      var _this = this;

      var statusesNames = ['openStepsCount', 'failedStepsCount', 'doneStepsCount'];
      var groupedSteps = (0, _groupBy.groupAsObject)(this.steps, 'status');
      statusesNames.forEach(function (status, index) {
        var group = groupedSteps[index];
        var count = Ember.isPresent(group) ? group.length : 0;

        _this.set(status, count);
      });
    })
  });

  _exports.default = _default;
});