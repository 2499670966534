define("ntfrontend/components/templates/project-templates/edit/document-templates/document-names/component", ["exports", "ntfrontend/models/document"], function (_exports, _document) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    documentsNames: _document.documentsNames,
    filteredDocumentTemplates: Ember.computed('documentTemplates.length', function () {
      var _this = this;

      return this.documentsNames.reduce(function (acc, item) {
        acc[item] = _this.documentTemplates.filterBy('documentType', item);
        return acc;
      }, []);
    }),
    actions: {
      setObjectParent: function setObjectParent(projectType) {
        this.set('selectedProjectType', projectType);
      },
      clearObjectParent: function clearObjectParent() {
        this.set('selectedProjectType', null);
      }
    }
  });

  _exports.default = _default;
});