define("ntfrontend/components/auth/resend-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k2uqFSFq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[9],[0,\"\\n  \"],[5,\"shared/form-input\",[],[[\"@errors\"],[[25,[\"data\",\"errors\",\"email\"]]]],{\"statements\":[[0,\"\\n    \"],[5,\"input\",[],[[\"@id\",\"@value\",\"@placeholder\",\"@class\",\"@required\",\"@autofocus\"],[\"inputEmail\",[25,[\"data\",\"email\"]],[29,\"t\",[\"auth.email.placeholder\"],null],\"form-control\",\"true\",\"true\"]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"shared/form-confirm-button\",[],[[\"@disabled\",\"@label\"],[[23,\"disabled\"],[29,\"t\",[\"auth.resendActiation\"],null]]]],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"resend\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/auth/resend-form/template.hbs"
    }
  });

  _exports.default = _default;
});