define("ntfrontend/mixins/components/tasks/task-switcher", ["exports", "ntfrontend/mixins/components/switcher"], function (_exports, _switcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_switcher.default, {
    taskDistributionNotifier: Ember.inject.service(),
    state: Ember.computed.alias('model.completed'),
    _afterSaveSuccess: function _afterSaveSuccess() {
      this.model.refreshParents();
      this.taskDistributionNotifier.notify(this.get('model.members'));
    }
  });

  _exports.default = _default;
});