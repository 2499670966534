define("ntfrontend/components/projects/dashboard/milestones/milestones-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sort: 'deadlineAt',
    milestones: Ember.computed('sort', 'project.id', function () {
      return this.store.query('milestone', {
        filter: {
          projectId: this.get('project.id')
        },
        include: 'ability',
        sort: this.sort
      });
    }),
    actions: {
      refreshMilestones: function refreshMilestones() {
        this.notifyPropertyChange('milestones');
      }
    }
  });

  _exports.default = _default;
});