define("ntfrontend/components/topics/template-topic-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lXeRo4ie",
    "block": "{\"symbols\":[\"drop-zone\"],\"statements\":[[7,\"div\"],[11,\"class\",\"topic__item__top\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"topic__name\"],[9],[0,\"\\n    \"],[5,\"shared/inplace-input\",[],[[\"@model\",\"@afterChange\",\"@value\",\"@required\",\"@editable\"],[[23,\"model\"],[29,\"action\",[[24,0,[]],\"saveModel\"],null],[25,[\"model\",\"name\"]],true,[23,\"editable\"]]]],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"editable\"]]],null,{\"statements\":[[0,\"    \"],[5,\"shared/actions/remove-button\",[],[[\"@model\",\"@afterRemove\"],[[23,\"model\"],[29,\"action\",[[24,0,[]],\"afterRemove\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[7,\"div\"],[12,\"class\",[30,[\"topic__full collapse \",[29,\"unless\",[[25,[\"agendaMode\"]],\"in\"],null]]]],[9],[0,\"\\n  \"],[5,\"shared/inplace-editor\",[],[[\"@value\",\"@afterChange\",\"@editable\",\"@placeholderKey\"],[[25,[\"model\",\"description\"]],[29,\"action\",[[24,0,[]],\"saveModel\"],null],[23,\"editable\"],\"topic.placeholders.description\"]]],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"topic__content\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"files-section\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"can\",[\"create attachments in topic\",[25,[\"model\"]]],null]],null,{\"statements\":[[0,\"        \"],[5,\"shared/attachments/file-uploader\",[],[[\"@model\"],[[23,\"model\"]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[5,\"shared/drop-zone\",[],[[\"@onDrop\"],[[29,\"action\",[[24,0,[]],\"addAttachments\"],null]]],{\"statements\":[[0,\"\\n        \"],[6,[24,1,[\"content\"]],[],[[],[]],{\"statements\":[[0,\"\\n          \"],[5,\"shared/attachments/file-list\",[],[[\"@model\"],[[23,\"model\"]]]],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\\n        \"],[6,[24,1,[\"overlay\"]],[],[[],[]]],[0,\"\\n      \"]],\"parameters\":[1]}],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/topics/template-topic-item/template.hbs"
    }
  });

  _exports.default = _default;
});