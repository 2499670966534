define("ntfrontend/components/projects/project-details/status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editable: false,
    dataToggle: Ember.computed('editable', function () {
      return this.editable ? 'dropdown' : '';
    }),
    actions: {
      changeStatus: function changeStatus(status) {
        this.set('model.status', status);
        this.set('showModal', true);
      }
    }
  });

  _exports.default = _default;
});