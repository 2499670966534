define("ntfrontend/components/works/create-tab/details/users-working/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cXxMh+XV",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[7,\"div\"],[11,\"class\",\"create-item__overview-data\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"create-item__overview-label text-muted\"],[9],[1,[29,\"t\",[\"work.labels.usersWorking\"],null],false],[10],[0,\"\\n\"],[4,\"each\",[[25,[\"uniqueUsers\"]]],null,{\"statements\":[[0,\"    \"],[5,\"users/profile-box/picture\",[],[[\"@user\",\"@width\",\"@height\"],[[24,1,[]],\"25\",\"25\"]]],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/works/create-tab/details/users-working/template.hbs"
    }
  });

  _exports.default = _default;
});