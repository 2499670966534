define("ntfrontend/abilities/task", ["exports", "ntfrontend/abilities/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    canCreatePdf: Ember.computed.readOnly('model.ability.actions.createPdf'),
    canCreateLabels: Ember.computed.readOnly('model.ability.actions.createLabels'),
    canCreateMembers: Ember.computed.readOnly('model.ability.actions.createMembers'),
    canCreateAttachments: Ember.computed.readOnly('model.ability.actions.createAttachments')
  });

  _exports.default = _default;
});