define("ntfrontend/components/works/create-tab/new-document/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zO5c4PFa",
    "block": "{\"symbols\":[\"@projectSlug\",\"@objectType\",\"@transitionPath\"],\"statements\":[[5,\"create-page/tabs-content/template-nav\",[],[[\"@project\",\"@filterQuery\",\"@searchQuery\",\"@navHeader\",\"@items\",\"@filterLabel\",\"@changeFilters\",\"@onClear\",\"@isCreateWorkTab\"],[[24,0,[\"selectedProject\"]],[24,0,[\"filterQuery\"]],[24,0,[\"searchQuery\"]],[29,\"t\",[\"createPage.actions.selectProject\"],null],[24,0,[\"statuses\"]],[29,\"t\",[\"createPage.labels.status\"],null],[29,\"action\",[[24,0,[]],[29,\"mut\",[[24,0,[\"filterQuery\"]]],null]],null],[29,\"action\",[[24,0,[]],[29,\"mut\",[[24,0,[\"selectedProject\"]]],null]],null],[24,0,[\"selectedProject\"]]]]],[0,\"\\n\\n\"],[5,\"works/create-tab/create-work\",[],[[\"@disabled\",\"@documentTemplates\",\"@projects\",\"@projectSlug\",\"@searchedProjects\",\"@selectedProject\",\"@objectType\",\"@changeProject\",\"@changeTemplate\",\"@isLoaded\",\"@transitionPath\"],[[24,0,[\"disabled\"]],[24,0,[\"searchedDocumentTemplates\"]],[24,0,[\"projects\"]],[24,1,[]],[24,0,[\"searchedProjects\"]],[24,0,[\"selectedProject\"]],[24,2,[]],[29,\"action\",[[24,0,[]],\"changeProject\"],null],[29,\"action\",[[24,0,[]],\"changeTemplate\"],null],[24,0,[\"isLoaded\"]],[24,3,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/works/create-tab/new-document/template.hbs"
    }
  });

  _exports.default = _default;
});