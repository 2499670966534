define("ntfrontend/components/tasks/new-task/copy-task/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KnfIS4RR",
    "block": "{\"symbols\":[\"@resource\",\"@modalTask\",\"@cloneDataTask\"],\"statements\":[[5,\"tasks/new-task\",[],[[\"@resource\",\"@task\",\"@cloneDataTask\",\"@label\",\"@customStyle\"],[[24,1,[]],[24,2,[]],[24,3,[]],[29,\"t\",[\"task.labels.copy\"],null],\"icon-copy icon-hover-animation\"]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/tasks/new-task/copy-task/template.hbs"
    }
  });

  _exports.default = _default;
});