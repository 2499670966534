define("ntfrontend/services/task-distribution-notifier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Service.extend({
    currentSession: Ember.inject.service(),
    notifyService: Ember.inject.service('notify-alert'),
    mutedNotificationSettings: {
      icon: 'icon-warning',
      type: 'warning'
    },
    notify: function notify(members) {
      var saveNotification = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      this._notify(members, saveNotification);
    },
    notifyWithReloadMembers: function notifyWithReloadMembers(members) {
      var _this = this;

      var saveNotification = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      members.reload().then(function () {
        _this._notify(members, saveNotification);
      });
    },
    _notify: function _notify(members, saveNotification) {
      var filteredMembers = this._membersWithoutCurrentUser(members);

      this._notifyDistributed(filteredMembers);

      if (!filteredMembers.length && saveNotification) {
        this.notifyService.success('notify.default.saved');
      }
    },
    _notifyDistributed: function _notifyDistributed(members) {
      if (members.length) {
        this.notifyService.successWithUsers('notify.task.distributed', null, _toConsumableArray(members));
      }
    },
    _notifyMuted: function _notifyMuted(members) {
      if (members.length) {
        var options = this.mutedNotificationSettings;
        var message = 'notify.task.muted';
        var title = 'notify.default.warning.title';
        this.notifyService.successWithUsers(message, title, _toConsumableArray(members), options);
      }
    },
    _membersWithoutCurrentUser: function _membersWithoutCurrentUser(members) {
      return members.rejectBy('user.email', this.get('currentSession.user.email'));
    }
  });

  _exports.default = _default;
});