define("ntfrontend/components/custom-fields/custom-values/components/richtext/modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aNkS4qGf",
    "block": "{\"symbols\":[\"modal\",\"&default\"],\"statements\":[[15,2],[0,\"\\n\"],[5,\"shared/modal-dialog\",[],[[\"@showModal\",\"@customModal\",\"@onSuccess\"],[[23,\"showModal\"],true,[29,\"action\",[[24,0,[]],\"submit\"],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-dialog\"],[11,\"role\",\"document\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"modal-content\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n        \"],[7,\"h4\"],[11,\"class\",\"modal-title\"],[9],[1,[25,[\"model\",\"name\"]],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"modal-body short-text\"],[9],[0,\"\\n        \"],[5,\"shared/inplace-editor\",[],[[\"@value\",\"@placeholderKey\",\"@editable\",\"@afterChange\"],[[25,[\"model\",\"value\"]],\"organization.customField.placeholders.setValue\",[23,\"allowEditing\"],[24,1,[\"onSuccess\"]]]]],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/custom-fields/custom-values/components/richtext/modal/template.hbs"
    }
  });

  _exports.default = _default;
});