define("ntfrontend/components/shared/activity-log/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1hA9Y15R",
    "block": "{\"symbols\":[\"log\"],\"statements\":[[5,\"shared/loading-state/block\",[],[[\"@model\",\"@tagName\"],[[23,\"activityLogs\"],\"\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,[\"activityLogs\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"activity-feed\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"activityLogs\"]]],null,{\"statements\":[[0,\"        \"],[5,\"shared/activity-log/log\",[],[[\"@log\"],[[24,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"text-center text-muted\"],[9],[0,\"\\n      \"],[1,[29,\"t\",[\"activityLog.labels.noActivity\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/activity-log/template.hbs"
    }
  });

  _exports.default = _default;
});