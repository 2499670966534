define("ntfrontend/components/create-page/tabs-content/template-nav/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    selectedOrganization: Ember.computed.alias('filterQuery'),
    hasSelectedProject: Ember.computed.bool('project'),
    breadCrumbLabel: Ember.computed('project', function () {
      if (this.project) {
        var label = this.isCreateWorkTab ? 'createPage.actions.addWorkLog' : 'createPage.actions.chooseTemplate';
        return this.intl.t(label);
      }

      return this.navHeader;
    }),
    actions: {
      change: function change(filter) {
        if (filter) {
          return this.changeFilters(filter);
        }

        return this.changeFilters();
      }
    }
  });

  _exports.default = _default;
});