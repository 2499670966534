define("ntfrontend/components/documents/archive-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service('notify-alert'),
    sweetAlert: Ember.inject.service('sweet-alert'),
    tagName: 'li',
    click: function click() {
      var _this = this;

      this.sweetAlert.confirm(null, null, function () {
        var action = _this.get('model.archived') ? _this.model.unarchive() : _this.model.archive();
        action.then(function () {
          _this.notify.success('notify.default.saved');
        });
      });
    }
  });

  _exports.default = _default;
});