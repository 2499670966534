define("ntfrontend/services/zendesk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    activate: function activate() {
      zE.activate({
        hideOnClose: true
      });
    },
    regenerateSuggestions: function regenerateSuggestions() {
      zE(function () {
        zE.setHelpCenterSuggestions({
          url: true
        });
      });
    },
    identify: function identify(name, email) {
      zE(function () {
        zE.identify({
          name: name,
          email: email
        });
      });
    }
  });

  _exports.default = _default;
});