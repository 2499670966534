define("ntfrontend/components/tasks/task-list/item/component", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    paneManager: Ember.inject.service(),
    tagName: 'tbody',
    taskSelected: Ember.computed('paneManager.sneakPeekTask', function () {
      var taskId = this.get('paneManager.sneakPeekTask.id');
      return taskId === this.model.id;
    }),
    pluralModelName: Ember.computed('documentModelName', function () {
      return (0, _emberInflector.pluralize)(this.model.resourceDocumentType.toLowerCase());
    }),
    documentRoute: Ember.computed('pluralModelName', 'model.resourceDocumentType', function () {
      return "".concat(this.pluralModelName, ".").concat(this.model.resourceDocumentType.toLowerCase());
    })
  });

  _exports.default = _default;
});