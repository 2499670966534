define("ntfrontend/components/shared/inplace-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ojoi6rES",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"showField\"]]],null,{\"statements\":[[4,\"if\",[[25,[\"isEditing\"]]],null,{\"statements\":[[0,\"    \"],[7,\"form\"],[9],[0,\"\\n      \"],[5,\"shared/form-input\",[],[[],[]],{\"statements\":[[0,\"\\n        \"],[7,\"input\"],[12,\"placeholder\",[23,\"placeholder\"]],[12,\"required\",[23,\"required\"]],[12,\"value\",[23,\"value\"]],[12,\"onchange\",[29,\"action\",[[24,0,[]],\"submitFocusOut\"],null]],[12,\"onkeyup\",[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"value\"]]],null]],[[\"value\"],[\"target.value\"]]]],[12,\"onclick\",[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"value\"]]],null]],[[\"value\"],[\"target.value\"]]]],[11,\"class\",\"form-control\"],[12,\"type\",[23,\"type\"]],[9],[10],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],\"toggleEditing\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"static-value\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"value\"]]],null,{\"statements\":[[0,\"        \"],[1,[23,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"editable\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"placeholder\"],[9],[1,[23,\"placeholder\"],false],[10],[0,\"\\n      \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"    \"],[3,\"action\",[[24,0,[]],\"toggleEditing\"]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/shared/inplace-input/template.hbs"
    }
  });

  _exports.default = _default;
});