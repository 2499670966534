define("ntfrontend/templates/auth/resend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OzmBShvH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"col-xs-12 col-sm-6 auth-page\"],[9],[0,\"\\n  \"],[7,\"h2\"],[11,\"class\",\"page-header\"],[9],[1,[29,\"t\",[\"auth.resendInstructionsPrompt\"],null],false],[10],[0,\"\\n  \"],[5,\"auth/resend-form\",[],[[],[]]],[0,\"\\n  \"],[5,\"auth/auth-links\",[],[[],[]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/auth/resend.hbs"
    }
  });

  _exports.default = _default;
});