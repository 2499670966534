define("ntfrontend/services/pane-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    showPane: false,
    sneakPeekTask: null,
    sneakPeekProject: null,
    sneakPeekTransport: null,
    sneakPeekDocumentTemplate: null,
    selectedPane: null,
    loadTask: function loadTask(slug) {
      var _this = this;

      this.set('selectedSneak', 'task');

      if (slug) {
        var filter = {
          slug: "".concat(slug, ",").concat(this.get('currentSession.account.id'))
        };
        this.store.query('task', {
          filter: filter
        }).then(function (tasks) {
          _this.set('sneakPeekTask', tasks.get('firstObject'));

          _this.set('showPane', true);
        });
      }
    },
    loadProject: function loadProject(slug) {
      var _this2 = this;

      this.set('selectedSneak', 'project');

      if (slug) {
        var filter = {
          slug: "".concat(slug, ",").concat(this.get('currentSession.account.id'))
        };
        this.store.query('project', {
          filter: filter
        }).then(function (project) {
          _this2.set('sneakPeekProject', project.get('firstObject'));

          _this2.set('showPane', true);
        });
      }
    },
    closePane: function closePane(field) {
      this.set("sneakPeek".concat(field.capitalize()), null);
      this.set('selectedSneak', null);
      this.set('showPane', false);
    }
  });

  _exports.default = _default;
});