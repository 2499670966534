define("ntfrontend/components/templates/new-template/modal-body/project-types/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3dJG5Zdr",
    "block": "{\"symbols\":[],\"statements\":[[5,\"shared/multi-select\",[],[[\"@options\",\"@selected\",\"@resourceAsObject\",\"@placeholder\",\"@onChange\",\"@triggerClass\"],[[23,\"projectTypes\"],[25,[\"model\",\"projectTypes\"]],true,[29,\"t\",[\"shared.labels.projectTemplatesPlaceholder\"],null],[23,\"onChange\"],\"select form-control custom-trigger-position\"]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/templates/new-template/modal-body/project-types/template.hbs"
    }
  });

  _exports.default = _default;
});