define("ntfrontend/components/shared/loading-state/table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    loaded: Ember.computed('model.{isLoaded,isFulfilled,isReloading}', function () {
      return (this.get('model.isLoaded') || this.get('model.isFulfilled')) && !this.get('model.isReloading');
    })
  });

  _exports.default = _default;
});