define("ntfrontend/components/requests/list-item/component", ["exports", "ntfrontend/mixins/components/lists/mouse-over-async-abilities", "ntfrontend/mixins/components/lists/hidden-custom-values-filter"], function (_exports, _mouseOverAsyncAbilities, _hiddenCustomValuesFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mouseOverAsyncAbilities.default, _hiddenCustomValuesFilter.default, {
    tagName: 'tbody',
    classNameBindings: ['model.archived:row-muted'],
    customValuesCollectionType: 'requests'
  });

  _exports.default = _default;
});