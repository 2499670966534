define("ntfrontend/routes/resource", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    resourceCode: Ember.inject.service(),
    redirect: function redirect(params) {
      this._super.apply(this, arguments);

      var resourceCodeObject = this.resourceCode.decode(params.code);

      if (!resourceCodeObject) {
        return this.transitionTo('index');
      }

      this.transitionTo(resourceCodeObject.routePath, resourceCodeObject.resourceId);
    }
  });

  _exports.default = _default;
});