define("ntfrontend/components/tasks/breadcrumbs-link/component", ["exports", "ember-inflector", "ntfrontend/helpers/model-type-name"], function (_exports, _emberInflector, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    resource: Ember.computed.reads('model.resource'),
    document: Ember.computed.reads('resource.document'),
    project: Ember.computed.reads('document.project'),
    modelName: Ember.computed('document', function () {
      return (0, _modelTypeName.modelTypeName)([this.document]).camelize();
    }),
    pluralModelName: Ember.computed('modelName', function () {
      return (0, _emberInflector.pluralize)(this.modelName);
    }),
    projectDocumentsRoute: Ember.computed('pluralModelName', function () {
      return "projects.project.".concat(this.pluralModelName);
    }),
    documentRoute: Ember.computed('pluralModelName', 'modelName', function () {
      return "".concat(this.pluralModelName, ".").concat(this.modelName);
    })
  });

  _exports.default = _default;
});