define("ntfrontend/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var quaterOfHourInMilliSeconds = 900000;

  var _default = Ember.Controller.extend({
    currentSession: Ember.inject.service(),
    isInApp: Ember.computed.not('currentSession.isApp'),
    isLogged: Ember.computed.bool('currentSession.loggedIn'),
    displaySearch: Ember.computed.and('isInApp', 'isLogged'),
    displaySubscriptionStatus: true,
    checkVersionInterval: quaterOfHourInMilliSeconds
  });

  _exports.default = _default;
});