define("ntfrontend/components/labels/labels-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G7KJ6yb7",
    "block": "{\"symbols\":[\"label\",\"labels\"],\"statements\":[[4,\"if\",[[25,[\"isEditing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"labels\"],[9],[0,\"\\n    \"],[5,\"power-select-multiple-with-create\",[],[[\"@class\",\"@options\",\"@searchField\",\"@selected\",\"@allowClear\",\"@onchange\",\"@oncreate\"],[\"labels__search\",[23,\"labelsOptions\"],\"name\",[23,\"selectedLabels\"],true,[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"selectedLabels\"]]],null]],null],[29,\"action\",[[24,0,[]],\"createNewLabel\"],null]]],{\"statements\":[[0,\"\\n      \"],[1,[24,2,[\"name\"]],false],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-sm btn-success\"],[11,\"type\",\"button\"],[9],[0,\"\\n      \"],[1,[29,\"t\",[\"shared.actions.save\"],null],false],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],\"doneEditing\"]],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-sm btn-warning\"],[11,\"type\",\"button\"],[9],[0,\"\\n      \"],[1,[29,\"t\",[\"shared.actions.cancel\"],null],false],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],\"cancelEditing\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[29,\"if\",[[25,[\"labels\"]],\"labels__show\",\"\"],null]],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"labels\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"inline-block label label-info\"],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[3,\"action\",[[24,0,[]],\"toggleEditing\"]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/labels/labels-list/template.hbs"
    }
  });

  _exports.default = _default;
});