define("ntfrontend/components/templates/project-templates/import-templates/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CFnJH3IJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[11,\"class\",\"btn-success icon-plus-circle\"],[9],[0,\"\\n  \"],[1,[29,\"t\",[\"organization.documentTemplate.actions.import\"],null],false],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"openModal\"]],[10],[0,\"\\n\\n\"],[5,\"shared/modal-dialog\",[],[[\"@showModal\",\"@onSuccess\",\"@title\"],[[23,\"showModal\"],[29,\"action\",[[24,0,[]],\"importTemplates\"],null],[29,\"t\",[\"organization.documentTemplate.labels.importDocumentTemplate\"],null]]],{\"statements\":[[0,\"  \\n  \"],[7,\"div\"],[11,\"class\",\"modal-body\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-group select\"],[9],[0,\"\\n      \"],[5,\"templates/project-templates/import-templates/account-select\",[],[[\"@accounts\",\"@model\",\"@class\"],[[23,\"accountsNames\"],[23,\"account\"],\"form-control\"]]],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[5,\"templates/project-templates/import-templates/get-project-templates\",[],[[\"@sendProjectTypes\"],[[29,\"action\",[[24,0,[]],\"getProjectTypes\"],null]]]],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"account\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"form-group select\"],[9],[0,\"\\n        \"],[5,\"templates/project-templates/import-templates/templates-select\",[],[[\"@selectedTemplates\",\"@projectTypes\",\"@class\"],[[23,\"templates\"],[23,\"filteredProjectTypes\"],\"form-control\"]]],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[5,\"shared/form-confirm-button\",[],[[\"@disabled\",\"@label\"],[[23,\"disabled\"],[29,\"t\",[\"shared.actions.import\"],null]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/templates/project-templates/import-templates/template.hbs"
    }
  });

  _exports.default = _default;
});