define("ntfrontend/components/accounts/create-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9+u+MxBN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"action\",[[24,0,[]],\"openModal\"],null]]],[0,\"\\n\\n\"],[5,\"shared/modal-dialog\",[],[[\"@showModal\",\"@customModal\"],[[23,\"showModal\"],true]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-dialog\"],[11,\"role\",\"document\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"modal-content\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n        \"],[7,\"h4\"],[11,\"class\",\"modal-title\"],[9],[1,[29,\"t\",[\"organization.create\"],null],false],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"modal-body\"],[9],[0,\"\\n        \"],[5,\"accounts/create-form\",[],[[],[]]],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/accounts/create-modal/template.hbs"
    }
  });

  _exports.default = _default;
});