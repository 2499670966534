define("ntfrontend/components/search-box/previews/default/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    model: Ember.computed.alias('result.resource'),
    typeName: Ember.computed.readOnly('result.resourceType'),
    onClose: function onClose() {},
    actions: {
      redirect: function redirect() {
        this.onClose();
        var urlToRedirect = this.router.urlFor(this.get('result.resourceRoute'), this.get('result.slug'));
        this.router.transitionTo(urlToRedirect);
      }
    }
  });

  _exports.default = _default;
});