define("ntfrontend/templates/tasks/task", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tEKyA1yH",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"page-title\",[[25,[\"model\",\"name\"]]],null],false],[0,\"\\n\"],[5,\"tasks/task-details\",[],[[\"@model\",\"@memo\"],[[23,\"model\"],[25,[\"model\",\"resource\",\"document\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/templates/tasks/task.hbs"
    }
  });

  _exports.default = _default;
});