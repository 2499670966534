define("ntfrontend/components/auth/auth-links/auth-to/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var component = Ember.Component.extend({
    link: Ember.computed('linkName', function () {
      return "auth.".concat(this.linkName);
    }),
    translation: Ember.computed('linkName', function () {
      var tScope = 'auth';
      var link = this.linkName;
      var translations = {
        register: 'signUp',
        forgot: 'forgottenPasswordPrompt',
        resend: 'resendInstructionsPrompt',
        login: 'signIn'
      };
      return "".concat(tScope, ".").concat(translations[link]);
    })
  });
  component.reopenClass({
    positionalParams: ['linkName']
  });
  var _default = component;
  _exports.default = _default;
});