define("ntfrontend/components/transports/transport-modal/system-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2FSW2oTT",
    "block": "{\"symbols\":[\"system\",\"@withPlaceholder\"],\"statements\":[[4,\"if\",[[24,2,[]]],null,{\"statements\":[[0,\"  \"],[5,\"shared/power-select-with-placeholder\",[],[[\"@value\",\"@items\",\"@onChange\",\"@objectType\",\"@placeholder\"],[[24,0,[\"selectedSystem\"]],[24,0,[\"systems\"]],[29,\"action\",[[24,0,[]],\"setSystem\"],null],\"system\",[29,\"t\",[\"transport.placeholders.system\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"power-select\",[],[[\"@selected\",\"@options\",\"@onchange\",\"@placeholder\"],[[24,0,[\"selectedSystem\"]],[24,0,[\"systems\"]],[29,\"action\",[[24,0,[]],\"setSystem\"],null],[29,\"t\",[\"transport.placeholders.system\"],null]]],{\"statements\":[[0,\"\\n    \"],[1,[24,1,[]],false],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/transports/transport-modal/system-select/template.hbs"
    }
  });

  _exports.default = _default;
});