define("ntfrontend/components/steps/add-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dropdown'],
    taskDistributionNotifier: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    account: Ember.computed.alias('currentSession.account'),
    stepIsNotEmpty: Ember.computed.or('step.hasTasks', 'step.attachments.length', 'step.transportDocuments.length'),
    stepIsEmpty: Ember.computed.not('stepIsNotEmpty'),
    actions: {
      openTaskModal: function openTaskModal(isTaskForStep) {
        this.set('showModal', true);
        this.set('forStep', isTaskForStep);
      },
      afterTaskCreation: function afterTaskCreation(task) {
        this.set('showModal', false);
        this.taskDistributionNotifier.notify(task.get('members'), false);
      },
      focusInput: function focusInput() {
        this.set('modalJustShown', true);
      }
    }
  });

  _exports.default = _default;
});