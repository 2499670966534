define("ntfrontend/models/work-item", ["exports", "ember-data", "ember-custom-actions"], function (_exports, _emberData, _emberCustomActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    name: attr('string'),
    description: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    hoursPlanned: attr('number'),
    hoursLogged: attr('number', {
      defaultValue: 0
    }),
    hoursRemaining: attr('number', {
      defaultValue: 0
    }),
    hoursEstimated: attr('number', {
      defaultValue: 0
    }),
    variance: attr('number', {
      defaultValue: 0
    }),
    progress: attr('number', {
      defaultValue: 0
    }),
    position: attr('number', {
      defaultValue: 0
    }),
    completed: attr('boolean'),
    project: belongsTo('project'),
    workPackage: belongsTo('work-package'),
    milestone: belongsTo('milestone'),
    owner: belongsTo('user'),
    workLogs: hasMany('work-log'),
    complete: (0, _emberCustomActions.modelAction)('complete', {
      method: 'POST'
    })
  });

  _exports.default = _default;
});