define("ntfrontend/mixins/routes/show-project-pane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    paneManager: Ember.inject.service(),
    afterModel: function afterModel(model, transition) {
      this._super.apply(this, arguments);

      this.paneManager.loadProject(transition.to.queryParams.project);
    }
  });

  _exports.default = _default;
});