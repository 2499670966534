define("ntfrontend/models/search-result", ["exports", "ember-data", "ember-inflector", "ember-custom-actions"], function (_exports, _emberData, _emberInflector, _emberCustomActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    slug: attr('number'),
    name: attr(),
    resourceType: attr(),
    highlights: attr(),
    resource: belongsTo(),
    search: (0, _emberCustomActions.resourceAction)('search', {
      method: 'GET',
      responseType: 'array'
    }),
    resourceRoute: Ember.computed('resourceType', function () {
      var type = this.resourceType;
      return "".concat((0, _emberInflector.pluralize)(type), ".").concat(type).toLowerCase();
    }),
    resourceId: Ember.computed('id', function () {
      return this.id.split(':')[1];
    })
  });

  _exports.default = _default;
});