define("ntfrontend/components/work-logs/kanban-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yv2/jqmN",
    "block": "{\"symbols\":[\"board\",\"record\",\"@project\"],\"statements\":[[7,\"div\"],[11,\"class\",\"kanban-grid__wrapper\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"kanban-grid\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"boards\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[12,\"class\",[30,[\"board kanban-grid__board -flex-column \",[29,\"if\",[[29,\"eq\",[[29,\"format-date\",[[24,1,[\"date\"]]],[[\"format\"],[\"long\"]]],[29,\"format-date\",[[25,[\"todayDate\"]]],[[\"format\"],[\"long\"]]]],null],\"today-column\"],null]]]],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"kanban-grid__work-log-header\"],[9],[0,\"\\n          \"],[7,\"h3\"],[11,\"class\",\"kanban-grid__board-title\"],[9],[0,\"\\n            \"],[1,[29,\"format-date\",[[24,1,[\"date\"]]],[[\"format\"],[\"long\"]]],false],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"span\"],[11,\"class\",\"tooltip--nowrap\"],[12,\"tooltip_top\",[29,\"t\",[\"work.tooltips.addWorkLog\"],null]],[9],[0,\"\\n            \"],[5,\"works/sneak-peek/sneak-peek-button\",[],[[\"@isCreated\",\"@classNames\",\"@project\",\"@date\"],[true,\"plan-work__log-icon icon-plus-circle\",[24,3,[]],[24,1,[\"date\"]]]]],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"board-tiles kanban-grid__tiles\"],[9],[0,\"\\n\"],[4,\"each\",[[24,1,[\"records\"]]],null,{\"statements\":[[0,\"            \"],[5,\"work-logs/kanban-list/item\",[],[[\"@record\",\"@filterTimeScope\"],[[24,2,[]],[23,\"filterTimeScope\"]]]],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"kanban-grid__board-summary\"],[9],[0,\"\\n          \"],[5,\"work-logs/kanban-list/column-summary\",[],[[\"@records\",\"@filterTimeScope\"],[[24,1,[\"records\"]],[23,\"filterTimeScope\"]]]],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/work-logs/kanban-list/template.hbs"
    }
  });

  _exports.default = _default;
});