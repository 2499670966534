define("ntfrontend/components/comments/comment-list/component", ["exports", "ntfrontend/helpers/model-type-name"], function (_exports, _modelTypeName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    thread: Ember.computed.alias('threads.firstObject'),
    comments: Ember.computed('thread.comments', function () {
      return this.get('thread.comments').sortBy('createdAt').reverse();
    }),
    sortedComments: Ember.computed.filterBy('comments', 'isDeleted', false),
    modelName: Ember.computed('model', function () {
      return (0, _modelTypeName.modelTypeName)([this.model]);
    }),
    threads: Ember.computed('model.id', 'modelName', function () {
      var resourceId = this.get('model.id');
      var resourceType = this.modelName;
      var data = {
        filter: {
          parentResource: "".concat(resourceId, ",").concat(resourceType)
        },
        include: 'ability,comments.creator'
      };
      return this.store.query('thread', data);
    })
  });

  _exports.default = _default;
});