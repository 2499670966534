define("ntfrontend/helpers/model-type-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.modelTypeName = modelTypeName;
  _exports.default = void 0;

  function modelTypeName(params) {
    if (params[0] && params[0].constructor && params[0].constructor.modelName) {
      return params[0].constructor.modelName.capitalize();
    } else if (params[0] && params[0].content && params[0].content._internalModel && params[0].content._internalModel.modelName) {
      return params[0].get('_internalModel.modelName').capitalize();
    } else if (params[0] && params[0].modelName) {
      return params[0].modelName.capitalize();
    } else {
      return '';
    }
  }

  var _default = Ember.Helper.helper(modelTypeName);

  _exports.default = _default;
});