define("ntfrontend/routes/auth/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect: function redirect() {
      this._super.apply(this, arguments);

      if (this.get('currentSession.loggedIn')) {
        this.currentSession.logout();
      } else {
        this.transitionTo('auth.login');
      }
    }
  });

  _exports.default = _default;
});