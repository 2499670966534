define("ntfrontend/models/work-package", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    name: attr(),
    description: attr(),
    memosCount: attr('number'),
    overdueMemosCount: attr('number'),
    issuesCount: attr('number'),
    overdueIssuesCount: attr('number'),
    requestsCount: attr('number'),
    overdueRequestsCount: attr('number'),
    scenariosCount: attr('number'),
    overdueScenariosCount: attr('number'),
    position: attr('number'),
    owner: belongsTo('user'),
    project: belongsTo(),
    issues: hasMany(),
    requests: hasMany(),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    workItems: hasMany('work-item')
  });

  _exports.default = _default;
});