define("ntfrontend/components/projects/project-card/-header/-progress-bar/component", ["exports", "ntfrontend/components/projects/dashboard/progress-bar/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    actions: {
      redirectToWorkView: function redirectToWorkView() {
        var projectSlug = this.get('model.slug');
        var urlToRedirect = "/projects/".concat(projectSlug, "/work/plan");

        if (this.can.can('manage work in project'), this.model) {
          this.router.transitionTo(urlToRedirect);
        }
      }
    }
  });

  _exports.default = _default;
});