define("ntfrontend/components/shared/search-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['search'],
    delayBeforeQuery: 400,
    inputObserver: Ember.observer('inputValue', function () {
      Ember.run.debounce(this, this._setQuery, this.delayBeforeQuery);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('inputValue', this.value);
    },
    actions: {
      clearField: function clearField() {
        this.set('inputValue', null);
        this.set('value', null);
      }
    },
    _setQuery: function _setQuery() {
      this.set('value', this.inputValue);
    }
  });

  _exports.default = _default;
});