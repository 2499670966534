define("ntfrontend/components/tasks/task-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WVJXycUy",
    "block": "{\"symbols\":[\"task\"],\"statements\":[[0,\"\\n\"],[7,\"table\"],[11,\"class\",\"table table--tasks\"],[9],[0,\"\\n  \"],[7,\"thead\"],[9],[0,\"\\n    \"],[7,\"tr\"],[9],[0,\"\\n      \"],[5,\"shared/header-sort\",[],[[\"@label\",\"@sort\",\"@name\"],[\"task.labels.deadlineAt\",[23,\"sort\"],\"deadlineAt\"]]],[0,\"\\n      \"],[5,\"shared/header-sort\",[],[[\"@label\",\"@sort\",\"@name\"],[\"shared.labels.status\",[23,\"sort\"],\"completed\"]]],[0,\"\\n      \"],[5,\"shared/header-sort\",[],[[\"@label\",\"@sort\",\"@name\"],[\"shared.labels.owner\",[23,\"sort\"],\"owner\"]]],[0,\"\\n      \"],[5,\"shared/header-sort\",[],[[\"@label\",\"@sort\",\"@name\"],[\"shared.labels.requester\",[23,\"sort\"],\"requester\"]]],[0,\"\\n      \"],[5,\"shared/header-sort\",[],[[\"@label\",\"@sort\",\"@name\"],[\"shared.labels.task\",[23,\"sort\"],\"name\"]]],[0,\"\\n      \"],[7,\"th\"],[9],[1,[29,\"t\",[\"task.labels.taskSource\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"each\",[[25,[\"tasks\"]]],null,{\"statements\":[[0,\"    \"],[5,\"tasks/task-list/item\",[],[[\"@model\"],[[24,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"],[4,\"unless\",[[25,[\"tasks\"]]],null,{\"statements\":[[0,\"  \"],[5,\"tasks/task-list/empty-state\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/tasks/task-list/template.hbs"
    }
  });

  _exports.default = _default;
});