define("ntfrontend/routes/scenarios/scenario", ["exports", "ntfrontend/routes/org-auth", "ntfrontend/mixins/routes/document-detailed-breadcrumb", "ntfrontend/mixins/routes/show-task-pane"], function (_exports, _orgAuth, _documentDetailedBreadcrumb, _showTaskPane) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orgAuth.default.extend(_documentDetailedBreadcrumb.default, _showTaskPane.default, {
    currentSession: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      var includes = ['owner', 'creator', 'ability', 'project.ability', 'project.project-setting', 'steps.tasks.ability', 'steps.transport-documents.transport.creator'];
      var filter = {
        slug: "".concat(params.scenario_slug, ",").concat(this.get('currentSession.account.id'))
      };
      var data = {
        include: includes.join(','),
        filter: filter
      };
      return this.store.query('scenario', data).then(function (scenarios) {
        return _this.objectOrRedirect(scenarios.get('firstObject'), 'scenarios');
      });
    }
  });

  _exports.default = _default;
});