define("ntfrontend/components/tasks/filters/document-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1qYtomhd",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[11,\"class\",\"text-muted filter-name\"],[9],[0,\"\\n    \"],[1,[29,\"t\",[\"shared.labels.document\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[25,[\"model\",\"name\"]],false],[0,\" \"],[7,\"span\"],[11,\"class\",\"icon-clear\"],[9],[3,\"action\",[[24,0,[]],\"clearFilter\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/tasks/filters/document-filter/template.hbs"
    }
  });

  _exports.default = _default;
});