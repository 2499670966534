define("ntfrontend/components/accounts/account-details/document-templates/import-template/account-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V/YsjwK4",
    "block": "{\"symbols\":[\"account\"],\"statements\":[[7,\"div\"],[11,\"class\",\"dropdown\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"dropdown-toggle\"],[11,\"data-toggle\",\"dropdown\"],[11,\"aria-haspopup\",\"true\"],[11,\"aria-expanded\",\"true\"],[11,\"type\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"model\",\"name\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,[\"model\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"placeholder\"],[9],[1,[29,\"t\",[\"organization.placeholders.choose\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"shownDropdown\"]]],null,{\"statements\":[[0,\"    \"],[5,\"shared/loading-state/block\",[],[[\"@model\",\"@tagName\",\"@class\"],[[23,\"accounts\"],\"ul\",\"dropdown-menu\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[25,[\"accounts\"]]],null,{\"statements\":[[4,\"if\",[[29,\"not-eq\",[[24,1,[]],[25,[\"currentSession\",\"account\"]]],null]],null,{\"statements\":[[0,\"          \"],[7,\"li\"],[9],[7,\"a\"],[9],[1,[24,1,[\"name\"]],false],[3,\"action\",[[24,0,[]],\"changeAccount\",[24,1,[]]]],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ntfrontend/components/accounts/account-details/document-templates/import-template/account-select/template.hbs"
    }
  });

  _exports.default = _default;
});